const state = {
  user: null,
  confirmEmail: '',
  confirmUsername: '',
  forcePassUser: null,
  loginError: ''
}
const getters = {
  authUser: (state) => state.user,
  userGroups: (state) => {
    return state.user.signInUserSession?.accessToken?.payload['cognito:groups']
  },
  userToken: (state) => {
    if (state.user) return state.user.signInUserSession?.idToken?.jwtToken
  },
  tokenExp: (state) => {
    if (state.user) return state.user.signInUserSession?.idToken?.payload?.exp
  },
  forcePassUser: (state) => state.forcePassUser,
  confirmEmail: (state) => state.confirmEmail,
  confirmUsername: (state) => state.confirmUsername,
  loginError: (state) => state.loginError
}
const actions = {}
const mutations = {
  SET_AUTH_USER: (state, payload) => (state.user = payload),
  SET_USER_SESSION: (state, payload) =>
    (state.user.signInUserSession = payload),
  SET_FORCE_PASS_USER: (state, payload) => (state.forcePassUser = payload),
  SET_CONFIRM_EMAIL: (state, payload) => (state.confirmEmail = payload),
  SET_CONFIRM_USERNAME: (state, payload) => (state.confirmUsername = payload),
  SET_LOGIN_ERROR: (state, payload) => (state.loginError = payload)
}
export default {
  state,
  getters,
  actions,
  mutations
}
