<template>
  <div>
    <b-card no-body>
      <b-row no-gutters class="my-2 mx-3 align-items-center">
        <h4 class="header-title w-700 mb-0 text-light-blue text-left">
          Processing Cost
        </h4>
        <base-button
          size="sm"
          :icon="['fas', 'fa-plus']"
          variant="warning"
          class="ml-auto font-weight-bold"
          :loading="loadingProcPrice == 'proc-price-list'"
          @click.native="
            $refs.processingPriceModal.add(), (procPriceModalAction = 'add')
          "
          >Add Processing Cost</base-button
        >
      </b-row>
      <base-table-grouped
        class="mb-3"
        :fields="tableFields"
        :items="processingPriceList"
        groupBy="name"
        :groups="processingCostTypes"
        :busy="loadingProcPrice == 'proc-price-list'"
        hover
        small
        thead-class="bg-light-blue"
        :show-header="false"
        @addItem="
          $refs.processingPriceModal.add($event), (procPriceModalAction = 'add')
        "
        @editItem="editItem($event)"
        @deleteItem="deleteItem($event)"
      >
        <template #cell(effective_date)="{ value }">
          {{ value | date }}
        </template>
        <template #cell(cost)="{ value }"> {{ value | decimals3 }} </template>
        <template #cell(weight)="{ item }">
          <b-row
            no-gutters
            class="flex-column"
            v-if="weightRequiredCostTypes.includes(item.name)"
          >
            <div>Min: {{ item.minweight }}</div>
            <div>Max: {{ item.maxweight }}</div>
          </b-row>
          <div v-else>-</div>
        </template>
      </base-table-grouped>
    </b-card>
    <!-- Modals -->
    <new-proc-price-modal
      ref="processingPriceModal"
      :type="procPriceModalAction"
    />
  </div>
</template>

<script>
import NewProcPriceModal from '../components/NewProcPriceModal.vue'
import BaseTableGrouped from '../components/BaseTableGrouped.vue'
import BaseButton from '../components/BaseButton.vue'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ParPriceList',
  components: {
    NewProcPriceModal,
    BaseTableGrouped,
    BaseButton
  },
  data() {
    return {
      procPriceModalAction: 'add',
      tableFields: [
        {
          key: 'effective_date',
          label: 'Effective Date',
          thClass: ['text-left', 'pl-3'],
          tdClass: ['text-left', 'pl-3']
        },
        {
          key: 'name',
          label: 'Category',
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          key: 'category',
          label: 'Section',
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          key: 'cost',
          label: 'Price ($)',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'weight',
          label: 'Weight (kg)',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'unit',
          label: 'Unit',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'description',
          label: 'Description',
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          key: 'actions',
          label: '',
          thClass: ['text-left'],
          tdClass: 'text-left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'processingPriceList',
      'loadingProcPrice',
      'processingCostTypes',
      'weightRequiredCostTypes'
    ])
  },
  methods: {
    ...mapActions(['fetchProcPrice', 'deleteProcPrice']),
    deletePrice: async function (info) {
      try {
        let res = await this.deleteProcPrice({ vm: this, payload: info })
        if (res === 'success') {
          this.fetchProcPrice({ vm: this })
        }
      } catch (err) {
        console.log(err)
      }
    },
    editItem: function (item) {
      this.procPriceModalAction = 'edit'
      this.$refs.processingPriceModal.edit(item)
    },
    deleteItem: function (item) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete ${item.name} ${item.category} Cost ($${item.cost}) for ${item.effective_date}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then(async (value) => {
          if (value) {
            this.deletePrice(item)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  async mounted() {
    this.fetchProcPrice({ vm: this })
  }
}
</script>
