<template>
  <div class="container-fluid">
    <div class="wrapper">
      <side-filter
        :filterList="filtersProcCost"
        @inputWeek="updateWeekFilter"
        @inputYear="updateYearFilter"
        ref="procCostSideFilter"
      />
      <div class="content-page">
        <b-row no-gutters class="my-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Processing Costs</h4>
          </div>
          <b-button
            @click="prepareForExport()"
            size="sm"
            variant="warning"
            class="ml-auto"
          >
            <b-spinner
              v-if="generatingPDF === 'processing-cost-summary'"
              small
              class="mr-2"
            ></b-spinner>
            <font-awesome-icon
              v-else
              icon="fas fa-file-export"
              class="mr-2"
            />Export</b-button
          >
        </b-row>

        <!-- ---------------- Summary section ------------------- -->
        <b-card
          class="mb-3"
          v-if="loadingProcCost != 'list' && missingCostTypeError == ''"
        >
          <b-row
            no-gutters
            class="align-items-baseline justify-content-between"
          >
            <b-col cols="12" class="d-flex flex-column align-items-baseline">
              <h4 class="header-title mb-3 w-700 text-light-blue text-center">
                Processing Costs for week
                {{ filtersProcCost.week.value }}
              </h4>
            </b-col>
          </b-row>

          <b-table-simple
            small
            hover
            responsive
            class="text-left mt-2"
            id="processing-cost-summary"
          >
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Description</b-th>
                <b-th class="text-right">Quantity</b-th>
                <b-th class="text-center">Unit</b-th>
                <b-th class="text-right">Unit Price ($)</b-th>
                <b-th class="text-right">Amount excl GST ($)</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr class="bg-light">
                <b-td colspan="5"><b>Slaughter</b></b-td>
              </b-tr>
              <b-tr v-if="procCostList.SUK.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.SUK.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.SUK.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.SUK.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.SUK.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.SUK.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.BP.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.BP.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.BP.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.BP.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.BP.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.BP.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.SP.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.SP.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.SP.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.SP.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.SP.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.SP.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.XSMALL.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.XSMALL.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.XSMALL.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.XSMALL.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.XSMALL.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.XSMALL.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.SMALL.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.SMALL.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.SMALL.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.SMALL.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.SMALL.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.SMALL.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.MEDIUM.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.MEDIUM.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.MEDIUM.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.MEDIUM.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.MEDIUM.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.MEDIUM.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.LARGE.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.LARGE.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.LARGE.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.LARGE.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.LARGE.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.LARGE.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr v-if="procCostList.XLARGE.qty > 0">
                <b-td class="text-nowrap pl-3">{{
                  procCostList.XLARGE.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.XLARGE.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.XLARGE.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.XLARGE.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.XLARGE.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-nowrap pl-3">{{
                  procCostList.GRADING.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.GRADING.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.GRADING.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.GRADING.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.GRADING.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr class="bg-light">
                <b-td colspan="1" class="text-left"><b>Totals</b></b-td>
                <b-td colspan="4" class="text-right"
                  ><b>${{ totalSlaughter() | decimals2 }}</b></b-td
                >
              </b-tr>
              <b-tr class="bg-light">
                <b-td colspan="5"><b>Government Levies</b></b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-nowrap pl-3">{{
                  procCostList.LEVY.config.description
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.LEVY.qty | decimals2
                }}</b-td>
                <b-td class="text-center">{{
                  procCostList.LEVY.config.unit
                }}</b-td>
                <b-td class="text-nowrap text-right">{{
                  procCostList.LEVY.config.cost | decimals3
                }}</b-td>
                <b-td class="text-right">{{
                  procCostList.LEVY.total | decimals2
                }}</b-td>
              </b-tr>
              <b-tr class="">
                <b-td colspan="5" class="text-right"
                  ><b
                    >TOTAL (Excl. GST): ${{ grandTotal() | decimals2 }}</b
                  ></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>

        <b-card v-else-if="loadingProcCost == 'list'">
          <div class="text-center text-danger my-2">
            <b-spinner small class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </b-card>
        <b-card v-else>
          <div class="text-center text-danger my-2">
            <div class="mb-3">{{ missingCostTypeError }}</div>
            <b-btn @click="$router.push('system-settings')" variant="danger"
              >Create new Cost</b-btn
            >
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { pdfUtilsMixin } from '../mixins/pdfUtils'
import SideFilter from '../components/SideFilter.vue'
import { PIG_INFO } from '@/constants/pagination'

export default {
  mixins: [pdfUtilsMixin],
  components: {
    SideFilter
  },
  name: 'ProcessingCostsView',
  data() {
    return {
      generatingPDF: ''
    }
  },
  computed: {
    ...mapGetters([
      'procCostList',
      'loadingProcCost',
      'filtersProcCost',
      'missingCostTypeError'
    ])
  },
  methods: {
    ...mapActions(['fetchProcCost', 'fetchProcPrice']),
    ...mapMutations([
      'SET_FILTER_PROC_COST',
      'SET_ACTIVE',
      'SET_USING_VUETIFY'
    ]),
    totalSlaughter: function () {
      const total =
        this.procCostList.SUK.total +
        this.procCostList.SP.total +
        this.procCostList.BP.total +
        this.procCostList.XSMALL.total +
        this.procCostList.SMALL.total +
        this.procCostList.MEDIUM.total +
        this.procCostList.LARGE.total +
        this.procCostList.XLARGE.total +
        this.procCostList.GRADING.total
      return total
    },
    grandTotal: function () {
      const total = this.totalSlaughter() + this.procCostList.LEVY.total
      return total
    },
    prepareForExport: async function () {
      const titleDatePart = this.filtersProcCost.week.value
      let dateTitle = await this.createTitle(
        `PROCESSING COST SUMMARY - WEEK ${titleDatePart}`
      )
      this.SharedExportToPDF({
        tableID: 'processing-cost-summary',
        splitByOne: 25,
        splitBy: 31,
        orientation: 'portrait',
        fileName: `Processing-cost[week-${titleDatePart}]`,
        title: dateTitle
      })
    },
    updateWeekFilter: async function (dateRange) {
      await Promise.all([
        this.SET_FILTER_PROC_COST({ field: 'week', value: dateRange.weekNr }),
        this.SET_FILTER_PROC_COST({
          field: 'date_start',
          value: dateRange.startDay
        }),
        this.SET_FILTER_PROC_COST({
          field: 'date_end',
          value: dateRange.endDay
        })
      ])
      if (this.filtersProcCost.year.value != dateRange.year) {
        this.SET_FILTER_PROC_COST({ field: 'year', value: dateRange.year })
      }
      this.fetchProcCost({ vm: this })
    },
    updateYearFilter: async function ({ year }) {
      const cleanYear = parseInt(year.trim())
      if (cleanYear == this.filtersProcCost.year.value) return
      this.SET_FILTER_PROC_COST({ field: 'year', value: cleanYear })
      if (!this.filtersProcCost.week.value) return
      this.$refs.procCostSideFilter.generateWeekDays(
        this.filtersProcCost.week.value,
        cleanYear
      )
    }
  },
  async created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE(PIG_INFO)
    this.fetchProcCost({ vm: this })
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
  line-height: 1.1;
}
</style>
