<template>
  <b-pagination
    v-model="currentPage"
    :total-rows="total"
    :per-page="perPage"
    first-text="First"
    prev-text="Prev"
    next-text="Next"
    last-text="Last"
    class="mb-0"
  ></b-pagination>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    total: Number,
    perPage: Number,
    current: Number
  },
  computed: {
    currentPage: {
      get() {
        return this.current
      },
      // setter
      set(newValue) {
        this.$emit('updatePage', newValue)
      }
    }
  }
}
</script>

<style></style>
