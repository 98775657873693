<script setup>
import SelectFreight from '@/components/SelectFreight.vue'

import {
  reactive,
  defineProps,
  computed,
  ref,
  getCurrentInstance,
  defineExpose
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'

defineProps({
  type: { type: String, default: 'edit' }
})
const store = useStore()
const vm = getCurrentInstance().proxy

const summary = reactive({
  id: null,
  delivery_co_ids: 0
})
const tempInfo = ref(null)
const loadingTransport = computed(() => store.getters.loadingTransport)

const actionString = computed(() => {
  if (!tempInfo.value || !tempInfo.value.destination?.name) return ''
  return `Update Transport for ${tempInfo.value.destination.name}`
})

const setValues = (val) => {
  summary.delivery_co_ids = val
}
const onSubmit = async (event) => {
  event.preventDefault()
  let copy = JSON.parse(JSON.stringify(summary))
  copy.delivery_co_ids = copy.delivery_co_ids.join(',')
  const res = await store.dispatch('updateTransport', {
    payload: copy,
    vm
  })
  if (res !== 'error') {
    store.dispatch('fetchTransport', { vm })
    vm.$bvModal.hide('transport-dialog')
  }
}
const onReset = (event) => {
  event.preventDefault()
  restoreValues()
}
const edit = (info) => {
  tempInfo.value = info
  summary.delivery_co_ids = info.delivery_co_ids
  summary.id = info.id
  restoreValues()
  vm.$bvModal.show('transport-dialog')
}
const restoreValues = () => {
  tempInfo.value.delivery_co_ids.trim() == ''
    ? (summary.delivery_co_ids = [])
    : (summary.delivery_co_ids = tempInfo.value.delivery_co_ids.split(','))
}

defineExpose({ edit })
</script>

<template>
  <b-modal id="transport-dialog" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <select-freight
        :freight="summary.delivery_co_ids"
        :label="'Transport:'"
        @input="(val) => setValues(val)"
      />
      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingTransport == 'update'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<style></style>
