<template>
  <div id="app">
    <nav-bar v-if="authUser" />
    <router-view />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    NavBar
  },
  computed: mapGetters(['authUser'])
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h6 {
  font-weight: 700 !important;
}
.small,
small {
  font-size: 0.75rem !important;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}
.w-700 {
  font-weight: 700;
}
.card {
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}
.nav-link {
  font-size: 0.9375rem;
  position: relative;
  padding: 1rem 1.3rem;
  color: rgba(255, 255, 255, 0.55) !important;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.nav-link:hover {
  color: rgba(255, 255, 255, 0.75) !important;
}
.nav-tabs .nav-link {
  padding: 0.5rem 1rem;
}
.dropdown-toggle.active {
  color: #fff !important;
}
.pointer {
  cursor: pointer;
}

@media (min-width: 1400px) {
  .container-fluid {
    max-width: 85%;
  }
}
.container-fluid {
  padding-right: 24px;
  padding-left: 24px;
}
.leftside-menu {
  width: 260px;
  position: relative;
  bottom: 0;
  top: 0;
  background: #fff !important;
  min-width: 260px;
  max-width: 260px;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  margin-top: 30px;
  padding-top: 0 !important;
}
.content-page {
  margin-top: 30px;
}
.page-title-box .page-title,
.page-title {
  font-size: 18px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
  font-weight: 700;
}

.bg-light-blue {
  background-color: #e4eff9 !important;
}
.bg-dark-blue {
  background-color: #4472c4 !important;
}
.text-light-blue {
  color: #4685c1;
}
.bg-light-grey {
  background-color: #eef2f7;
}
.hover-blue:hover {
  color: #4685c1;
}
.hover-red:hover {
  color: #dc3545;
}
.icon-width {
  min-width: 20px;
}

#pig-info-tab-list .nav-link,
#stock-tab-list .nav-link {
  color: #495057 !important;
}
#pig-info-tab-list .nav-link.active,
#stock-tab-list .nav-link.active {
  color: #097aeb !important;
}

/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.7 !important;
}

.max-w-actions {
  max-width: 45px;
}
.fit-content {
  min-width: fit-content;
}
</style>
