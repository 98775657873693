<script setup>
import SideFilter from '@/components/SideFilter.vue'

import { computed, ref } from 'vue'
import useWeekDateFilter from '@/utils/useWeekDateFilter'
import { useStore } from '@/utils/VuexHelpers'
import { DATE_START_STR, DATE_END_STR, WEEK_STR } from '@/constants/general'

const store = useStore()
const filtersOrders = computed(() => store.getters.filtersOrders)
const ordersSideFilter = ref(null)

const { updateDateFilter } = useWeekDateFilter(
  'filtersOrders',
  'SET_FILTER_ORDERS'
)

const removeFilters = async () => {
  await store.dispatch('clearFiltersOrders')
  store.dispatch('fetchOrders')
}
const updateFilter = ({ value, field }) => {
  const fields = ['customer_name']
  if (value && fields.includes(field)) {
    value = value.split(' - ').slice(1).join(' - ')
  }
  if (field === DATE_START_STR || field === DATE_END_STR) {
    updateDateFilter({ value, field })
  } else {
    store.commit('SET_FILTER_ORDERS', { value, field })
  }
  store.dispatch('fetchOrders')
}
const updateWeekFilter = (dateRange) => {
  store.commit('SET_FILTER_ORDERS', {
    field: WEEK_STR,
    value: dateRange.weekNr
  })
  store.commit('SET_FILTER_ORDERS', {
    field: DATE_START_STR,
    value: dateRange.startDay
  })
  store.commit('SET_FILTER_ORDERS', {
    field: DATE_END_STR,
    value: dateRange.endDay
  })
  if (filtersOrders.value.year.value != dateRange.year) {
    store.commit('SET_FILTER_ORDERS', { field: 'year', value: dateRange.year })
  }

  store.dispatch('fetchOrders')
}
const updateYearFilter = ({ year: inputYear }) => {
  const { year, week } = filtersOrders.value
  const cleanYear = parseInt(inputYear.trim())

  if (cleanYear == year.value) return
  store.commit('SET_FILTER_ORDERS', { field: 'year', value: cleanYear })
  if (!week.value) return
  ordersSideFilter.value.generateWeekDays(week.value, cleanYear)
}
</script>

<template>
  <SideFilter
    :filterList="filtersOrders"
    @input="updateFilter"
    @inputWeek="updateWeekFilter"
    @inputYear="updateYearFilter"
    @clear="removeFilters()"
    ref="ordersSideFilter"
    class="mt-0"
  />
</template>
