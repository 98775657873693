<template>
  <div>
    <b-card
      v-if="
        loadingSettings == `charges-${grower.company_id}` ||
        loadingSuppliers == `update-${grower.id}`
      "
      class="mb-3"
    >
      <div class="text-center text-danger my-2">
        <b-spinner small class="align-middle mr-2"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-card>
    <b-card v-else>
      <b-row
        class="justify-content-end align-items-center flex-column my-5"
        no-gutters
        v-if="isInGrid(grower)"
      >
        <span class="mb-3"
          >{{ grower.company.name }} is using pricing from Grower Grid</span
        >
        <b-btn size="sm" @click="toggleGrid(false)"
          ><font-awesome-icon :icon="['fas', 'times']" class="mr-2" />Disable
          Grid Pricing</b-btn
        >
      </b-row>
      <div v-else>
        <b-row no-gutters class="justify-content-end mb-2"
          ><b-btn
            size="sm"
            class="mr-2"
            variant="success"
            @click="toggleGrid(true)"
            ><font-awesome-icon :icon="['fas', 'check']" class="mr-2" />Enable
            Grid Pricing</b-btn
          ><b-button
            variant="warning"
            size="sm"
            @click="$refs.parPriceModal.add(), (pricingAction = 'add')"
            ><font-awesome-icon icon="fas fa-plus" class="mr-2" />Add PAR
            Price</b-button
          ></b-row
        >
        <b-table-simple
          :id="`grow-par-price-${grower.code}`"
          small
          responsive
          striped
        >
          <b-thead>
            <b-tr class="bg-light-blue text-center">
              <b-th class="v-align-center">Effective Date</b-th>
              <b-th class="v-align-center">Category</b-th>
              <b-th class="v-align-center">Applied To</b-th>
              <b-th class="v-align-center">Penalty Rates</b-th>
              <b-th class="v-align-center">Price ($)</b-th>
              <b-th class="v-align-center">Actions</b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="loadingSettings == `par-price-${grower.company_id}`">
            <b-tr>
              <b-td colspan="8"
                ><div class="text-center text-danger my-2">
                  <b-spinner small class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div></b-td
              ></b-tr
            >
          </b-tbody>
          <b-tbody v-else>
            <b-tr
              v-for="(value, key) in growerParPrice[grower.company_id]"
              :key="key"
              class="text-center"
            >
              <b-td>{{ value.effective_date }}</b-td>
              <b-td>{{ value.category }}</b-td>
              <b-td>{{
                value.applied_to == 'Default' ? 'All' : grower.code
              }}</b-td>
              <b-td v-if="value.penaltyrules.length == 0">-</b-td>
              <b-td v-else>
                <b-row
                  v-for="(penalty, id) in value.penaltyrules"
                  :key="id"
                  class="penalty-row font-italic justify-content-center"
                  no-gutters
                >
                  <div class="mr-2">
                    <b class="mr-2 text-muted">Starting P2:</b
                    >{{ penalty.p2.start }}
                  </div>
                  <div class="mr-2">
                    <b class="mr-2 text-muted">Starting Weight:</b
                    >{{ penalty.weight.start | decimals2 }}kg
                  </div>
                  <div class="mr-2">
                    <b class="mr-2 text-muted">Value:</b>${{
                      penalty.value | decimals2
                    }}
                  </div>
                </b-row>
              </b-td>
              <b-td>{{ value.value | decimals2 }}</b-td>
              <b-td>
                <icon-delete
                  @click.native="deletePrice(value)"
                  :position="'left'"
                  class="mr-2"
                />
                <icon-edit
                  :position="'bottom'"
                  @click.native="
                    $refs.parPriceModal.edit(value), (pricingAction = 'edit')
                  "
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-card>
    <new-par-price-modal
      ref="parPriceModal"
      :type="pricingAction"
      :grower="grower"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import IconDelete from '../components/IconDelete.vue'
import IconEdit from '../components/IconEdit.vue'
import NewParPriceModal from '../components/NewParPriceModal.vue'

export default {
  name: 'GrowerPricing',
  components: {
    NewParPriceModal,
    IconDelete,
    IconEdit
  },
  props: {
    grower: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      pricingAction: 'add'
    }
  },
  computed: {
    ...mapGetters(['loadingSettings', 'loadingSuppliers', 'growerParPrice'])
  },
  methods: {
    ...mapActions(['updateSupplier', 'fetchGrowerParPrice', 'deleteParPrice']),
    isInGrid: function (item) {
      if (item.extra?.ingrid == true) return true
      return false
    },
    toggleGrid: function (state) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to ${
            state ? 'ENABLE' : 'DISABLE'
          } Grower Grid pricing for ${this.grower.code}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then((value) => {
          if (value) {
            const payload = {
              extra: { ingrid: state },
              id: this.grower.id
            }
            this.updateSupplier({
              vm: this,
              payload: payload,
              index: this.rowIndex
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deletePrice: async function (info) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete PAR Price for ${this.grower.code}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then(async (value) => {
          if (value) {
            const res = await this.deleteParPrice({ vm: this, payload: info })
            if (res === 'success') {
              this.fetchGrowerParPrice({
                vm: this,
                grower: String(this.grower.company_id)
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  mounted() {
    if (!this.isInGrid(this.grower)) {
      this.fetchGrowerParPrice({
        vm: this,
        grower: String(this.grower.company_id)
      })
    }
  }
}
</script>

<style scoped>
.v-align-center {
  vertical-align: middle;
}
.penalty-row:not(:last-child) {
  border-bottom: 1px #ced4da solid;
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
}
</style>
