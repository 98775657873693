<template>
  <div class="container-fluid">
    <div class="wrapper">
      <side-filter
        :filterList="filtersStock"
        @input="updateFilter"
        @inputWeek="updateWeekFilter"
        @inputYear="updateYearFilter"
        @clear="removeFilters()"
        ref="stockSideFilter"
      />
      <div class="content-page">
        <b-row no-gutters class="my-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Product Stock</h4>
          </div>
        </b-row>
        <b-card no-body>
          <b-tabs id="stock-tab-list" card @activate-tab="toggleTabs">
            <b-tab
              title="All Stock"
              :active.sync="activeTab.tab0"
              title-item-class="text-dark"
            >
              <template #title>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    icon="fas fa-box-open"
                    size="sm"
                    class="mr-2 text-warning"
                  />
                  All Stock
                </div>
              </template>
              <product-stock-list />
            </b-tab>
            <b-tab :active.sync="activeTab.tab1" lazy class="summary-tab">
              <template #title>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    :icon="['far', 'file-alt']"
                    size="sm"
                    class="mr-2 text-success"
                  />Summary
                </div>
              </template>
              <product-stock-summary />
            </b-tab>
            <b-tab :active.sync="activeTab.tab2" lazy>
              <template #title>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    :icon="['fas', 'barcode']"
                    size="sm"
                    class="mr-2 text-dark"
                  />Scanner
                </div>
              </template>
              <product-scanner :currentIndex="activeTab.tab2"
            /></b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SideFilter from '../components/SideFilter.vue'
import ProductScanner from '../components/ProductScanner.vue'
import ProductStockList from '../components/ProductStockList.vue'
import ProductStockSummary from '../components/ProductStockSummary.vue'

export default {
  components: {
    SideFilter,
    ProductScanner,
    ProductStockList,
    ProductStockSummary
  },
  name: 'ProductStockView',
  data() {
    return {
      activeTab: {
        tab0: true,
        tab1: false,
        tab2: false,
        tab3: false,
        tab4: false
      }
    }
  },
  computed: {
    ...mapGetters(['filtersStock'])
  },
  methods: {
    ...mapActions(['fetchStock', 'clearFiltersStock']),
    ...mapMutations(['SET_FILTER_STOCK', 'SET_ACTIVE', 'SET_USING_VUETIFY']),

    updateWeekFilter: async function (dateRange) {
      await Promise.all([
        this.SET_FILTER_STOCK({ field: 'week', value: dateRange.weekNr }),
        this.SET_FILTER_STOCK({
          field: 'prod_date_start',
          value: dateRange.startDay
        }),
        this.SET_FILTER_STOCK({
          field: 'prod_date_end',
          value: dateRange.endDay
        })
      ])
      if (this.filtersStock.year.value != dateRange.year) {
        this.SET_FILTER_STOCK({ field: 'year', value: dateRange.year })
      }
      this.fetchStock({ vm: this })
    },
    updateYearFilter: async function ({ year }) {
      const cleanYear = parseInt(year.trim())
      if (cleanYear == this.filtersStock.year.value) return
      await this.SET_FILTER_STOCK({ field: 'year', value: cleanYear })
      if (!this.filtersStock.week.value) return
      this.$refs.stockSideFilter.generateWeekDays(
        this.filtersStock.week.value,
        cleanYear
      )
    },
    updateFilter: async function (value) {
      this.SET_FILTER_STOCK(value)
      this.fetchStock({ vm: this })
    },
    removeFilters: async function () {
      await this.clearFiltersStock()
      this.fetchStock({ vm: this })
    },
    toggleTabs: function (id) {
      if (this.$route.query.tab == id) return
      setTimeout(() => {
        this.routerReplace(id)
      }, 200)
    },
    routerReplace(id) {
      this.$router.replace({ name: 'productStock', query: { tab: id } })
    }
  },
  created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE('products')
  },
  mounted() {
    if (this.$route.query.tab) {
      let tab = this.$route.query.tab
      this.activeTab[`tab${tab}`] = true
    }
  },
  watch: {
    $route(value) {
      const id = value.query.tab
      if (this.activeTab[`tab${id}`] !== true) {
        this.activeTab[`tab${id}`] = true
      }
    }
  }
}
</script>

<style>
.summary-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
