<script setup>
import PaginationBase from '../components/PaginationBase.vue'
import ProductFormDialog from '@/components/products-page/ProductFormDialog.vue'
import BaseAlert from '@/components/base/BaseAlert.vue'
import ProductsFilter from '@/components/products-page/ProductsFilter.vue'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm.vue'

import { formatCurrency } from '@/helpers/stringHelpers'
import { useStore } from '@/utils/VuexHelpers'
import { computed } from 'vue'
import BaseStatusChip from '@/components/base/BaseStatusChip.vue'

const store = useStore()
const productList = computed(() => store.getters.products)
const sortbyProd = computed(() => store.getters.sortbyProd)
const loadingProducts = computed(() => store.getters.loadingProducts)
const ascProd = computed(() => !store.getters.ascProd)
const perPageProducts = computed(() => store.getters.perPageProducts)
const totalProducts = computed(() => store.getters.totalProducts)
const pageNumberProd = computed(() => store.getters.pageNumberProd)
const perPageOptions = computed(() => store.getters.perPageOptions)

const headers = [
  { value: 'product', text: 'Name', sortable: true },
  { value: 'code', text: 'Code', sortable: true },
  { value: 'type', text: 'Type', sortable: true },
  { value: 'pack', text: 'Pack', sortable: true },
  { value: 'soaker', text: 'Soaker Insert', sortable: false },
  { value: 'PCS', text: 'PCS', sortable: false },
  { value: 'cnt_base', text: 'Cnt Base', sortable: false },
  { value: 'ref_status', text: 'Ref Status', sortable: false },
  {
    value: 'est_yield',
    text: 'Est Yield',
    cellClass: 'text-end',
    class: 'text-end',
    sortable: false
  },
  {
    value: 'price_kg',
    text: 'Price/Kg',
    cellClass: 'text-end',
    class: 'text-end',
    sortable: true
  },
  { value: 'comment', text: 'Comment', sortable: false },
  { value: 'actions', text: 'Actions', sortable: false }
]

const perPage = computed({
  get: () => perPageProducts.value,
  set: (value) => {
    store.commit('SET_PER_PAGE_PROD', value)
    store.dispatch('fetchProducts')
  }
})

const deleteProd = async (info) => {
  let res = await store.dispatch('deleteProduct', { payload: info })
  if (res === 'success') {
    store.dispatch('fetchProducts')
  }
}

const sortChange = ({ sortBy, sortDesc }) => {
  store.commit('SET_ASC_PROD', !sortDesc[0])
  store.commit('SET_SORT_BY_PROD', sortBy[0])
  store.dispatch('fetchProducts')
}
const updatePageNr = (nr) => {
  store.commit('SET_PAGE_NR_PROD', nr - 1)
  store.dispatch('fetchProducts')
}

// ON CREATED
store.commit('SET_USING_VUETIFY', true)
store.commit('SET_ACTIVE', 'products')
</script>

<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <ProductsFilter />
        <div class="content-page mt-0">
          <v-row no-gutters class="my-2 align-center">
            <div class="page-title-box d-flex">
              <h4 class="page-title">Products</h4>
            </div>
          </v-row>
          <v-row class="mt-1 mb-4 align-center" no-gutters>
            <ProductFormDialog />
            <div class="d-flex align-center">
              Total Products:
              <span class="font-weight-bold ml-2">{{ totalProducts }}</span>
            </div>
            <div class="ms-auto mr-2 d-flex align-center">
              Products per page:
            </div>
            <b-select
              v-model="perPage"
              :options="perPageOptions"
              class="d-inline-block w-auto mr-4"
            ></b-select>
            <PaginationBase
              :total="totalProducts"
              :perPage="perPageProducts"
              :current="pageNumberProd + 1"
              @updatePage="updatePageNr"
            />
          </v-row>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="productList"
              item-key="id"
              :sort-by="sortbyProd"
              :sort-desc="ascProd"
              @update:options="sortChange"
              class="elevation-1"
              :height="productList.length > 15 ? 800 : ''"
              :fixed-header="productList.length > 15"
              :loading="loadingProducts === 'list'"
              loading-text="Loading... Please wait"
              :server-items-length="perPageProducts"
              hide-default-footer
            >
              <template v-slot:item.code="{ item }">
                <div>{{ item.code }}</div>
                <div v-if="item.barcode" class="text-nowrap">
                  GS1: {{ item.barcode }}
                </div>
              </template>
              <template v-slot:item.price_kg="{ value }">
                {{ formatCurrency(value) }}
              </template>
              <template v-slot:item.soaker="{ item }">
                <BaseStatusChip
                  v-if="item.soaker"
                  :label="'Soaker'"
                  color="blue lighten-1"
                />
                <BaseStatusChip
                  v-if="item.insert"
                  :label="'Insert'"
                  color="teal darken-1"
                />
              </template>
              <template v-slot:item.PCS="{ item }">
                <div>Bag: {{ item.pcs_bag }}</div>
                <div>Ctn: {{ item.pcs_ctn }}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-row class="text-nowrap align-center flex-nowrap" no-gutters>
                  <BaseDeleteConfirm
                    :icon="true"
                    size="small"
                    title="Are you sure you want to delete this product?"
                    @deleteConfirm="deleteProd(item)"
                  >
                    <template v-slot:content>
                      <div class="text-subtitle-2 mt-2">Product Name:</div>
                      <div class="font-weight-bold">{{ item.product }}</div>
                      <div class="text-subtitle-2 mt-2">Product Code:</div>
                      <div class="font-weight-bold">{{ item.code }}</div>
                    </template>
                  </BaseDeleteConfirm>
                  <ProductFormDialog :edit="true" :details="item" />
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>
