<template>
  <b-row no-gutters class="mb-2" :class="rootClass">
    <label for="freight-select" v-if="label !== ''"
      >{{ label }}<span class="text-danger">*</span></label
    >
    <vue-select
      multiple
      id="freight-select"
      placeholder="Select Freight Company ..."
      :get-option-label="
        ({ code }) => {
          return code
        }
      "
      :options="freightNames"
      :reduce="(freight) => freight.company_id"
      v-model="frei"
      class="w-100"
      ><template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="freight.length == 0"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template #option="{ name, code }">
        {{ code ? `${code} - ` : '' }}{{ name }}<br />
      </template>
    </vue-select>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SelectFreight',
  props: {
    freight: Array,
    rootClass: { type: String, default: '' },
    label: {
      type: String,
      default: 'Freight Company'
    }
  },
  computed: {
    ...mapGetters(['freightNames']),
    frei: {
      get() {
        return this.freight
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    ...mapActions(['fetchFreightNames'])
  },
  async created() {
    if (this.freightNames.length == 0) {
      this.fetchFreightNames({ vm: this })
    }
  }
}
</script>

<style></style>
