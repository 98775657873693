<script setup>
import BaseDynamicForm from '../base/BaseDynamicForm.vue'
import { ref, defineProps, computed, set } from 'vue'
import { formatCurrency, formatDecimals } from '@/helpers/stringHelpers'
import { useStore } from '@/utils/VuexHelpers'

const props = defineProps(['details'])

const store = useStore()
const loadingGrowerReport = computed(() => store.getters.loadingGrowerReport)
const dialog = ref(false)
const pigPrice = computed(() => {
  const value =
    props.details.value.customPrice === undefined
      ? formatDecimals(
          props.details.value.value / props.details.specification.HSCW
        )
      : props.details.value.customPrice
  return value
})
const formHeader = computed(
  () => `Update Price per Kilogram - Pig ${props.details.body_no}`
)

const fields = ref([
  {
    key: 'price',
    label: 'Price per Kilogram',
    value: pigPrice.value,
    type: 'number',
    min: 0,
    step: 0.01,
    rules: [
      (v) => !!v || 'Price is required',
      (v) => v >= 0 || 'Price must be more or equal to $0'
    ],
    placeholder: 'Update price',
    prefix: '$'
  }
])

const handleFormSubmit = async ({ price }) => {
  const { id, value, specification, body_no } = props.details

  const customValue = specification.HSCW * parseFloat(price)
  const customPrice = parseFloat(price)

  const payload = {
    id,
    body_no,
    value: {
      customValue,
      customPrice
    }
  }
  const res = await store.dispatch('updateGrowerReport', { payload })
  if (res !== 'error') {
    dialog.value = false
    set(value, 'customValue', customValue)
    set(value, 'customPrice', customPrice)
    fields.value[0].value = customPrice
    store.dispatch('adjustPriceValues', res)
  }
}
</script>

<template>
  <div>
    <v-hover v-slot="{ hover }"
      ><span class="p-relative" role="button" @click.stop="dialog = true">
        <span :class="hover ? 'text-decoration-underline' : ''">{{
          formatCurrency(pigPrice)
        }}</span>
        <v-btn
          icon
          small
          color="primary"
          class="p-center"
          :class="hover ? '' : 'd-none'"
        >
          <v-icon small>mdi-square-edit-outline</v-icon>
        </v-btn>
      </span></v-hover
    >
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
        <v-card-text class="pt-4">
          <h6 class="text-button text-light-blue text-start">
            {{ formHeader }}
          </h6>
          <BaseDynamicForm
            :formFields="fields"
            @submit="handleFormSubmit"
            :loading="loadingGrowerReport === `update-${details.id}`"
            :resetOnInputChange="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.p-center {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-90%, -50%);
}
</style>
