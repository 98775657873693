<script setup>
import { ref, defineProps, watch, computed } from 'vue'
import BaseDynamicForm from '@/components/base/BaseDynamicForm.vue'
import { useStore } from '@/utils/VuexHelpers'

const props = defineProps(['details'])
const store = useStore()

const boningWeekDay = computed(
  () => store.getters.filtersBone.weekDay.value.day
)
const loadingBone = computed(() => store.getters.loadingBone)
const isEditForm = computed(() => props.details.id)
const dialog = ref(false)
const fields = ref([
  {
    key: 'boning_date',
    label: 'Boning Date',
    value: '',
    type: 'date',
    rules: [(v) => !!v || 'Start date is required'],
    placeholder: 'Choose processing date start',
    class: 'mb-6'
  },
  {
    key: 'start_date',
    label: 'Processing Start Date',
    value: '',
    type: 'date',
    rules: [(v) => !!v || 'Start date is required'],
    placeholder: 'Choose processing date start'
  },
  {
    key: 'end_date',
    label: 'Processing End Date',
    value: '',
    type: 'date',
    rules: [(v) => !!v || 'Start date is required'],
    placeholder: 'Choose processing date start'
  }
])

const handleFormSubmit = async (formData) => {
  const payload = { ...formData }
  let action = 'createBoningInstructions'
  if (isEditForm.value) {
    payload.id = props.details.id
    action = 'updateBoningInstructions'
  }
  const res = await store.dispatch(action, {
    payload,
    onSuccess: 'insert'
  })
  if (!isEditForm.value) {
    store.dispatch('fetchBoningList')
  }
  if (res === 'success') {
    dialog.value = false
  }
}
watch(
  () => boningWeekDay.value,
  () => {
    fields.value[0].value = props.details.boning_date
    fields.value[1].value = props.details.start_date
    fields.value[2].value = props.details.end_date
  },
  { deep: true, immediate: true }
)
</script>
<template>
  <div>
    <v-btn
      v-if="details.id"
      @click.stop="dialog = true"
      color="info"
      class="my-1 mr-2"
      small
      text
    >
      <font-awesome-icon :icon="['fas', 'edit']" class="me-2" />Edit
    </v-btn>
    <v-btn v-else @click.stop="dialog = true" color="info" class="my-1">
      <font-awesome-icon :icon="['fas', 'bone']" class="me-2" />Generate Boning
      Instructions
    </v-btn>
    <v-dialog v-model="dialog" scrollable max-width="1200px">
      <v-card>
        <v-card-text class="pt-4">
          <h6 class="text-button text-start text-light-blue">
            {{ `${isEditForm ? 'Update' : 'New'} Boning Instructions` }}
          </h6>
          <BaseDynamicForm
            :formFields="fields"
            :resetOnInputChange="true"
            :loading="loadingBone === 'create'"
            @submit="handleFormSubmit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.p-center {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-90%, -50%);
}
</style>
