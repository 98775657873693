<template>
  <b-table-simple
    id="booyong-table"
    small
    hover
    responsive
    striped
    bordered
    class="text-left"
    v-show="false"
  >
    <b-thead>
      <b-tr class="bg-dark-blue text-center text-white">
        <b-th class="col-width">BODY</b-th>
        <b-th class="col-width">TRUNK</b-th>
        <b-th class="col-width">HAUNCH</b-th>
        <b-th class="col-width">FQ</b-th>
        <b-th class="col-width">SADDLE</b-th>
        <b-th class="col-width">LEG</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="i in maxItems" :key="i.id" class="text-center">
        <b-td>{{
          boningItems.WholePig[i - 1] ? boningItems.WholePig[i - 1] : ''
        }}</b-td>
        <b-td>{{
          boningItems.Trunk[i - 1] ? boningItems.Trunk[i - 1] : ''
        }}</b-td>
        <b-td>{{
          boningItems.Haunch[i - 1] ? boningItems.Haunch[i - 1] : ''
        }}</b-td>
        <b-td>{{
          boningItems.Forequarter[i - 1] ? boningItems.Forequarter[i - 1] : ''
        }}</b-td>
        <b-td>{{
          boningItems.Saddle[i - 1] ? boningItems.Saddle[i - 1] : ''
        }}</b-td>
        <b-td>{{ boningItems.Leg[i - 1] ? boningItems.Leg[i - 1] : '' }}</b-td>
      </b-tr>
    </b-tbody>
    <b-tfoot>
      <b-tr class="text-center bg-dark-blue text-white">
        <b-th>{{ boningItems.WholePig.length }} PIGS</b-th>
        <b-th>{{ boningItems.Trunk.length }} TRUNKS</b-th>
        <b-th>{{ boningItems.Haunch.length }} HAUNCHES</b-th>
        <b-th>{{ boningItems.Forequarter.length }} FQ'S</b-th>
        <b-th>{{ boningItems.Saddle.length }} SADDLES</b-th>
        <b-th>{{ boningItems.Leg.length }} LEGS</b-th>
      </b-tr>
    </b-tfoot>
  </b-table-simple>
</template>

<script>
export default {
  name: 'BoningExportTable',
  data() {
    return {}
  },
  props: {
    boningList: Array
  },
  computed: {
    maxItems() {
      let max = 0
      for (const item of Object.entries(this.boningItems)) {
        if (item[1].length > max) {
          max = item[1].length
        }
      }
      return max
    },
    boningItems() {
      let report = {
        WholePig: [],
        Trunk: [],
        Haunch: [],
        Forequarter: [],
        Saddle: [],
        Leg: []
      }
      this.boningList.forEach((info) => {
        report[info.product].push(parseInt(info.body_no))
      })
      for (const key in report) {
        report[key].sort()
      }
      return report
    }
  }
}
</script>

<style scoped>
.col-width {
  min-width: 123px;
}
</style>
