<script setup>
import { LIGHT_YELLOW_HEX } from '@/constants/general'
import { formatDate } from '@/helpers/stringHelpers'
import { getCurrentDay } from '@/utils/DateHelpers'
import usePdfGenerator from '@/utils/usePdfGenerator'
import { computed, defineProps } from 'vue'

const STOCK_COLSPAN = 5

const props = defineProps(['loadoutItems', 'single'])

const { generatingPdf, exportPdf } = usePdfGenerator()
const totalsArray = []
const today = getCurrentDay()
const subTitle = computed(() => `CARTON Loadout ${formatDate(today)}`)
const fileName = computed(() => {
  const firstProduct = props.loadoutItems.values().next().value[0]
  const transportCode = props.single ? `-${firstProduct.transportCode}` : ''

  return `CARTON Loadout-[${formatDate(today, '-')}]${transportCode}`
})
const tableHeaders = ['Code', 'Name', 'Destination', 'No. Ctns', 'Loadout Date']

const loadoutByTransport = computed(() => {
  const products = new Map()

  props.loadoutItems.forEach((loadout, transport) => {
    products.set(transport, [])
    loadout.sort((a, b) => a.customer.localeCompare(b.customer))
    const stockItems = loadout.filter((item) => item.stock === true)
    const booyongItems = loadout.filter((item) => item.stock === false)

    if (booyongItems.length > 0) {
      processItems(products, booyongItems, transport)
    }

    if (stockItems.length > 0) {
      addCustomerSeparator(
        products,
        transport,
        LIGHT_YELLOW_HEX,
        STOCK_COLSPAN,
        '--- IN TRANSIT - BSP STOCK ---'
      )
      processItems(
        products,
        stockItems,
        transport,
        LIGHT_YELLOW_HEX,
        STOCK_COLSPAN
      )
    }
  })

  return products
})

const formatProductDetails = (product, fillColor = null) => {
  const productDetails = [
    { content: product.productcode, styles: fillColor ? { fillColor } : {} },
    { content: product.productName, styles: fillColor ? { fillColor } : {} },
    { content: product.customer, styles: fillColor ? { fillColor } : {} },
    { content: product.quantity, styles: fillColor ? { fillColor } : {} },
    {
      content: formatDate(product.loadout_date),
      styles: fillColor ? { fillColor } : {}
    }
  ]
  return productDetails
}

const addCustomerSeparator = (
  products,
  transport,
  fillColor = null,
  colSpan = 3,
  message = '---'
) => {
  products.get(transport).push([
    {
      content: message,
      colSpan,
      styles: { fontStyle: 'bold', ...(fillColor ? { fillColor } : {}) }
    }
  ])
}

const processItems = (
  products,
  items,
  transport,
  fillColor = null,
  colSpan = 3
) => {
  let currentCustomer = items[0]?.customer

  items.forEach((product) => {
    if (currentCustomer !== product.customer) {
      currentCustomer = product.customer
      addCustomerSeparator(products, transport, fillColor, colSpan)
    }
    const productDetails = formatProductDetails(product, fillColor)
    products.get(transport).push(productDetails)
  })
}

const generatePdf = () => {
  exportPdf(
    totalsArray,
    subTitle.value,
    loadoutByTransport.value,
    tableHeaders,
    fileName.value
  )
}
</script>
<template>
  <v-btn
    @click="generatePdf"
    small
    color="warning"
    :loading="generatingPdf"
    :text="single === true"
  >
    <font-awesome-icon icon="fas fa-file-export" class="mr-2" />{{
      single ? 'Transport Export' : 'Export for Booyong'
    }}</v-btn
  >
</template>
