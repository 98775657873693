<template>
  <b-button
    @click="$emit('click')"
    size="sm"
    class="btn-sm btn-export btn-neutral"
  >
    <b-spinner v-if="generating" small class="mr-2"></b-spinner>
    <font-awesome-icon v-else icon="fas fa-file-export" class="mr-2" />{{
      text ? text : 'Export'
    }}</b-button
  >
</template>

<script>
export default {
  name: 'SingleExportButton',
  props: {
    generating: Boolean,
    text: String
  }
}
</script>

<style scoped>
.bg-light-grey {
  background-color: #0000000d;
}
.btn-sm {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
btn-neutral,
.btn-neutral:hover {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.btn-export {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  position: relative;
  text-transform: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
  -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.btn-export:hover {
  -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
</style>
