<script setup>
import BaseDeleteConfirm from '../base/BaseDeleteConfirm.vue'
import BaseFreightCodeDisplay from '@/components/base/BaseFreightCodeDisplay.vue'
import BaseStatusChip from '../base/BaseStatusChip.vue'

import { defineProps, defineEmits, computed } from 'vue'
import { formatDate } from '@/helpers/stringHelpers'
import { PORKER_CODE_MAX } from '@/constants/product'
import { CARTON, PORKER } from '@/constants/general'
import { isBeforeDate } from '@/utils/DateHelpers'

const props = defineProps({ order: Object })
defineEmits(['delete'])

const headers = [
  { text: 'Product Name', value: 'productName', sortable: true },
  { text: 'QTY', value: 'quantity', sortable: false },
  { text: 'Type', value: 'type' },
  { text: 'Product Code', value: 'productcode', sortable: true },
  { text: 'Boning Date', value: 'boning_date', sortable: true },
  { text: 'Loadout Date', value: 'loadout_date', sortable: true },
  { text: 'Transport', value: 'transport' },
  { text: 'Actions', value: 'actions' }
]

const groupedItems = computed(() => {
  return props.order.ordered_items.map((item) => {
    const type = item.productcode <= PORKER_CODE_MAX ? PORKER : CARTON

    const productName = item.product?.product ?? '-'
    const subsequentTransport =
      item.subsequent_transport === '' || item.subsequent_transport === null
        ? ''
        : `,${item.subsequent_transport}`
    const primaryTransport =
      item.primary_transport === '' || item.primary_transport === null
        ? '-'
        : item.primary_transport
    const transport = `${primaryTransport}${subsequentTransport}`
    return { ...item, type, productName, transport }
  })
})
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="groupedItems"
    :items-per-page="groupedItems.length"
    item-key="id"
    sort-by="type"
    :sort-desc="true"
    class="elevation-1 w-100"
    dense
    hide-default-footer
  >
    <template v-slot:item.productName="{ item }">
      <v-row no-gutters>
        <span>{{ item.productName }}</span>
        <span v-if="item?.order_detail?.stock" class="ms-2"
          ><BaseStatusChip label="STOCK" color="warning"
        /></span>
      </v-row>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-row class="text-nowrap" no-gutters>
        <BaseDeleteConfirm
          :icon="true"
          size="small"
          title="Are you sure you want to remove product?"
          @deleteConfirm="$emit('delete', item)"
        >
          <template v-slot:content>
            <div class="mt-4">
              From Order:
              <span class="font-weight-bold">{{
                order.customer.company.name
              }}</span>
            </div>
            <div class="text-subtitle-2 mt-2">
              Product Name:
              <span class="font-weight-bold">{{ item.productName }}</span>
            </div>
            <div class="text-subtitle-2">
              Qty: <span class="font-weight-bold">{{ item.quantity }}</span>
            </div>
          </template>
        </BaseDeleteConfirm>
      </v-row>
    </template>
    <template v-slot:item.loadout_date="{ value }">
      <span :class="value !== order.date ? 'yellow lighten-4 pa-1' : ''">
        {{ formatDate(value) }}
      </span>
    </template>
    <template v-slot:item.boning_date="{ value, item }">
      <span
        :class="
          item.loadout_date && value && isBeforeDate(item.loadout_date, value)
            ? 'red lighten-3 pa-1'
            : ''
        "
      >
        {{ item.type === CARTON ? formatDate(value) : '-' }}
      </span>
    </template>
    <template v-slot:item.transport="{ value }">
      <BaseFreightCodeDisplay :freightIds="value" />
    </template>
  </v-data-table>
</template>
