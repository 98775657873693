<script setup>
import { INSTRUCTIONS, ORDERS, PIG_INFO } from '@/constants/pagination'
import { capitalise } from '@/helpers/stringHelpers'
</script>
<template>
  <div class="">
    <b-navbar-nav align="center">
      <b-nav-item
        v-if="allowAccess(['admin'])"
        :active="active == 'home'"
        to="upload-files"
        >Upload Files</b-nav-item
      >
      <b-nav-item-dropdown
        v-if="allowAccess(['admin'])"
        :toggle-class="active == ORDERS ? 'active' : ''"
        :text="capitalise(ORDERS)"
        right
        menu-class="drop-item"
      >
        <b-dropdown-item to="orders">All Orders</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="allowAccess(['admin', 'stock-manager'])"
        text="Products"
        right
        menu-class="drop-item"
        :toggle-class="active == 'products' ? 'active' : ''"
      >
        <b-dropdown-item to="products">All Products</b-dropdown-item>
        <b-dropdown-item to="product-stock?tab=0"
          >Product Stock</b-dropdown-item
        >
        <b-dropdown-item to="product-stock?tab=2">Scanner</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="allowAccess(['admin'])"
        text="Customers"
        right
        menu-class="drop-item"
        :toggle-class="active == 'customers' ? 'active' : ''"
      >
        <b-dropdown-item to="customers">All Customers</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="allowAccess(['admin'])"
        text="Suppliers"
        right
        menu-class="drop-item"
        :toggle-class="active == 'suppliers' ? 'active' : ''"
      >
        <b-dropdown-item to="suppliers">All Suppliers</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="allowAccess(['admin'])"
        text="Transport"
        right
        menu-class="drop-item"
        :toggle-class="active == 'transport' ? 'active' : ''"
      >
        <b-dropdown-item to="transport">Summary</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="allowAccess(['admin'])"
        :text="capitalise(PIG_INFO)"
        right
        menu-class="drop-item"
        :toggle-class="active == PIG_INFO ? 'active' : ''"
      >
        <b-dropdown-item to="pig-info">Pig Information</b-dropdown-item>
        <b-dropdown-item to="reconcile-orders"
          >Reconcile Orders</b-dropdown-item
        >
        <b-dropdown-item to="processing-costs"
          >Processing Costs</b-dropdown-item
        >
        <b-dropdown-item to="grower-report">Grower Report</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="allowAccess(['admin'])"
        :text="capitalise(INSTRUCTIONS)"
        right
        menu-class="drop-item"
        :toggle-class="active === INSTRUCTIONS ? 'active' : ''"
      >
        <b-dropdown-item to="boning">Boning Instructions</b-dropdown-item>
        <b-dropdown-item to="loadout">Loadout CARTON</b-dropdown-item>
        <b-dropdown-item to="loadout-report">Loadout PORKER</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TopBar',
  computed: {
    ...mapGetters(['active', 'userGroups'])
  },
  methods: {
    allowAccess: function (groups) {
      if (!this.userGroups) return false

      let found = false
      groups.forEach((group) => {
        const index = this.userGroups.findIndex((element) => element == group)
        if (index !== -1) found = true
      })
      return found
    }
  }
}
</script>

<style scoped>
.active {
  color: #fff !important;
}
</style>
