import { getCurrentInstance, computed } from 'vue'
import { getISOWeekNumber } from '@/utils/DateHelpers'
import { DATE_END_STR, DATE_START_STR, WEEK_STR } from '@/constants/general'

const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}

// function checkIfFullWeek(start, end) {
//   if (!start || !end) return false
//   if (getDayValues(start).day !== MONDAY || getDayValues(end).day !== SUNDAY)
//     return false
//   if (!isBeforeDate(start, end)) return false

//   const startDate = new Date(start)
//   const endDate = new Date(end)
//   const diffDays = (endDate - startDate) / (1000 * 3600 * 24)
//   return diffDays === 6 && startDate.getDay() === 1
// }

export default function useWeekDateFilter(
  getterName,
  setterFunctionName,
  startFilterName = DATE_START_STR,
  endFilterName = DATE_END_STR,
  weekFilterName = WEEK_STR
) {
  const store = useStore()
  const filters = computed(() => store.getters[getterName])

  const setFilter = (field, value) => {
    store.commit(setterFunctionName, { field, value })
  }

  const updateDateFilter = ({ value, field }) => {
    let weekNr = null
    if (field === startFilterName || field === endFilterName) {
      const start =
        field === startFilterName ? value : filters.value[startFilterName].value
      const end =
        field === endFilterName ? value : filters.value[endFilterName].value
      weekNr = getWeekNumber(start, end)
    }
    setFilter(weekFilterName, weekNr)
    setFilter(field, value)
  }

  const getWeekNumber = (start, end) => {
    if (!start && !end) return null

    const startWeek = start ? getISOWeekNumber(start) : null
    const endWeek = end ? getISOWeekNumber(end) : null

    if (start && end && startWeek !== endWeek) return null

    return startWeek || endWeek
  }

  return {
    updateDateFilter
  }
}
