import axios from 'axios'
import Vue from 'vue'

import { PER_PAGE_OPTIONS, PER_PAGE_MAX } from '@/constants/pagination'
import { PORKER_CODE_MAX } from '@/constants/product'
import { WEEK_DAYS, DEFAULT_VIEW, CLASSIC_VIEW } from '@/constants/general'
import {
  getDayValues,
  getISOWeekNumber,
  getWeekBounds,
  getYear,
  getISOWeekday
} from '@/utils/DateHelpers'

const emptySummary = {
  WholePig: 0,
  Trunk: 0,
  Haunch: 0,
  Forequarter: 0,
  Saddle: 0,
  Leg: 0
}

const { day } = getDayValues()
const dayIndex = () => {
  const index = WEEK_DAYS.findIndex((item) => item === day)
  if (index === -1) return 0
  return index
}
const { start, end } = getWeekBounds()

const state = {
  orderList: [],
  loadingOrders: '',
  loadingOrderItem: '',
  loadingCustomerNameList: '',
  customerNameList: [],
  orderPageView: DEFAULT_VIEW,
  orderTab: dayIndex(),
  orderSummaryDays: [dayIndex()],

  orderSummary: {
    WholePig: 0,
    Trunk: 0,
    Haunch: 0,
    Forequarter: 0,
    Saddle: 0,
    Leg: 0
  },

  //pagination
  totalOrders: 0,
  perPageOrders: PER_PAGE_MAX,
  pageNrOrders: 0,

  //date
  startDateOrders: '',
  startDayOrders: '',
  startWeekOrders: '',

  //sorting
  sortbyOrders: 'date,id',
  ascOrders: false,

  //filters
  filtersOrders: {
    date_start: {
      type: 'date',
      label: 'Start Date - Processing Day',
      value: start,
      readOnly: true
    },
    date_end: {
      type: 'date',
      label: 'End Date - Processing Day',
      value: end,
      readOnly: true
    },
    customer_name: {
      type: 'select',
      name: 'name',
      label: 'Customer',
      value: '',
      options: []
    },
    week: {
      type: 'week',
      label: 'Week Nr',
      value: getISOWeekNumber()
    },
    year: { type: 'year', value: getYear(), hidden: true }
  }
}
const getters = {
  orders: (state) => state.orderList,
  customerNameList: (state) => state.customerNameList,
  loadingOrders: (state) => state.loadingOrders,
  loadingOrderItem: (state) => state.loadingOrderItem,
  loadingCustomerNameList: (state) => state.loadingCustomerNameList,
  orderPageView: (state) => state.orderPageView,
  currentOrderTab: (state) => state.orderTab,

  orderSummaryDays: (state) => state.orderSummaryDays,
  orderSummary: (state) => state.orderSummary,
  porkerOrdersSorted: (state) => {
    if (!state.orderList || state.orderList.length === 0) {
      return []
    }

    const listCopy = JSON.parse(JSON.stringify(state.orderList))
    return listCopy
      .sort((a, b) =>
        a.customer.company.name.localeCompare(b.customer.company.name)
      )
      .filter((order) => {
        if (state.orderPageView === DEFAULT_VIEW) {
          const currentWeekDayIdx = getISOWeekday(order.date) - 1
          if (!state.orderSummaryDays.includes(currentWeekDayIdx)) return false
        }

        return order.ordered_items.some(
          (item) => item.productcode && item.productcode <= PORKER_CODE_MAX
        )
      })
  },

  //pagination
  totalOrders: (state) => state.totalOrders,
  perPageOrders: (state) => state.perPageOrders,
  pageNumberOrders: (state) => state.pageNrOrders,
  sortbyOrders: (state) => state.sortbyOrders,
  ascOrders: (state) => state.ascOrders,
  offsetOrders: (state) => {
    return state.pageNrOrders * state.perPageOrders
  },

  startDateOrders: (state) => state.startDateOrders,
  startDayOrders: (state) => state.startDayOrders,
  startWeekOrders: (state) => state.startWeekOrders,

  //filters
  filtersOrders: (state) => state.filtersOrders,
  selectedOrderWeek: (state) => state.filtersOrders.week.value
}

const actions = {
  async fetchOrders({ commit, dispatch, getters }) {
    commit('SET_LOADING_ORDERS', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'orders/',
        parameters: {
          limit: getters.perPageOrders,
          offset: getters.offsetOrders,
          sort_by: getters.sortbyOrders,
          ascending: getters.ascOrders
        },
        filters: getters.filtersOrders
      },
      { root: true }
    )
    commit('SET_LOADING_ORDERS', '')
    if (!res.error) {
      commit('SET_ORDER_LIST', res.data)
      commit('SET_TOTAL_ORDERS', res.total)

      try {
        let minDateStr = getters.filtersOrders.date_start.value
        if (minDateStr === '') {
          minDateStr = res.data[res.data.length - 1].date
        }
        let startDate = await dispatch(
          'getWeeks',
          {
            dataDate: minDateStr
          },
          { root: true }
        )
        commit('SET_START_DATE_ORDERS', minDateStr)
        commit('SET_START_WEEK_ORDERS', 'W' + startDate[1])
        commit('SET_START_DAY_ORDERS', startDate[2])
      } catch (error) {
        commit('SET_START_DATE_ORDERS', '')
        commit('SET_START_DAY_ORDERS', '')
        commit('SET_START_WEEK_ORDERS', '')
      }
    } else {
      dispatch('showError', {
        error: res.error,
        messagePrefix: 'Error while loading orders! '
      })
    }
  },
  async fetchCustomerName({ rootGetters, commit, dispatch }) {
    const URI = rootGetters.baseUrl + 'customers/customerlist?sort_by=name'

    try {
      commit('SET_LOADING_CUSTOMER_NAME_LIST', 'list')
      let res = await axios.get(URI)
      commit('SET_CUSTOMER_NAME_LIST', res.data.data)
      commit('UPDATE_CUSTOMER_FILTER', res.data.data)
    } catch (error) {
      dispatch('showError', {
        error,
        messagePrefix: 'Error while loading customer list! '
      })
    } finally {
      commit('SET_LOADING_CUSTOMER_NAME_LIST', '')
    }
  },
  async createOrder({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'orders/'
    try {
      commit('SET_LOADING_ORDERS', 'create')
      await axios.post(URI, payload)
      dispatch('showMessage', 'New Order created successfuly!')
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_ORDERS', '')
    }
  },
  async createOrderItem({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'orders/ordereditem/'
    try {
      commit('SET_LOADING_ORDER_ITEM', `item-${payload.order_id}`)
      let res = await axios.post(URI, payload)
      dispatch('showMessage', 'New Order Item created successfuly!')
      return res.data
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_ORDER_ITEM', '')
    }
  },
  async updateOrder(
    { rootGetters, commit, dispatch, getters },
    { payload, onSuccess = '' }
  ) {
    const URI = rootGetters.baseUrl + 'orders/' + payload.id
    try {
      commit('SET_LOADING_ORDERS', `update-${payload.id}`)
      const res = await axios.patch(URI, payload)
      dispatch('showMessage', 'Order updated successfuly!')

      if (onSuccess === 'insert') {
        const index = getters.orders.findIndex(
          (order) => order.id === payload.id
        )
        if (index !== -1) {
          commit('UPDATE_ORDER_IN_LIST', { index, newOrder: res.data })
        }
      }

      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_ORDERS', '')
    }
  },
  async updateOrderItem({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'orders/ordereditem/' + payload.id
    try {
      commit('SET_LOADING_ORDER_ITEM', `item-${payload.order_id}`)
      let res = await axios.patch(URI, payload)
      dispatch('showMessage', 'Order item updated successfuly!')
      return res.data
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_ORDER_ITEM', '')
    }
  },
  async deleteOrder({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'orders/' + payload.id
    try {
      commit('SET_LOADING_ORDERS', 'delete')
      await axios.delete(URI)
      dispatch(
        'showMessage',
        'Order deleted successfuly! ' + payload.customer.company.name
      )
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_ORDERS', '')
    }
  },
  async deleteOrderItem({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'orders/ordereditem/' + payload.id
    try {
      commit('SET_LOADING_ORDER_ITEM', `item-${payload.order_id}`)
      await axios.delete(URI)
      dispatch(
        'showMessage',
        'Order Item deleted successfuly! ' + payload.productName
      )
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_ORDER_ITEM', '')
    }
  },
  async clearFiltersOrders({ commit, state }) {
    if (state.orderPageView !== DEFAULT_VIEW) {
      commit('SET_FILTER_ORDERS', { field: 'date_start', value: '' })
      commit('SET_FILTER_ORDERS', { field: 'date_end', value: '' })
      commit('SET_FILTER_ORDERS', { field: 'week', value: null })
    }
    commit('SET_FILTER_ORDERS', { field: 'customer_name', value: '' })
  },

  changeOrderPageview({ state, dispatch, commit }, { payload: view }) {
    commit('SET_ORDER_PAGE_VIEW', view)

    if (view === CLASSIC_VIEW) {
      state.pageNrOrders = 0
      state.perPageOrders = PER_PAGE_OPTIONS[0]
      state.filtersOrders.date_start.readOnly = false
      state.filtersOrders.date_end.readOnly = false
    }
    if (view === DEFAULT_VIEW) {
      state.pageNrOrders = 0
      state.perPageOrders = PER_PAGE_MAX
      state.filtersOrders.date_start.value = start
      state.filtersOrders.date_end.value = end
      state.filtersOrders.week.value = getISOWeekNumber()
      state.filtersOrders.year.value = getYear()
      state.filtersOrders.date_start.readOnly = true
      state.filtersOrders.date_end.readOnly = true
    }
    dispatch('fetchOrders')
  }
}
const mutations = {
  SET_ORDER_LIST: (state, payload) => (state.orderList = payload),
  UPDATE_ORDER_IN_LIST: (state, { index, newOrder }) => {
    Vue.set(state.orderList, index, newOrder)
  },
  SET_CUSTOMER_NAME_LIST: (state, payload) =>
    (state.customerNameList = payload),
  SET_LOADING_ORDERS: (state, payload) => (state.loadingOrders = payload),
  SET_LOADING_ORDER_ITEM: (state, payload) =>
    (state.loadingOrderItem = payload),
  SET_LOADING_CUSTOMER_NAME_LIST: (state, payload) =>
    (state.loadingCustomerNameList = payload),
  SET_ORDER_PAGE_VIEW: (state, payload) => (state.orderPageView = payload),
  SET_ORDER_TAB: (state, payload) => {
    state.orderTab = payload
  },

  SET_ORDER_SUMMARY_DAYS: (state, payload) =>
    (state.orderSummaryDays = payload),
  UPDATE_SUMMARY: (state) => {
    state.orderSummary = { ...emptySummary }
    state.orderList.forEach((order) => {
      if (state.orderPageView === DEFAULT_VIEW) {
        const currentWeekDayIdx = getISOWeekday(order.date) - 1
        if (!state.orderSummaryDays.includes(currentWeekDayIdx)) return
      }
      order.ordered_items.forEach((el) => {
        if (el.productcode > PORKER_CODE_MAX) return
        if (!el.product) return

        const productName = el.product.product
        const currentQuantity = state.orderSummary[productName] || 0

        state.orderSummary[productName] = currentQuantity + Number(el.quantity)
      })
    })
  },

  SET_TOTAL_ORDERS: (state, payload) => (state.totalOrders = payload),
  SET_START_DATE_ORDERS: (state, payload) => (state.startDateOrders = payload),
  SET_START_DAY_ORDERS: (state, payload) => (state.startDayOrders = payload),
  SET_START_WEEK_ORDERS: (state, payload) => (state.startWeekOrders = payload),
  SET_PAGE_NR_ORDERS: (state, payload) => (state.pageNrOrders = payload),
  SET_PER_PAGE_ORDERS: (state, payload) => (state.perPageOrders = payload),
  SET_SORT_BY_ORDERS: (state, payload) => (state.sortbyOrders = payload),
  SET_ASC_ORDERS: (state, payload) => (state.ascOrders = payload),
  UPDATE_CUSTOMER_FILTER: (state, payload) => {
    const options = payload.map((item) => {
      const code = item.code ? `${item.code} - ` : ''
      return { ...item, name: `${code}${item.name}` }
    })

    state.filtersOrders.customer_name.options = options
  },
  SET_FILTER_ORDERS: (state, payload) => {
    if (state.filtersOrders[payload.field].type == 'select') {
      if (payload.value) {
        state.filtersOrders[payload.field].value = payload.value
      } else {
        state.filtersOrders[payload.field].value = ''
      }
      return
    }
    state.filtersOrders[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
