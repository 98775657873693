<script setup>
import SideFilter from '../components/SideFilter.vue'

import { computed, getCurrentInstance, ref } from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const vm = getCurrentInstance().proxy
const store = useStore()
const loadReportList = computed(() => store.getters.loadReportList)
const freightCodeMap = computed(() => store.getters.freightCodeMap)
const customerCodeMap = computed(() => store.getters.customerCodeMap)
const loadingLoadReport = computed(() => store.getters.loadingLoadReport)

const sortedLoadoutReport = computed(() => {
  const reportArray = Object.entries(loadReportList.value)
    .map(([key, value]) => ({
      transport: getFreightName(key),
      id: key,
      items: value
    }))
    .sort((a, b) => a.transport.localeCompare(b.transport))
  return reportArray
})

const getCustomerName = (id) => {
  return customerCodeMap.value[id].name
}

const getFreightName = (id) => {
  return freightCodeMap.value[id].name
}

const calcPorker = (items) => {
  let total = 0
  items.forEach((item) => {
    total += item.loaded_items.length
  })
  return total
}
const calcCartons = (cartons) => {
  let total = 0
  cartons.forEach((carton) => {
    carton.loaded_items.forEach((item) => {
      let array = item.split(' x ')
      total += parseInt(array[1])
    })
  })

  return total
}

const dataReady = ref(false)
const getCodeMaps = async () => {
  if (!freightCodeMap.value || !customerCodeMap.value) {
    await Promise.all([
      store.dispatch('fetchFreightNames', { vm }),
      store.dispatch('fetchCustomerNames', { vm })
    ])
  }
  dataReady.value = true
}

// ON CREATED
getCodeMaps()
store.commit('SET_USING_VUETIFY', false)
store.commit('SET_ACTIVE', INSTRUCTIONS)
store.dispatch('fetchLoadoutReport', vm)
</script>

<template>
  <div class="container-fluid">
    <div class="wrapper">
      <SideFilter
        :filterList="filtersLoadReport"
        :hideClear="true"
        @input="updateFilter"
        @inputWeek="updateWeekFilter"
        @inputYear="updateYearFilter"
        ref="loadReportSideFilter"
      />
      <div class="content-page">
        <b-row no-gutters class="my-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Loadout PORKER</h4>
          </div>
        </b-row>

        <b-card v-if="loadingLoadReport === 'list'">
          <div class="text-center text-danger my-2">
            <b-spinner small class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </b-card>

        <!-- ---------------- Summary section ------------------- -->
        <b-card class="mb-3" v-else>
          <b-row
            no-gutters
            class="align-items-baseline justify-content-between"
          >
            <b-col cols="12" class="d-flex flex-column align-items-baseline">
              <h4 class="header-title mb-3 w-700 text-light-blue text-center">
                Loadout Report By Date
              </h4>
              <h4 class="header-title mb-3 w-700 text-light-blue text-center">
                Week
                {{
                  filtersLoadReport.week.value +
                  ' - ' +
                  filtersLoadReport.weekDay.value.day
                }}
              </h4>
            </b-col>
          </b-row>

          <span id="loadout-report">
            <b-row
              class="align-items-left mt-3"
              no-gutters
              v-for="{ transport, items, id } in sortedLoadoutReport"
              :key="id"
              :id="`report-${id}`"
            >
              <b-col v-if="dataReady">
                <b-row no-gutters class="align-items-center">
                  <p class="">
                    <b>{{ transport }}</b>
                  </p>
                  <div id="report-week" class="ml-auto"></div>
                  <b-button
                    @click="exportToPDF(id)"
                    size="sm"
                    class="btn-sm btn-export btn-neutral"
                  >
                    <b-spinner
                      v-if="generatingPDF == `id-${id}`"
                      small
                      class="mr-2"
                    ></b-spinner>
                    <font-awesome-icon
                      v-else
                      icon="fas fa-file-export"
                      class="mr-2"
                    />Export</b-button
                  ></b-row
                >
                <b-table-simple
                  small
                  hover
                  responsive
                  bordered
                  class="text-left loadout-table"
                  :id="`report-table-${id}`"
                >
                  <b-thead>
                    <b-tr class="bg-light-blue">
                      <b-th>Destination</b-th>
                      <b-th class="text-center">Pigs</b-th>
                      <b-th class="text-center">Break</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(cust, key2) in items.customers"
                      :key="cust.id"
                    >
                      <b-td>{{ getCustomerName(key2) }}</b-td>
                      <b-td
                        ><b-row
                          v-if="cust.PORKER.length > 0"
                          class="flex-row align-items-center"
                          no-gutters
                        >
                          <span class="mr-2 font-weight-bold">{{
                            calcPorker(cust.PORKER)
                          }}</span>
                          <b-col class="d-flex flex-column text-center"
                            ><span v-for="item in cust.PORKER" :key="item.id"
                              ><span
                                v-for="loadedItem in item.loaded_items"
                                :key="loadedItem"
                                >{{ loadedItem
                                }}<br /></span></span></b-col></b-row
                      ></b-td>
                      <b-td
                        ><b-row
                          v-if="cust.BREAK.length > 0"
                          class="flex-row align-items-center"
                          no-gutters
                          ><span class="mr-2 font-weight-bold">
                            {{ calcCartons(cust.BREAK) }}</span
                          ><b-col class="d-felx flex-column text-center"
                            ><span
                              v-for="carton in cust.BREAK"
                              :key="`${carton.weekday}-${carton.freight}-${carton.destination}`"
                            >
                              <span
                                v-for="item in carton.loaded_items"
                                :key="item"
                                >{{ item }}<br /></span></span></b-col></b-row
                      ></b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot>
                    <b-tr>
                      <b-td class="text-right bg-light-grey">
                        <span class="mr-2">Totals: </span></b-td
                      >
                      <b-td class="text-right bg-light-grey">
                        {{
                          items.totalPorker > 0
                            ? `${items.totalPorker} pigs`
                            : '-'
                        }}
                      </b-td>
                      <b-td class="text-right bg-light-grey">
                        {{
                          items.totalBreak > 0 ? `${items.totalBreak} pcs` : '-'
                        }}
                      </b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple></b-col
              >
            </b-row>
          </span>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import html2pdf from 'html2pdf.js'

import { pdfUtilsMixin } from '../mixins/pdfUtils'
import { formatDateWithAddedDays, getWeekBounds } from '@/utils/DateHelpers'
import { INSTRUCTIONS } from '@/constants/pagination'

export default {
  mixins: [pdfUtilsMixin],
  name: 'LoadoutReportView',
  data() {
    return {
      generatingPDF: ''
    }
  },
  computed: {
    ...mapGetters(['filtersLoadReport', 'freightCodeMap', 'customerCodeMap'])
  },
  methods: {
    ...mapActions(['fetchLoadoutReport']),
    ...mapMutations(['SET_FILTER_LOAD_REPORT']),
    exportToPDF: async function (key = 0) {
      try {
        this.generatingPDF = `id-${key}`
        const element = document.getElementById(`report-${key}`)
        let tableCopy = element.cloneNode(true)
        // Split tables into pages
        let [firstPageTable, miniTableCount, miniTableArray] =
          await this.splitTable(14, 11, 11, `#report-table-${key}`)

        let fullTable = tableCopy.getElementsByTagName('table')[0]
        fullTable.replaceWith(firstPageTable)
        let title = await this.createTitle(
          'BANGALOW SWEET PORK',
          'h4',
          '1rem',
          '100%'
        )
        let startDate = this.$options.filters.date(
          this.filtersLoadReport.date_start.value
        )
        let weekNr = this.filtersLoadReport.week.value
        let weekDay = this.filtersLoadReport.weekDay.value.day.toUpperCase()
        let dateTitle = await this.createTitle(
          `LOADOUT REPORT ${weekDay} W${weekNr} ${startDate}`,
          'h5',
          '2rem',
          '100%'
        )
        if (miniTableCount > 0) {
          firstPageTable.classList.add('tableClass')
          for (let i = 0; i < miniTableCount; i++) {
            let el3Copy = miniTableArray[i].cloneNode(true)
            if (i != miniTableCount - 1) {
              el3Copy.classList.add('tableClass')
            }
            tableCopy.appendChild(el3Copy)
          }
        }

        tableCopy.insertBefore(dateTitle, tableCopy.firstChild)
        tableCopy.insertBefore(title, tableCopy.firstChild)
        let button = tableCopy.getElementsByTagName('button')
        let week = tableCopy.querySelector('#report-week')
        week.innerHTML = `Week ${weekNr}`
        button[0].style.display = 'none'
        startDate = startDate.replaceAll('/', '-')
        const freightCode = this.freightCodeMap[key].code
        const opt = {
          margin: [0.3, 0.4, 1, 0.4],
          filename: `Loadout-report-${weekDay}[W${weekNr} ${startDate}]-${freightCode}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 6,
            letterRendering: true,
            useCORS: true,
            width: 740
          },
          pagebreak: { after: ['.tableClass'] },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        }
        await html2pdf()
          .set(opt)
          .from(tableCopy)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages()
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i)
              pdf.setFontSize(10)
              pdf.setTextColor(150)
              pdf.text(
                'Page ' + i + ' of ' + totalPages,
                pdf.internal.pageSize.getWidth() / 2 - 0.3,
                pdf.internal.pageSize.getHeight() - 0.5
              )
            }
          })
          .save()
        this.generatingPDF = ''
      } catch (error) {
        this.generatingPDF = ''
        console.log(error)
      }
    },
    updateWeekFilter: async function (dateRange) {
      await this.SET_FILTER_LOAD_REPORT({
        field: 'week',
        value: dateRange.weekNr
      })
      const dayNr = this.filtersLoadReport.weekDay.value.nr
      const day = formatDateWithAddedDays(dateRange.startDay, dayNr)
      await this.updateDatePicker(day)
      if (this.filtersLoadReport.year.value != dateRange.year) {
        this.SET_FILTER_LOAD_REPORT({ field: 'year', value: dateRange.year })
      }
      this.fetchLoadoutReport(this)
    },
    updateYearFilter: async function ({ year }) {
      const cleanYear = parseInt(year.trim())
      if (cleanYear == this.filtersLoadReport.year.value) return
      await this.SET_FILTER_LOAD_REPORT({ field: 'year', value: cleanYear })
      if (!this.filtersLoadReport.week.value) return
      this.$refs.loadReportSideFilter.generateWeekDays(
        this.filtersLoadReport.week.value,
        cleanYear
      )
    },
    updateFilter: async function (value) {
      if (value.field == 'weekDay') {
        if (!value.value) return
        const weekNr = this.filtersLoadReport.week.value
        const year = this.filtersLoadReport.year.value
        const { start } = getWeekBounds(weekNr, year)
        const day = formatDateWithAddedDays(start, value.value.nr)
        await this.updateDatePicker(day)
      }
      await this.SET_FILTER_LOAD_REPORT(value)
      this.fetchLoadoutReport(this)
    },
    updateDatePicker: async function (day) {
      await Promise.all([
        this.SET_FILTER_LOAD_REPORT({
          field: 'date_start',
          value: day
        }),
        this.SET_FILTER_LOAD_REPORT({
          field: 'date_end',
          value: day
        })
      ])
      return
    }
  }
}
</script>

<style scoped>
.loadout-table {
  min-width: 600px;
}
.bg-light-grey {
  background-color: #0000000d;
}
.btn-sm {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
btn-neutral,
.btn-neutral:hover {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.btn-export {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  position: relative;
  text-transform: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
  -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.btn-export:hover {
  -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
</style>
