<template>
  <div id="login-card">
    <h4 class="mt-0">Reset Password</h4>
    <p class="text-muted mb-5">
      Enter your username and we'll send you an email with instructions to reset
      your password.
    </p>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="error != ''"
      @dismissed="error = ''"
    >
      <i class="fas fa-exclamation-triangle mr-2"></i>{{ error }}
    </b-alert>
    <b-form @submit="onSubmit" class="d-flex flex-column text-left">
      <b-form-group id="input-group-1" label="Username:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="form.username"
          type="text"
          placeholder="Enter username"
          required
        ></b-form-input>
      </b-form-group>

      <b-button
        type="submit"
        class="btn-forecast d-flex justify-content-center align-items-center"
        ><b-spinner v-if="resetLoading" small></b-spinner>
        <i v-if="!resetLoading" class="fa fa-undo"></i>
        <span class="ml-2">Reset Password</span></b-button
      >
    </b-form>
    <footer class="footer footer-alt">
      <p class="text-muted">
        Back to
        <router-link to="login" class="text-muted ml-1"
          ><b>Sign In</b></router-link
        >
      </p>
    </footer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { Auth } from 'aws-amplify'

export default {
  name: 'ResetPass',
  data() {
    return {
      form: {
        username: ''
      },
      resetLoading: false,
      error: ''
    }
  },
  methods: {
    ...mapMutations(['SET_CONFIRM_EMAIL', 'SET_CONFIRM_USERNAME']),
    async onSubmit(event) {
      event.preventDefault()
      this.resetLoading = true
      try {
        const data = await Auth.forgotPassword(this.form.username)
        this.resetLoading = false
        this.SET_CONFIRM_EMAIL(data.CodeDeliveryDetails.Destination)
        this.SET_CONFIRM_USERNAME(this.form.username)
        this.$router.push({ path: '/resetpass/confirm' })
      } catch (err) {
        console.log(err)
        this.error = err.message
        this.resetLoading = false
      }
    }
  }
}
</script>
