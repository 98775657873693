<template>
  <div class="container-fluid">
    <div class="wrapper">
      <div class="content-page w-50">
        <new-pig-part-modal :type="modalAction" ref="partModal" />
        <b-row class="d-flex row no-gutters mt-1">
          <b-button
            @click="$refs.partModal.add(), (modalAction = 'add')"
            class="mr-2"
          >
            + Create New Pig Part
          </b-button>
          <div class="d-flex align-items-center">
            Total Parts:
            <span class="font-weight-bold ml-2">{{ totalParts }}</span>
          </div>
        </b-row>

        <b-row no-gutters class="mt-3 d-flex text-left">
          <b-table
            id="product-table"
            striped
            hover
            sort-icon-left
            :fields="fields"
            :items="pigPartList"
            :busy="loadingPigParts == 'list'"
            class="mt-3"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner small class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(actions)="data">
              <b-row class="text-nowrap mb-2" no-gutters>
                <icon-delete
                  @click.native="deletePart(data.item)"
                  class="mr-2"
                />
                <icon-edit
                  @click.native="
                    ;(modalAction = 'edit'), $refs.partModal.edit(data.item)
                  "
                />
              </b-row>
            </template>
          </b-table>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IconDelete from '../components/IconDelete.vue'
import IconEdit from '../components/IconEdit.vue'
import NewPigPartModal from '../components/NewPigPartModal.vue'

export default {
  components: {
    IconDelete,
    IconEdit,
    NewPigPartModal
  },
  name: 'PigPartsView',
  data() {
    return {
      modalAction: 'add',
      fields: [{ key: 'part', label: 'Pig Part Name' }, { key: 'actions' }]
    }
  },
  computed: {
    ...mapGetters(['pigPartList', 'loadingPigParts', 'totalParts'])
  },
  methods: {
    ...mapActions(['fetchPigParts', 'deletePigPart']),
    ...mapMutations([]),
    deletePart: function (info) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete ${info.part}?`, {
          buttonSize: 'sm',
          size: 'sm',
          okTitle: 'Yes',
          cancelTitle: 'No'
        })
        .then(async (value) => {
          if (value) {
            let res = await this.deletePigPart({ vm: this, payload: info })
            if (res === 'success') {
              this.fetchPigParts({ vm: this })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created() {
    this.fetchPigParts({ vm: this })
  }
}
</script>
