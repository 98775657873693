<script setup>
import BaseProductSelect from '@/components/base/BaseProductSelect.vue'
import BaseDateSelect from '@/components/base/BaseDateSelect.vue'
import BaseSelectFreight from '@/components/base/BaseSelectFreight.vue'

import { defineProps, defineEmits } from 'vue'
import IconDelete from '../IconDelete.vue'
import { CARTON } from '@/constants/general'

import { useStore } from '@/utils/VuexHelpers'
import { useFreight } from '@/utils/useFreight'

const props = defineProps({
  items: [Array, String],
  minDate: String,
  itemType: String,
  customerId: Number
})
const emit = defineEmits(['delete'])

const store = useStore()
const { getTransport } = useFreight(store)

const updateOrderItem = (item, product) => {
  item.productcode = product ? product.code : null
}

const updateFreight = (value, item) => {
  item.primary_transport = value[0] ?? null
  item.subsequent_transport = value.slice(1).join(',') || ''
}

const getFreightValue = (item) => {
  if (!item.primary_transport) return []

  return [
    item.primary_transport.toString(),
    ...(item.subsequent_transport ? item.subsequent_transport.split(',') : [])
  ]
}

const updateBoningDate = (value, item) => {
  if (value === item.boning_date) return
  item.boning_date = value
}

const updateLoadoutDate = (value, item) => {
  if (value === item.loadout_date) return
  item.loadout_date = value

  if (!props.customerId) {
    item.primary_transport = null
    item.subsequent_transport = ''
    return
  }

  const { primary, subsequent } = getTransport(props.customerId, value)
  item.primary_transport = primary
  item.subsequent_transport = subsequent
}

const deleteItem = (item) => {
  item.delete = true
  emit('delete')
}

const getStockStatus = (item) => {
  return item?.order_detail?.stock ? true : null
}
const udpateStockStatus = (value, item) => {
  if (!item.order_detail) item.order_detail = {}
  item.order_detail.stock = value
}
</script>

<template>
  <v-simple-table class="bordered-table my-2" dense>
    <template v-slot:default>
      <thead>
        <tr class="blue lighten-5">
          <th
            :colspan="itemType === CARTON ? 7 : 5"
            class="text-center text-uppercase"
          >
            {{ itemType }} items
          </th>
        </tr>
        <tr>
          <th v-if="itemType === CARTON" class="text-center">
            <font-awesome-icon
              icon="fas fa-box-open"
              class="text-warning"
            ></font-awesome-icon>
          </th>
          <th class="text-left product">
            Product<span class="red--text">*</span>
          </th>
          <th class="text-left qty">Qty<span class="red--text">*</span></th>
          <th v-if="itemType === CARTON" class="text-left">
            Boning Date<span class="red--text">*</span>
          </th>
          <th class="text-left">
            Loadout Date<span class="red--text">*</span>
          </th>
          <th>Transport<span class="red--text">*</span></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in items" :key="`${item.productcode}-${idx}`">
          <td v-if="itemType === CARTON" class="text-center">
            <v-checkbox
              :input-value="getStockStatus(item)"
              color="warning"
              hide-details
              class="mt-0 stock-checkbox"
              dense
              @change="udpateStockStatus($event, item)"
            ></v-checkbox>
          </td>
          <td class="product">
            <BaseProductSelect
              :type="itemType"
              :product="item"
              @update:product="updateOrderItem(item, $event)"
            />
          </td>
          <td class="qty">
            <v-text-field
              type="number"
              min="0"
              step="1"
              hide-details
              v-model="item.quantity"
            ></v-text-field>
          </td>
          <td v-if="itemType === CARTON">
            <BaseDateSelect
              :date="item.boning_date ? item.boning_date : ''"
              :min-date="minDate"
              min-date-error-message="Date should be the same or later than the Processing Day."
              @update:date="updateBoningDate($event, item)"
            />
          </td>
          <td>
            <BaseDateSelect
              :date="item.loadout_date ? item.loadout_date : ''"
              :min-date="item.boning_date"
              min-date-error-message="Date should be the same or later than the Boning Day."
              @update:date="updateLoadoutDate($event, item)"
            />
          </td>
          <td>
            <BaseSelectFreight
              :freight="getFreightValue(item)"
              :label="''"
              @update:freight="updateFreight($event, item)"
            />
          </td>

          <td>
            <IconDelete @click.native="deleteItem(item)" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style>
.stock-checkbox .v-input--selection-controls__input {
  margin-right: 0 !important;
}
.bordered-table > .v-data-table__wrapper {
  overflow-y: visible;
  overflow-x: visible;
}
</style>

<style scoped>
.bordered-table th,
.bordered-table td {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.qty {
  max-width: 7rem;
}
.product {
  width: 40%;
}
</style>
