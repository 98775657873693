<script setup>
import { computed } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { useFreight } from '@/utils/useFreight'
import usePdfGenerator from '@/utils/usePdfGenerator'
import { formatDate, formatKilograms } from '@/helpers/stringHelpers'

const { generatingPdf, exportPdf } = usePdfGenerator()
const store = useStore()
const pigInfoList = computed(() => store.getters.pigInfoList)
const startDatePig = computed(() => store.getters.startDatePig)
const pigInfoTotals = computed(() => store.getters.pigInfoTotals)
const totalsArray = computed(() => {
  const array = []
  for (const [key, value] of Object.entries(pigInfoTotals.value)) {
    if (value > 0) {
      array.push(`${key}: ${value}`)
    }
  }
  return array
})
const { getFreightText } = useFreight(store)

const subTitle = computed(
  () => `Pigs Summary for Kill Date: ${formatDate(startDatePig.value)}`
)
const fileName = computed(
  () => `Pigs-Table-[${formatDate(startDatePig.value, '-')}]`
)

const tableHeaders = [
  'Body',
  'P2',
  'Sex',
  'Hot Weight',
  'Grade',
  'Carcas Type',
  'Pig Part',
  'Destination'
]

const getTransportName = (allocation) => {
  let firstTransportId = allocation.transport_id.trim().split(',')[0]
  const freightText = getFreightText(firstTransportId, 'name')
  const transport =
    freightText === '-' || freightText === '' ? 'NO TRANSPORT' : freightText
  return transport
}

const allocationByTrasnport = computed(() => {
  const allocations = new Map()
  const totals = new Map()

  pigInfoList.value.forEach((pig) => {
    pig.allocation.forEach((alloc) => {
      const transportName = getTransportName(alloc)
      if (!allocations.has(transportName)) {
        allocations.set(transportName, [])
        totals.set(transportName, new Map())
      }

      const partName = alloc.part.part
      const allocationDetail = [
        pig.body_no,
        pig.p2,
        pig.sex,
        formatKilograms(pig.HSCW),
        pig.grade,
        pig.carcase_type,
        partName,
        alloc.destination.name
      ]
      allocations.get(transportName).push(allocationDetail)

      const bodyPart = partName
      const transportTotals = totals.get(transportName)

      if (!transportTotals.has(bodyPart)) {
        transportTotals.set(bodyPart, 0)
      }
      transportTotals.set(bodyPart, transportTotals.get(bodyPart) + 1)
    })
  })
  return { allocations, totals }
})

const sortByCompanyName = (map) => {
  for (const value of map.values()) {
    value.sort((a, b) => a[a.length - 1].localeCompare(b[b.length - 1]))
  }
}

const generatePdf = () => {
  const { allocations, totals } = allocationByTrasnport.value
  const config = {
    individualTotals: totals
  }

  sortByCompanyName(allocations)
  exportPdf(
    totalsArray.value,
    subTitle.value,
    allocations,
    tableHeaders,
    fileName.value,
    config
  )
}
</script>

<template>
  <v-btn
    color="warning"
    small
    class="my-1"
    @click="generatePdf"
    :loading="generatingPdf"
    ><font-awesome-icon icon="fas fa-file-export" class="me-2" />Export</v-btn
  >
</template>
