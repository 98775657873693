<script setup>
import { useStore } from '@/utils/VuexHelpers'
import BaseDynamicForm from '../base/BaseDynamicForm.vue'
import { ref, defineProps, computed, watch } from 'vue'

const props = defineProps(['edit', 'details'])
const store = useStore()
const loadingProducts = computed(() => store.getters.loadingProducts)

const dialog = ref(false)

const fields = ref([
  {
    key: 'product',
    label: 'Product Name',
    value: '',
    type: 'text',
    rules: [(v) => !!v || 'Name is required'],
    placeholder: 'Enter product name...'
  },
  {
    key: 'code',
    label: 'Product Code',
    value: null,
    type: 'number',
    min: 0,
    step: 1,
    rules: [(v) => !!v || 'Code is required'],
    placeholder: 'Enter product code...'
  },
  {
    key: 'type',
    label: 'Product Type',
    value: '',
    type: 'text',
    placeholder: 'Enter product type...'
  },
  {
    key: 'pack',
    label: 'Pack',
    value: '',
    type: 'text',
    placeholder: 'Enter pack...'
  },
  {
    key: 'soaker',
    label: 'Soaker',
    value: false,
    type: 'checkbox',
    placeholder: 'Enter soaker...'
  },
  {
    key: 'insert',
    label: 'Insert',
    value: false,
    type: 'checkbox',
    placeholder: 'Enter insert...'
  },
  {
    key: 'pcs_bag',
    label: 'Pcs/bag',
    value: '',
    type: 'text',
    placeholder: 'Enter Pcs per bag...'
  },
  {
    key: 'pcs_ctn',
    label: 'Pcs/ctn',
    value: '',
    type: 'text',
    placeholder: 'Enter Pcs per ctn...'
  },
  {
    key: 'cnt_base',
    label: 'Cnt Base',
    value: '',
    type: 'text',
    placeholder: 'Enter cnt base...'
  },
  {
    key: 'ref_status',
    label: 'Ref Status',
    value: '',
    type: 'text',
    placeholder: 'Enter ref status...'
  },
  {
    key: 'est_yield',
    label: 'Est Yield',
    value: 0,
    type: 'number',
    min: 0,
    placeholder: 'Enter est yield...'
  },
  {
    key: 'price_kg',
    label: 'Price/kg',
    value: 0,
    type: 'number',
    min: 0,
    step: 0.01,
    prefix: '$',
    placeholder: 'Enter price/kg...'
  },
  {
    key: 'barcode',
    label: 'GS1 Barcode',
    value: '',
    type: 'text',
    placeholder: 'Enter GS1 barcode...'
  },
  {
    key: 'comment',
    label: 'Comment',
    value: '',
    type: 'text',
    placeholder: 'Enter comment...'
  }
])

const formHeader = computed(
  () => `${props.edit ? 'Update Product Detais' : 'Create new Product'}`
)
const isLoading = computed(() => {
  if (props.edit) return loadingProducts.value === `update-${props.details.id}`
  return loadingProducts.value === 'create'
})

const handleFormSubmit = async (formData) => {
  const soaker = formData.soaker === '' ? false : formData.soaker
  const insert = formData.insert === '' ? false : formData.insert
  const est_yield = formData.est_yield === '' ? 0 : formData.est_yield
  const price_kg = formData.price_kg === '' ? 0 : formData.price_kg
  const payload = { ...formData, soaker, insert, est_yield, price_kg }
  let action = 'createProduct'

  if (props.edit) {
    payload.id = props.details.id
    action = 'updateProduct'
  }

  const res = await store.dispatch(action, { payload })
  if (res === 'success') {
    dialog.value = false
    store.dispatch('fetchProducts')
  }
}

watch(
  () => props.details,
  (newDetails) => {
    if (!props.edit) return
    fields.value = fields.value.map((field) => ({
      ...field,
      value: newDetails[field.key] ?? field.value
    }))
  },
  { deep: true, immediate: true }
)
</script>
<template>
  <v-dialog v-model="dialog" scrollable max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="edit" small text color="info" v-bind="attrs" v-on="on"
        ><font-awesome-icon icon="fa-solid fa-edit" class="mr-1" />Edit</v-btn
      >
      <v-btn v-else color="primary" class="me-2" v-bind="attrs" v-on="on">
        <v-icon left>mdi-plus</v-icon>New Product</v-btn
      >
    </template>
    <v-card>
      <v-card-text class="pt-4">
        <h6 class="text-button text-light-blue text-start">
          {{ formHeader }}
        </h6>
        <BaseDynamicForm
          :formFields="fields"
          @submit="handleFormSubmit"
          :loading="isLoading"
          :resetOnInputChange="edit"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
