import { render, staticRenderFns } from "./GrowerPricing.vue?vue&type=template&id=b53ddb08&scoped=true"
import script from "./GrowerPricing.vue?vue&type=script&lang=js"
export * from "./GrowerPricing.vue?vue&type=script&lang=js"
import style0 from "./GrowerPricing.vue?vue&type=style&index=0&id=b53ddb08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b53ddb08",
  null
  
)

export default component.exports