<script setup>
import { useStore } from '@/utils/VuexHelpers'
import { computed } from 'vue'

const store = useStore()
const orderSummary = computed(() => store.getters.orderSummary)
const porkerOrdersSorted = computed(() => store.getters.porkerOrdersSorted)

const getItems = (item, data) => {
  let value = 0
  data.forEach((element) => {
    if (element.product?.product === item) {
      value += parseInt(element.quantity)
    }
  })
  return value > 0 ? value : ''
}
</script>

<template>
  <b-collapse id="order-summary-collapse">
    <b-table-simple
      small
      hover
      bordered
      responsive
      class="text-left mt-2"
      id="total-summary"
    >
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Code</b-th>
          <b-th>Customer</b-th>
          <b-th v-for="(item, key) in orderSummary" :key="item.id">{{
            key
          }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item in porkerOrdersSorted" :key="item.id">
          <b-td>{{ item.customer.code }}</b-td>
          <b-td class="text-nowrap">{{ item.customer.company.name }}</b-td>
          <b-td v-for="(part, key) in orderSummary" :key="part.id">{{
            getItems(key, item.ordered_items)
          }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr variant="secondary" class="w-700 text-left">
          <b-td colspan="2" class=""> Grand Total </b-td>
          <b-td v-for="(part, key) in orderSummary" :key="part.id">{{
            orderSummary[key]
          }}</b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-collapse>
</template>

<style></style>
