<script setup>
import SingleOrder from '@/components/orders-page/SingleOrder.vue'
import BaseLoadingSpinner from '@/components/base/BaseLoadingSpinner.vue'
import OrderFormDialog from './OrderFormDialog.vue'

import { defineProps, computed } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { WEEK_DAYS } from '@/constants/general'
import { getDateByYearAndWeek } from '@/utils/DateHelpers'

const store = useStore()
const props = defineProps(['orders'])

const filtersOrders = computed(() => store.getters.filtersOrders)
const loadingOrders = computed(() => store.getters.loadingOrders)
const currentOrderTab = computed(() => store.getters.currentOrderTab)

const getDateOfWeek = (dayIndex) => {
  const { year, week } = filtersOrders.value
  const date = getDateByYearAndWeek(year.value, week.value, dayIndex)
  return date
}

const tab = computed({
  get: () => currentOrderTab.value,
  set: (value) => {
    store.commit('SET_ORDER_TAB', value)
    store.commit('SET_ORDER_SUMMARY_DAYS', [value])
    store.commit('UPDATE_SUMMARY')
  }
})

const weeklyOrders = computed(() => {
  let ordersByDay = {}

  WEEK_DAYS.forEach((day, index) => {
    ordersByDay[day] = props.orders.filter(
      (order) => order.date === getDateOfWeek(index)
    )
  })

  return ordersByDay
})
</script>

<template>
  <div>
    <v-tabs v-model="tab" background-color="#e4eff9" color="primary" grow>
      <v-tab v-for="(orders, day) in weeklyOrders" :key="day">
        <v-badge
          :color="WEEK_DAYS[tab] === day ? '#4685c1' : 'blue-grey lighten-3'"
          :content="orders.length === 0 ? '-' : orders.length"
          offset-y="10"
          offset-x="1"
          class="order-badge"
          >{{ day }}</v-badge
        >
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(orders, day) in weeklyOrders" :key="day">
        <BaseLoadingSpinner text="Orders" v-if="loadingOrders === 'list'" />
        <v-card flat v-else>
          <v-card-text class="text-start pb-0">
            <OrderFormDialog
              :buttonSmall="true"
              :selectedDate="getDateOfWeek(tab)"
            />
          </v-card-text>
          <v-card-text v-for="order in orders" :key="order.id">
            <SingleOrder :orderDetails="order" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
