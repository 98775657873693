<template>
  <font-awesome-icon
    icon="fas fa-file-invoice-dollar"
    v-b-tooltip.hover="{
      variant: 'warning',
      title: title
    }"
    class="icon-button"
  />
</template>

<script>
export default {
  name: 'IconDollar',
  props: {
    title: {
      type: String,
      default: 'Show Charges'
    }
  }
}
</script>

<style scoped>
.icon-button {
  cursor: pointer;
}
.icon-button:hover {
  color: #f7d514;
}
</style>
