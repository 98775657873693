<script setup>
import { computed, defineProps } from 'vue'
import { formatDate } from '@/helpers/stringHelpers'
import usePdfGenerator from '@/utils/usePdfGenerator'
import { useStore } from '@/utils/VuexHelpers'

const TOTALS_TITLE = 'Total Product To Be Boned:  '

const props = defineProps(['boningItems', 'totals'])
const { generatingPdf, exportPdf } = usePdfGenerator()
const store = useStore()
const startDateBoning = computed(() => store.getters.startDateBoning)
const weekDay = computed(() => store.getters.weekDay)

const subTitle = computed(
  () => `Boning for ${weekDay.value.day}: ${formatDate(startDateBoning.value)}`
)
const fileName = computed(
  () =>
    `Boning-${weekDay.value.day}-[${formatDate(startDateBoning.value, '-')}]`
)
const totalsArray = computed(() => {
  const totals = Object.entries(props.totals)
    .filter((item) => item[1] > 0)
    .map(([key, value]) => `${key}: ${value}`)
  if (totals.length === 0) {
    totals.push('-')
  }
  return totals
})
const tableHeaders = [
  'Code',
  'Name',
  'Qty',
  'Pack',
  'Psc/Bag',
  'Soaker',
  'Insert',
  'Bags/Ctn',
  'Ctn Base',
  'Lid',
  'Ref. Status',
  'Comments'
]

const boningByType = computed(() => {
  const products = new Map()
  props.boningItems.forEach((product) => {
    const type = product.product_type
    if (!products.has(type)) {
      products.set(type, [])
    }
    const soaker = product.BooyongProductList.soaker ? 'Y' : 'N'
    const insert = product.BooyongProductList.insert ? 'Y' : 'N'

    const productDetails = [
      product.productcode,
      product.product_name,
      product.quantity,
      product.BooyongProductList.pack,
      product.BooyongProductList.pcs_bag,
      soaker,
      insert,
      product.BooyongProductList.pcs_ctn,
      product.BooyongProductList.cnt_base,
      product.BooyongProductList.lid,
      product.BooyongProductList.ref_status,
      product.comments
    ]
    products.get(type).push(productDetails)
  })
  return products
})

const generatePdf = () => {
  const config = {
    orientation: 'landscape',
    theme: 'grid',
    totalsPrefix: TOTALS_TITLE
  }
  exportPdf(
    totalsArray.value,
    subTitle.value,
    boningByType.value,
    tableHeaders,
    fileName.value,
    config
  )
}
</script>
<template>
  <v-btn @click="generatePdf" small color="warning" :loading="generatingPdf">
    <font-awesome-icon icon="fas fa-file-export" class="mr-2" />Export</v-btn
  >
</template>
