import axios from 'axios'

const state = {
  displayNames: {
    //sf1: 'Weekly Order Sheets',
    sf2: 'Feedback Pigs',
    //sf5: 'Booyong Weekly Report',
    //sf7a: 'Boning Spec Sheet',
    sf8a: 'Carton Production Details',
    // sf8b: 'Carton Production by Loctn',
    sf8c: 'Run-In Report',
    sf9: 'Order Report',
    sf10: 'Weight Listing',
    carton_export: 'Export Carton Listing'
  },
  loadingFiles: [],
  //sf1: null,
  sf2: null,
  //sf5: null,
  //sf7a: null,
  sf8a: null,
  // sf8b: null,
  sf8c: null,
  sf9: null,
  sf10: null,
  carton_export: null
}
const getters = {
  getFileByName: (state) => (fileName) => {
    return state[fileName]
  },
  getDisplayName: (state) => (file) => {
    return state.displayNames[file]
  },
  fileLoading: (state) => (file) => {
    let index = state.loadingFiles.indexOf(file)
    if (index !== -1) {
      return true
    }
    return false
  }
}
const actions = {
  async uploadFile(
    { getters, commit, rootGetters, dispatch },
    { vm, fileName, force }
  ) {
    let URI = rootGetters.baseUrl + 'imports/' + fileName
    if (force) URI += '?insert_only=false'
    let bodyFormData = new FormData()
    bodyFormData.append('file', getters.getFileByName(fileName))
    commit('ADD_LOADING_FILES', fileName)
    try {
      await axios.post(URI, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      const message = 'Upload successful!'
      vm.$bvToast.toast(message, {
        title: getters.getDisplayName(fileName),
        variant: 'success',
        solid: true
      })
    } catch (error) {
      if (error.response.status === 409) {
        dispatch('confirmForceUpload', {
          vm: vm,
          message: error.response.data.detail,
          file: fileName
        })
        console.log(error)
        return
      }
      dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: getters.getDisplayName(fileName),
          messagePrefix: 'Upload Failed! '
        },
        { root: true }
      )
    } finally {
      commit('REMOVE_LOADING_FILES', fileName)
    }
  },
  async confirmForceUpload({ dispatch }, { vm, message, file }) {
    const titleVNode = await dispatch('generateTitleNode', vm)
    const messageVNode = await dispatch('generateMessageNode', {
      vm: vm,
      message: message
    })
    vm.$bvModal
      .msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'FORCE File Upload',
        cancelTitle: 'CANCEL',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then((value) => {
        if (value === true)
          dispatch('uploadFile', { vm: vm, fileName: file, force: true })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async generateTitleNode(attr, vm) {
    const h = vm.$createElement
    const titleVNode = h('div', [
      h('font-awesome-icon', {
        props: { icon: ['fas', 'fa-exclamation-triangle'] },
        class: ['mr-2', 'text-danger']
      }),
      'Duplicated Entry Error',
      h('font-awesome-icon', {
        props: { icon: ['fas', 'fa-exclamation-triangle'] },
        class: ['ml-2', 'text-danger']
      })
    ])
    return titleVNode
  },
  async generateMessageNode(attr, { vm, message }) {
    const h = vm.$createElement
    const messageVNode = h('div', { class: ['foobar'] }, [
      h('p', { class: ['text-left'] }, [
        'It seems like the information you are attempting to upload already exists in the database.'
      ]),
      h('b-alert', { props: { show: true, variant: 'danger' } }, [message]),
      h('p', { class: ['text-left'] }, [
        'Do you want to proceed and replace the existing entry in the database?'
      ]),
      h('p', { class: ['text-left'] }, [
        'If yes, click on "',
        h('b', { class: ['text-danger'] }, ['FORCE File Upload']),
        '". If not, click on "CANCEL".'
      ])
    ])
    return messageVNode
  }
}
const mutations = {
  SET_FILE: (state, payload) => (state[payload.name] = payload.file),
  ADD_LOADING_FILES: (state, payload) => state.loadingFiles.push(payload),
  REMOVE_LOADING_FILES: (state, payload) => {
    let index = state.loadingFiles.indexOf(payload)
    if (index !== -1) {
      state.loadingFiles.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
