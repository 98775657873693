<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'
import { formatDate } from '@/helpers/stringHelpers.js'
import { parseDate, isBeforeDate } from '@/utils/DateHelpers'

const props = defineProps([
  'date',
  'label',
  'icon',
  'minDate',
  'minDateErrorMessage',
  'maxDate',
  'maxDateErrorMessage'
])
const emit = defineEmits(['update:date'])

const dateMenu = ref(false)
const dateFormatted = computed(() => {
  return formatDate(props.date)
})
const localDate = ref(props.date)

const errorMessages = computed(() => {
  if (!props.minDate && !props.maxDate) return []

  const errorMessages = []
  const valueDate = parseDate(props.date)

  if (props.minDate) {
    const minDate = parseDate(props.minDate)
    if (isBeforeDate(valueDate, minDate)) {
      errorMessages.push(props.minDateErrorMessage)
    }
  }

  if (props.maxDate) {
    const maxDate = parseDate(props.maxDate)
    if (isBeforeDate(maxDate, valueDate)) {
      errorMessages.push(props.maxDateErrorMessage)
    }
  }

  return errorMessages
})

const updateDate = (value) => {
  emit('update:date', value)
  dateMenu.value = false
}
</script>

<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="date-input"
        v-model="dateFormatted"
        :label="label"
        :prepend-icon="icon ? '$calendar' : ''"
        readonly
        outlined
        dense
        v-bind="attrs"
        v-on="on"
        hide-details="auto"
        :error-messages="errorMessages"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="localDate"
      no-title
      @input="updateDate"
      :first-day-of-week="1"
      locale="en-au"
      :min="minDate ? minDate : ''"
      :max="maxDate ? maxDate : ''"
      show-adjacent-months
    ></v-date-picker>
  </v-menu>
</template>
