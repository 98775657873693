<script setup>
import SideFilter from '@/components/SideFilter.vue'
import { computed, ref } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import useWeekDateFilter from '@/utils/useWeekDateFilter'

const DATE_START_STR = 'loadout_start'
const DATE_END_STR = 'loadout_end'

const store = useStore()
const filtersLoad = computed(() => store.getters.filtersLoad)
const loadoutSideFilter = ref(null)

const { updateDateFilter } = useWeekDateFilter(
  'filtersLoad',
  'SET_FILTER_LOAD',
  DATE_START_STR,
  DATE_END_STR
)

const updateWeekFilter = (dateRange) => {
  const { weekNr, startDay, endDay, year } = dateRange
  store.commit('SET_FILTER_LOAD', {
    field: 'week',
    value: weekNr
  })
  store.commit('SET_FILTER_LOAD', {
    field: DATE_START_STR,
    value: startDay
  })
  store.commit('SET_FILTER_LOAD', {
    field: DATE_END_STR,
    value: endDay
  })
  if (filtersLoad.value.year.value !== year) {
    store.commit('SET_FILTER_LOAD', { field: 'year', value: year })
  }
  store.dispatch('fetchLoadoutList')
}
const updateYearFilter = ({ year: inputYear }) => {
  const { year, week } = filtersLoad.value
  const cleanYear = parseInt(inputYear.trim())

  if (cleanYear === year.value) return
  store.commit('SET_FILTER_LOAD', { field: 'year', value: cleanYear })

  if (!week.value) return
  loadoutSideFilter.value.generateWeekDays(week.value, cleanYear)
}
const updateFilter = async ({ value, field }) => {
  if (field === DATE_START_STR || field === DATE_END_STR) {
    updateDateFilter({ value, field })
  } else {
    store.commit('SET_FILTER_LOAD', { value, field })
  }
  store.dispatch('fetchLoadoutList')
}
const removeFilters = async () => {
  await store.dispatch('clearFiltersLoad')
  store.dispatch('fetchLoadoutList')
}
</script>

<template>
  <SideFilter
    :filterList="filtersLoad"
    @input="updateFilter"
    @inputWeek="updateWeekFilter"
    @inputYear="updateYearFilter"
    @clear="removeFilters()"
    ref="loadoutSideFilter"
    class="mt-0"
  />
</template>
