<template>
  <font-awesome-icon
    v-b-tooltip.hover="{
      variant: 'danger',
      title: 'Delete',
      placement: position
    }"
    :icon="['far', 'trash-alt']"
    class="icon-button"
  />
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'top'
    }
  }
}
</script>

<style scoped>
.icon-button {
  cursor: pointer;
}
.icon-button:hover {
  color: #dc3545;
}
</style>
