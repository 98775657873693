import { parse, format, isDate } from 'date-fns'

const kilogramFormatter = new Intl.NumberFormat('en-AU', {
  style: 'unit',
  unit: 'kilogram',
  unitDisplay: 'narrow'
})

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const capitalise = (value) => {
  return value
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const formatDate = (value, separator = '/') => {
  if (!value) return '-'

  const dateFormat = `dd${separator}MM${separator}yyyy`
  let date = null
  if (isDate(value)) {
    date = value
  } else {
    const datePart = value.split(' ')[0]
    date = parse(datePart, 'yyyy-MM-dd', new Date())
  }

  return format(date, dateFormat)
}

export const formatCurrency = (value) => {
  return isNaN(value) ? '-' : currencyFormatter.format(value)
}

export const formatKilograms = (value, decimals = 1) => {
  const parsedValue = parseFloat(value).toFixed(decimals)

  if (parsedValue === 'NaN') return '-'

  return kilogramFormatter.format(parsedValue)
}

export const formatDecimals = (number, decimal = 2) => {
  if (isNaN(number)) return (0).toFixed(decimal)
  return parseFloat(number).toFixed(decimal)
}

export const uuid = () => {
  let uuidValue = '',
    k,
    randomValue
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0

    if (k == 8 || k == 12 || k == 16 || k == 20) {
      uuidValue += '-'
    }
    uuidValue += (
      k == 12 ? 4 : k == 16 ? (randomValue & 3) | 8 : randomValue
    ).toString(16)
  }
  return uuidValue
}
