import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Auth } from 'aws-amplify'

import LoginPage from '../views/LoginPage.vue'
import FileUploadView from '../views/FileUploadView'
import OrdersView from '../views/OrdersView'
import CustomersView from '../views/CustomersView'
import SuppliersView from '../views/SuppliersView'
import TransportView from '../views/TransportView'
import PigInformationView from '../views/PigInformationView'
import BoningView from '../views/BoningView'
import LoadoutView from '../views/LoadoutView'
import ProductStockView from '../views/ProductStockView'
import ProductsView from '../views/ProductsView'
import PigPartsView from '../views/PigPartsView'
import ReconcileOrdersView from '../views/ReconcileOrdersView'
import ProcessingCostsView from '../views/ProcessingCostsView'
import LoadoutReportView from '../views/LoadoutReportView'
import GrowerReportView from '../views/GrowerReportView'
import SystemSettings from '../views/SystemSettings.vue'

Vue.use(VueRouter)

async function guardMyroute(to, from, next) {
  let isAuthenticated = false
  let currentUser = store.getters.authUser
  if (!currentUser) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        store.commit('SET_AUTH_USER', user)
        isAuthenticated = true
      }
    } catch (err) {
      console.log(err)
    }
  } else {
    isAuthenticated = true
  }
  if (isAuthenticated) {
    next() // allow to enter route
  } else {
    next('/login') // go to '/login';
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: FileUploadView,
    beforeEnter: guardMyroute
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/resetpass',
    name: 'resetPass',
    component: LoginPage
  },
  {
    path: '/resetpass/force',
    name: 'resetPassForce',
    component: LoginPage
  },
  {
    path: '/resetpass/confirm',
    name: 'resetPassConfirm',
    component: LoginPage
  },
  {
    path: '/resetpass/success',
    name: 'resetPassSuccess',
    component: LoginPage
  },
  {
    path: '/upload-files',
    name: 'upload',
    component: FileUploadView,
    beforeEnter: guardMyroute
  },
  {
    path: '/orders',
    name: 'ordes',
    component: OrdersView,
    beforeEnter: guardMyroute
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersView,
    beforeEnter: guardMyroute
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: SuppliersView,
    beforeEnter: guardMyroute
  },
  {
    path: '/transport',
    name: 'transport',
    component: TransportView,
    beforeEnter: guardMyroute
  },
  {
    path: '/pig-info',
    name: 'pig',
    component: PigInformationView,
    beforeEnter: guardMyroute
  },
  {
    path: '/reconcile-orders',
    name: 'reconcileOrders',
    component: ReconcileOrdersView,
    beforeEnter: guardMyroute
  },
  {
    path: '/processing-costs',
    name: 'processingCosts',
    component: ProcessingCostsView,
    beforeEnter: guardMyroute
  },
  {
    path: '/grower-report',
    name: 'GrowerReport',
    component: GrowerReportView,
    beforeEnter: guardMyroute
  },
  {
    path: '/boning',
    name: 'bone',
    component: BoningView,
    beforeEnter: guardMyroute
  },
  {
    path: '/loadout',
    name: 'load',
    component: LoadoutView,
    beforeEnter: guardMyroute
  },
  {
    path: '/loadout-report',
    name: 'loadReport',
    component: LoadoutReportView,
    beforeEnter: guardMyroute
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView,
    beforeEnter: guardMyroute
  },
  {
    path: '/pig-parts',
    name: 'pigParts',
    component: PigPartsView,
    beforeEnter: guardMyroute
  },
  {
    path: '/product-stock',
    name: 'productStock',
    props: (route) => ({ tab: route.query.tab }),
    component: ProductStockView,
    beforeEnter: guardMyroute
  },
  {
    path: '/system-settings',
    name: 'systemSettings',
    component: SystemSettings,
    beforeEnter: guardMyroute
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    // always scroll to top
    return { x: 0, y: 0 }
  },
  routes
})

export default router
