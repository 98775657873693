<template>
  <b-btn v-bind="$attrs"
    ><div class="d-flex align-items-center">
      <b-spinner v-if="loading" small class="align-middle mr-2"></b-spinner>
      <font-awesome-icon v-else-if="icon" :icon="icon" class="mr-2" />
      <slot>Submit</slot>
    </div></b-btn
  >
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Array,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {}
  }
}
</script>
