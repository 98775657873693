import axios from 'axios'

import { PER_PAGE_MAX } from '@/constants/pagination'
import { WEEK_OPTIONS } from '@/constants/general'
import {
  getCurrentDay,
  getDayValues,
  getISOWeekNumber,
  getYear,
  formatDateWithAddedDays
} from '@/utils/DateHelpers'
import Vue from 'vue'

const tomorrow = formatDateWithAddedDays(getCurrentDay(), 1)

const state = {
  boningList: [],
  loadingBone: '',

  //pagination
  perPageBone: PER_PAGE_MAX,
  pageNrBone: 0,

  //sorting
  sortbyBone: '',
  ascBone: false,

  //filters
  filtersBone: {
    boning_date: {
      type: 'date',
      label: 'Boning Day',
      value: tomorrow,
      readOnly: true
    },
    week: {
      type: 'week',
      label: 'Week Number',
      value: getISOWeekNumber(tomorrow)
    },
    year: { type: 'year', value: getYear(tomorrow), hidden: true },
    weekDay: {
      type: 'selectWeekDay',
      name: 'day',
      options: WEEK_OPTIONS,
      label: 'Day of the week',
      noReduce: true,
      value: getDayValues(tomorrow)
    }
  }
}
const getters = {
  boningList: (state) => state.boningList,
  loadingBone: (state) => state.loadingBone,
  perPageBone: (state) => state.perPageBone,
  pageNumberBone: (state) => state.pageNrBone,
  sortbyBone: (state) => state.sortbyBone,
  ascBone: (state) => state.ascBone,
  filtersBone: (state) => state.filtersBone,
  startDateBoning: (state) => state.filtersBone.boning_date.value,
  weekDay: (state) => state.filtersBone.weekDay.value,
  offsetBone: (state) => {
    return state.perPageBone * state.pageNrBone
  }
}
const actions = {
  async fetchBoningList({ commit, dispatch, getters }) {
    commit('SET_LOADING_BONE', 'list')
    const res = await dispatch(
      'fetch',
      {
        endpoint: 'boninginstruction/',
        parameters: {
          limit: getters.perPageBone,
          offset: getters.offsetBone,
          sort_by: getters.sortbyBone,
          ascending: getters.ascBone
        },
        filters: getters.filtersBone
      },
      { root: true }
    )
    if (res.error) {
      dispatch('showError', {
        error: res.error,
        messagePrefix: 'Error while loading Boning Instructions! '
      })
      commit('SET_LOADING_BONE', '')
      return
    }
    res.forEach((item) => {
      item.instructions.boning_instruction =
        item.instructions.boning_instruction.map((product) => {
          return {
            ...product,
            product_name: product.BooyongProductList.product,
            product_type: product.BooyongProductList.type
          }
        })
    })
    commit('SET_BONING_LIST', res)
    commit('SET_LOADING_BONE', '')
  },
  async createBoningInstructions(
    { rootGetters, commit, dispatch },
    { payload }
  ) {
    const URI = rootGetters.baseUrl + 'boninginstruction/'
    try {
      commit('SET_LOADING_BONE', `create`)
      await axios.post(URI, payload)
      dispatch('showMessage', 'Boning Instructions created successfuly!')
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_BONE', '')
    }
  },
  async updateBoningInstructions(
    { rootGetters, commit, dispatch, getters },
    { payload, onSuccess }
  ) {
    const URI = `${rootGetters.baseUrl}boninginstruction/${payload.id}`
    try {
      commit('SET_LOADING_BONE', `update-${payload.id}`)
      const { data: res } = await axios.patch(URI, payload)
      dispatch('showMessage', 'Boning Instructions updated successfuly!')
      if (onSuccess === 'insert') {
        res.instructions.boning_instruction =
          res.instructions.boning_instruction.map((product) => {
            return {
              ...product,
              product_name: product.BooyongProductList.product,
              product_type: product.BooyongProductList.type
            }
          })

        const resId = res.instructions.id
        const instructionIndex = getters.boningList.findIndex(
          (set) => set.instructions.id === resId
        )

        if (instructionIndex !== -1) {
          commit('UPDATE_ISTRUCTIONS_IN_LIST', {
            index: instructionIndex,
            newInstructions: res
          })
        }
      }
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_BONE', '')
    }
  },
  async updateBoningItem(
    { rootGetters, commit, dispatch, getters },
    { payload, onSuccess = '', showMessage = true }
  ) {
    const URI =
      rootGetters.baseUrl + 'boninginstruction/instruction/' + payload.id
    try {
      commit('SET_LOADING_BONE', `update-item-${payload.id}`)
      const res = await axios.patch(URI, payload)

      if (showMessage) {
        dispatch('showMessage', 'Boning Instructions updated successfuly!')
      }

      if (onSuccess === 'insert') {
        const instructionSetId = res.data.instructionset_id
        const instructionSet = getters.boningList.find(
          (set) => set.instructions.id === instructionSetId
        )

        if (instructionSet) {
          const resId = res.data.id
          const resName = res.data.BooyongProductList.product
          const resType = res.data.BooyongProductList.type

          const index =
            instructionSet.instructions.boning_instruction.findIndex((item) => {
              return item.id === resId && item.product_name === resName
            })
          if (index !== -1) {
            const data = {
              ...res.data,
              product_name: resName,
              product_type: resType
            }
            commit('UPDATE_BONING_ITEM_IN_LIST', {
              setIndex: getters.boningList.indexOf(instructionSet),
              index,
              newItem: data
            })
          }
        }
      }

      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_BONE', '')
    }
  }
}
const mutations = {
  SET_BONING_LIST: (state, payload) => (state.boningList = payload),
  UPDATE_ISTRUCTIONS_IN_LIST: (state, { index, newInstructions }) => {
    Vue.set(state.boningList, index, newInstructions)
  },
  UPDATE_BONING_ITEM_IN_LIST: (state, { setIndex, index, newItem }) => {
    Vue.set(
      state.boningList[setIndex].instructions.boning_instruction,
      index,
      newItem
    )
  },
  SET_PAGE_NR_BONE: (state, payload) => (state.pageNrBone = payload),
  SET_LOADING_BONE: (state, payload) => (state.loadingBone = payload),
  SET_PER_PAGE_BONE: (state, payload) => (state.perPageBone = payload),
  SET_SORT_BY_BONE: (state, payload) => (state.sortbyBone = payload),
  SET_ASC_BONE: (state, payload) => (state.ascBone = payload),
  SET_FITLER_BONE: (state, payload) => {
    state.filtersBone[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
