<script setup>
import BaseAlert from '@/components/base/BaseAlert.vue'
import LoadoutFilter from '@/components/loadout-page/LoadoutFilter.vue'

import { computed, getCurrentInstance } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { formatDate } from '@/helpers/stringHelpers'
import { INSTRUCTIONS } from '@/constants/pagination'
import { PORKER_CODE_MAX } from '@/constants/product'
import { getDayValues, parseDate } from '@/utils/DateHelpers'
import LoadoutPdfExport from '@/components/loadout-page/LoadoutPdfExport.vue'
import BaseLoadingSpinner from '@/components/base/BaseLoadingSpinner.vue'
import BaseStatusChip from '@/components/base/BaseStatusChip.vue'

const BONING_DATE_FORMAT = 'dd/MM/yyyy'

const vm = getCurrentInstance().proxy
const store = useStore()
const loadingLoad = computed(() => store.getters.loadingLoad)
const freightList = computed(() => store.getters.freightList)
const loadingTransport = computed(() => store.getters.loadingTransport)

const groupedLoadout = computed(() => {
  const sortedArray = store.getters.loadoutList
    .filter((item) => item.productcode > PORKER_CODE_MAX)
    .sort((a, b) => {
      const companyA = a.name
      const companyB = b.name
      if (companyA && companyB) {
        return companyA.localeCompare(companyB)
      }
      return 0
    })
  return groupByTransport(sortedArray, 'name')
})

const groupByTransport = (items, transport) => {
  return items.reduce((acc, item) => {
    const key = item[transport]
    if (!acc.has(key)) {
      acc.set(key, [])
    }
    const boningDate = formatDate(item.boning_date)
    const product = {
      loadout_date: item.loadout_date,
      transport: item.name,
      transportCode: item.code,
      productName: item.BooyongProductList.product,
      quantity: item.quantity,
      productcode: item.productcode,
      customer: item.Company.name,
      stock: item?.order_detail?.stock ? true : false,
      boningDates: new Set([boningDate])
    }
    let found = acc
      .get(key)
      .find(
        (p) =>
          p.loadout_date === product.loadout_date &&
          p.customer === product.customer &&
          p.productName === product.productName &&
          p.stock === product.stock
      )

    if (found) {
      found.quantity += product.quantity
      found.boningDates.add(boningDate)
    } else {
      acc.get(key).push(product)
    }
    return acc
  }, new Map())
}

const headers = [
  { text: 'Code', value: 'productcode', sortable: true, groupable: false },
  { text: 'Name', value: 'productName', sortable: true, groupable: false },
  { text: 'Destination', value: 'customer', groupable: true },
  { text: 'No. Ctns', value: 'quantity', sortable: false, groupable: false },
  {
    text: 'Loadout Date',
    value: 'loadout_date',
    sortable: false,
    groupable: false
  },
  {
    text: 'Boning Days',
    value: 'boningDates',
    sortable: false,
    groupable: false
  }
]

const getActiveWeekdays = (items) => {
  const activeDays = new Set()
  items.forEach((item) => {
    const shortDay = getDayValues(item.loadout_date).day
    activeDays.add(shortDay)
  })
  return Array.from(activeDays)
}

// ON CREATED
store.commit('SET_USING_VUETIFY', true)
store.commit('SET_ACTIVE', INSTRUCTIONS)
store.dispatch('fetchProductNames', { vm })
store.dispatch('fetchLoadoutList')
if (freightList.value.length === 0 && !loadingTransport.value !== 'freight') {
  store.dispatch('fetchFreight', { vm })
}
</script>

<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <LoadoutFilter />
        <div class="content-page mt-0">
          <v-row no-gutters class="mt-2 mb-4 align-items-center">
            <div class="page-title-box me-auto">
              <h4 class="page-title">Loadout CARTON</h4>
            </div>
            <LoadoutPdfExport :loadoutItems="groupedLoadout" />
          </v-row>
          <v-card loading class="mb-4" v-if="loadingLoad === 'list'">
            <BaseLoadingSpinner :text="'Loadout Instructions'" />
            <v-card-text>
              <v-col cols="12" md="10" lg="5">
                <v-skeleton-loader
                  class="mb-4"
                  type="card-heading, paragraph"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="card-heading, paragraph"
                ></v-skeleton-loader> </v-col
            ></v-card-text>
          </v-card>
          <template v-else>
            <v-card
              :loading="loadingLoad == 'list'"
              v-for="items in groupedLoadout"
              :key="items[0]"
              class="mb-3"
              outlined
            >
              <v-card-title>
                <font-awesome-icon
                  icon="fas fa-truck-arrow-right"
                  class="mr-2 text-light-blue"
                />
                <h6 class="text-h6 mb-0 me-auto">
                  {{ items[1][0].transport }}
                </h6>
                <LoadoutPdfExport
                  :loadoutItems="new Map([items])"
                  :single="true"
                />
              </v-card-title>
              <v-card-subtitle
                ><v-row no-gutters class="align-center">
                  <h6 class="mr-1 mb-0 text-light-blue text-button">
                    Loadout Days:
                  </h6>
                  <v-chip
                    class="mr-2 text-uppercase"
                    label
                    outlined
                    small
                    color="primary"
                    v-for="day in getActiveWeekdays(items[1])"
                    :key="day"
                    >{{ day.slice(0, 3) }}</v-chip
                  >
                </v-row></v-card-subtitle
              >
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="items[1]"
                  :items-per-page="items[1].length"
                  hide-default-footer
                  dense
                  group-by="customer"
                  show-group-by
                >
                  <template v-slot:item.productName="{ item }">
                    <v-row no-gutters>
                      <span>{{ item.productName }}</span>
                      <span v-if="item.stock" class="ms-2"
                        ><BaseStatusChip label="STOCK" color="warning"
                      /></span>
                    </v-row>
                  </template>
                  <template v-slot:item.loadout_date="{ value }">
                    {{ `${formatDate(value)} - ${getDayValues(value).day}` }}
                  </template>
                  <template v-slot:item.boningDates="{ value }">
                    {{
                      Array.from(value)
                        .sort(
                          (a, b) =>
                            parseDate(a, BONING_DATE_FORMAT) -
                            parseDate(b, BONING_DATE_FORMAT)
                        )
                        .join(', ')
                    }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
        </div>
      </div>
    </v-container>
  </v-app>
</template>
