<template>
  <font-awesome-icon icon="fas fa-check" />
</template>

<script>
export default {}
</script>

<style scoped>
.fa-check {
  color: #0ea578;
}
</style>
