<template>
  <div class="container-fluid">
    <div class="wrapper">
      <side-filter
        :filterList="filtersRecOrder"
        @input="updateFilter"
        @inputWeek="updateWeekFilter"
        @inputYear="updateYearFilter"
        @clear="removeFilters()"
        ref="recOrderSideFilter"
      />
      <div class="content-page">
        <b-row no-gutters class="my-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Order Reconciliation</h4>
          </div>
          <b-button
            @click="prepareForExport()"
            size="sm"
            variant="warning"
            class="ml-auto"
          >
            <b-spinner
              v-if="generatingPDF === 'total-summary'"
              small
              class="mr-2"
            ></b-spinner>
            <font-awesome-icon
              v-else
              icon="fas fa-file-export"
              class="mr-2"
            />Export</b-button
          >
        </b-row>

        <!-- ---------------- Summary section ------------------- -->
        <b-card class="mb-3" v-if="loadingRecOrder != 'list'">
          <b-row
            no-gutters
            class="align-items-baseline justify-content-between"
          >
            <b-col cols="12" class="d-flex flex-column align-items-baseline">
              <h4 class="header-title mb-3 w-700 text-light-blue text-center">
                Reconciliation Report
              </h4>
              <h4 class="header-title mb-3 w-700 text-light-blue text-center">
                Week
                {{
                  filtersRecOrder.week.value +
                  ' - ' +
                  filtersRecOrder.weekDay.value.day
                }}
              </h4>
            </b-col>
          </b-row>

          <b-table-simple
            small
            hover
            responsive
            class="text-left mt-2"
            ref="totalsTable"
            id="total-summary"
          >
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Customer</b-th>
                <b-th class="">Orders</b-th>
                <b-th class="">Allocations</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="[code, item] in sortedRecOrderList"
                :key="code"
                :class="
                  compareItems(item.orderTotals, item.allocationTotals)
                    ? ''
                    : 'bg-light-red'
                "
              >
                <b-td class="text-nowrap">{{ item.name }}</b-td>
                <b-td class=""
                  ><b-row class="flex-column" no-gutters
                    ><span
                      v-for="(value, key) in item.orderTotals"
                      :key="value.id"
                      >{{ key }}: {{ value }}</span
                    ></b-row
                  ></b-td
                >
                <b-td class=""
                  ><b-row class="flex-column" no-gutters
                    ><span
                      v-for="(value, key) in item.allocationTotals"
                      :key="value.id"
                      >{{ key }}: {{ value }}</span
                    ></b-row
                  ></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>

        <b-card v-if="loadingRecOrder == 'list'">
          <div class="text-center text-danger my-2">
            <b-spinner small class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { pdfUtilsMixin } from '../mixins/pdfUtils'
import SideFilter from '../components/SideFilter.vue'
import { formatDateWithAddedDays, getWeekBounds } from '@/utils/DateHelpers'
import { PIG_INFO } from '@/constants/pagination'

export default {
  mixins: [pdfUtilsMixin],
  components: {
    SideFilter
  },
  name: 'ReconcileOredersView',
  data() {
    return {
      generatingPDF: ''
    }
  },
  computed: {
    ...mapGetters(['recOrderList', 'loadingRecOrder', 'filtersRecOrder']),
    sortedRecOrderList() {
      const array = Object.entries(this.recOrderList)
      const sorted = array.sort((a, b) => a[1].name.localeCompare(b[1].name))

      return sorted
    }
  },
  methods: {
    ...mapActions(['fetchRecOrder']),
    ...mapMutations([
      'SET_FILTER_REC_ORDER',
      'SET_ACTIVE',
      'SET_USING_VUETIFY'
    ]),
    compareItems: function (item1, item2) {
      const parts1 = Object.keys(item1)
      const parts2 = Object.keys(item2)
      if (parts1.length != parts2.length) return false
      let status = true
      parts1.forEach((item) => {
        if (item1[item] != item2[item]) status = false
      })
      return status
    },
    prepareForExport: async function () {
      const titleDatePart = `${
        this.filtersRecOrder.week.value
      } - ${this.filtersRecOrder.weekDay.value.day.toUpperCase()}`

      let dateTitle = await this.createTitle(
        `RECONCILIATION REPORT FOR WEEK ${titleDatePart}`
      )

      this.SharedExportToPDF({
        tableID: 'total-summary',
        splitByOne: 22,
        splitBy: 25,
        orientation: 'portrait',
        fileName: `Reconciliation-report[WEEK-${titleDatePart}]`,
        title: dateTitle
      })
    },
    updateWeekFilter: async function (dateRange) {
      await this.SET_FILTER_REC_ORDER({
        field: 'week',
        value: dateRange.weekNr
      })
      const dayNr = this.filtersRecOrder.weekDay.value.nr
      const day = formatDateWithAddedDays(dateRange.startDay, dayNr)
      await this.updateDatePicker(day)
      if (this.filtersRecOrder.year.value != dateRange.year) {
        this.SET_FILTER_REC_ORDER({ field: 'year', value: dateRange.year })
      }
      this.fetchRecOrder({ vm: this })
    },
    updateYearFilter: async function ({ year }) {
      const cleanYear = parseInt(year.trim())
      if (cleanYear == this.filtersRecOrder.year.value) return
      await this.SET_FILTER_REC_ORDER({ field: 'year', value: cleanYear })
      if (!this.filtersRecOrder.week.value) return
      this.$refs.recOrderSideFilter.generateWeekDays(
        this.filtersRecOrder.week.value,
        cleanYear
      )
    },
    updateFilter: async function (value) {
      if (value.field == 'weekDay') {
        if (!value.value) return
        const weekNr = this.filtersRecOrder.week.value
        const year = this.filtersRecOrder.year.value
        const { start } = getWeekBounds(weekNr, year)
        const day = formatDateWithAddedDays(start, value.value.nr)
        await this.updateDatePicker(day)
      }
      await this.SET_FILTER_REC_ORDER(value)
      this.fetchRecOrder({ vm: this })
    },
    updateDatePicker: async function (day) {
      await Promise.all([
        this.SET_FILTER_REC_ORDER({
          field: 'date_start',
          value: day
        }),
        this.SET_FILTER_REC_ORDER({
          field: 'date_end',
          value: day
        })
      ])
      return
    }
  },
  async created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE(PIG_INFO)
    await this.fetchRecOrder({ vm: this })
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
  line-height: 1.1;
}
.bg-light-red {
  background-color: #ffe0e3;
}
</style>
