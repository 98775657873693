import axios from 'axios'
import Vue from 'vue'

import { PER_PAGE_OPTIONS } from '@/constants/pagination'

const state = {
  supplierList: null,
  supplierTypes: [],
  freightNames: [],
  freightCodeMap: null,
  loadingSuppliers: '',

  //pagination
  totalSupp: 0,
  perPageSupp: PER_PAGE_OPTIONS[0],
  pageNrSupp: 0,

  //sorting
  sortbySupp: 'companies.name',
  ascSupp: true,

  //filters
  filtersSupp: {
    name: { type: 'text', label: 'Supplier Name', value: '' },
    code: { type: 'text', label: 'Code', value: '' },
    address: { type: 'text', label: 'Address', value: '' },
    phone: { type: 'text', label: 'Phone Number', value: '' },
    abn: { type: 'text', label: 'ABN', value: '' },
    service_type: {
      type: 'select',
      name: 'type',
      label: 'Service Type',
      value: '',
      options: []
    }
  }
}
const getters = {
  suppliers: (state) => state.supplierList,
  supplierTypes: (state) => state.supplierTypes,
  freightNames: (state) => state.freightNames,
  freightCodeMap: (state) => state.freightCodeMap,

  loadingSuppliers: (state) => state.loadingSuppliers,
  totalSuppliers: (state) => state.totalSupp,
  perPageSuppliers: (state) => state.perPageSupp,
  pageNumberSupp: (state) => state.pageNrSupp,
  sortbySupp: (state) => state.sortbySupp,
  ascSupp: (state) => state.ascSupp,
  filtersSupp: (state) => state.filtersSupp,
  offsetSupp: (state) => {
    return state.perPageSupp * state.pageNrSupp
  }
}
const actions = {
  async fetchSuppliers({ commit, dispatch, getters }, { vm }) {
    commit('SET_LOADING_SUPPLIERS', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'supplier/',
        parameters: {
          limit: getters.perPageSuppliers,
          offset: getters.offsetSupp,
          sort_by: getters.sortbySupp,
          ascending: getters.ascSupp
        },
        filters: getters.filtersSupp
      },
      { root: true }
    )
    commit('SET_LOADING_SUPPLIERS', '')
    if (!res.error) {
      commit('SET_SUPPLIER_LIST', res.data)
      commit('SET_TOTAL_SUPP', res.total)
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'List of Suppliers',
          messagePrefix: 'Error while loading Suppliers! '
        },
        { root: true }
      )
    }
  },
  async fetchSupplierTypes({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'supplier/types'
    try {
      commit('SET_LOADING_SUPPLIERS', 'types')
      let res = await axios.get(URI)
      commit('SET_SUPPLIER_TYPES', res.data.data)
      commit('UPDATE_TYPES_FILTER', res.data.data)
      commit('SET_LOADING_SUPPLIERS', '')
    } catch (error) {
      commit('SET_LOADING_SUPPLIERS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'List of Supplier Types',
          messagePrefix: 'Error while loading supplier types! '
        },
        { root: true }
      )
    }
  },
  async fetchFreightNames({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'supplier/freightnames?sort_by=name'
    try {
      commit('SET_LOADING_SUPPLIERS', 'freightnames')
      let res = await axios.get(URI)
      commit('SET_FREIGHT_NAMES', res.data.data)
      await dispatch('mapFreightCode')
      commit('SET_LOADING_SUPPLIERS', '')
      return 'success'
    } catch (error) {
      commit('SET_LOADING_SUPPLIERS', '')
      const messagePrefix = 'Error while loading freight names! '
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        await dispatch(
          'handleError',
          {
            vm,
            error,
            title: 'List of Freight Names',
            messagePrefix
          },
          { root: true }
        )
      }
      return 'error'
    }
  },
  async mapFreightCode({ getters, commit }) {
    let map = new Object()
    getters.freightNames.forEach((el) => {
      map[el.company_id] = { code: el.code, name: el.name }
    })
    commit('SET_FREIGHT_CODE_MAP', map)
    return
  },
  async createSupplier({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'supplier/'
    try {
      commit('SET_LOADING_SUPPLIERS', 'create')
      await axios.post(URI, payload)
      commit('SET_LOADING_SUPPLIERS', '')
      vm.$bvToast.toast(
        'New Supplier created successfuly! ' + payload.company.name,
        {
          title: 'Create Supplier',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_SUPPLIERS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Supplier Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async updateSupplier(
    { rootGetters, commit, dispatch },
    { payload, vm, index }
  ) {
    const URI = rootGetters.baseUrl + 'supplier/' + payload.id
    try {
      commit('SET_LOADING_SUPPLIERS', `update-${payload.id}`)
      const res = await axios.patch(URI, payload)

      if (index != null) {
        commit('REPLACE_SUPPLIER', { payload: res.data, id: index })
      }

      vm.$bvToast.toast(
        `Supplier: ${res.data.company.name} information updated successfuly!`,
        {
          title: 'Update Supplier',
          variant: 'success',
          solid: true
        }
      )
      return 'success'
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Supplier Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_SUPPLIERS', '')
    }
  },
  async deleteSupplier({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'supplier/' + payload.id
    try {
      commit('SET_LOADING_SUPPLIERS', 'delete')
      await axios.delete(URI)
      commit('SET_LOADING_SUPPLIERS', '')
      vm.$bvToast.toast(
        'Supplier deleted successfuly! ' + payload.company.name,
        {
          title: 'Delete Supplier',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_SUPPLIERS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Supplier Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async clearFiltersSupp({ commit }) {
    commit('SET_FILTER_SUPP', { field: 'name', value: '' })
    commit('SET_FILTER_SUPP', { field: 'code', value: '' })
    commit('SET_FILTER_SUPP', { field: 'address', value: '' })
    commit('SET_FILTER_SUPP', { field: 'phone', value: '' })
    commit('SET_FILTER_SUPP', { field: 'abn', value: '' })
    commit('SET_FILTER_SUPP', { field: 'service_type', value: '' })
  }
}
const mutations = {
  SET_SUPPLIER_LIST: (state, payload) => (state.supplierList = payload),
  REPLACE_SUPPLIER: (state, { payload, id }) => {
    Vue.set(state.supplierList, id, payload)
  },
  SET_SUPPLIER_TYPES: (state, payload) => (state.supplierTypes = payload),
  SET_FREIGHT_NAMES: (state, payload) => (state.freightNames = payload),
  SET_FREIGHT_CODE_MAP: (state, payload) => (state.freightCodeMap = payload),

  SET_TOTAL_SUPP: (state, payload) => (state.totalSupp = payload),
  SET_PAGE_NR_SUPP: (state, payload) => (state.pageNrSupp = payload),
  SET_LOADING_SUPPLIERS: (state, payload) => (state.loadingSuppliers = payload),
  SET_PER_PAGE_SUPPLIERS: (state, payload) => (state.perPageSupp = payload),
  SET_SORT_BY_SUPP: (state, payload) => (state.sortbySupp = payload),
  SET_ASC_SUPP: (state, payload) => (state.ascSupp = payload),
  UPDATE_TYPES_FILTER: (state, payload) =>
    (state.filtersSupp.service_type.options = payload),
  SET_FILTER_SUPP: (state, payload) => {
    if (state.filtersSupp[payload.field].type == 'select') {
      if (payload.value) {
        state.filtersSupp[payload.field].value = payload.value
      } else {
        state.filtersSupp[payload.field].value = ''
      }
      return
    }
    state.filtersSupp[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
