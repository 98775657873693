<template>
  <b-modal id="processing-price-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <label for="price-date"
        >Effective Date:<span class="text-danger">*</span></label
      >
      <b-datepicker
        id="price-date"
        v-model="price.effective_date"
        class="mb-3"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }"
        locale="en-Au"
        start-weekday="1"
        required
      ></b-datepicker>

      <label for="price-name"
        >Category:<span class="text-danger">*</span></label
      >
      <b-select
        class="mb-3"
        id="price-name"
        v-model="price.name"
        :options="processingCostTypes"
        :disabled="type != 'add'"
        required
      ></b-select>

      <label for="price-section"
        >Section in the Report:<span class="text-danger">*</span></label
      >
      <b-select
        class="mb-3"
        id="price-section"
        v-model="price.category"
        :options="processingCostSections"
        required
      ></b-select>

      <label for="price-value">Price:<span class="text-danger">*</span></label>
      <b-input
        class="mb-3"
        type="number"
        id="price-value"
        :step="price.name == 'LEVY' || price.name == 'GRADING' ? 0.001 : 0.01"
        v-model="price.cost"
      ></b-input>

      <span v-if="weightRequiredCostTypes.includes(price.name)">
        <label for="price-min-weight"
          >Minimum Weight:<span class="text-danger">*</span></label
        >
        <b-input
          class="mb-3"
          type="number"
          id="price-min-weight"
          step="0.01"
          v-model="price.minweight"
        ></b-input>

        <label for="price-max-weight"
          >Maximum Weight:<span class="text-danger">*</span></label
        >
        <b-input
          class="mb-3"
          type="number"
          id="price-max-weight"
          step="0.01"
          v-model="price.maxweight"
        ></b-input>
      </span>

      <label for="price-unit"
        >Calculation Unit:<span class="text-danger">*</span></label
      >
      <b-select
        class="mb-3"
        id="price-unit"
        v-model="price.unit"
        :options="processingCostUnit"
        required
      ></b-select>

      <label for="price-desc"
        >Description:<span class="text-danger">*</span></label
      >
      <b-input
        class="mb-3"
        type="text"
        id="price-desc"
        v-model="price.description"
      ></b-input>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingProcPrice == 'proc-price'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { getCurrentDay } from '@/utils/DateHelpers'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'NewProcPriceModal',
  props: {
    type: String
  },
  data() {
    return {
      price: {
        category: '',
        name: '',
        effective_date: '',
        cost: 0,
        description: '',
        maxweight: null,
        minweight: null,
        unit: ''
      },
      tempInfo: null
    }
  },
  computed: {
    ...mapGetters([
      'loadingProcPrice',
      'processingCostTypes',
      'processingCostSections',
      'processingCostUnit',
      'weightRequiredCostTypes'
    ]),
    actionString() {
      if (this.type == 'add') return 'Create Processing Price'
      return `Update Processing Price - ${this.price.name}`
    }
  },
  methods: {
    ...mapActions(['createProcPrice', 'fetchProcPrice', 'updateProcPrice']),
    ...mapMutations(['UPDATE_PROCESSING_PRICE_LIST']),
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      if (this.type == 'add') {
        res = await this.createProcPrice({
          payload: this.price,
          vm: this
        })
      }
      if (this.type == 'edit') {
        res = await this.updateProcPrice({
          payload: this.price,
          vm: this
        })
      }
      if (res === 'error') return

      this.$bvModal.hide('processing-price-modal')
      if (this.type == 'edit') {
        this.UPDATE_PROCESSING_PRICE_LIST(res)
      }
      if (this.type == 'add') {
        this.fetchProcPrice({ vm: this })
      }
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: function (type = this.processingCostTypes[0]) {
      this.resetValues(type)
      this.$bvModal.show('processing-price-modal')
    },
    edit: function (info) {
      this.tempInfo = info
      this.price.id = this.tempInfo.id
      this.restoreValues()
      this.$bvModal.show('processing-price-modal')
    },
    // Reset price form values
    resetValues: function (type) {
      if (Object.prototype.hasOwnProperty.call(this.price, 'id'))
        delete this.price.id
      this.price.effective_date = getCurrentDay()
      this.price.category = this.processingCostSections[0]
      this.price.name = type
      this.price.cost = 0
      this.price.maxweight = 0
      this.price.minweight = 0
      this.price.unit = this.processingCostUnit[0]
    },
    // Restore price form values to initial
    restoreValues: function () {
      this.price.effective_date = this.tempInfo.effective_date
      this.price.category = this.tempInfo.category
      this.price.name = this.tempInfo.name
      this.price.cost = this.tempInfo.cost
      this.price.description = this.tempInfo.description
      this.price.maxweight = this.tempInfo.maxweight
      this.price.minweight = this.tempInfo.minweight
      this.price.unit = this.tempInfo.unit
    }
  }
}
</script>

<style></style>
