<script setup>
import {
  reactive,
  defineProps,
  computed,
  ref,
  getCurrentInstance,
  defineExpose
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'

defineProps({
  type: { type: String, default: 'edit' }
})
const store = useStore()
const vm = getCurrentInstance().proxy

const summary = reactive({
  id: null,
  invoice: ''
})
const tempInfo = ref(null)
const loadingTransport = computed(() => store.getters.loadingTransport)

const actionString = computed(() => {
  if (!tempInfo.value || !tempInfo.value.destination?.name) return ''
  return `Update Invoice number for ${tempInfo.value.destination.name}`
})

const onSubmit = async (event) => {
  event.preventDefault()
  const res = await store.dispatch('updateTransport', {
    payload: {
      id: summary.id,
      invoice: summary.invoice.trim()
    },
    vm
  })
  if (res !== 'error') {
    store.dispatch('fetchTransport', { vm })
    vm.$bvModal.hide('invoice-dialog')
  }
}
const onReset = (event) => {
  event.preventDefault()
  restoreValues()
}
const edit = (info) => {
  tempInfo.value = info
  summary.invoice = info.invoice
  summary.id = info.id
  vm.$bvModal.show('invoice-dialog')
}
const restoreValues = () => {
  summary.invoice = tempInfo.value.invoice
}

defineExpose({ edit })
</script>

<template>
  <b-modal id="invoice-dialog" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-input v-model="summary.invoice" required></b-input>
      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingTransport === 'update'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<style></style>
