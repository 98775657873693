import axios from 'axios'

import { PER_PAGE_MAX } from '@/constants/pagination'
import { WEEK_OPTIONS } from '@/constants/general'
import {
  getCurrentDay,
  getISOWeekNumber,
  getWeekBounds,
  getYear,
  getDayValues
} from '@/utils/DateHelpers'

const state = {
  growerList: [],
  growerReportList: null,
  loadingGrowerReport: '',

  //pagination
  perPageGrowerReport: PER_PAGE_MAX,
  pageNrGrowerReport: 0,

  //sorting
  sortbyGrowerReport: 'body_no',
  ascGrowerReport: true,

  //filters
  filtersGrowerReport: {
    date_start: {
      type: 'date',
      label: 'Date',
      value: getCurrentDay(),
      readOnly: true
    },
    date_end: {
      type: 'date',
      label: 'End Date',
      value: getCurrentDay(),
      readOnly: true
    },
    producer: {
      type: 'select',
      name: 'name',
      label: 'Grower',
      value: '',
      customOptions: (option) => {
        return `${option.code} - ${option.name}`
      },
      options: []
    },
    week: {
      type: 'week',
      label: 'Week Number',
      value: getISOWeekNumber()
    },
    weekDay: {
      type: 'selectWeekDay',
      name: 'day',
      options: WEEK_OPTIONS,
      label: 'Day of the week',
      noReduce: true,
      value: getDayValues()
    },
    year: { type: 'year', value: getYear(), hidden: true }
  }
}
const getters = {
  growerList: (state) => state.growerList,
  growerReportList: (state) => state.growerReportList,
  loadingGrowerReport: (state) => state.loadingGrowerReport,
  perPageGrowerReport: (state) => state.perPageGrowerReport,
  sortbyGrowerReport: (state) => state.sortbyGrowerReport,
  ascGrowerReport: (state) => state.ascGrowerReport,
  filtersGrowerReport: (state) => state.filtersGrowerReport,
  offsetGrowerReport: (state) => {
    return state.perPageGrowerReport * state.pageNrGrowerReport
  }
}
const actions = {
  async fetchGrowerReport({ commit, getters, dispatch }) {
    commit('SET_LOADING_GROWER_REPORT', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'piginfo/',
        parameters: {
          limit: getters.perPageGrowerReport,
          offset: getters.offsetGrowerReport,
          sort_by: getters.sortbyGrowerReport,
          ascending: getters.ascGrowerReport
        },
        filters: getters.filtersGrowerReport
      },
      { root: true }
    )
    commit('SET_LOADING_GROWER_REPORT', '')
    if (!res.error) {
      dispatch('createGrowerReport', res.data)
    } else {
      dispatch('showError', {
        error: res.error,
        messagePrefix: 'Error while loading Pig Information! '
      })
    }
  },
  async updateGrowerReport({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'piginfo/' + payload.id
    try {
      commit('SET_LOADING_GROWER_REPORT', `update-${payload.id}`)
      const res = await axios.patch(URI, payload)
      dispatch(
        'showMessage',
        `Pig body No: ${payload.body_no} price/kg updated successfuly!`
      )
      return res.data
    } catch (error) {
      dispatch('showError', {
        error: error,
        messagePrefix: 'Update Pig price/kg Error! '
      })
      return 'error'
    } finally {
      commit('SET_LOADING_GROWER_REPORT', '')
    }
  },
  async fetchGrowers({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'supplier/growernames'
    try {
      let res = await axios.get(URI)
      commit('SET_GROWER_LIST', res.data.data)
    } catch (error) {
      const messagePrefix = 'Error while loading growers list! '
      const title = 'List of Growers'
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        dispatch(
          'handleError',
          { vm, error, title, messagePrefix },
          { root: true }
        )
      }
    }
  },
  createGrowerReport({ commit }, payload) {
    commit('RESET_GROWER_REPORT_LIST')
    let report = {}
    payload.forEach((element) => {
      //create deep copy for each allocation item
      let rowCopy = JSON.parse(JSON.stringify(element))
      const { producer, value, specification } = rowCopy
      const growerId = producer.id

      if (!Object.prototype.hasOwnProperty.call(report, growerId)) {
        report[growerId] = {
          items: [],
          total: 0,
          totalWeight: 0,
          name: producer.company.name,
          code: producer.code
        }
      }
      report[growerId].total +=
        value.customValue === undefined ? value.value : value.customValue
      report[growerId].totalWeight += parseFloat(specification.HSCW)
      report[growerId].items.push(rowCopy)
    })
    commit('SET_GROWER_REPORT_LIST', report)
  },
  adjustPriceValues({ getters }, data) {
    const { producer } = data
    const list = getters.growerReportList
    let total = 0

    list[producer.id].items.forEach(({ value }) => {
      total += value.customValue === undefined ? value.value : value.customValue
    })
    list[producer.id].total = total
  },
  async clearFiltersGrowerReport({ commit }) {
    commit('SET_FILTER_GROWER_REPORT', { field: 'producer', value: '' })
    commit('SET_FILTER_GROWER_REPORT', { field: 'weekDay', value: '' })
    commit('SET_FILTER_GROWER_REPORT', {
      field: 'week',
      value: getISOWeekNumber()
    })
    const { start, end } = getWeekBounds()
    commit('SET_FILTER_GROWER_REPORT', {
      field: 'date_start',
      value: start
    })
    commit('SET_FILTER_GROWER_REPORT', {
      field: 'date_end',
      value: end
    })
  }
}

const mutations = {
  SET_GROWER_LIST: (state, payload) => (state.growerList = payload),
  SET_GROWER_REPORT_LIST: (state, payload) =>
    (state.growerReportList = payload),
  RESET_GROWER_REPORT_LIST: (state) => {
    state.growerReportList = null
  },
  SET_LOADING_GROWER_REPORT: (state, payload) =>
    (state.loadingGrowerReport = payload),
  SET_FILTER_GROWER_REPORT: (state, payload) => {
    if (state.filtersGrowerReport[payload.field].type == 'select') {
      if (payload.value) {
        state.filtersGrowerReport[payload.field].value = payload.value
      } else {
        state.filtersGrowerReport[payload.field].value = ''
      }
      return
    }
    state.filtersGrowerReport[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
