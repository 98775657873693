import { DEFAULT_WEIGHTS } from '@/constants/weight'

export const checkBodyTypeError = (item) => {
  if (item.cat === 'SUK' && item.HSCW > DEFAULT_WEIGHTS.SUK) return true
  if (item.cat !== 'SUK' && item.HSCW <= DEFAULT_WEIGHTS.SUK) return true

  if (
    (item.cat === 'BP' || item.cat === 'SP') &&
    item.HSCW < DEFAULT_WEIGHTS.BP
  )
    return true
  if (item.HSCW >= DEFAULT_WEIGHTS.BP && item.cat !== 'BP' && item.cat !== 'SP')
    return true

  if (!item.CSCW) return false
  if (item.CSCW >= item.HSCW) return true
  return false
}

export const checkForDamage = (item) => {
  if (item.condems !== '') return true
  return false
}
