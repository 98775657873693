<template>
  <b-modal id="part-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <label for="part-name"
        >Pig Part Name:<span class="text-danger">*</span></label
      >
      <b-input id="part-name" required v-model="part.part"></b-input>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingPigParts == 'create'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewPigPartModal',
  props: {
    type: String
  },
  data() {
    return {
      part: {
        part: ''
      },
      tempInfo: null
    }
  },
  computed: {
    ...mapGetters(['loadingPigParts']),
    actionString() {
      if (this.type == 'add') return 'Create New Pig Part'
      return 'Update Pig Part'
    }
  },
  methods: {
    ...mapActions(['createPigPart', 'fetchPigParts', 'updatePigPart']),
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      if (this.type == 'add') {
        res = await this.createPigPart({
          payload: this.part,
          vm: this
        })
      }
      if (this.type == 'edit') {
        res = await this.updatePigPart({
          payload: this.part,
          vm: this
        })
      }
      if (res === 'success') {
        this.$bvModal.hide('part-modal')
        this.fetchPigParts({ vm: this })
      }
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: function () {
      this.resetValues()
      this.$bvModal.show('part-modal')
    },
    edit: function (info) {
      this.tempInfo = info
      this.restoreValues()
      this.$bvModal.show('part-modal')
    },
    // Reset part form values
    resetValues: function () {
      if (Object.prototype.hasOwnProperty.call(this.part, 'id'))
        delete this.part.id
      this.part.part = ''
    },
    // Restore part form values to initial
    restoreValues: function () {
      this.part.id = this.tempInfo.id
      this.part.part = this.tempInfo.part
    }
  }
}
</script>

<style></style>
