import {
  endOfWeek,
  format,
  getISOWeek,
  getISOWeeksInYear,
  getYear,
  setISOWeek,
  setISOWeekYear,
  setYear,
  startOfWeek
} from 'date-fns'

export const selectWeekMixin = {
  computed: {
    currentWeek() {
      return getISOWeek(new Date())
    },
    yearList() {
      const currentYear = getYear(new Date())
      const firstYear = 2022
      const stop = currentYear + 3 //show extra 3 year for user to select on week filter
      const step = 1
      let yearArray = Array.from(
        { length: stop - firstYear },
        (_, i) => firstYear + i * step
      )
      return yearArray
    }
  },
  methods: {
    weekOptions: function (year) {
      const date = setYear(new Date(), year)
      const numOfWeeks = getISOWeeksInYear(date)
      const currentWeek = getISOWeek(new Date())
      const weekArray = [{ value: null, text: '-- Please select a week --' }]
      for (let i = 1; i <= numOfWeeks; i++) {
        const optionText = this.isCurrentWeek(i, year)
          ? `${String(i)} - Current Week`
          : `${String(i)}`

        weekArray.push({
          value: i,
          html: optionText,
          disabled: i <= currentWeek ? false : false
        })
      }
      return weekArray
    },
    isCurrentWeek: function (week, year) {
      const currentWeek = getISOWeek(new Date())
      const currentYear = getYear(new Date())
      if (week == currentWeek && year == currentYear) return true
      return false
    },
    generateWeekDays: function (week, year) {
      if (!year) year = getYear(new Date())
      let startDay = ''
      let endDay = ''
      if (week) {
        const date = setISOWeek(setISOWeekYear(new Date(), year), week)
        startDay = format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd')
        endDay = format(endOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd')
      }
      this.$emit('inputWeek', {
        startDay: startDay,
        endDay: endDay,
        weekNr: week,
        year: year
      })
    },
    changeYear(event) {
      this.$emit('inputYear', {
        year: event.target.innerText
      })
    }
  }
}
