import { PER_PAGE_MAX } from '@/constants/pagination'
import { BREAK, PORKER, WEEK_OPTIONS } from '@/constants/general'
import {
  getCurrentDay,
  getDayValues,
  getISOWeekNumber,
  getYear
} from '@/utils/DateHelpers'

const state = {
  loadReportList: null,
  loadingLoadReport: '',

  //pagination
  perPageLoadReport: PER_PAGE_MAX,
  pageNrLoadReport: 0,

  //sorting
  sortbyLoadReport: 'freight',
  ascLoadReport: true,

  //filters
  filtersLoadReport: {
    date_start: {
      type: 'date',
      label: 'Date',
      value: getCurrentDay(),
      readOnly: true
    },
    date_end: {
      type: 'date',
      label: 'End Date',
      value: getCurrentDay(),
      hidden: true
    },
    week: { type: 'week', label: 'Week Number', value: getISOWeekNumber() },
    year: { type: 'year', value: getYear(), hidden: true },
    weekDay: {
      type: 'selectWeekDay',
      name: 'day',
      options: WEEK_OPTIONS,
      label: 'Day of the week',
      noReduce: true,
      value: getDayValues()
    }
  }
}
const getters = {
  loadReportList: (state) => state.loadReportList,
  loadingLoadReport: (state) => state.loadingLoadReport,
  perPageLoadReport: (state) => state.perPageLoadReport,
  sortbyLoadReport: (state) => state.sortbyLoadReport,
  ascLoadReport: (state) => state.ascLoadReport,
  filtersLoadReport: (state) => state.filtersLoadReport,
  offsetLoadReport: (state) => {
    return state.perPageLoadReport * state.pageNrLoadReport
  }
}
const actions = {
  async fetchLoadoutReport({ commit, getters, dispatch }, vm) {
    commit('SET_LOADING_LOAD_REPORT', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'loadoutset/loadout_by_date',
        parameters: {
          limit: getters.perPageLoadReport,
          offset: getters.offsetLoadReport,
          sort_by: getters.sortbyLoadReport,
          ascending: getters.ascLoadReport
        },
        filters: getters.filtersLoadReport
      },
      { root: true }
    )
    if (!res.error) {
      dispatch('parseLoadoutReportByDate', res.data)
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'Loadout Instructions by Date',
          messagePrefix: 'Error while loading Loadout Instructions by Date! '
        },
        { root: true }
      )
      commit('SET_LOADING_LOAD_REPORT', '')
    }
  },
  async parseLoadoutReportByDate({ commit }, res) {
    const data = {}

    res.forEach((el) => {
      // ---- TO DO ---- fix this, backend wrong freight code
      if (el.freight === 111) return
      // ---- end FIX ------

      if (!data[el.freight]) {
        data[el.freight] = {
          customers: {},
          totalBreak: 0,
          totalPorker: 0
        }
      }

      const freightData = data[el.freight]
      const destinationData = freightData.customers[el.destination] || {
        BREAK: [],
        PORKER: []
      }

      let loadedItems = []

      if (el.type === BREAK) {
        const validJsonStr = el.loaded_items.replace(/'/g, '"')
        const items = JSON.parse(validJsonStr)
        for (const [key, value] of Object.entries(items)) {
          loadedItems.push(`${key} x ${value}`)
          freightData.totalBreak += parseInt(value)
        }
      }
      if (el.type === PORKER) {
        loadedItems = el.loaded_items.split(',')
        freightData.totalPorker += loadedItems.length
      }
      el.loaded_items = loadedItems
      destinationData[el.type].push(el)

      freightData.customers[el.destination] = destinationData
    })
    commit('SET_LOAD_REPORT_LIST', data)
    commit('SET_LOADING_LOAD_REPORT', '')
  }
}

const mutations = {
  SET_LOAD_REPORT_LIST: (state, payload) => (state.loadReportList = payload),
  SET_LOADING_LOAD_REPORT: (state, payload) =>
    (state.loadingLoadReport = payload),
  SET_FILTER_LOAD_REPORT: (state, payload) => {
    state.filtersLoadReport[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
