import { ref } from 'vue'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { formatDate } from '@/helpers/stringHelpers'

const TOTAL_PAGE_EXPR = '{total_pages_count_string}'
const FOOTER_DUMMY_TEXT = 'Page 2 of 3'
const BOTTOM_PAGE_BREAK = 262
const BOTTOM_PAGE_BREAK_LANDSCAPE = 179
const HEADER_FONT_SIZE = 22
const SUBTITLE_FONT_SIZE = 14
const TABLE_HEADER_FONT_SIZE = 12
const INDIVIDUAL_TOTALS_HEIGHT = 6.98
const FOOTER_FONT_SIZE = 10
const INIT_POS_Y = 12
const POS_Y_TABLE_OFFSET = 8
const FOOTER_OFFSET = 8
const SUBTITLE_OFFSET = 10
const LIGHT_GREY_HEX = '#F2F2F2'
const LIGHT_BLUE_HEX = '#e4eff9'
const BLUE_HEX = '#4685c1'
const WHITE_HEX = '#ffffff'

export default function usePdfGenerator() {
  const generatingPdf = ref(false)
  let scaleFactor = 0
  let pageHeight = 0
  let pageWidth = 0
  let posY = 0

  const globalConfig = {
    orientation: 'portrait',
    theme: 'striped',
    tableFontSize: 8,
    halign: 'left',
    alternateStyle: null,
    headFillColor: LIGHT_BLUE_HEX,
    headTextColor: '#505050',
    totalsPrefix: '',
    individualTotals: null
  }

  const initPageDefaults = (doc) => {
    scaleFactor = doc.internal.scaleFactor
    const pageSize = doc.internal.pageSize
    pageHeight = pageSize.getHeight()
    pageWidth = pageSize.getWidth()
    posY = INIT_POS_Y
  }

  const initGlobalConfig = (userConfig) => {
    Object.entries(userConfig).forEach((value) => {
      globalConfig[value[0]] = value[1]
    })
  }

  const addCenteredText = (doc, text, fontSize = 16, fontStyle = 'normal') => {
    doc.setFontSize(fontSize)
    doc.setFont('helvetica', fontStyle)

    const textWidth =
      (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / scaleFactor
    const xPos = pageWidth / 2 - textWidth / 2

    doc.text(text, xPos, posY)
  }

  const addFooter = (doc) => {
    let text = `Page ${doc.internal.getNumberOfPages()} of ${TOTAL_PAGE_EXPR}`
    doc.setFontSize(FOOTER_FONT_SIZE)

    const textWidth =
      (doc.getStringUnitWidth(FOOTER_DUMMY_TEXT) * doc.internal.getFontSize()) /
      scaleFactor
    const xPos = pageWidth / 2 - textWidth / 2
    doc.text(text, xPos, pageHeight - FOOTER_OFFSET)
  }

  const addTotalsSection = (doc, totalsArray) => {
    const { totalsPrefix: prefix } = globalConfig
    const headText = prefix + totalsArray.join(' | ')

    autoTable(doc, {
      head: [[headText]],
      headStyles: {
        fontSize: TABLE_HEADER_FONT_SIZE,
        fontStyle: 'normal',
        fillColor: LIGHT_BLUE_HEX,
        textColor: '#000000'
      },
      startY: posY,
      didDrawPage: (data) => addFooter(doc, data)
    })
    posY = doc.lastAutoTable.finalY
  }

  const ensureValidPagePosition = (doc, pageBreak) => {
    if (posY > pageBreak) {
      doc.addPage()
      addFooter(doc)
      posY = INIT_POS_Y
    }
  }

  const addTableHeader = (doc, key) => {
    autoTable(doc, {
      head: [
        [
          {
            content: key,
            styles: { halign: 'center', fontSize: TABLE_HEADER_FONT_SIZE }
          }
        ]
      ],
      startY: posY,
      didDrawPage: (data) => addFooter(doc, data)
    })
  }

  const addIndividualTotals = (doc, key) => {
    const { individualTotals: totalsMap, tableFontSize: fontSize } =
      globalConfig
    const totalsArray = Array.from(totalsMap.get(key).entries())

    const sortedArray = totalsArray.sort((a, b) => b[0].localeCompare(a[0]))
    const totalText =
      'Total: ' +
      sortedArray.map(([part, count]) => `${part} - ${count}`).join('   ')

    autoTable(doc, {
      head: [[totalText]],
      headStyles: {
        fontSize,
        fontStyle: 'normal',
        fillColor: LIGHT_BLUE_HEX,
        textColor: '#000000',
        lineColor: 200,
        lineWidth: { bottom: 0.2 }
      },
      startY: posY,
      didDrawPage: (data) => addFooter(doc, data)
    })
    posY = doc.lastAutoTable.finalY + 0.2
  }

  const calculatePageBreak = () => {
    const { orientation, individualTotals } = globalConfig

    let pageBreak =
      orientation === 'portrait'
        ? BOTTOM_PAGE_BREAK
        : BOTTOM_PAGE_BREAK_LANDSCAPE
    if (individualTotals) {
      pageBreak -= INDIVIDUAL_TOTALS_HEIGHT
    }
    return pageBreak
  }

  const addHeader = (doc) => {
    addCenteredText(doc, 'BANGALOW SWEET PORK', HEADER_FONT_SIZE, 'bold')
  }

  const addSubTitle = (doc, subTitle) => {
    posY += SUBTITLE_OFFSET
    addCenteredText(doc, subTitle, SUBTITLE_FONT_SIZE)
  }

  const finalizePdf = (doc, fileName) => {
    doc.putTotalPages(TOTAL_PAGE_EXPR)
    doc.save(fileName)
  }

  function addTableBody(doc, item, tableHeaders) {
    const {
      theme,
      tableFontSize: fontSize,
      halign,
      alternateStyle,
      headFillColor,
      headTextColor
    } = globalConfig
    let alternateRowStyles = {}
    if (alternateStyle) {
      alternateRowStyles = {
        fillColor: LIGHT_GREY_HEX
      }
    }
    autoTable(doc, {
      theme,
      head: [tableHeaders],
      styles: { halign },
      alternateRowStyles,
      headStyles: {
        fontSize,
        fillColor: headFillColor,
        textColor: headTextColor,
        lineColor: 200,
        lineWidth: theme === 'grid' ? 0.1 : 0
      },
      body: item,
      bodyStyles: { fontSize },
      pageBreak: 'auto',
      startY: posY,
      didDrawPage: (data) => addFooter(doc, data)
    })
  }

  const exportPdf = (
    totalsArray,
    subTitle,
    dataTables,
    tableHeaders,
    fileName,
    config = {}
  ) => {
    try {
      initGlobalConfig(config)
      const { orientation, individualTotals } = globalConfig

      const doc = new jsPDF({ orientation })
      generatingPdf.value = true

      initPageDefaults(doc)
      addHeader(doc)
      addSubTitle(doc, subTitle)

      const pageBreak = calculatePageBreak()

      if (totalsArray.length > 0) {
        posY += POS_Y_TABLE_OFFSET
        addTotalsSection(doc, totalsArray)
      }

      posY += POS_Y_TABLE_OFFSET
      if (Array.isArray(dataTables)) {
        globalConfig.headFillColor = BLUE_HEX
        globalConfig.headTextColor = WHITE_HEX

        addTableBody(doc, dataTables, tableHeaders)
      } else {
        dataTables.forEach((item, key) => {
          ensureValidPagePosition(doc, pageBreak)

          addTableHeader(doc, key)
          posY = doc.lastAutoTable.finalY

          if (individualTotals) {
            addIndividualTotals(doc, key)
          }

          addTableBody(doc, item, tableHeaders)
          posY = doc.lastAutoTable.finalY + POS_Y_TABLE_OFFSET
        })
      }

      finalizePdf(doc, fileName)
    } catch (e) {
      console.error('Error generating PDF: ', e)
    } finally {
      generatingPdf.value = false
    }
  }

  const titleDatePart = (startDate, filters, isFileName = false) => {
    let week = ''
    let date = formatDate(startDate)
    if (filters) {
      if (filters?.date_start.value) {
        date = formatDate(filters.date_start.value)
      }
      if (filters?.week.value) {
        week = `WEEK-${filters.week.value} `
      } else {
        if (filters?.date_end.value) {
          date = date + ' - ' + formatDate(filters?.date_end.value)
        }
      }
    }
    if (isFileName) {
      date = date.replaceAll('/', '-')
    }
    return `${week}${date}`
  }

  return {
    generatingPdf,
    exportPdf,
    titleDatePart
  }
}
