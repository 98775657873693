import axios from 'axios'
import store from '../store'
import router from '../router'
import { Auth } from 'aws-amplify'

export default function setup() {
  axios.interceptors.request.use(
    async function (config) {
      let token = store.getters.userToken
      const tokenExpTime = store.getters.tokenExp
      const currentTimeSeconds = Math.round(+new Date() / 1000)

      if (tokenExpTime < currentTimeSeconds) {
        try {
          const session = await Auth.currentSession()
          store.commit('SET_USER_SESSION', session)
          token = session.idToken.jwtToken
        } catch (err) {
          if (
            err === 'No current user' ||
            err.code === 'NotAuthorizedException'
          ) {
            store.commit('SET_AUTH_USER', null)
            if (router.currentRoute.path !== '/login') {
              store.commit(
                'SET_LOGIN_ERROR',
                'Session expired! Please sign in again'
              )
              router.push('/login')
            }
            return Promise.reject({
              error: 'Refresh token expired',
              message: err
            })
          }
        }
      }
      if (token) {
        config.headers.Authorization = token
      }
      return config
    },
    function (err) {
      return Promise.reject(err)
    }
  )
}
