import { render, staticRenderFns } from "./OrderItemsEditTable.vue?vue&type=template&id=48bc1dc0&scoped=true"
import script from "./OrderItemsEditTable.vue?vue&type=script&setup=true&lang=js"
export * from "./OrderItemsEditTable.vue?vue&type=script&setup=true&lang=js"
import style0 from "./OrderItemsEditTable.vue?vue&type=style&index=0&id=48bc1dc0&prod&lang=css"
import style1 from "./OrderItemsEditTable.vue?vue&type=style&index=1&id=48bc1dc0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bc1dc0",
  null
  
)

export default component.exports