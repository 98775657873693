<script setup>
import BaseAlert from '@/components/base/BaseAlert.vue'
import UpdatePriceDialog from '@/components/grower-report-page/UpdatePriceDialog.vue'

import { formatCurrency } from '@/helpers/stringHelpers'
</script>
<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <side-filter
          :filterList="filtersGrowerReport"
          @input="updateFilter"
          @inputWeek="updateWeekFilter"
          @inputYear="updateYearFilter"
          @clear="removeFilters()"
          ref="growerReportSideFilter"
          class="mt-0"
        />
        <div class="content-page mt-0">
          <v-row no-gutters class="my-2 align-center">
            <div class="page-title-box d-flex">
              <h4 class="page-title">Grower Report</h4>
            </div>
          </v-row>

          <!-- ---------------- Summary section ------------------- -->
          <v-card v-if="loadingGrowerReport === 'list'">
            <v-card-text class="text-center text-danger my-2">
              <b-spinner small class="align-middle mr-2"></b-spinner>
              <strong>Loading Report...</strong>
            </v-card-text>
          </v-card>
          <b-card class="mb-3" v-else>
            <v-row no-gutters class="align-baseline">
              <h4 class="header-title mb-3 w-700 text-light-blue text-left">
                Report by Grower
                {{
                  filtersGrowerReport.producer.value == ''
                    ? ''
                    : ` - ${filtersGrowerReport.producer.value}`
                }}
              </h4>
            </v-row>
            <v-row no-gutters class="align-items-baseline">
              <h4 class="header-title mb-3 w-700 text-light-blue text-left">
                Week-{{ filtersGrowerReport.week.value }}
                {{
                  !filtersGrowerReport.weekDay.value
                    ? ''
                    : `${filtersGrowerReport.weekDay.value.day}`
                }}
              </h4>
            </v-row>
            <b-row
              class="align-items-left mt-3"
              no-gutters
              v-for="(grower, key) in growerReportList"
              :key="grower.id"
            >
              <b-col>
                <b-row no-gutters class="align-items-center w-50 mb-1">
                  <b>{{ grower.name }}</b>
                  <!-- div below used for week string generation in report -->
                  <div id="report-week" class="ml-auto"></div>
                  <b-button
                    @click="prepareForExport(key, grower.name)"
                    size="sm"
                    class="btn-sm btn-export btn-neutral"
                  >
                    <b-spinner
                      v-if="generatingPDF == `report-${key}`"
                      small
                      class="mr-2"
                    ></b-spinner>
                    <font-awesome-icon
                      v-else
                      icon="fas fa-file-export"
                      class="mr-2"
                    />Export</b-button
                  >
                </b-row>
                <b-table-simple
                  small
                  hover
                  responsive
                  bordered
                  class="text-left loadout-table w-50"
                  :id="`report-${key}`"
                >
                  <b-thead>
                    <b-tr class="bg-dark text-white">
                      <b-th colspan="1"
                        >Total Pigs: {{ grower.items.length }}</b-th
                      >
                      <b-th colspan="2" class="text-right"
                        >Total Weight:
                        {{ grower.totalWeight | decimals2 }}kg</b-th
                      >
                      <b-th colspan="3" class="text-right"
                        >Total Value: {{ grower.total | currency }}</b-th
                      >
                    </b-tr>
                    <b-tr class="bg-light-blue">
                      <b-th>Body Number</b-th>
                      <b-th>Date</b-th>
                      <b-th class="text-right">Hot Weight</b-th>
                      <b-th class="text-right">P2</b-th>
                      <b-th class="text-right">Price/Kg</b-th>
                      <b-th class="text-right">Value</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="pig in grower.items" :key="pig.id">
                      <b-td>{{ pig.body_no }}</b-td>
                      <b-td>{{ pig.date | date }}</b-td>
                      <b-td class="text-right">{{
                        pig.specification.HSCW | decimals2
                      }}</b-td>
                      <b-td class="text-right">{{ pig.specification.p2 }}</b-td>
                      <b-td
                        class="text-right"
                        :class="
                          pig.value.customValue !== undefined
                            ? 'yellow lighten-4'
                            : ''
                        "
                      >
                        <UpdatePriceDialog :details="pig" />
                      </b-td>
                      <b-td
                        class="text-right"
                        :class="
                          pig.value.customValue !== undefined
                            ? 'yellow lighten-4'
                            : ''
                        "
                        >{{
                          formatCurrency(
                            pig.value.customValue !== undefined
                              ? pig.value.customValue
                              : pig.value.value
                          )
                        }}</b-td
                      >
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { pdfUtilsMixin } from '../mixins/pdfUtils'

import SideFilter from '../components/SideFilter.vue'
import { formatDateWithAddedDays, getWeekBounds } from '@/utils/DateHelpers'
import { PIG_INFO } from '@/constants/pagination'

export default {
  name: 'GrowerReportView',
  mixins: [pdfUtilsMixin],
  components: {
    SideFilter
  },
  data() {
    return {
      generatingPDF: '',
      week: {
        startDay: '',
        endDay: '',
        weekNr: null
      },
      dataReady: false
    }
  },
  computed: {
    ...mapGetters([
      'growerReportList',
      'loadingGrowerReport',
      'filtersGrowerReport'
    ])
  },
  methods: {
    ...mapActions(['fetchGrowerReport', 'clearFiltersGrowerReport']),
    ...mapMutations([
      'SET_FILTER_GROWER_REPORT',
      'SET_ACTIVE',
      'SET_USING_VUETIFY'
    ]),
    prepareForExport: async function (key = 0, name) {
      const dateTitleText = `GROWER REPORT ${this.generateTitleDatePart()}`
      let dateTitle = await this.createTitle(dateTitleText, 'h5', '1rem')

      this.SharedExportToPDF({
        tableID: `report-${key}`,
        splitByOne: 28,
        splitBy: 30,
        fontSize: 12,
        headerRow: 2,
        orientation: 'portrait',
        fileName: `${this.modifyDate(dateTitleText)}`,
        title: dateTitle,
        extraTitle: [name, 'h6', '1rem']
      })
    },
    modifyDate: function (dateString) {
      // Use a regular expression to find the date within the string
      const regex = /(\d{1,2}\/\d{1,2}\/\d{2,4})/g
      let match = regex.exec(dateString)

      if (!match) {
        return dateString
      }

      let modifiedString = dateString
      while (match !== null) {
        // Format the date as [mm-dd-yyyy]
        const date = match[0].replace(/\//g, '-')
        const formattedDate = `[${date}]`
        modifiedString = modifiedString.replace(match[0], formattedDate)
        match = regex.exec(dateString)
      }

      // Make all words lowercase except for the first letter of the string
      const lowerCaseString = modifiedString.toLowerCase()
      const capitalizedString =
        lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)

      return capitalizedString
    },
    generateTitleDatePart: function () {
      const week = `WEEK-${this.filtersGrowerReport.week.value}`
      const weekDay = this.filtersGrowerReport.weekDay.value
        ? this.filtersGrowerReport.weekDay.value.day
        : ''
      const startDay = this.$options.filters.date(
        this.filtersGrowerReport.date_start.value
      )
      const endDay = weekDay
        ? ''
        : ` - ${this.$options.filters.date(
            this.filtersGrowerReport.date_end.value
          )}`
      return `${week} ${weekDay.toUpperCase()} ${startDay}${endDay}`
    },
    updateWeekFilter: async function (dateRange) {
      this.SET_FILTER_GROWER_REPORT({
        field: 'week',
        value: dateRange.weekNr
      })
      if (this.filtersGrowerReport.weekDay.value) {
        const dayNr = this.filtersGrowerReport.weekDay.value.nr
        const day = formatDateWithAddedDays(dateRange.startDay, dayNr)
        await this.updateDatePicker(day, day)
      } else {
        await this.updateDatePicker(dateRange.startDay, dateRange.endDay)
      }
      if (this.filtersGrowerReport.year.value != dateRange.year) {
        this.SET_FILTER_GROWER_REPORT({ field: 'year', value: dateRange.year })
      }
      this.fetchGrowerReport(this)
    },
    updateYearFilter: async function ({ year }) {
      const cleanYear = parseInt(year.trim())
      if (cleanYear == this.filtersGrowerReport.year.value) return
      await this.SET_FILTER_GROWER_REPORT({ field: 'year', value: cleanYear })
      if (!this.filtersGrowerReport.week.value) return
      this.$refs.growerReportSideFilter.generateWeekDays(
        this.filtersGrowerReport.week.value,
        cleanYear
      )
    },
    updateFilter: async function (value) {
      if (value.field == 'weekDay') {
        const weekNr = this.filtersGrowerReport.week.value
        const year = this.filtersGrowerReport.year.value
        const { start, end } = getWeekBounds(weekNr, year)
        if (value.value) {
          const day = formatDateWithAddedDays(start, value.value.nr)
          await this.updateDatePicker(day, day)
        } else {
          await this.updateDatePicker(start, end)
        }
      }
      this.SET_FILTER_GROWER_REPORT(value)
      this.fetchGrowerReport(this)
    },
    updateDatePicker: async function (sDay, eDay) {
      await Promise.all([
        this.SET_FILTER_GROWER_REPORT({
          field: 'date_start',
          value: sDay
        }),
        this.SET_FILTER_GROWER_REPORT({
          field: 'date_end',
          value: eDay
        })
      ])
      return
    },
    removeFilters: async function () {
      await this.clearFiltersGrowerReport()
      this.fetchGrowerReport(this)
    }
  },
  filters: {
    currency: function (value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })

      return isNaN(value) ? '-' : formatter.format(value)
    }
  },
  async created() {
    this.SET_USING_VUETIFY(true)
    this.SET_ACTIVE(PIG_INFO)
    await this.fetchGrowerReport(this)
  }
}
</script>

<style scoped>
.loadout-table {
  min-width: 600px;
}
.bg-light-grey {
  background-color: #0000000d;
}
.btn-sm {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
btn-neutral,
.btn-neutral:hover {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.btn-export {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  position: relative;
  text-transform: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
  -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.btn-export:hover {
  -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
</style>
