<script setup>
import {
  computed,
  getCurrentInstance,
  defineProps,
  defineEmits,
  ref,
  watch
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  bodyPart: {
    type: Number
  }
})
const emit = defineEmits(['update:product'])
const vm = getCurrentInstance().proxy

const store = useStore()
const loadingPigParts = computed(() => store.getters.loadingPigParts)
const pigPartList = computed(() => store.getters.pigPartList)

const value = ref(null)
const isLoading = computed(() => loadingPigParts.value === 'list')

const customFilter = (item, queryText) => {
  const textOne = item.part.toLowerCase()
  const searchText = queryText.toLowerCase().trim()

  return textOne.indexOf(searchText) > -1
}

const updateValue = (id) => {
  emit('update:bodyPart', id)
}

if (pigPartList.value.length === 0 && !isLoading.value) {
  store.dispatch('fetchPigParts', { vm })
}

watch(
  () => props.bodyPart,
  (newValue) => {
    if (value.value === newValue) return
    value.value = newValue
  },
  { immediate: true }
)
</script>

<template>
  <v-autocomplete
    v-model="value"
    :items="pigPartList"
    :filter="customFilter"
    item-text="part"
    item-value="id"
    outlined
    dense
    :label="label"
    :loading="isLoading"
    hide-details
    class="my-1"
    @change="updateValue"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content
        v-text="item.part"
        class="text-start"
      ></v-list-item-content>
    </template>
  </v-autocomplete>
</template>
