import axios from 'axios'
import Vue from 'vue'

import { PER_PAGE_MAX } from '@/constants/pagination'

const state = {
  processingPriceList: null,
  loadingProcPrice: ''
}
const getters = {
  processingPriceList: (state) => state.processingPriceList,
  loadingProcPrice: (state) => state.loadingProcPrice
}
const actions = {
  async fetchProcPrice({ commit, dispatch }, { vm, effectiveDate }) {
    let filters = {}
    if (effectiveDate) filters = { ref_date: effectiveDate }
    commit('SET_LOADING_PROC_PRICE', 'proc-price-list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'systemsettings/processingcost',
        parameters: {
          limit: PER_PAGE_MAX,
          offset: 0,
          sort_by: '',
          ascending: false
        },
        filters: filters
      },
      { root: true }
    )
    commit('SET_LOADING_PROC_PRICE', '')
    if (!res.error) {
      commit('SET_PROCESSING_PRICE_LIST', res.data)
      return res.data
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'Processing Price List',
          messagePrefix: 'Error while loading Processing price list! '
        },
        { root: true }
      )
      return 'error'
    }
  },
  async createProcPrice({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'systemsettings/processingcost'
    try {
      commit('SET_LOADING_PROC_PRICE', `proc-price`)
      await axios.post(URI, payload)

      vm.$bvToast.toast('New processing price created successfuly! ', {
        title: 'Create Processing Price',
        variant: 'success',
        solid: true
      })
      return 'success'
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Processing Price Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_PROC_PRICE', '')
    }
  },
  async updateProcPrice({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI =
      rootGetters.baseUrl + 'systemsettings/processingcost/' + payload.id
    try {
      commit('SET_LOADING_PROC_PRICE', `proc-price`)
      const res = await axios.patch(URI, payload)

      vm.$bvToast.toast('New processing price updated successfuly! ', {
        title: 'Update Processing Price',
        variant: 'success',
        solid: true
      })
      return res.data
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Processing Price Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_PROC_PRICE', '')
    }
  },
  async deleteProcPrice({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'systemsettings/' + payload.id
    try {
      commit('SET_LOADING_PROC_PRICE', `proc-price-list`)
      await axios.delete(URI)
      vm.$bvToast.toast(
        'Processing price deleted successfuly! ' + payload.effective_date,
        {
          title: 'Delete Processing Price',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Processing Price Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_PROC_PRICE', '')
    }
  }
}
const mutations = {
  SET_PROCESSING_PRICE_LIST: (state, payload) =>
    (state.processingPriceList = payload),
  SET_LOADING_PROC_PRICE: (state, payload) =>
    (state.loadingProcPrice = payload),
  UPDATE_PROCESSING_PRICE_LIST: (state, payload) => {
    const itemID = payload.id
    const { effective_date, id, setting } = payload
    const newPrice = { effective_date, id, ...setting }
    const index = state.processingPriceList.findIndex(
      (element) => element.id === itemID
    )
    Vue.set(state.processingPriceList, index, newPrice)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
