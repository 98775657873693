<template>
  <div class="container-fluid">
    <b-row no-gutters class="mb-2 mt-4 align-items-center">
      <div class="page-title-box d-flex">
        <h4 class="page-title">Upload Files</h4>
      </div>
    </b-row>
    <!-- <file-input fileName="sf1" /> -->
    <file-input fileName="sf2" />
    <!-- <file-input fileName="sf5" /> -->
    <!-- <file-input fileName="sf7a" /> -->
    <file-input fileName="sf8a" />
    <!-- <file-input fileName="sf8b" /> -->
    <file-input fileName="sf8c" />
    <file-input fileName="sf9" />
    <file-input fileName="sf10" />
    <file-input fileName="carton_export" />
  </div>
</template>

<script>
import FileInput from '@/components/FileInput.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'FileUploadView',
  components: {
    FileInput
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...mapMutations(['SET_ACTIVE', 'SET_USING_VUETIFY'])
  },
  created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE('home')
  }
}
</script>
