import axios from 'axios'

import { PER_PAGE_OPTIONS } from '@/constants/pagination'
import { PORKER_CODE_MAX } from '@/constants/product'

const state = {
  productList: [],
  productNames: [],
  loadingProducts: '',

  //pagination
  totalProd: 0,
  perPageProd: PER_PAGE_OPTIONS[0],
  pageNrProd: 0,

  //sorting
  sortbyProd: 'code',
  ascProd: true,

  //filters
  filtersProd: {
    product: { type: 'text', label: 'Product Name', value: '' },
    code: { type: 'text', label: 'Code', value: '' },
    type: { type: 'text', label: 'Product Type', value: '' }
  }
}
const getters = {
  products: (state) => state.productList,
  productNames: (state) => state.productNames,
  productNamesCarton: (state) =>
    state.productNames.filter((product) => product.code > PORKER_CODE_MAX),
  productNamesPorker: (state) =>
    state.productNames.filter((product) => product.code <= PORKER_CODE_MAX),
  loadingProducts: (state) => state.loadingProducts,
  totalProducts: (state) => state.totalProd,
  perPageProducts: (state) => state.perPageProd,
  pageNumberProd: (state) => state.pageNrProd,
  sortbyProd: (state) => state.sortbyProd,
  ascProd: (state) => state.ascProd,
  filtersProd: (state) => state.filtersProd,
  offsetProd: (state) => {
    return state.perPageProd * state.pageNrProd
  }
}
const actions = {
  async fetchProducts({ commit, dispatch, getters }) {
    commit('SET_LOADING_PRODUCTS', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'productlist/',
        parameters: {
          limit: getters.perPageProducts,
          offset: getters.offsetProd,
          sort_by: getters.sortbyProd,
          ascending: getters.ascProd
        },
        filters: getters.filtersProd
      },
      { root: true }
    )
    commit('SET_LOADING_PRODUCTS', '')
    if (!res.error) {
      commit('SET_PRODUCT_LIST', res.data)
      commit('SET_TOTAL_PROD', res.total)
    } else {
      const messagePrefix = 'Error while loading products! '
      const { error } = res
      dispatch('showError', { error, messagePrefix })
    }
  },
  async fetchProductNames({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'productlist/productnames'

    try {
      commit('SET_LOADING_PRODUCTS', 'names')
      let res = await axios.get(URI)
      commit('SET_PRODUCT_NAMES', res.data.data)
      commit('SET_LOADING_PRODUCTS', '')
    } catch (error) {
      commit('SET_LOADING_PRODUCTS', '')
      const messagePrefix = 'Error while loading product names! '
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        dispatch(
          'handleError',
          {
            vm,
            error,
            title: 'List of Product Names',
            messagePrefix
          },
          { root: true }
        )
      }
    }
  },
  async createProduct({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'productlist/'
    try {
      commit('SET_LOADING_PRODUCTS', 'create')
      await axios.post(URI, payload)
      dispatch(
        'showMessage',
        `New Product created successfuly! ${payload.product}`
      )
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_PRODUCTS', '')
    }
  },
  async updateProduct({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'productlist/' + payload.id
    try {
      commit('SET_LOADING_PRODUCTS', `update-${payload.id}`)
      await axios.patch(URI, payload)
      dispatch(
        'showMessage',
        `Product: ${payload.product} information updated successfuly!`
      )
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_PRODUCTS', '')
    }
  },
  async deleteProduct({ rootGetters, commit, dispatch }, { payload }) {
    const URI = rootGetters.baseUrl + 'productlist/' + payload.id
    try {
      commit('SET_LOADING_PRODUCTS', 'delete')
      await axios.delete(URI)
      dispatch('showMessage', `Product deleted successfuly! ${payload.product}`)
      return 'success'
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_PRODUCTS', '')
    }
  },
  async clearFiltersProd({ commit }) {
    commit('SET_FILTER_PROD', { field: 'product', value: '' })
    commit('SET_FILTER_PROD', { field: 'code', value: '' })
    commit('SET_FILTER_PROD', { field: 'type', value: '' })
  }
}
const mutations = {
  SET_PRODUCT_LIST: (state, payload) => (state.productList = payload),
  SET_LOADING_PRODUCTS: (state, payload) => (state.loadingProducts = payload),
  SET_PRODUCT_NAMES: (state, payload) => (state.productNames = payload),

  SET_TOTAL_PROD: (state, payload) => (state.totalProd = payload),
  SET_PAGE_NR_PROD: (state, payload) => (state.pageNrProd = payload),
  SET_PER_PAGE_PROD: (state, payload) => (state.perPageProd = payload),
  SET_SORT_BY_PROD: (state, payload) => (state.sortbyProd = payload),
  SET_ASC_PROD: (state, payload) => (state.ascProd = payload),
  SET_FILTER_PROD: (state, payload) => {
    state.filtersProd[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
