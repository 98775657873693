<script setup>
import {
  ref,
  computed,
  defineProps,
  getCurrentInstance,
  defineEmits
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm.vue'

const props = defineProps(['pigItems'])
const emit = defineEmits(['clearSuccess'])
const store = useStore()
const vm = getCurrentInstance().proxy
const dialog = ref(null)

const isPigSelected = computed(() => props.pigItems.length > 0)
const allocations = computed(() => {
  const list = []
  props.pigItems.forEach((pig) => {
    pig.allocation.forEach(({ id, company_id, pig_id, part_id }) => {
      list.push({
        id,
        company_id,
        pig_id,
        part_id
      })
    })
  })
  return list
})

const deleteAllocations = async () => {
  const res = await store.dispatch('bulkDeleteAllocation', {
    payload: allocations.value,
    vm
  })
  if (res === 'success') {
    dialog.value.closeDialog()
    emit('clearSuccess')
  }
}
</script>
<template>
  <BaseDeleteConfirm
    ref="dialog"
    :disabled="!isPigSelected"
    button-text="Clear Allocations"
    title="Are you sure you want to delete allocations?"
    size="x-small"
    @deleteConfirm="deleteAllocations"
  >
    <template #content>
      <div class="text-subtitle-2 mt-2">
        {{
          `From ${props.pigItems.length} Pig${
            props.pigItems.length > 1 ? 's' : ''
          }:`
        }}
      </div>
      <div>
        <span
          v-for="(pig, id) in props.pigItems"
          :key="pig.id"
          class="text-overline font-weight-bold"
        >
          {{ `${pig.body_no}${id + 1 !== props.pigItems.length ? ',' : ''}` }}
        </span>
      </div>
    </template>
  </BaseDeleteConfirm>
</template>
