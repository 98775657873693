<script setup>
import BaseSelectFreight from '@/components/base/BaseSelectFreight.vue'
import BaseSelectBodyPart from '@/components/base/BaseSelectBodyPart.vue'
import BaseSelectDestination from '@/components/base/BaseSelectDestination.vue'

import { defineProps, computed, getCurrentInstance } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { getISOWeekday } from '@/utils/DateHelpers'

const props = defineProps({
  items: Array,
  date: String
})

const store = useStore()
const vm = getCurrentInstance().proxy
const customerNames = computed(() => store.getters.customerNames)
const loadingCustomers = computed(() => store.getters.loadingCustomers)

const getTransport = (custId) => {
  const selectedCustomer = customerNames.value.find(
    (name) => name.company_id === custId
  )
  if (selectedCustomer === undefined) return ''
  if (!selectedCustomer.transport_schedule) return ''

  const dayOfWeek = getISOWeekday(props.date)
  const schedule = selectedCustomer.transport_schedule.find(
    (item) => item.weekday === dayOfWeek
  )
  if (!schedule) return ''
  return schedule.schedule.delivery_co_ids
}

const joinValues = (value) => {
  return value.join()
}

const updateFreight = (value, item) => {
  item.transport_id = joinValues(value)
}

const updateDestination = (value, item) => {
  item.company_id = value
  if (!value) {
    item.transport_id = ''
    return
  }
  item.transport_id = getTransport(value)
}

if (customerNames.value.length === 0 && loadingCustomers.value !== 'names') {
  store.dispatch('fetchCustomerNames', { vm })
}
</script>

<template>
  <v-simple-table class="bordered-table my-2" dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left product" style="width: 30%">
            Body Part<span class="text-danger">*</span>
          </th>
          <th class="text-left qty">
            Destination<span class="text-danger">*</span>
          </th>
          <th style="width: 30%">
            Transport<span class="text-danger">*</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in items" :key="`${item.name}-${idx}`">
          <td>
            <BaseSelectBodyPart
              id="body-part-select"
              :body-part="item.part_id"
              @update:bodyPart="item.part_id = $event"
            />
          </td>
          <td>
            <BaseSelectDestination
              :destination="item.company_id"
              :label="''"
              @update:destination="updateDestination($event, item)"
            />
          </td>
          <td>
            <BaseSelectFreight
              :freight="
                item.transport_id.trim() ? item.transport_id.split(',') : []
              "
              :label="''"
              :rootClass="'mt-2'"
              @update:freight="updateFreight($event, item)"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style scoped>
.bordered-table th,
.bordered-table td {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>

<style>
.bordered-table > .v-data-table__wrapper {
  overflow-y: visible;
  overflow-x: visible;
}
</style>
