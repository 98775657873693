<script setup>
import { useStore } from '@/utils/VuexHelpers'
import { computed } from 'vue'

const store = useStore()
const orderSummaryDays = computed(() => store.getters.orderSummaryDays)

const selectedDays = computed({
  get: () => {
    return [...orderSummaryDays.value]
  },
  set: (value) => {
    store.commit('SET_ORDER_SUMMARY_DAYS', value)
    store.commit('UPDATE_SUMMARY')
  }
})
</script>

<template>
  <v-row no-gutters>
    <v-chip-group
      v-model="selectedDays"
      active-class="primary--text text--accent-4"
      multiple
      mandatory
    >
      <v-chip filter label outlined small>MON</v-chip>
      <v-chip filter label outlined small>TUE</v-chip>
      <v-chip filter label outlined small>WED</v-chip>
      <v-chip filter label outlined small>THU</v-chip>
      <v-chip filter label outlined small>FRI</v-chip>
    </v-chip-group>
  </v-row>
</template>
