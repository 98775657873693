<script setup>
import {
  computed,
  getCurrentInstance,
  defineProps,
  defineEmits,
  ref
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { CARTON, PORKER } from '@/constants/general'

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  product: {
    type: Object
  },
  type: {
    type: String
  }
})
const emit = defineEmits(['update:product'])

const store = useStore()
const vm = getCurrentInstance().proxy
const names = computed(() => store.getters.productNames)
const productNamesCarton = computed(() => store.getters.productNamesCarton)
const productNamesPorker = computed(() => store.getters.productNamesPorker)
const loadingProducts = computed(() => store.getters.loadingProducts)
const productNames = computed(() => {
  if (props.type === CARTON) return productNamesCarton.value
  if (props.type === PORKER) return productNamesPorker.value
  return names.value
})

const value = ref(props.product.productcode)

const customFilter = (item, queryText) => {
  const textOne = item.product.toLowerCase()
  const textTwo = item.code ? item.code.toString() : ''
  const searchText = queryText.toLowerCase().trim()

  return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
}

const updateValue = (code) => {
  if (!code) {
    emit('update:product', null)
    return
  }
  const item = productNames.value.find((item) => item.code === code)
  emit('update:product', item)
}

const closeChip = () => {
  value.value = null
  emit('update:product', null)
}

if (names.value.length === 0 && loadingProducts.value !== 'names') {
  store.dispatch('fetchProductNames', { vm })
}
</script>

<template>
  <v-autocomplete
    v-model="value"
    :items="productNames"
    :filter="customFilter"
    item-text="product"
    item-value="code"
    outlined
    dense
    chips
    small-chips
    :label="label"
    :loading="loadingProducts === 'names'"
    hide-details
    class="my-1"
    @change="updateValue"
  >
    <template v-slot:selection="{ item, selected, attrs }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        small
        close
        label
        @click:close="closeChip"
      >
        {{ `${props.type === PORKER ? '' : item.code + ' - '}${item.product}` }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content class="text-start">
          <v-list-item-title>
            {{ `${data.item.code} - ${data.item.product}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
