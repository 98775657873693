<template>
  <font-awesome-icon icon="fas fa-times" />
</template>

<script>
export default {}
</script>

<style scoped>
.fa-xmark {
  color: #dc3545;
}
</style>
