import { uuid } from '@/helpers/stringHelpers.js'
import { unref } from 'vue'

const CONFIRM_DIALOG_TEXT = 'Are you sure you want to delete this item?'
const SUCCESS = 'success'
const ERROR = 'error'

const state = {
  messageList: [],
  confirmDialog: false,
  confirmAction: null,
  confirmText: CONFIRM_DIALOG_TEXT
}
const getters = {
  messageList: (state) => state.messageList
}
const actions = {
  showError({ dispatch }, { error, messagePrefix = '' }) {
    let message = error.message

    message = error.response?.data?.message || message
    if (error.response?.data?.detail) {
      message =
        typeof error.response.data.detail === 'string' ||
        typeof error.response.data.detail === 'number'
          ? error.response.data.detail
          : error.response.data.detail[0].msg
    }

    if (error.error !== 'Refresh token expired') {
      dispatch('addMessage', {
        message: messagePrefix + message,
        type: ERROR
      })
    }

    console.log(error)
  },
  showMessage({ dispatch }, message) {
    message = unref(message)
    dispatch('addMessage', { message, type: SUCCESS })
  },
  addMessage({ commit, dispatch }, { message, type }) {
    const messageId = uuid()
    commit('ADD_MESSAGE_TO_LIST', { id: messageId, message, type })
    if (type === SUCCESS) {
      setTimeout(() => {
        dispatch('closeMessage', messageId)
      }, 3000)
    }
  },
  closeMessage({ commit }, messageId) {
    const index = state.messageList.findIndex(
      (message) => message.id === messageId
    )
    if (index !== -1) {
      commit('REMOVE_MESSAGE_FROM_LIST', index)
    }
  }
}

const mutations = {
  ADD_MESSAGE_TO_LIST: (state, payload) => state.messageList.unshift(payload),
  REMOVE_MESSAGE_FROM_LIST: (state, payload) =>
    state.messageList.splice(payload, 1)
}

export default {
  state,
  getters,
  actions,
  mutations
}
