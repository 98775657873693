<template>
  <div class="leftside-menu p-2">
    <b-row
      v-if="sortingList"
      no-gutters
      class="mb-2 border-bottom align-items-baseline"
    >
      <font-awesome-icon
        icon="fas fa-sort-amount-down"
        class="mr-2 text-primary"
      />
      <h5>Sorting</h5>
      <b-badge
        variant="light"
        href="#"
        class="align-self-top ml-auto"
        @click=";(sorting = []), $emit('sort', sorting)"
        >Remove Sorting</b-badge
      >
    </b-row>
    <b-row v-if="sortingList" class="flex-column text-left mb-4" no-gutters>
      <vue-select
        :options="sortingList"
        v-model="sorting"
        multiple
        @input="$emit('sort', sorting)"
      >
        <template #selected-option="ctx">
          <div style="display: flex; align-items: center">
            <font-awesome-icon
              v-if="ctx.asc == true"
              @click="
                $emit('direction', { id: ctx.id, asc: false, sorting: sorting })
              "
              icon="fas fa-sort-alpha-down"
              class="mr-2 pointer"
            />
            <font-awesome-icon
              v-else
              @click="
                $emit('direction', { id: ctx.id, asc: true, sorting: sorting })
              "
              icon="fas fa-sort-alpha-up"
              class="mr-2 pointer"
            />{{ ctx.label }}
          </div>
        </template>
      </vue-select>
    </b-row>
    <b-row no-gutters class="mb-2 border-bottom align-items-baseline">
      <font-awesome-icon icon="fas fa-filter" class="mr-2 text-primary" />
      <h5>Filters</h5>
      <b-badge
        v-if="!hideClear"
        variant="light"
        href="#"
        class="align-self-top ml-auto"
        @click="$emit('clear')"
        >Clear filters</b-badge
      >
    </b-row>
    <b-row class="flex-column text-left" no-gutters>
      <b-col v-for="(item, key) in filterList" :key="item.id">
        <b-form-group
          :label="item.label"
          :label-for="`${key}`"
          :description="null"
          :class="item.hidden ? 'd-none' : ''"
        >
          <b-row v-if="item.type == 'week'" no-gutters>
            <b-form-select
              size="sm"
              :value="item.value"
              :options="weekOptions(filterList.year.value)"
              :id="`${key}`"
              @change="generateWeekDays($event, filterList.year.value)"
            >
            </b-form-select>
            <small class="mt-1 text-muted d-flex w-100"
              ><div
                class="mr-1 pointer under"
                v-if="currentWeek > 1"
                @click="generateWeekDays(currentWeek - 1)"
              >
                Previous:
                <span class="font-weight-bold">{{ currentWeek - 1 }}</span>
              </div>
              <div class="pointer under" @click="generateWeekDays(currentWeek)">
                Current: <span class="font-weight-bold">{{ currentWeek }}</span>
              </div>
              <div
                v-if="filterList.year"
                id="year-select"
                class="pointer under ml-auto"
              >
                Year:
                <span class="font-weight-bold">{{
                  filterList.year.value
                }}</span>
              </div>
            </small>
          </b-row>
          <b-input
            :id="`${key}`"
            v-if="item.type == 'text'"
            size="sm"
            :value="item.value"
            @change="$emit('input', { value: $event, field: key })"
          ></b-input>
          <b-form-datepicker
            v-if="item.type == 'date'"
            :id="`${key}`"
            size="sm"
            :disabled="item.readOnly"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }"
            locale="en-Au"
            start-weekday="1"
            :reset-button="item.reset ? true : false"
            :value="item.value"
            @input="$emit('input', { value: $event, field: key })"
          ></b-form-datepicker>
          <b-checkbox-group
            :id="`${key}`"
            :value="item.value"
            :options="item.options"
            @change="$emit('input', { value: $event, field: key })"
            v-if="item.type == 'checkbox'"
          ></b-checkbox-group>
          <vue-select
            id="select-filter"
            v-if="item.type == 'select' || item.type == 'selectWeekDay'"
            placeholder="Select ..."
            :label="getSelectLabel(item)"
            :options="selectOptions(item, key)"
            :reduce="(option) => getReduce(item, option)"
            :value="item.value"
            @input="$emit('input', { value: $event, field: key })"
            class="mb-2"
          >
            <template #option="obj" v-if="item.customOptions">
              {{ item.customOptions(obj) }}
            </template>
          </vue-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-popover target="year-select" triggers="hover" placement="bottom">
      <template #title> Please select the year </template>
      <ul v-if="filterList.year" no-gutters class="flex-column year-content">
        <li
          v-for="year in yearList"
          :key="year"
          @click="changeYear"
          class="under pointer year-item"
        >
          {{ year }}
          <icon-check v-if="filterList.year.value == year" class="ml-1" />
        </li>
      </ul>
    </b-popover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { selectWeekMixin } from '../mixins/calendar'
import IconCheck from '../components/IconCheck.vue'

export default {
  name: 'SideFilter',
  mixins: [selectWeekMixin],
  components: { IconCheck },
  props: {
    filterList: Object,
    sortingList: {
      type: Array,
      default: null
    },
    hideClear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sorting: []
    }
  },
  computed: {
    ...mapGetters([
      'growerList',
      'destinationList',
      'stockDestinationList',
      'freightList'
    ])
  },
  methods: {
    ...mapActions(['fetchGrowers']),
    selectOptions: function (item, key) {
      if (key == 'producer') {
        if (!this.growerList.length) {
          this.fetchGrowers({ vm: this })
        }
        return this.growerList
      }
      if (key == 'allocation_destination_co_id') {
        return this.destinationList
      }
      if (key == 'destination_co_id') {
        return this.stockDestinationList
      }
      if (key === 'primary_transport') {
        return this.freightList
      }
      return item.options
    },
    getSelectLabel(item) {
      if (item.selectLabel) return item.selectLabel
      if (item.name) return item.name
      return 'item'
    },
    getReduce(item, option) {
      if (item.noReduce) return option
      if (item.name) return option[item.name]
      return option
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.under:hover {
  text-decoration: underline;
}
.year-content {
  max-height: 165px;
  overflow: auto;
}
.year-item {
  border-radius: 3px;
  padding-left: 2px !important;
  padding-bottom: 2px;
}
.year-item:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
</style>
