import axios from 'axios'

const state = {
  pigPartUrl: 'piginfo/pigpart/',
  pigPartList: [],
  loadingPigParts: '',
  totalParts: ''
}

const getters = {
  pigPartList: (state) => state.pigPartList,
  pigPartUrl: (state) => state.pigPartUrl,
  loadingPigParts: (state) => state.loadingPigParts,
  totalParts: (state) => state.totalParts
}

const actions = {
  async fetchPigParts({ rootGetters, getters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + getters.pigPartUrl
    try {
      commit('SET_LOADING_PIG_PARTS', 'list')
      let res = await axios.get(URI)
      commit('SET_PIG_PARTS_LIST', res.data.data)
      commit('SET_TOTAL_PIG_PARTS', res.data.total)
      commit('SET_LOADING_PIG_PARTS', '')
    } catch (error) {
      commit('SET_LOADING_PIG_PARTS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'List of Pig Parts',
          messagePrefix: 'Error while loading pig part list! '
        },
        { root: true }
      )
    }
  },
  async createPigPart(
    { rootGetters, getters, commit, dispatch },
    { payload, vm }
  ) {
    const URI = rootGetters.baseUrl + getters.pigPartUrl
    try {
      commit('SET_LOADING_PIG_PARTS', 'create')
      await axios.post(URI, payload)
      commit('SET_LOADING_PIG_PARTS', '')
      vm.$bvToast.toast('New Pig Part created successfuly! ', {
        title: 'Create Pig Part',
        variant: 'success',
        solid: true
      })

      return 'success'
    } catch (error) {
      commit('SET_LOADING_PIG_PARTS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Pig Part Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async updatePigPart(
    { rootGetters, getters, commit, dispatch },
    { payload, vm }
  ) {
    const URI = rootGetters.baseUrl + getters.pigPartUrl + payload.id
    try {
      commit('SET_LOADING_PIG_PARTS', `update-${payload.id}`)
      await axios.patch(URI, payload)
      commit('SET_LOADING_PIG_PARTS', '')
      vm.$bvToast.toast('Pig part updated successfuly!', {
        title: 'Update Pig Part Information',
        variant: 'success',
        solid: true
      })

      return 'success'
    } catch (error) {
      commit('SET_LOADING_PIG_PARTS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Pig Part Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async deletePigPart(
    { rootGetters, getters, commit, dispatch },
    { payload, vm }
  ) {
    const URI = rootGetters.baseUrl + getters.pigPartUrl + payload.id
    try {
      commit('SET_LOADING_PIG_PARTS', 'delete')
      await axios.delete(URI)
      commit('SET_LOADING_PIG_PARTS', '')
      vm.$bvToast.toast('Pig Part deleted successfuly! ' + payload.part, {
        title: 'Delete Pig Part',
        variant: 'success',
        solid: true
      })

      return 'success'
    } catch (error) {
      commit('SET_LOADING_PIG_PARTS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Pig Part Error'
        },
        { root: true }
      )
      return 'error'
    }
  }
}

const mutations = {
  SET_PIG_PARTS_LIST: (state, payload) => (state.pigPartList = payload),
  SET_LOADING_PIG_PARTS: (state, payload) => (state.loadingPigParts = payload),
  SET_TOTAL_PIG_PARTS: (state, payload) => (state.totalParts = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
