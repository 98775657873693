<template>
  <b-collapse id="run-report" class="text-left">
    <b-row no-gutters class="align-items-baseline">
      <h4 class="header-title mb-3 w-700 text-light-blue text-left">
        Completed Load Outs <span>({{ runReport.completed.pigs.length }})</span>
      </h4>
      <b-badge
        variant="light"
        class="align-self-top ml-2"
        @click.prevent
        v-b-toggle.run-completed
        ><span class="when-closed"
          >Show<font-awesome-icon icon="fas fa-chevron-right" class="ml-2"
        /></span>
        <span class="when-open"
          >Hide<font-awesome-icon
            icon="fas fa-chevron-down"
            class="ml-2" /></span></b-badge
    ></b-row>

    <b-collapse id="run-completed" class="text-left">
      <simple-totals
        :totals="runReport.completed.totals"
        :id="'completed-totals'"
      >
        <template #export-button>
          <single-export-button
            :generating="generatingPDF == 'completed-table' ? true : false"
            @click="prepareForExport('completed')"
          />
        </template>
      </simple-totals>
      <b-table
        id="completed-table"
        v-if="dataReady"
        striped
        small
        sort-icon-left
        :fields="incompleteFields"
        :items="runReport.completed.pigs"
      >
        <template #cell(date)="data">
          {{ data.item.date | date }}
        </template>
        <template #cell(transport_ids)="data">
          {{ freightCode(data.item.transport_ids) }}
        </template>
      </b-table>
    </b-collapse>

    <b-row no-gutters class="align-items-baseline">
      <h4 class="header-title mb-3 w-700 text-light-blue text-left">
        Incomplete Load Outs
        <span>({{ runReport.incomplete.pigs.length }})</span>
      </h4>
      <b-badge
        variant="light"
        class="align-self-top ml-2"
        @click.prevent
        v-b-toggle.run-incomplete
        ><span class="when-closed"
          >Show<font-awesome-icon icon="fas fa-chevron-right" class="ml-2"
        /></span>
        <span class="when-open"
          >Hide<font-awesome-icon
            icon="fas fa-chevron-down"
            class="ml-2" /></span></b-badge
    ></b-row>
    <b-collapse id="run-incomplete" class="text-left">
      <simple-totals
        :totals="runReport.incomplete.totals"
        :id="'incomplete-totals'"
        ><template #export-button>
          <single-export-button
            :generating="generatingPDF == 'incomplete-table' ? true : false"
            @click="prepareForExport('incomplete')"
          /> </template
      ></simple-totals>
      <b-table
        id="incomplete-table"
        v-if="freightCodeMap"
        striped
        small
        sort-icon-left
        :fields="incompleteFields"
        :items="runReport.incomplete.pigs"
      >
        <template #cell(date)="data">
          {{ data.item.date | date }}
        </template>
        <template #cell(transport_ids)="data">
          {{ freightCode(data.item.transport_ids) }}
        </template>
      </b-table>
    </b-collapse>

    <b-row no-gutters class="align-items-baseline">
      <h4 class="header-title mb-3 w-700 text-light-blue text-left">
        Unallocated <span>({{ runReport.unalloc.pigs.length }})</span>
      </h4>
      <b-badge
        variant="light"
        class="align-self-top ml-2"
        @click.prevent
        v-b-toggle.run-unalloc
        ><span class="when-closed"
          >Show<font-awesome-icon icon="fas fa-chevron-right" class="ml-2"
        /></span>
        <span class="when-open"
          >Hide<font-awesome-icon
            icon="fas fa-chevron-down"
            class="ml-2" /></span></b-badge
    ></b-row>
    <b-collapse id="run-unalloc" class="text-left">
      <b-table
        v-if="freightCodeMap"
        striped
        small
        sort-icon-left
        :fields="unallocFields"
        :items="runReport.unalloc.pigs"
      >
        <template #cell(kill_date)="data">
          {{ data.item.date | date }}
        </template>
        <template #cell(body_no)="data"
          ><span
            class="pointer"
            @click="scrollToPig(data.item.body_no, data.item.date)"
            >{{ data.item.body_no }}</span
          >
        </template>
      </b-table>
    </b-collapse>
  </b-collapse>
</template>

<script>
import { pdfUtilsMixin } from '../mixins/pdfUtils'

import { mapActions, mapGetters } from 'vuex'

import SimpleTotals from '../components/SimpleTotals.vue'
import SingleExportButton from '../components/SingleExportButton.vue'

export default {
  name: 'RunReport',
  mixins: [pdfUtilsMixin],
  components: {
    SimpleTotals,
    SingleExportButton
  },
  data() {
    return {
      dataReady: false,
      generatingPDF: '',
      incompleteFields: [
        { key: 'transport_ids', label: 'Transport', sortable: true },
        { key: 'customer', sortable: true },
        { key: 'product', label: 'Product/Type' },
        { key: 'date', label: 'Est. Loadout Date' },
        { key: 'body_no', label: 'Body No#' },
        { key: 'kill_date', label: 'Kill Date' }
      ],
      unallocFields: [
        { key: 'product', label: 'Product/Type' },
        { key: 'body_no', label: 'Body No#' },
        { key: 'kill_date', label: 'Kill Date' },
        { key: 'HSCW' },
        { key: 'sex' },
        { key: 'p2' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'runReport',
      'freightNames',
      'freightCodeMap',
      'startDatePig',
      'startDayPig',
      'startWeekPig',
      'filtersPig'
    ])
  },
  methods: {
    ...mapActions(['fetchFreightNames']),
    prepareForExport: function (type) {
      const titleDatePart = this.sharedGenerateTitlePart(
        '',
        this.startDatePig,
        this.filtersPig,
        true
      )
      switch (type) {
        case 'incomplete':
          this.SharedExportToPDF({
            tableID: 'incomplete-table',
            totalsID: 'incomplete-totals',
            splitByOne: 17,
            splitBy: 20,
            fileName: `Incomplete-LoadOuts-[${titleDatePart}]`,
            startDate: this.startDatePig,
            filters: this.filtersPig,
            title: 'INCOMPLETE LOADOUTS'
          })
          break
        case 'completed':
          this.SharedExportToPDF({
            tableID: 'completed-table',
            totalsID: 'completed-totals',
            splitByOne: 17,
            splitBy: 20,
            fileName: `Completed-LoadOuts-[${titleDatePart}]`,
            startDate: this.startDatePig,
            filters: this.filtersPig,
            title: 'COMPLETED LOADOUTS'
          })
          break
        default:
          break
      }
    },
    scrollToPig: function (id, date) {
      const element = document.getElementById(`${id}-${date}`)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    freightCode: function (value) {
      if (this.freightCodeMap) {
        let arr = value.split(',')
        const mapped = arr.map((el) => {
          if (el) {
            return this.freightCodeMap[el].code
          }
        }, this)
        return mapped.join('/')
      } else return '-'
    }
  },
  async mounted() {
    if (!this.freightCodeMap) {
      await this.fetchFreightNames({ vm: this })
    }
    this.dataReady = true
  }
}
</script>
