import { WEEK_OPTIONS } from '@/constants/general'
import {
  setISOWeekYear,
  setISOWeek,
  startOfWeek,
  endOfWeek,
  addDays,
  format,
  parseISO,
  isDate,
  getDay,
  getISOWeek,
  getYear as getDfnsYear,
  parse,
  isBefore,
  differenceInDays
} from 'date-fns'

/**
 * Retrieves the configuration for day of the week.
 *
 * @param {string|Date} [date=new Date()] - The date to determine the day of the week, defaults to the current date.
 * @returns {object} Object containing the name and number the date.
 */
export const getDayValues = (date = new Date()) => {
  const dateToUse = isDate(date) ? date : parseISO(date)
  const todayIndex = getDay(dateToUse)
  const adjustedIndex = (todayIndex + 6) % 7
  return WEEK_OPTIONS[adjustedIndex]
}

/**
 * Parses a given date string into a Date object using the specified format.
 * Default format of date string is 'yyyy-MM-dd', it can be overwritten.
 *
 * @param {string} dateString - The date string to be parsed.
 * @param {string} [dateFormat = 'yyyy-MM-dd'] - The format that describes the structure of the dateString, defaults to 'yyyy-MM-dd'.
 * @returns {Date} The parsed Date object, or an invalid Date object if parsing fails.
 */
export const parseDate = (dateString, dateFormat = 'yyyy-MM-dd') => {
  return parse(dateString, dateFormat, new Date())
}

/**
 * Returns today's date formatted as 'yyyy-MM-dd'.
 *
 * @returns {string} The current date formatted as 'yyyy-MM-dd'.
 */
export const getCurrentDay = () => {
  const today = new Date() // Get the current date
  return format(today, 'yyyy-MM-dd') // Format and return the date
}

/**
 * Retrieves the ISO week number for a given date or for the current date if no date is provided.
 * ISO weeks start on Monday, with the first week of the year containing the first Thursday of the year.
 *
 * @param {string|Date} [date=new Date()] - The date to determine the ISO week number for, defaults to the current date.
 * @returns {number} The ISO week number of the specified or current date.
 */
export const getISOWeekNumber = (date = new Date()) => {
  const dateToUse = isDate(date) ? date : parseISO(date)
  return getISOWeek(dateToUse)
}

/**
 * Returns the calendar year from given date as a four-digit number.
 *
 * @param {string|Date} [date=new Date()] - The date to determine the year, defaults to the current date.
 * @returns {number} The current year.
 */
export const getYear = (date = new Date()) => {
  const dateToUse = isDate(date) ? date : parseISO(date)
  return getDfnsYear(dateToUse)
}

/**
 * Gets a formatted date string based on the specified year, ISO week number, and optional day index.
 *
 * @param {number} year - The calendar year.
 * @param {number} week - The ISO week number within the given year.
 * @param {number} [dayIndex=0] - The index of the day within the week, where 0 corresponds to Monday.
 * @returns {string} The date in 'yyyy-MM-dd' format.
 */
export const getDateByYearAndWeek = (year, week, dayIndex = 0) => {
  let date = setISOWeek(setISOWeekYear(new Date(), year), week)
  date = startOfWeek(date, { weekStartsOn: 1 })
  date = addDays(date, dayIndex)

  return format(date, 'yyyy-MM-dd')
}

/**
 * Formats a date, provided as a string or Date object, by adding a specified number of days and returning it in 'yyyy-MM-dd' format.
 *
 * @param {Date|string} date - The initial date which can be a Date object or a date string in ISO format.
 * @param {number} increment - The number of days to add to the date.
 * @returns {string} The new date formatted as 'yyyy-MM-dd'.
 */
export const formatDateWithAddedDays = (date, increment) => {
  const dateToUse = isDate(date) ? date : parseISO(date)
  const adjustedDate = addDays(dateToUse, increment)

  return format(adjustedDate, 'yyyy-MM-dd')
}

/**
 * Returns the start and end dates of the specified or current ISO week, formatted as 'yyyy-MM-dd'.
 * If no year and week number are specified, it defaults to the current week.
 *
 * @param {number} [week=getISOWeekNumber()] - The ISO week number, defaults to the current week number.
 * @param {number} [year=getYear()] - The ISO week number, defaults to the current week number.
 * @returns {{ start: string, end: string }} An object containing the formatted start and end dates of the specified or current ISO week.
 */
export const getWeekBounds = (week = getISOWeekNumber(), year = getYear()) => {
  const baseDate = setISOWeekYear(new Date(), year)
  const date = setISOWeek(baseDate, week)
  const start = startOfWeek(date, { weekStartsOn: 1 })
  const end = endOfWeek(date, { weekStartsOn: 1 })

  return {
    start: format(start, 'yyyy-MM-dd'),
    end: format(end, 'yyyy-MM-dd')
  }
}

/**
 * Returns the ISO weekday for a given date.
 *
 * @param {string|Date} date - The date string or Date object to find the ISO weekday for.
 * @returns {number} The ISO weekday number.
 */
export const getISOWeekday = (date) => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date
  const dayOfWeek = getDay(parsedDate)
  return dayOfWeek === 0 ? 7 : dayOfWeek // Convert Sunday from 0 to 7, keep other days as is
}

/**
 * Parses a date string in 'yyMMdd' format and formats it to 'yyyy-MM-dd'.
 *
 * @param {string} dateString - The date string in 'yyMMdd' format.
 * @returns {string} The formatted date string in 'yyyy-MM-dd' format.
 */
export const formatScannerDate = (dateString) => {
  const parsedDate = parse(dateString, 'yyMMdd', new Date())
  return format(parsedDate, 'yyyy-MM-dd')
}

/**
 * Checks if the first date is before the second date.
 *
 * @param {string|Date} firstDate - The first date in string or date format.
 * @param {string|Date} secondDate - The second date in string or date format.
 * @returns {boolean} - Returns true if the first date is before the second date, otherwise false.
 */
export const isBeforeDate = (firstDate, secondDate) => {
  const valueDate = isDate(firstDate) ? firstDate : parseISO(firstDate)
  const minDate = isDate(secondDate) ? secondDate : parseISO(secondDate)

  return isBefore(valueDate, minDate)
}

/**
 * Calculates the difference in days between two dates.
 *
 * @param {string|Date} startDate - The start date in string or date format.
 * @param {string|Date} endDate - The end date in string or date format.
 * @returns {number} - Returns the difference in days between the two dates.
 */
export const calculateDayDifference = (startDate, endDate) => {
  const start = isDate(startDate) ? startDate : parseISO(startDate)
  const end = isDate(endDate) ? endDate : parseISO(endDate)

  return differenceInDays(end, start)
}
