<template>
  <b-modal id="trans-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <select-freight
        :freight="schedule.schedule.delivery_co_ids"
        :label="schedule.weekday | weekDay"
        @input="(val) => setValues(val)"
      />
      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingTransport == 'schedule'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectFreight from '../components/SelectFreight.vue'

export default {
  name: 'ModifyCustomerTransportModal',
  components: {
    SelectFreight
  },
  props: {
    type: { type: String, default: 'edit' }
  },
  data() {
    return {
      schedule: {
        weekday: 0,
        destination_co_id: 0,
        schedule: {
          delivery_co_ids: ''
        }
      },
      tempInfo: null
    }
  },
  computed: {
    ...mapGetters(['freightNames', 'loadingTransport']),
    actionString() {
      return 'Select/Remove Transport Provider'
    }
  },
  methods: {
    ...mapActions(['createSchedule', 'updateSchedule']),
    setValues: function (val) {
      this.schedule.schedule.delivery_co_ids = val
    },
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      if (this.type == 'add') {
        let copy = JSON.parse(JSON.stringify(this.schedule))
        copy.schedule.delivery_co_ids = copy.schedule.delivery_co_ids.join(',')
        res = await this.createSchedule({
          payload: copy,
          vm: this
        })
        if (res !== 'error') {
          this.tempInfo.transport_schedule[res.data.weekday - 1] = res.data
          this.$root.$emit('bv::refresh::table', 'customer-table')
          this.$bvModal.hide('trans-modal')
        }
      }
      if (this.type == 'edit') {
        let copy = JSON.parse(JSON.stringify(this.schedule))
        copy.schedule.delivery_co_ids = copy.schedule.delivery_co_ids.join(',')
        res = await this.updateSchedule({
          payload: copy,
          vm: this
        })
        if (res !== 'error') {
          this.tempInfo.schedule.delivery_co_ids =
            res.data.schedule.delivery_co_ids
          this.$bvModal.hide('trans-modal')
        }
      }
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: function (payload) {
      this.tempInfo = payload.company
      this.resetValues()
      this.schedule.weekday = payload.weekday
      this.schedule.destination_co_id = payload.company.id
      this.$bvModal.show('trans-modal')
    },
    edit: function (info) {
      this.tempInfo = info
      this.schedule.weekday = this.tempInfo.weekday
      this.schedule.destination_co_id = this.tempInfo.destination_co_id
      this.schedule.id = this.tempInfo.id
      this.restoreValues()
      this.$bvModal.show('trans-modal')
    },
    // Reset part form values
    resetValues: function () {
      if (Object.prototype.hasOwnProperty.call(this.schedule, 'id'))
        delete this.schedule.id
      this.schedule.schedule.delivery_co_ids = []
    },
    // Restore part form values to initial
    restoreValues: function () {
      this.tempInfo.schedule.delivery_co_ids.trim() == ''
        ? (this.schedule.schedule.delivery_co_ids = [])
        : (this.schedule.schedule.delivery_co_ids =
            this.tempInfo.schedule.delivery_co_ids.split(','))
    }
  }
}
</script>

<style></style>
