// COLORS
export const LIGHT_YELLOW_HEX = '#fff9c4'

// ORDERS
export const PORKER = 'PORKER'
export const CARTON = 'CARTON'
export const BREAK = 'BREAK'

// FILTERS
export const DATE_START_STR = 'date_start'
export const DATE_END_STR = 'date_end'
export const WEEK_STR = 'week'

// CALENDAR
export const MONDAY = 'Monday'
export const TUESDAY = 'Tuesday'
export const WEDNESDAY = 'Wednesday'
export const THURSDAY = 'Thursday'
export const FRIDAY = 'Friday'
export const SATURDAY = 'Saturday'
export const SUNDAY = 'Sunday'

export const WEEK_DAYS = Object.freeze([
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY
])

export const DEFAULT_VIEW = 'Weekly View'
export const CLASSIC_VIEW = 'Classic View'
export const PAGE_VIEWS = [CLASSIC_VIEW, DEFAULT_VIEW]

export const WEEK_OPTIONS = Object.freeze([
  { day: MONDAY, nr: 0, abbr: 'MON' },
  { day: TUESDAY, nr: 1, abbr: 'TUE' },
  { day: WEDNESDAY, nr: 2, abbr: 'WED' },
  { day: THURSDAY, nr: 3, abbr: 'THU' },
  { day: FRIDAY, nr: 4, abbr: 'FRI' },
  { day: SATURDAY, nr: 5, abbr: 'SAT' },
  { day: SUNDAY, nr: 6, abbr: 'SUN' }
])

export const PROCESSING_DATE_OFFSETS = Object.freeze({
  [MONDAY]: {
    start: -4,
    end: -3
  },
  [TUESDAY]: {
    start: -5,
    end: -1
  },
  [WEDNESDAY]: {
    start: -2,
    end: -1
  },
  [THURSDAY]: {
    start: -1,
    end: -1
  },
  [FRIDAY]: {
    start: -2,
    end: -1
  }
})

export const BOOYONG_COMPANY_ID = 10
export const STORAGE_COMPANY_IDS = [157, 154, 124]
