<template>
  <b-table
    :fields="fields"
    :items="groupNameList"
    v-bind="$attrs"
    :tbody-tr-class="detailsClassFn"
  >
    <template #table-busy>
      <base-table-loader />
    </template>
    <template
      v-slot:[`cell(${field.key})`]="{ item, toggleDetails, detailsShowing }"
      v-for="field in fields"
    >
      <span v-if="field.key == 'actions'" :key="field.key"
        ><b-row no-gutters class="mb-2" v-if="groupedItemList[item[groupBy]]"
          ><icon-delete
            :position="'left'"
            @click.native="deleteItem(groupedItemList[item[groupBy]][0])"
            class="mr-2" />
          <icon-edit
            :position="'bottom'"
            @click.native="
              $emit('editItem', groupedItemList[item[groupBy]][0])
            "
        /></b-row>
        <b-row
          no-gutters
          v-if="groupedItemList[item[groupBy]]"
          class="align-items-center"
        >
          <b-link
            href="#"
            @click="toggleDetails"
            v-if="groupedItemList[item[groupBy]].length > 1"
            ><font-awesome-icon :icon="['fas', 'history']" class="mr-1" />{{
              `${detailsShowing == true ? 'Hide' : 'Show'}`
            }}
            History</b-link
          ></b-row
        >
        <b-row v-else no-gutters
          ><b-link
            href="#"
            :icon="['fas', 'fa-plus']"
            class="text-danger"
            @click="$emit('addItem', item[groupBy])"
            ><font-awesome-icon :icon="['fas', 'fa-plus']" class="mr-1" />Create
            New</b-link
          ></b-row
        >
      </span>

      <slot
        v-else-if="field.key == groupBy"
        :name="`cell(${groupBy})`"
        :item="
          groupedItemList[item[groupBy]]
            ? groupedItemList[item[groupBy]][0]
            : null
        "
        :value="item[groupBy]"
      >
        <span v-if="groupedItemList[item[groupBy]]" :key="field.key">{{
          item[groupBy]
        }}</span>
        <span v-else class="text-left" :key="field.key"
          ><div class="text-danger font-weight-bold">{{ item[groupBy] }}</div>
          <div>is Missing!</div></span
        >
      </slot>

      <slot
        v-else-if="groupedItemList[item[groupBy]]"
        :name="`cell(${field.key})`"
        :item="groupedItemList[item[groupBy]][0]"
        :value="groupedItemList[item[groupBy]][0][field.key]"
      >
        {{ groupedItemList[item[groupBy]][0][field.key] }}
      </slot>
    </template>
    <template #row-details="{ item }"
      ><b-table
        thead-class="d-none"
        class="processing-details-table"
        :fields="fields"
        :items="groupedItemList[item[groupBy]].slice(1)"
      >
        <template
          v-slot:[`cell(${field.key})`]="{ item }"
          v-for="field in fields"
        >
          <b-row no-gutters v-if="field.key == 'actions'" :key="field.key"
            ><icon-delete
              :position="'left'"
              @click.native="deleteItem(item)"
              class="mr-2" />
            <icon-edit
              :position="'bottom'"
              @click.native="$emit('editItem', item)"
          /></b-row>
          <slot
            v-else
            :name="`cell(${field.key})`"
            :item="item"
            :value="item[field.key]"
          >
            {{ item[field.key] }}
          </slot>
        </template>
      </b-table></template
    >
  </b-table>
</template>

<script>
import IconDelete from '../components/IconDelete.vue'
import IconEdit from '../components/IconEdit.vue'
import BaseTableLoader from '../components/BaseTableLoader.vue'

export default {
  name: 'BaseTableGrouped',
  components: {
    IconDelete,
    IconEdit,
    BaseTableLoader
  },
  props: {
    groups: {
      type: Array,
      required: true
    },
    groupBy: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      default: function () {
        return []
      }
    },
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    colSpan() {
      return this.fields.length
    },
    groupNameList() {
      const nameList = this.groups.map((item) => ({
        [this.groupBy]: item
      }))
      return nameList
    },
    groupedItemList() {
      let groupedList = {}
      if (this.items === null) return []
      this.items.forEach((item) => {
        const groupName = item[this.groupBy]
        if (!Object.prototype.hasOwnProperty.call(groupedList, groupName))
          groupedList[groupName] = []
        groupedList[groupName].push(item)
      })
      return groupedList
    }
  },
  methods: {
    detailsClassFn(item, rowType) {
      if (rowType === 'row-details') {
        return 'border-top table-details-custom'
      }
    },
    deleteItem: function (item) {
      this.$emit('deleteItem', item)
    }
  }
}
</script>

<style>
.table-details-custom {
  background-color: #eef4f98f;
  box-shadow: inset 0px -6px 10px -5px rgb(145 144 144 / 75%);
}
.table-details-custom > td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
