<script setup>
import { useStore } from '@/utils/VuexHelpers'
import { computed, defineProps } from 'vue'

defineProps(['total'])

const store = useStore()
const orderSummary = computed(() => store.getters.orderSummary)

const fq = computed(() => {
  return (
    orderSummary.value.WholePig +
    orderSummary.value.Trunk +
    orderSummary.value.Forequarter
  )
})
const saddles = computed(() => {
  return (
    orderSummary.value.WholePig +
    orderSummary.value.Trunk +
    orderSummary.value.Haunch +
    orderSummary.value.Saddle
  )
})
const legs = computed(() => {
  return (
    orderSummary.value.WholePig +
    orderSummary.value.Haunch +
    orderSummary.value.Leg
  )
})
</script>

<template>
  <b-col
    col
    lg="5"
    md="10"
    sm="12"
    id="pigs-table"
    class="table-responsive mb-0 pt-0"
  >
    <table class="text-left table">
      <tr class="bg-light-blue">
        <th>Product</th>
        <th>Orders</th>
        <th>Boning</th>
      </tr>
      <tr>
        <td>Forequarters</td>
        <td>{{ fq }}</td>
        <td>{{ total != 0 ? total - fq : '-' }}</td>
      </tr>
      <tr>
        <td>Saddles</td>
        <td>{{ saddles }}</td>
        <td>{{ total != 0 ? total - saddles : '-' }}</td>
      </tr>
      <tr>
        <td>Legs</td>
        <td>{{ legs }}</td>
        <td>{{ total != 0 ? total - legs : '-' }}</td>
      </tr>
    </table>
  </b-col>
</template>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  padding: 0.3rem;
}
.table tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
