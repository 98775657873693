<template>
  <b-card-text>
    <div v-if="loadingStock == 'list'" class="text-center text-danger my-2">
      <b-spinner small class="align-middle mr-2"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <b-row
        no-gutters
        class="alert-color px-3 pb-2 align-items-center"
        v-if="showTotalAlert()"
        ><font-awesome-icon
          :icon="['fas', 'exclamation-circle']"
          class="mr-2"
        />This summary is generated from
        <b class="mx-1">{{ stockListLength }}</b> products. Your current total
        is <b class="ml-1">{{ totalStock }}</b
        >.</b-row
      >
      <b-card
        v-for="([location, values], idx) in sortedStockSummary"
        :key="`${idx}-${location}`"
        class="mb-4 rounded-0 border-right-0 border-left-0 stock-summary-card"
        :header="location"
        body-class="px-0 pb-0"
        header-tag="h6"
        header-class="text-center bg-white"
      >
        <b-card-text
          v-for="(docket, key1) in values"
          :key="key1"
          class="mb-4 border-bottom px-3"
        >
          <b-row
            class="justify-content-md-start bg-light-blue py-2 align-items-center"
            no-gutters
          >
            <b-col
              md="auto"
              class="d-flex text-nowrap align-items-baseline mx-3"
              ><h6 class="text-muted mr-1 mb-0">Total Weight:</h6>
              <p class="mb-0">{{ docket.totalWeight | decimals2 }}kg</p></b-col
            >
            <b-col
              md="auto"
              class="d-flex text-nowrap align-items-baseline mr-3"
              ><h6 class="text-muted mr-1 mb-0">Total Cartons:</h6>
              <p class="mb-0">{{ docket.qty }}</p></b-col
            >
            <b-col
              md="auto"
              class="d-flex text-nowrap align-items-baseline ml-auto mr-3"
              ><h6 class="text-muted mr-1 mb-0">Docket:</h6>
              <p class="mb-0">{{ key1 === 'NONE' ? '-' : key1 }}</p></b-col
            >
          </b-row>
          <b-table-simple small hover responsive class="text-left mt-2">
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Product</b-th>
                <b-th class="text-right">Code</b-th>
                <b-th class="text-right">Weight (kg)</b-th>
                <b-th class="text-right">Cartons</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(product, key3) in filterDocket(docket)" :key="key3">
                <b-td>{{ product.name }}</b-td>
                <b-td class="text-right">{{ key3 }}</b-td>
                <b-td class="text-right">{{
                  product.totalWeight | decimals2
                }}</b-td>
                <b-td class="text-right">{{ product.qty }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card-text>
      </b-card>
    </div>
  </b-card-text>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProductStockSummary',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['loadingStock', 'stockSummary', 'totalStock', 'stockList']),
    stockListLength() {
      return this.stockList?.length || '-'
    },
    sortedStockSummary() {
      const array = Object.entries(this.stockSummary)
      const sorted = array.sort((a, b) => a[0].localeCompare(b[0]))

      return sorted
    }
  },
  methods: {
    filterDocket: function (docketInfo) {
      let newObj = JSON.parse(JSON.stringify(docketInfo))
      delete newObj.totalWeight
      delete newObj.qty

      return newObj
    },
    showTotalAlert: function () {
      if (this.totalStock > this.stockList?.length) return true
      return false
    }
  }
}
</script>

<style>
.stock-summary-card .card-text:last-child {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
}
</style>
<style scoped>
.alert-color {
  color: #dc3545ba;
}
</style>
