<script setup>
import {
  computed,
  defineProps,
  getCurrentInstance,
  ref,
  defineEmits
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const emit = defineEmits(['update:customer'])
const props = defineProps({
  label: {
    type: String,
    default: 'label'
  },
  customer: {
    type: Number,
    default: null
  }
})

const vm = getCurrentInstance().proxy
const store = useStore()
const customerNames = computed(() => {
  const copy = JSON.parse(JSON.stringify(store.getters.customerNames))
  return copy.sort((a, b) => {
    if (a.is_active === b.is_active) {
      return a.name.localeCompare(b.name)
    }
    // Convert is_active to 1 (true) or -1 (false) and subtract to determine order
    return (b.is_active ? 1 : -1) - (a.is_active ? 1 : -1)
  })
})
const loadingCustomers = computed(() => store.getters.loadingCustomers)

const value = ref(props.customer)

const updateValue = (item) => {
  emit('update:customer', item)
}

const closeChip = () => {
  value.value = null
  emit('update:customer', null)
}

const customFilter = (item, queryText) => {
  const textOne = item.name.toLowerCase()
  const textTwo = item.code ? item.code.toLowerCase() : ''
  const searchText = queryText.toLowerCase().trim()

  return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
}

if (customerNames.value.length === 0 && loadingCustomers.value !== 'names') {
  store.dispatch('fetchCustomerNames', { vm })
}
</script>

<template>
  <v-autocomplete
    v-model="value"
    :items="customerNames"
    :filter="customFilter"
    outlined
    dense
    chips
    small-chips
    :label="label"
    :loading="loadingCustomers === 'names'"
    item-text="name"
    item-value="id"
    hide-details
    class="mb-2"
    @change="updateValue"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        small
        close
        @click:close="closeChip"
      >
        {{ `${data.item.code} - ${data.item.name}` }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content class="text-start">
          <v-list-item-title>
            {{ `${data.item.code} - ${data.item.name}` }}
            <span v-if="data.item.is_active !== true">
              <span class="mx-2">|</span>
              <span class="red--text text-caption">
                <font-awesome-icon
                  :icon="['fas', 'ban']"
                  class="me-1"
                />Inactive</span
              >
            </span>
          </v-list-item-title>
          <v-list-item-subtitle>{{ data.item.phone }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
