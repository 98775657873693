<script setup>
import { ref, defineProps, computed } from 'vue'
import BaseDynamicForm from '../base/BaseDynamicForm.vue'
import { useStore } from '@/utils/VuexHelpers'

const props = defineProps(['details'])
const store = useStore()
const loadingBone = computed(() => store.getters.loadingBone)

const dialog = ref(false)
const fields = ref([
  {
    key: 'comment',
    label: 'Comment',
    value: props.details.comments,
    type: 'text-chips',
    rules: [
      (v) => !!v || 'Comment is required',
      (v) => v?.trim() !== '' || 'Comment is required'
    ],
    placeholder: 'Comment product',
    chips: [
      'ALL AVAILABLE',
      'ALL REMAINING',
      'OFF X BODIES ONLY',
      'OFF X RACK BODIES',
      'X CTNS ONLY'
    ]
  }
])

const handleFormSubmit = async (formData) => {
  const payload = {
    id: props.details.id,
    comments: formData.comment
  }
  const res = await store.dispatch('updateBoningItem', {
    payload,
    onSuccess: 'insert'
  })
  if (res === 'success') {
    dialog.value = false
    fields.value[0].value = props.details.comments
  }
}
</script>
<template>
  <div>
    <v-btn
      v-if="!details.comments || details.comments.trim() === ''"
      @click.stop="dialog = true"
      key="empty"
      x-small
      color="info"
      class="my-1"
    >
      <font-awesome-icon :icon="['far', 'comment-dots']" class="me-2" />Comment
    </v-btn>
    <v-hover v-else v-slot="{ hover }"
      ><div class="p-relative" role="button" @click.stop="dialog = true">
        <span class="font-weight-bold">{{ details.comments }}</span>
        <v-btn
          icon
          small
          color="primary"
          class="p-center"
          :class="hover ? '' : 'd-none'"
        >
          <v-icon small>mdi-square-edit-outline</v-icon>
        </v-btn>
      </div></v-hover
    >

    <v-dialog v-model="dialog" scrollable max-width="1200px">
      <v-card>
        <v-card-text class="pt-4">
          <h6 class="text-button text-light-blue text-start">
            {{ details.product_name }}
          </h6>
          <BaseDynamicForm
            :formFields="fields"
            @submit="handleFormSubmit"
            :loading="loadingBone === `update-item-${details.id}`"
            :resetOnInputChange="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.p-center {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-90%, -50%);
}
</style>
