<script setup>
import { useStore } from '@/utils/VuexHelpers'
import { computed, getCurrentInstance, defineProps } from 'vue'

const props = defineProps({
  freightIds: String,
  textType: {
    type: String,
    default: 'code'
  },
  separator: {
    type: String,
    default: '/'
  }
})
const vm = getCurrentInstance().proxy

const store = useStore()
const freightNames = computed(() => store.getters.freightNames)
const freightCodeMap = computed(() => store.getters.freightCodeMap)
const loadingSuppliers = computed(() => store.getters.loadingSuppliers)

const freightText = computed(() => {
  if (!props.freightIds) return '-'
  if (!freightCodeMap.value) return ''

  let arr = props.freightIds.split(',')
  const mapped = arr.map((el) => {
    return freightCodeMap.value[el]
      ? freightCodeMap.value[el][props.textType]
      : el
  })
  return mapped.join(props.separator)
})

if (
  freightNames.value.length === 0 &&
  loadingSuppliers.value !== 'freightnames'
)
  store.dispatch('fetchFreightNames', { vm })
</script>

<template>
  <span>{{ freightText }}</span>
</template>
