<script setup>
import BoningFilter from '@/components/boning-page/BoningFilter.vue'
import BoningPdfExport from '@/components/boning-page/BoningPdfExport.vue'
import CommentDialog from '@/components/boning-page/CommentDialog.vue'
import InstructionsDialog from '@/components/boning-page/InstructionsDialog.vue'
import BaseLoadingSpinner from '@/components/base/BaseLoadingSpinner.vue'
import BaseAlert from '@/components/base/BaseAlert.vue'

import { useStore } from '@/utils/VuexHelpers'
import { formatDate } from '@/helpers/stringHelpers'
import { computed } from 'vue'
import { formatDateWithAddedDays } from '@/utils/DateHelpers'
import { PROCESSING_DATE_OFFSETS } from '@/constants/general'
import { INSTRUCTIONS } from '@/constants/pagination'

const priorityList = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '-', value: -1 }
]

const store = useStore()
const boningList = computed(() => store.getters.boningList)
const loadingBone = computed(() => store.getters.loadingBone)
const loadingBoneList = computed(() => store.getters.loadingBone === 'list')
const boningWeekDay = computed(
  () => store.getters.filtersBone.weekDay.value.day
)
const boningDate = computed(() =>
  formatDate(store.getters.filtersBone.boning_date.value)
)

const sortedBoningList = computed(() => {
  boningList.value.forEach(({ instructions }) => {
    sortByGroupAndPriority(instructions.boning_instruction)
  })

  return boningList.value
})

const istructionsExists = computed(() => boningList.value.length > 0)
const newDialogValues = computed(() => {
  const boningDate = store.getters.filtersBone.boning_date.value
  let startDate = boningDate
  let endDate = boningDate

  const boningWeekDay = store.getters.filtersBone.weekDay.value.day

  if (PROCESSING_DATE_OFFSETS[boningWeekDay]) {
    const start = PROCESSING_DATE_OFFSETS[boningWeekDay].start
    const end = PROCESSING_DATE_OFFSETS[boningWeekDay].end
    startDate = formatDateWithAddedDays(boningDate, start)
    endDate = formatDateWithAddedDays(boningDate, end)
  }

  return {
    boning_date: boningDate,
    start_date: startDate,
    end_date: endDate
  }
})

const headers = [
  { value: 'priority', sortable: false, text: 'Priority', groupable: false },
  { value: 'product_type', sortable: false, text: 'Type', groupable: true },
  { value: 'productcode', sortable: false, text: 'Code', groupable: false },
  { value: 'product_name', sortable: false, text: 'Name', groupable: false },
  { value: 'quantity', sortable: false, text: 'Qty', groupable: false },
  { value: 'comments', text: 'Comments', sortable: false, groupable: false },
  {
    value: 'pieces_required',
    sortable: false,
    text: 'Pieces Required',
    groupable: false
  },
  {
    value: 'pigs_required',
    sortable: false,
    text: 'Pigs Required',
    groupable: false
  }
]

const hasAnyBreakValues = (obj) => Object.values(obj).some((value) => value > 0)

const updatePriority = async (item, newValue, instruction) => {
  const initValue = item.priority
  const payload = {
    id: item.id,
    priority: newValue
  }

  item.priority = newValue
  sortByGroupAndPriority(instruction)

  const res = await store.dispatch('updateBoningItem', {
    payload,
    showMessage: false
  })

  if (res === 'error') {
    item.priority = initValue
    sortByGroupAndPriority(instruction)
  }
}

const sortByGroupAndPriority = (item) => {
  item.sort((a, b) => {
    const groupA = a.product_type.toLowerCase()
    const groupB = b.product_type.toLowerCase()

    if (groupA < groupB) return -1
    if (groupA > groupB) return 1

    const aValue = a.priority
    const bValue = b.priority

    if (aValue === null || aValue === -1) return 1
    if (bValue === null || bValue === -1) return -1

    if (aValue > bValue) return 1
    if (aValue < bValue) return -1

    return 0
  })
}

// ON CREATED
store.commit('SET_USING_VUETIFY', true)
store.commit('SET_ACTIVE', INSTRUCTIONS)
store.dispatch('fetchBoningList')
</script>
<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <BoningFilter />
        <div class="content-page mt-0">
          <h4 class="page-title mt-2 mb-4 text-start">Boning Instructions</h4>
          <v-card v-if="!istructionsExists || loadingBoneList" class="mb-3">
            <v-card-title>
              <h6 class="mr-1 mb-0 text-button me-auto">
                <span class="text-light-blue"
                  >Boning for {{ boningWeekDay }}:
                </span>
                <span class="me-auto">{{ boningDate }}</span>
              </h6>
            </v-card-title>
            <v-card-text class="justify-start d-flex">
              <BaseLoadingSpinner
                text="Boning Instructions"
                v-if="loadingBoneList"
              />
              <InstructionsDialog v-else :details="newDialogValues" />
            </v-card-text>
          </v-card>
          <v-card
            class="mb-3"
            :loading="loadingBoneList || loadingBone === `update-${boning.id}`"
            v-for="{
              instructions: boning,
              break: boningBreak
            } in sortedBoningList"
            :key="boning.id"
          >
            <v-card-title>
              <h6 class="mr-1 mb-0 text-button me-auto">
                <span class="text-light-blue"
                  >Boning for {{ boningWeekDay }}:
                </span>
                <span class="me-auto">{{
                  formatDate(boning.boning_date)
                }}</span>
              </h6>
              <InstructionsDialog :details="boning" />
              <BoningPdfExport
                :boningItems="boning.boning_instruction"
                :totals="boningBreak"
              />
            </v-card-title>
            <v-card-text class="d-flex align-baseline"
              ><h6 class="mr-2 mb-0 text-muted">Processing Dates:</h6>
              <span>{{ formatDate(boning.start_date) }}</span>
              <span class="mx-2">-</span>
              <span>{{ formatDate(boning.end_date) }}</span>
            </v-card-text>
            <v-card-text
              class="bg-light-blue align-center justify-start d-flex"
            >
              <h6 class="text-muted mb-0">
                <span class="me-2">Total Product To Be Boned:</span>
                <template v-if="hasAnyBreakValues(boningBreak)">
                  <span
                    v-for="(value, key) in boningBreak"
                    :key="key"
                    class="me-2 font-weight-normal"
                    >{{ value > 0 ? `${key}: ${value}` : '' }}</span
                  ></template
                >
                <span v-else>-</span>
              </h6>
            </v-card-text>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="boning.boning_instruction"
                :server-items-length="boning.boning_instruction.length"
                item-key="product_name"
                group-by="product_type"
                class="elevation-1 w-100"
                dense
                hide-default-footer
                show-group-by
              >
                <template v-slot:item.priority="{ value, item }">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        role="button"
                        v-bind="attrs"
                        v-on="on"
                        class="priority-item px-1"
                      >
                        {{ value > 0 ? value : '-' }}
                      </div>
                    </template>

                    <v-list dense>
                      <v-list-item
                        v-for="({ text, value: priority }, idx) in priorityList"
                        :key="idx"
                        @click="
                          updatePriority(
                            item,
                            priority,
                            boning.boning_instruction
                          )
                        "
                      >
                        <v-list-item-content>
                          {{ text }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:item.comments="{ item }">
                  <CommentDialog :details="item" />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<style scoped>
.priority-item:hover {
  background-color: #bebebe;
  border-radius: 2px;
}
</style>
