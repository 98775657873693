<script setup>
import usePdfGenerator from '@/utils/usePdfGenerator'
import { computed, defineProps, getCurrentInstance } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { formatDate, formatKilograms } from '@/helpers/stringHelpers'
import { BOOYONG_COMPANY_ID } from '@/constants/general'

const props = defineProps({
  booyong: {
    type: Boolean,
    default: false
  }
})
const { generatingPdf, exportPdf, titleDatePart } = usePdfGenerator()
const vm = getCurrentInstance().proxy
const store = useStore()
const startDatePig = computed(() => store.getters.startDatePig)
const filtersPig = computed(() => store.getters.filtersPig)
const runReport = computed(() => store.getters.runReport)
const customerCodeMap = computed(() => store.getters.customerCodeMap)
const loadingCustomers = computed(() => store.getters.loadingCustomers)

const breakBoningList = computed(() => {
  const boningList = []

  runReport.value.breakBoning.pigs.forEach((pig) => {
    const breakDetails = [
      pig.product,
      formatDate(pig.date),
      pig.body_no,
      formatKilograms(pig.HSCW, 2),
      formatKilograms(pig.CSCW, 2),
      pig.p2,
      pig.kill_date,
      pig.customer
    ]
    boningList.push(breakDetails)
  })
  return boningList
})
const totalsArray = computed(() => {
  const array = []

  const uniquePigs = new Set(
    runReport.value.breakBoning.pigs.map((pig) => pig.body_no)
  )
  const numPigs = uniquePigs.size
  if (numPigs > 0) array.push(`Body: ${numPigs}`)

  for (const [key, value] of Object.entries(
    runReport.value.breakBoning.totals
  )) {
    if (value > 0) {
      array.push(`${key}: ${value}`)
    }
  }
  return array
})
const subTitle = computed(
  () =>
    `BREAK BONING LIST ${titleDatePart(startDatePig.value, filtersPig.value)}`
)
const fileName = computed(() => {
  const bspStr = props.booyong ? 'BSP-' : ''
  const dateStr = titleDatePart(startDatePig.value, filtersPig.value, true)

  return `${bspStr}Break-boning-[${dateStr}]`
})

const tableHeaders = [
  'Product/Type',
  'Est. Loadout Date',
  'Body No#',
  'HSCW',
  'CSCW',
  'P2',
  'Kill Date',
  'Destination'
]

const bspTableHeaders = [
  'BODY NO#',
  'Trunk',
  'Haunch',
  'Forequarter',
  'Saddle',
  'Legs'
]

const booyongItems = () => {
  if (customerCodeMap.value === null) return []
  let report = {}
  runReport.value.breakBoning.pigs.forEach((item) => {
    if (!Object.prototype.hasOwnProperty.call(report, item.body_no)) {
      report[item.body_no] = {
        trunk: '',
        haunch: '',
        forequarter: '',
        saddle: '',
        leg: ''
      }
    }

    const codeStr =
      item.customerCompanyId === BOOYONG_COMPANY_ID
        ? 'BSP Boning'
        : customerCodeMap.value[item.customerCompanyId].code

    report[item.body_no][item.product.toLowerCase()] = codeStr
  })

  const bodyPartsOrder = ['trunk', 'haunch', 'forequarter', 'saddle', 'leg']
  const result = Object.entries(report).map(([bodyNo, parts]) => {
    const array = [bodyNo]
    bodyPartsOrder.forEach((part) => {
      array.push(parts[part])
    })
    return array
  })
  return result
}

const generatePdf = () => {
  const { booyong } = props

  const totalsPrefix = 'TOTALS:  '
  const headers = booyong ? bspTableHeaders : tableHeaders
  const items = booyong ? booyongItems() : breakBoningList.value
  const theme = booyong ? 'grid' : 'striped'
  const halign = booyong ? 'center' : 'left'
  const alternateStyle = booyong ? true : null

  const config = {
    totalsPrefix,
    theme,
    halign,
    alternateStyle
  }

  exportPdf(
    totalsArray.value,
    subTitle.value,
    items,
    headers,
    fileName.value,
    config
  )
}

// ON CREATED
if (customerCodeMap.value === null && loadingCustomers.value !== 'names') {
  store.dispatch('fetchCustomerNames', { vm })
}
</script>
<template>
  <v-btn
    color="warning"
    small
    class="my-1"
    @click="generatePdf"
    :loading="generatingPdf"
    ><font-awesome-icon icon="fas fa-file-export" class="me-2" />{{
      booyong ? 'Export For Booyong' : 'Export'
    }}</v-btn
  >
</template>
