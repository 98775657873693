<script setup>
import AllocationsEditTable from '@/components/pig-info-page/AllocationsEditTable.vue'

import {
  defineProps,
  ref,
  watch,
  computed,
  getCurrentInstance,
  defineEmits
} from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { BOOYONG_COMPANY_ID } from '@/constants/general'

const INITIAL_ALLOCATION = {
  pig_id: '',
  part_id: 1,
  company_id: null,
  transport_id: ''
}

const props = defineProps(['pigItems'])
const emit = defineEmits(['update:pigItems'])
const vm = getCurrentInstance().proxy
const store = useStore()

const loadingAllocations = computed(() => store.getters.loadingAllocations)

const dialog = ref(false)
const allocations = ref([])
const isPigSelected = computed(() => props.pigItems.length > 0)
const isFormValid = computed(() => {
  return allocations.value.every((element) => {
    if (element.part_id === null) return false
    if (element.company_id === null) return false
    if (
      element.transport_id === '' &&
      element.company_id !== BOOYONG_COMPANY_ID
    )
      return false
    return true
  })
})

const bodyNoList = computed(() => props.pigItems.map((pig) => pig.body_no))
const bodyIdList = computed(() => props.pigItems.map((pig) => pig.id))
const selectedDate = computed(() => {
  if (props.pigItems.length === 0) return ''
  return props.pigItems[0].date
})

let allocationsBackup = []

const resetValues = () => {
  allocations.value = JSON.parse(JSON.stringify(allocationsBackup))
}

const closeDialog = () => {
  resetValues()
  dialog.value = false
}

const addAllocation = () => {
  allocations.value.push({ ...INITIAL_ALLOCATION })
}

const allocate = async () => {
  const payloadArray = []
  bodyIdList.value.forEach((id) => {
    allocations.value.forEach((alloc) => {
      const details = {
        pig_id: id,
        part_id: alloc.part_id,
        company_id: alloc.company_id,
        transport_id: alloc.transport_id
      }
      if (bodyIdList.value.length === 1 && alloc.id) details.id = alloc.id
      payloadArray.push(details)
    })
  })
  const res = await store.dispatch('allocatePig', {
    vm,
    payload: payloadArray
  })
  if (res === 'error') return
  closeDialog()
  if (props.pigItems.length === 1) {
    res.forEach((el) => {
      if (!el.transport_id) {
        el.transport_id = ''
      }
    })
    emit('update:pigItems', res)
    return
  } else {
    emit('updateComplete')
  }
  store.dispatch('fetchPigInfo', { vm })
}

const handleClickOutside = () => {
  resetValues()
}

watch(
  () => props.pigItems,
  (newPigItems) => {
    if (newPigItems.length === 0) {
      allocationsBackup = []
      allocations.value = []
      return
    }
    if (
      newPigItems.length === 1 &&
      JSON.stringify(allocations.value) ===
        JSON.stringify(newPigItems[0].allocation)
    ) {
      return
    }

    if (newPigItems.length > 1) {
      allocationsBackup = [{ ...INITIAL_ALLOCATION }]
      allocations.value = [{ ...INITIAL_ALLOCATION }]
      return
    }

    allocationsBackup = JSON.parse(JSON.stringify(newPigItems[0].allocation))
    allocations.value = JSON.parse(JSON.stringify(newPigItems[0].allocation))
  },
  { deep: true, immediate: true }
)
</script>

<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="1200px"
    @click:outside="handleClickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        color="info"
        class="my-1"
        v-bind="attrs"
        v-on="on"
        :disabled="!isPigSelected"
      >
        <font-awesome-icon
          icon="fa-solid fa-truck-arrow-right"
          class="mr-2"
        />Allocate
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" v-if="pigItems.length > 0">{{
          `Pig body Nr: ${bodyNoList.join(', ')} -> Allocation`
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters class="mt-4">
          <v-btn
            x-small
            outlined
            color="primary"
            class="me-2"
            @click="addAllocation"
            >+ Add Allocation</v-btn
          ></v-row
        >
        <AllocationsEditTable :items="allocations" :date="selectedDate" />
        <v-row class="font-italic text-left" no-gutters>
          <small class="text-danger">* </small>required fields
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="resetValues" class="me-2">
          Reset
        </v-btn>
        <v-tooltip bottom color="white" :disabled="isFormValid">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                color="primary"
                @click="allocate"
                :loading="loadingAllocations.length > 0"
                :disabled="!isFormValid"
              >
                Submit
              </v-btn>
            </div>
          </template>
          <span class="red--text">Please fill required fields!</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
