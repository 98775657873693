<template>
  <b-modal id="fix-body-type-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-row class="" no-gutters>
        <label for="body-type-select"
          >Body Type:<span class="text-danger">*</span></label
        >
        <vue-select
          id="body-type-select"
          label="Type"
          placeholder="Select a type ..."
          :options="pigBodyTypes"
          v-model="pigInfo.specification.cat"
          class="w-100 mb-2"
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!pigInfo.specification.cat"
              v-bind="attributes"
              v-on="events"
            />
          </template> </vue-select
      ></b-row>

      <b-row class="mb-2" no-gutters>
        <label for="hot-weight-input"
          >Hot Weight:<span class="text-danger">*</span></label
        >
        <b-form-input
          type="number"
          step="0.1"
          id="hot-weight-input"
          v-model="pigInfo.specification.HSCW"
          class="w-100"
          :state="formValid ? null : false"
          aria-describedby="input-hot-weight-feedback"
          required
        >
        </b-form-input>
        <b-form-invalid-feedback id="input-hot-weight-feedback">
          <div>{{ bodyTypeValidation }}</div>
          <div>{{ weightValidation }}</div>
        </b-form-invalid-feedback>
      </b-row>

      <b-row class="" no-gutters v-if="initialValues.coldWeight">
        <label for="cold-weight-input"
          >Cold Weight:<span class="text-danger">*</span></label
        >
        <b-input
          type="number"
          step="0.1"
          id="cold-weight-input"
          v-model="pigInfo.specification.CSCW"
          class="w-100 mb-2"
          required
        >
        </b-input
      ></b-row>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters class="align-items-center">
        <b-form-checkbox
          v-model="ignoreTypeError"
          name="ignore-validation"
          switch
        >
          Ignore Body Type Error
        </b-form-checkbox>
        <b-button
          type="submit"
          variant="primary"
          class="mr-2 ml-auto"
          :disabled="SaveButtonDisabled"
          ><b-spinner
            v-if="loadingPigInfo == `update-${pigInfo.id}`"
            small
            class="mr-2"
          ></b-spinner>
          Save</b-button
        >
        <b-button type="reset" variant="danger" class="">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DEFAULT_WEIGHTS } from '@/constants/weight'

export default {
  name: 'FixBodyTypeModal',
  props: {
    type: String
  },
  data() {
    return {
      pigIndex: null,
      ignoreTypeError: false,
      initialValues: { type: '', coldWeight: null, hotWeight: null },
      pigInfo: {
        id: '',
        body_no: '',
        specification: { cat: 'SUK', HSCW: null, CSCW: null }
      }
    }
  },
  computed: {
    ...mapGetters(['loadingPigInfo', 'pigInfoList', 'pigBodyTypes']),
    actionString() {
      let bodyNr = ''
      if (this.pigInfo) {
        bodyNr = this.pigInfo.body_no
      }
      return `Pig body Nr: ${bodyNr} -> Update`
    },
    SaveButtonDisabled() {
      if (this.weightValidation !== '') return true
      if (this.ignoreTypeError) return false
      if (this.bodyTypeValidation !== '') return true
      return false
    },
    formValid() {
      if (this.bodyTypeValidation !== '') return false
      if (this.weightValidation !== '') return false
      return true
    },
    bodyTypeValidation() {
      const { cat, HSCW } = this.pigInfo.specification

      if (cat === 'SUK' && HSCW > DEFAULT_WEIGHTS.SUK)
        return `Hot weight must be ${DEFAULT_WEIGHTS.SUK}kg or less for body type SUK`

      if ((cat === 'BP' || cat === 'SP') && HSCW < DEFAULT_WEIGHTS.BP)
        return `Hot weight must be ${DEFAULT_WEIGHTS.BP}kg or more for body type SP or BP`

      if (cat !== 'SUK' && HSCW <= DEFAULT_WEIGHTS.SUK)
        return `Hot weight must be more than ${DEFAULT_WEIGHTS.SUK}kg if body type is not SUK`

      if (HSCW >= DEFAULT_WEIGHTS.BP && cat !== 'BP' && cat !== 'SP')
        return `Hot weight must be less than ${DEFAULT_WEIGHTS.BP}kg if body type is not BP or SP`
      return ''
    },
    weightValidation() {
      if (this.pigInfo.specification.HSCW <= this.pigInfo.specification.CSCW)
        return 'Hot weight must be greater than cold weight!'
      return ''
    }
  },
  methods: {
    ...mapActions(['updatePigInfo', 'destructPigInfo']),
    ...mapMutations(['ADD_PIG_TO_INFO_LIST']),
    onSubmit: async function (event) {
      event.preventDefault()

      this.pigInfo.specification.HSCW = Number(this.pigInfo.specification.HSCW)

      if (!this.initialValues.coldWeight) {
        delete this.pigInfo.specification.CSCW
      } else {
        this.pigInfo.specification.CSCW = Number(
          this.pigInfo.specification.CSCW
        )
      }

      const res = await this.updatePigInfo({
        vm: this,
        payload: this.pigInfo
      })
      if (res != 'error') {
        const destructedPig = await this.destructPigInfo(res)
        this.ADD_PIG_TO_INFO_LIST({ index: this.pigIndex, pig: destructedPig })
        this.$bvModal.hide('fix-body-type-modal')
      }
    },
    onReset: function (event) {
      event.preventDefault()
      this.restoreValues()
    },
    updateBodyType: async function ({ id, index, body_no, cat, HSCW, CSCW }) {
      this.ignoreTypeError = false
      this.pigIndex = index

      this.pigInfo.id = id
      this.pigInfo.body_no = body_no
      this.pigInfo.specification.cat = cat
      this.pigInfo.specification.HSCW = HSCW
      this.pigInfo.specification.CSCW = CSCW

      this.initialValues.type = cat
      this.initialValues.hotWeight = HSCW
      this.initialValues.coldWeight = CSCW

      this.$bvModal.show('fix-body-type-modal')
    },
    restoreValues: function () {
      this.pigInfo.specification.cat = this.initialValues.type
      this.pigInfo.specification.HSCW = this.initialValues.hotWeight
      this.pigInfo.specification.CSCW = this.initialValues.coldWeight
    }
  }
}
</script>

<style scoped></style>
