<script setup>
import { computed, defineProps, defineEmits, getCurrentInstance } from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const props = defineProps({
  freight: Array,
  rootClass: { type: String, default: '' },
  label: {
    type: String,
    default: 'Freight Company'
  }
})
const emit = defineEmits(['update:freight'])

const vm = getCurrentInstance().proxy
const store = useStore()
const freightNames = computed(() => store.getters.freightNames)
const loadingSuppliers = computed(() => store.getters.loadingSuppliers)
const isLoading = computed(() => loadingSuppliers.value === 'freightnames')
const frei = computed({
  get: () => {
    return props.freight
  },
  set: (newValue) => {
    emit('update:freight', newValue)
  }
})

const customFilter = (item, queryText) => {
  const textOne = item.name.toLowerCase()
  const textTwo = item.code ? item.code.toLowerCase() : ''
  const searchText = queryText.toLowerCase().trim()

  return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
}

const closeChip = (value) => {
  let filteredArray = frei.value.filter((item) => item !== value)
  emit('update:freight', filteredArray)
}

// ON CREATED
if (
  freightNames.value.length === 0 &&
  loadingSuppliers.value !== 'freightnames'
) {
  store.dispatch('fetchFreightNames', { vm })
}
</script>

<template>
  <v-autocomplete
    v-model="frei"
    :items="freightNames"
    :filter="customFilter"
    item-text="name"
    item-value="company_id"
    multiple
    outlined
    dense
    chips
    :label="label"
    :loading="isLoading"
    hide-details
    class="my-1"
  >
    <template v-slot:selection="{ item, selected, attrs }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        small
        close
        label
        @click:close="closeChip(item.company_id)"
      >
        {{ item.code }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content class="text-start">
          <v-list-item-title>
            {{ `${data.item.code} - ${data.item.name}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<style></style>
