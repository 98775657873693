import { PER_PAGE_MAX } from '@/constants/pagination'
import { getISOWeekNumber, getWeekBounds, getYear } from '@/utils/DateHelpers'

const { start, end } = getWeekBounds()

const state = {
  loadoutList: [],
  loadingLoad: '',

  //pagination
  totalLoad: 0,
  perPageLoad: PER_PAGE_MAX,
  pageNrLoad: 0,

  //sorting
  sortbyLoad: 'loadout_date',
  ascLoad: false,

  //filters
  filtersLoad: {
    loadout_start: { type: 'date', label: 'Start Date', value: start },
    loadout_end: { type: 'date', label: 'End Date', value: end },
    boning_start: {
      type: 'date',
      label: 'Boning Day - Start',
      value: '',
      reset: true
    },
    boning_end: {
      type: 'date',
      label: 'Boning Day - End',
      value: '',
      reset: true
    },
    primary_transport: {
      type: 'select',
      name: 'company_id',
      label: 'Transport Company',
      selectLabel: 'name',
      value: '',
      customOptions: (option) => {
        return `${option.code} - ${option.name}`
      },
      options: []
    },
    week: { type: 'week', label: 'Week Nr', value: getISOWeekNumber() },
    year: { type: 'year', value: getYear(), hidden: true }
  }
}
const getters = {
  loadoutList: (state) => state.loadoutList,
  loadingLoad: (state) => state.loadingLoad,

  totalLoad: (state) => state.totalLoad,
  perPageLoad: (state) => state.perPageLoad,
  pageNumberLoad: (state) => state.pageNrLoad,
  sortbyLoad: (state) => state.sortbyLoad,
  ascLoad: (state) => state.ascLoad,
  filtersLoad: (state) => state.filtersLoad,
  offsetLoad: (state) => {
    return state.perPageLoad * state.pageNrLoad
  }
}
const actions = {
  async fetchLoadoutList({ commit, dispatch, getters }) {
    commit('SET_LOADING_LOAD', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'orders/loadout/',
        parameters: {
          limit: getters.perPageLoad,
          offset: getters.offsetLoad,
          sort_by: getters.sortbyLoad,
          ascending: getters.ascLoad
        },
        filters: getters.filtersLoad
      },
      { root: true }
    )
    commit('SET_LOADING_LOAD', '')
    if (!res.error) {
      commit('SET_LOAD_LIST', res.data)
      commit('SET_TOTAL_LOAD', res.total)
    } else {
      dispatch('showError', {
        error: res.error,
        messagePrefix: 'Error while loading Loadout Instructions! '
      })
    }
  },
  async clearFiltersLoad({ commit }) {
    commit('SET_FILTER_LOAD', { field: 'loadout_start', value: start })
    commit('SET_FILTER_LOAD', { field: 'loadout_end', value: end })
    commit('SET_FILTER_LOAD', { field: 'boning_start', value: '' })
    commit('SET_FILTER_LOAD', { field: 'boning_end', value: '' })
    commit('SET_FILTER_LOAD', { field: 'primary_transport', value: '' })
    commit('SET_FILTER_LOAD', { field: 'week', value: getISOWeekNumber() })
  }
}
const mutations = {
  SET_LOAD_LIST: (state, payload) => (state.loadoutList = payload),

  SET_TOTAL_LOAD: (state, payload) => (state.totalLoad = payload),
  SET_PAGE_NR_LOAD: (state, payload) => (state.pageNrLoad = payload),
  SET_LOADING_LOAD: (state, payload) => (state.loadingLoad = payload),

  SET_PER_PAGE_LOAD: (state, payload) => (state.perPageLoad = payload),
  SET_SORT_BY_LOAD: (state, payload) => (state.sortbyLoad = payload),
  SET_ASC_LOAD: (state, payload) => (state.ascLoad = payload),
  SET_FILTER_LOAD: (state, { value, field }) => {
    const filter = state.filtersLoad[field]

    if (filter.type !== 'select') {
      filter.value = value
      return
    }

    filter.value = value ?? ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
