<template>
  <div>
    <simple-totals :totals="runReport.boning.totals" :id="'boning-totals'">
      <template #export-button>
        <single-export-button
          class="mr-2"
          :generating="generatingPDF == 'booyong-table' ? true : false"
          :text="'Export For Booyong'"
          @click="prepareForExport('booyong')"
        />
        <single-export-button
          :generating="generatingPDF == 'boning-table' ? true : false"
          @click="prepareForExport('boning')"
        /> </template
    ></simple-totals>
    <b-table
      id="boning-table"
      striped
      small
      sort-icon-left
      class="text-left"
      :busy="loadingPigInfo == 'list'"
      :fields="boningFields"
      :items="runReport.boning.pigs"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner small class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(date)="data">
        {{ data.item.date | date }}
      </template>
      <template #cell(HSCW)="data">
        {{ data.item.HSCW | decimals2 }}
      </template>
    </b-table>
    <boning-export-table
      :boningList="runReport.boning.pigs"
    ></boning-export-table>
  </div>
</template>

<script>
//import html2pdf from 'html2pdf.js'
import { pdfUtilsMixin } from '../mixins/pdfUtils'

import { mapGetters } from 'vuex'

import SimpleTotals from '../components/SimpleTotals.vue'
import SingleExportButton from '../components/SingleExportButton.vue'
import BoningExportTable from '../components/BoningExportTable.vue'

export default {
  name: 'BoningReport',
  components: {
    BoningExportTable,
    SimpleTotals,
    SingleExportButton
  },
  mixins: [pdfUtilsMixin],
  data() {
    return {
      generatingPDF: '',
      boningFields: [
        { key: 'product', label: 'Product/Type' },
        { key: 'date', label: 'Est. Loadout Date' },
        { key: 'body_no', label: 'Body No#' },
        { key: 'HSCW', label: 'HSCW (kg)' },
        { key: 'CSCW', label: 'CSCW (kg)' },
        { key: 'p2', label: 'P2' },
        { key: 'kill_date', label: 'Kill Date' }
      ]
    }
  },
  computed: {
    ...mapGetters(['runReport', 'startDatePig', 'filtersPig', 'loadingPigInfo'])
  },
  methods: {
    prepareForExport: function (type) {
      const dateTitlePart = this.sharedGenerateTitlePart(
        '',
        this.startDatePig,
        this.filtersPig,
        true
      )
      switch (type) {
        case 'booyong':
          this.SharedExportToPDF({
            tableID: 'booyong-table',
            totalsID: 'boning-totals',
            splitByOne: 24,
            splitBy: 28,
            orientation: 'portrait',
            fileName: `BSP-Boning-report-[${dateTitlePart}]`,
            startDate: this.startDatePig,
            filters: this.filtersPig,
            title: 'BONING LIST'
          })
          break
        case 'boning':
          this.SharedExportToPDF({
            tableID: 'boning-table',
            totalsID: 'boning-totals',
            splitByOne: 17,
            splitBy: 20,
            fileName: `Boning-report-[${dateTitlePart}]`,
            startDate: this.startDatePig,
            filters: this.filtersPig,
            title: 'BONING LIST'
          })
          break
        default:
          break
      }
    }
  }
}
</script>
<style></style>
