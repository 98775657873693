<script setup>
import { useStore } from '@/utils/VuexHelpers'
import { computed } from 'vue'

const store = useStore()

const messageList = computed(() => store.getters.messageList)
const closeMessage = (id) => {
  store.dispatch('closeMessage', id)
}
</script>
<template>
  <div class="dc-alert-wrapper">
    <TransitionGroup name="list" tag="ul" class="alert-container">
      <div v-for="item in messageList" :key="item.id" class="dc-alert-item">
        <v-alert
          border="left"
          elevation="2"
          dense
          :type="item.type"
          dismissible
          @input="closeMessage(item.id)"
        >
          <div class="mr-2 text-black text-center">
            {{ item.message }}
          </div>
        </v-alert>
      </div>
    </TransitionGroup>
  </div>
</template>

<style>
.dc-alert-item .v-alert {
  margin-bottom: 0.25rem;
}
</style>

<style scoped>
.dc-alert-wrapper {
  min-width: 600px;
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 16px;
  margin-right: 16px;
  z-index: 999;
}

.alert-container {
  position: relative;
  padding: 0;
}
.dc-alert-item {
  min-height: 30px;
  margin-bottom: 0.5rem;
  width: fit-content;
  margin-left: auto;
}
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.list-leave-active {
  position: absolute;
  right: 0;
}
</style>
