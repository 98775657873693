<template>
  <b-modal id="update-stock-type-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-row class="mb-3" no-gutters>
        <label for="stock-name-input">Item:</label>
        <b-form-input
          type="text"
          disabled
          id="stock-name-input"
          v-model="stockInfo.product_desc.product"
          class="w-100"
        >
        </b-form-input>
      </b-row>
      <b-row class="mb-3" no-gutters>
        <label for="stock-barcode-input">Barcode:</label>
        <b-form-input
          type="text"
          disabled
          id="stock-barcode-input"
          v-model="stockInfo.barcode"
          class="w-100"
        >
        </b-form-input>
      </b-row>
      <b-row class="mb-3" no-gutters>
        <label for="stock-status-select"
          >Product Stock Status:<span class="text-danger">*</span></label
        >
        <vue-select
          id="stock-status"
          label="Status"
          placeholder="Select a status ..."
          :options="stockStatusOptions"
          v-model="stockInfo.status"
          @input="stockInfo.tracklog.destination = ''"
          class="w-100 mb-2"
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!stockInfo.status"
              v-bind="attributes"
              v-on="events"
            />
          </template> </vue-select
      ></b-row>
      <b-row
        class="mb-3"
        no-gutters
        v-if="
          this.stockInfo.status && stockInfo.status.toLowerCase() !== 'expired'
        "
      >
        <label for="stock-location-select"
          >Location:<span class="text-danger">*</span></label
        >
        <vue-select
          id="stock-location-select"
          label="name"
          placeholder="Select a location ..."
          :options="locationOptions"
          :reduce="(location) => location.name"
          v-model="stockInfo.tracklog.destination"
          class="w-100 mb-2"
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!stockInfo.tracklog.destination"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #option="obj">
            {{ `${obj.code} - ${obj.name}` }}
          </template>
          <template #selected-option="{ code, name }">
            {{ code + ' - ' + name }}
          </template>
        </vue-select>
      </b-row>
      <b-row class="mb-3" no-gutters>
        <label for="stock-docket-input">Docket:</label>
        <b-form-input
          type="text"
          id="stock-docket-input"
          v-model="stockInfo.tracklog.docket"
          class="w-100"
        >
        </b-form-input>
      </b-row>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters class="justify-content-end">
        <b-button
          type="submit"
          variant="primary"
          class="mr-2"
          :disabled="!stockStatusOptions.includes(stockInfo.status)"
          ><b-spinner
            v-if="loadingStock == `update-${stockInfo.id}`"
            small
            class="mr-2"
          ></b-spinner>
          Save</b-button
        >
        <b-button type="reset" variant="danger" class="">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { getCurrentDay } from '@/utils/DateHelpers'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'UpdateProductStockStatusModal',
  props: {
    type: String
  },
  data() {
    return {
      stockIndex: null,
      initialValues: { status: '', destination: '', docket: '' },
      stockInfo: {
        id: '',
        date: getCurrentDay(),
        barcode: '',
        status: '',
        product_desc: {
          product: '',
          code: ''
        },
        tracklog: {
          docket: '',
          destination: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingStock',
      'stockStatusOptions',
      'customerNames',
      'locationBoning',
      'locationStorage',
      'locationCustomer'
    ]),
    locationOptions() {
      if (this.stockInfo.status === null) return []
      switch (this.stockInfo.status.toLowerCase()) {
        case 'inplant':
          return this.locationBoning
        case 'instorage':
          return this.locationStorage
        case 'delivered':
          return this.locationCustomer
        default:
          return []
      }
    },
    actionString() {
      return `Update Status for stock item:`
    }
  },
  methods: {
    ...mapActions([
      'updateStockItem',
      'destructStockItem',
      'fetchCustomerNames'
    ]),
    ...mapMutations(['UPDATE_STOCK_LIST']),
    onSubmit: async function (event) {
      event.preventDefault()

      const res = await this.updateStockItem({
        vm: this,
        payload: this.stockInfo
      })
      if (res != 'error') {
        const destructedStock = await this.destructStockItem(res)
        this.UPDATE_STOCK_LIST({
          index: this.stockIndex,
          stock: destructedStock
        })
        this.$bvModal.hide('update-stock-type-modal')
      }
    },
    onReset: function (event) {
      event.preventDefault()
      this.restoreValues()
    },
    updateStockStatus: async function ({
      id,
      index,
      barcode,
      status,
      product_desc,
      tracklog
    }) {
      this.stockIndex = index

      this.stockInfo.id = id
      this.stockInfo.status = status
      this.stockInfo.barcode = barcode
      this.stockInfo.product_desc.product = product_desc.product
      this.stockInfo.product_desc.code = product_desc.code
      this.stockInfo.tracklog.docket = tracklog?.docket
      this.stockInfo.tracklog.destination = tracklog?.destination

      this.initialValues.status = status
      this.initialValues.docket = tracklog?.docket
      this.initialValues.destination = tracklog?.destination

      this.$bvModal.show('update-stock-type-modal')
    },
    restoreValues: function () {
      this.stockInfo.status = this.initialValues.status
      this.stockInfo.tracklog.docket = this.initialValues.docket
      this.stockInfo.tracklog.destination = this.initialValues.destination
    }
  },
  async created() {
    if (this.customerNames.length == 0) {
      this.fetchCustomerNames({ vm: this })
    }
  }
}
</script>

<style scoped></style>
