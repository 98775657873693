<template>
  <div class="auth-fluid">
    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
      <div class="align-items-center d-flex h-100">
        <div class="card-body text-left">
          <!-- Logo -->
          <div class="auth-brand text-center text-lg-start">
            <router-link to="/" class="logo-dark">
              <span
                ><img src="../assets/logo-black.png" alt="" height="80"
              /></span>
            </router-link>
          </div>
          <user-login v-if="pathName == 'login'" />
          <reset-pass v-if="pathName == 'resetPass'" />
          <reset-pass-force v-if="pathName == 'resetPassForce'" />
          <reset-pass-code v-if="pathName == 'resetPassConfirm'" />
          <reset-pass-success v-if="pathName == 'resetPassSuccess'" />
          <sign-up v-if="pathName == 'signUp'" />
          <sign-up-confirm v-if="pathName == 'signUpConfirm'" />
          <sign-up-success v-if="pathName == 'signUpSuccess'" />
        </div>
      </div>
    </div>
    <!-- end auth-fluid-form-box-->

    <!-- Auth fluid right content -->
    <div class="auth-fluid-right text-center">
      <div class="auth-user-testimonial">
        <h2 class="mb-3">THE WAY PORK SHOULD TASTE</h2>
        <p class="lead">
          <i class="fas fa-quote-left"></i>Antibiotic Free No Added Hormones
          100% Australian
          <i class="fas fa-quote-right"></i>
        </p>
      </div>
      <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->
  </div>
</template>

<script>
import UserLogin from '../components/UserLogin.vue'
import ResetPass from '../components/ResetPass.vue'
import ResetPassCode from '../components/ResetPassCode.vue'
import ResetPassForce from '../components/ResetPassForce.vue'
import ResetPassSuccess from '../components/ResetPassSuccess.vue'

import { Auth } from 'aws-amplify'
import { mapGetters, mapMutations } from 'vuex'
// import SignUp from '../components/SignUp.vue'
// import SignUpConfirm from '../components/SignUpConfirm.vue'
// import SignUpSuccess from '../components/SignUpSuccess.vue'

export default {
  name: 'LoginPage',
  components: {
    UserLogin,
    ResetPass,
    ResetPassCode,
    ResetPassSuccess,
    ResetPassForce
    // SignUp,
    // SignUpConfirm,
    // SignUpSuccess
  },
  created() {},
  data() {
    return {
      form: {
        email: '',
        password: '',
        checked: []
      },
      authLoading: false
    }
  },
  computed: {
    ...mapGetters(['authUser']),
    pathName() {
      return this.$route.name
    }
  },
  methods: {
    ...mapMutations(['SET_AUTH_USER'])
  },
  async mounted() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        this.SET_AUTH_USER(user)
        this.$router.push({ path: '/pig-info' })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/css/variables';

#login-card {
  h4 {
    margin: 10px 0;
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.125rem;
    color: map-get($colors, text);
  }
  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
  }
  label,
  .label {
    font-weight: 600;
    color: map-get($colors, text);
    font-size: 0.9rem;
  }
  .custom-control-label::before {
    top: 0.18rem;
  }
  input,
  input:focus {
    color: map-get($colors, text);
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: map-get($colors, text);
  }
  i {
    min-width: 20px;
    text-align: center;
  }
  .btn-forecast {
    color: #fff;
    background-color: map-get($colors, primary);
    border-color: map-get($colors, primary);
  }
  .btn-forecast:hover,
  .btn-forecast:active,
  .btn-forecast:focus {
    background-color: map-get($colors, active) !important;
    border-color: map-get($colors, active) !important;
  }
  .rainbow {
    background-image: linear-gradient(to right, #8a00ff, #00f2fe);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .btn-forecast:focus {
    -webkit-box-shadow: 0 0 0 0.15rem map-get($colors, btn-shadow) !important;
    box-shadow: 0 0 0 0.15rem map-get($colors, btn-shadow) !important;
  }
  .text-forecast {
    color: map-get($colors, primary);
  }
  .input-readonly {
    font-weight: bolder;
    color: map-get($colors, text-muted);
  }
  .sso-btn {
    border: 1px solid;
    background-color: map-get($colors, primary);
    border-color: map-get($colors, primary);
    border-radius: 4px;
    transition: all 0.3s ease 0s;
  }
  .sso-btn:hover {
    background-color: map-get($colors, active);
    border-color: map-get($colors, active);
    color: #fff;
    cursor: pointer;
  }
  .footer-alt {
    left: 0;
    border: none;
    text-align: center;
  }
  .footer {
    bottom: 0;
    padding: 19px 24px 20px;
    position: absolute;
    right: 0;
    color: map-get($colors, text-muted);
  }
}
.text-muted {
  color: map-get($colors, text-muted) !important;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/variables';

/* Picture quote text css */
h2 {
  margin: 10px 0;
  font-weight: 700;
  line-height: 1.1;
  font-size: calc(1.3125rem + 0.75vw);
}
p {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}
.lead {
  font-size: 1.125rem;
  margin: 0 auto 20px auto;
  max-width: 700px;
  font-weight: 300;
  i {
    min-width: 5px;
    text-align: center;
    font-size: 8px;
    vertical-align: top;
    padding-top: 6px;
  }
}
/* End picture quote text css */

.auth-fluid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background: url(../assets/bg-auth_1.jpg) center;
  background-size: cover;

  @media screen and (max-width: 720px) {
    display: block;
  }

  .card-body {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 5rem 2rem 3rem 2rem;
    background-color: #fff;
    position: relative;
    width: 100%;

    @media screen and (max-width: 960px) {
      max-width: 400px;
    }
    @media screen and (max-width: 720px) {
      max-width: 100%;
      min-height: 100vh;
    }
  }
  .auth-fluid-right {
    padding: 6rem 3rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 720px) {
      display: none;
    }
  }
}
.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}
.auth-brand {
  margin-bottom: 2rem;

  @media (min-width: 720px) {
    position: absolute;
    top: 3rem;
  }
}
</style>
