<script setup>
import { formatCurrency, formatKilograms } from '@/helpers/stringHelpers'
import { useStore } from '@/utils/VuexHelpers'
import { computed } from 'vue'

const EXTERNAL_STR = 'external'

const store = useStore()
const stockList = computed(() => store.getters.stockList)
const totalStockWeight = computed(() =>
  formatKilograms(store.getters.totalStockWeight, 2)
)

const totalStockPrice = computed(() => {
  const total = stockList.value.reduce((total, item) => {
    return (total += item.product_desc.price_kg * item.weight)
  }, 0)
  return formatCurrency(total)
})

const isExternalStock = (item) => {
  const { product_desc: description } = item

  if (description.type.toLowerCase().includes(EXTERNAL_STR)) return true
  if (description.barcode && description.barcode.trim() !== '') return true
  if (description.comment.toLowerCase().includes(EXTERNAL_STR)) return true

  return false
}
</script>
<template>
  <b-card-text>
    <!-- MODALS -->
    <update-product-stock-status-modal ref="updateStockStatus" />

    <b-row class="d-flex row no-gutters mt-3">
      <div class="d-flex align-items-center">
        Total Product Stock:
        <span class="font-weight-bold ml-2">{{ totalStock }}</span>
      </div>
      <div class="ml-auto mr-2 d-flex align-items-center">
        Products stock per page:
      </div>
      <b-select
        v-model="perPage"
        :options="perPageOptions"
        class="d-inline-block w-auto mr-4"
      ></b-select>
      <pagination-base
        :total="totalStock"
        :perPage="perPageStock"
        :current="pageNumberStock + 1"
        @updatePage="updatePageNr"
      />
    </b-row>
    <b-row
      no-gutters
      class="bg-light-blue p-2 align-items-baseline mt-2"
      v-if="showTotals"
    >
      <h6 class="text-muted mr-2 mb-0">Total Weight:</h6>
      <p class="mb-0 mr-2">{{ totalStockWeight }}</p>
      <h6 class="text-muted mr-2 mb-0">Total Price:</h6>
      <p class="mb-0">{{ totalStockPrice }}</p>
    </b-row>
    <b-row no-gutters class="mt-3 d-flex text-left">
      <b-table
        id="product-table"
        striped
        hover
        sort-icon-left
        no-local-sorting
        thead-class="vertical-middle"
        :sort-by="sortbyStock"
        :sort-desc="!ascStock"
        @sort-changed="sortChange"
        :fields="fields"
        :items="stockList"
        :busy="loadingStock == 'list'"
        class="mt-3"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner small class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(plantlog:proddate)="data">
          <b-row no-gutters class="flex-column">
            <div class="text-nowrap">
              {{
                data.item.plantlog
                  ? `${$options.filters.date(data.item.plantlog.proddate)}`
                  : '-'
              }}
            </div>
          </b-row>
        </template>
        <template #cell(product)="{ item }">
          <b-row no-gutters class="flex-column">
            <div>{{ item.product }}</div>
            <span class="d-flex align-items-center">
              <small class="font-italic"
                ><b class="text-muted mr-2">Code:</b> {{ item.code }}</small
              >
              <b-badge
                v-if="isExternalStock(item)"
                class="ml-2"
                variant="warning"
                >External Stock</b-badge
              >
            </span>
          </b-row>
        </template>
        <template #cell(weight)="data">
          <span class="text-primary"
            ><b>{{ data.item.weight | decimals2 }}</b></span
          >
        </template>
        <template #cell(location)="{ item }">
          <b-row no-gutters class="flex-column">
            <div>{{ getDestination(item) }}</div>
            <small class="font-italic"
              ><b class="text-muted mr-2">Docket No:</b
              >{{ getDocket(item) }}</small
            >
          </b-row></template
        >
        <template #cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item.status)"
            ><font-awesome-icon
              :icon="getStatusIcon(data.item.status)"
              class="mr-1"
            />{{ data.item.status.toUpperCase() }}</b-badge
          >
        </template>
        <template #cell(actions)="{ index, item }">
          <b-link
            class="text-secondary text-nowrap"
            @click="
              $refs.updateStockStatus.updateStockStatus({
                ...item,
                index: index
              })
            "
          >
            <font-awesome-icon
              :icon="['fas', 'edit']"
              class="mr-2"
            />Update</b-link
          >
        </template>
      </b-table>
    </b-row>
  </b-card-text>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PaginationBase from '../components/PaginationBase.vue'
import UpdateProductStockStatusModal from '../components/UpdateProductStockStatusModal.vue'

export default {
  name: 'ProductStockList',
  components: {
    PaginationBase,
    UpdateProductStockStatusModal
  },
  data() {
    return {
      modalAction: 'add',
      fields: [
        {
          key: 'plantlog:proddate',
          label: 'Prod Date',
          thClass: ['text-nowrap'],
          sortable: true
        },
        {
          key: 'weight',
          label: 'Weight (kg)',
          thClass: ['text-right', 'vertical-middle', 'text-nowrap'],
          tdClass: ['text-right']
        },
        { key: 'product', thClass: ['vertical-middle'] },
        {
          key: 'barcode',
          thClass: ['text-left', 'vertical-middle'],
          tdClass: ['text-left']
        },
        { key: 'location', thClass: ['vertical-middle'] },
        { key: 'status', thClass: ['vertical-middle'] },
        { key: 'actions', thClass: ['vertical-middle'] }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'stockList',
      'loadingStock',
      'perPageStock',
      'pageNumberStock',
      'perPageOptions',
      'ascStock',
      'sortbyStock',
      'totalStock',
      'filtersStock'
    ]),
    showTotals() {
      if (this.filtersStock.docket.value) return true
      if (this.filtersStock.prod_date_end.value) return true
      if (this.filtersStock.prod_date_start.value) return true
      if (this.filtersStock.destination_co_id.value) return true
      if (this.filtersStock.product_name.value) return true
      if (this.filtersStock.status.value) return true
      return false
    },
    perPage: {
      get() {
        return this.perPageStock
      },
      set(value) {
        this.SET_PER_PAGE_STOCK(value)
        this.fetchStock({ vm: this })
      }
    }
  },
  methods: {
    ...mapActions(['fetchStock', 'fetchProductDestinations']),
    ...mapMutations([
      'SET_PER_PAGE_STOCK',
      'SET_PAGE_NR_STOCK',
      'SET_ASC_STOCK',
      'SET_SORT_BY_STOCK',
      'SET_FILTER_STOCK'
    ]),
    getDestination: function (item) {
      return item?.tracklog?.destination || '-'
    },
    getDocket: function (item) {
      return item?.tracklog?.docket || '-'
    },
    getStatusVariant: function (status) {
      switch (status.toLowerCase()) {
        case 'inplant':
          return 'primary'
        case 'instorage':
          return 'warning'
        case 'delivered':
          return 'success'
        case 'expired':
          return 'danger'
        default:
          return 'primary'
      }
    },
    getStatusIcon: function (status) {
      switch (status.toLowerCase()) {
        case 'inplant':
          return ['fas', 'fa-bone']
        case 'instorage':
          return ['fas', 'fa-box-open']
        case 'delivered':
          return ['fas', 'fa-check']
        case 'expired':
          return ['fas', 'fa-times']
        default:
          return 'success'
      }
    },
    updatePageNr: async function (nr) {
      this.SET_PAGE_NR_STOCK(nr - 1)
      let res = await this.fetchStock({ vm: this })
      if (res == 'success')
        this.$root.$emit('bv::refresh::table', 'product-table')
    },
    sortChange: function ({ sortDesc, sortBy }) {
      this.SET_ASC_STOCK(!sortDesc)
      this.SET_SORT_BY_STOCK(sortBy)
      this.fetchStock({ vm: this })
    }
  },
  async created() {
    this.fetchProductDestinations({ vm: this })
  },
  mounted() {
    this.fetchStock({ vm: this })
  }
}
</script>

<style>
.vertical-middle {
  vertical-align: middle !important;
}
</style>
