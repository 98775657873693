<script setup>
import PigInfoFilter from '@/components/pig-info-page/PigInfoFilter.vue'
import PigInfoTable from '@/components/pig-info-page/PigInfoTable.vue'
import RunReport from '@/components/RunReport.vue'
import PaginationBase from '@/components/PaginationBase.vue'
import BreakBoningReport from '@/components/BreakBoningReport.vue'
import BoningReport from '@/components/BoningReport.vue'

import { useStore } from '@/utils/VuexHelpers'
import { formatCurrency } from '@/helpers/stringHelpers'
import { checkBodyTypeError, checkForDamage } from '@/utils/Validators.js'
import { computed, getCurrentInstance, reactive, ref, watch } from 'vue'
import { PIG_INFO } from '@/constants/pagination'

const store = useStore()
const vm = getCurrentInstance().proxy

const pigInfoList = computed(() => store.getters.pigInfoList)
const totalPigInfo = computed(() => store.getters.totalPigInfo)
const loadingPigInfo = computed(() => store.getters.loadingPigInfo)
const destinationList = computed(() => store.getters.destinationList)
const loadingTransport = computed(() => store.getters.loadingTransport)
const pigPartList = computed(() => store.getters.pigPartList)
const loadingPigParts = computed(() => store.getters.loadingPigParts)
const perPagePigInfo = computed(() => store.getters.perPagePigInfo)
const perPageOptions = computed(() => store.getters.perPageOptions)
const pageNumberPig = computed(() => store.getters.pageNumberPig)

const errorsOnPage = ref(0)
const damagedPigsCount = ref(0)

const pigSummary = reactive({
  total: 0,
  allocated: 0,
  unallocated: 0,
  partiallyAllocated: 0,
  totalValue: 0
})

const updatePerPage = (value) => {
  store.commit('SET_PER_PAGE_PIG_INFO', value)
  store.dispatch('fetchPigInfo', { vm })
}

const updatePageNr = (nr) => {
  store.commit('SET_PAGE_NR_PIG', nr - 1)
  store.dispatch('fetchPigInfo', { vm })
}

const countErrors = () => {
  errorsOnPage.value = 0
  damagedPigsCount.value = 0
  pigInfoList.value.forEach((el) => {
    if (checkBodyTypeError(el)) errorsOnPage.value += 1
    if (checkForDamage(el)) damagedPigsCount.value += 1
  })
}

const setStatus = () => {
  let total = 0
  let allocated = 0
  let unallocated = 0
  let partiallyAllocated = 0
  let totalValue = 0

  const isComb2Correct = (part) =>
    ['Trunk', 'Haunch', 'HalfPig1', 'HalfPig2'].includes(part)
  const isComb3Correct = (part) =>
    ['Forequarter', 'Saddle', 'Leg'].includes(part)
  const isComb3Mistake = (part) =>
    ['WholePig', 'Trunk', 'Haunch', 'HalfPig1', 'HalfPig2'].includes(part)
  const checkTwoPartMistake = (alloc) => {
    const comb2partMistake = {
      Leg: ['Haunch', 'HalfPig1', 'HalfPig2', 'Leg'],
      Saddle: ['Haunch', 'HalfPig1', 'HalfPig2', 'Trunk', 'Saddle'],
      Forequarter: ['Trunk', 'HalfPig1', 'HalfPig2', 'Forequarter'],
      Trunk: [
        'Haunch',
        'HalfPig1',
        'HalfPig2',
        'Forequarter',
        'Saddle',
        'Trunk'
      ],
      Haunch: ['Haunch', 'HalfPig1', 'HalfPig2', 'Trunk', 'Saddle', 'Leg'],
      HalfPig1: ['Haunch', 'Forequarter', 'Leg', 'Trunk', 'Saddle'],
      HalfPig2: ['Haunch', 'Forequarter', 'Leg', 'Trunk', 'Saddle']
    }
    return comb2partMistake[alloc[0].part.part].includes(alloc[1].part.part)
  }

  const setStatusForAllocation = (alloc) => {
    if (alloc.length === 0) {
      unallocated++
      return 'Unallocated'
    }
    if (alloc.length === 1) {
      return handleSingleAllocation(alloc)
    }
    if (alloc.length === 2) {
      return handleDoubleAllocation(alloc)
    }
    if (alloc.length === 3) {
      return handleTripleAllocation(alloc)
    }
    return 'MISTAKE'
  }

  const handleSingleAllocation = (alloc) => {
    if (alloc[0].part.part !== 'WholePig') {
      partiallyAllocated++
      return 'Unfinished'
    }
    allocated++
    return alloc[0].destination.name === 'Booyong Pork Processing'
      ? 'BONING'
      : 'Butcher'
  }

  const handleDoubleAllocation = (alloc) => {
    if (
      alloc.some((a) => a.part.part === 'WholePig') ||
      checkTwoPartMistake(alloc)
    ) {
      unallocated++
      return 'MISTAKE'
    }
    const status = alloc.some((a) => isComb2Correct(a.part.part))
      ? 'Broken'
      : 'Unfinished'
    status === 'Broken' ? allocated++ : partiallyAllocated++
    return status
  }

  const handleTripleAllocation = (alloc) => {
    if (new Set(alloc.map((a) => a.part.part)).size !== 3) {
      unallocated++
      return 'MISTAKE'
    }
    const status =
      alloc.every((a) => isComb3Correct(a.part.part)) &&
      !alloc.some((a) => isComb3Mistake(a.part.part))
        ? 'Broken'
        : 'MISTAKE'
    status === 'Broken' ? allocated++ : unallocated++
    return status
  }

  pigInfoList.value.forEach((pigBody) => {
    total++
    totalValue +=
      pigBody.customValue === undefined ? pigBody.value : pigBody.customValue
    pigBody.status = setStatusForAllocation(pigBody.allocation)
  })

  Object.assign(pigSummary, {
    total,
    allocated,
    unallocated,
    partiallyAllocated,
    totalValue
  })
}

watch(
  () => pigInfoList.value,
  () => {
    setStatus()
    store.dispatch('createRunReport')
    countErrors()
  },
  { deep: true }
)

// ON CREATED
store.commit('SET_USING_VUETIFY', false)
store.commit('SET_ACTIVE', PIG_INFO)
if (
  destinationList.value.length === 0 &&
  loadingTransport.value !== 'destination'
) {
  store.dispatch('fetchDestinations', { vm })
}
if (pigPartList.value.length === 0 && loadingPigParts.value !== 'list') {
  store.dispatch('fetchPigParts', { vm })
}
</script>

<template>
  <v-app>
    <v-container fluid>
      <div class="wrapper">
        <PigInfoFilter />
        <div class="content-page mt-0 ps-4">
          <b-row no-gutters class="my-2 align-items-center">
            <div class="page-title-box d-flex">
              <h4 class="page-title">Pig Information</h4>
            </div>
          </b-row>

          <b-row class="d-flex row no-gutters mt-1 mb-3">
            <div class="d-flex align-items-center">
              Total Pigs:
              <span class="font-weight-bold ml-2">{{ totalPigInfo }}</span>
            </div>
            <div class="ml-auto mr-2 d-flex align-items-center">
              Pigs per page:
            </div>
            <b-select
              :value="perPagePigInfo"
              :options="perPageOptions"
              class="d-inline-block w-auto mr-4"
              @change="updatePerPage"
            ></b-select>
            <pagination-base
              :total="totalPigInfo"
              :perPage="perPagePigInfo"
              :current="pageNumberPig + 1"
              @updatePage="updatePageNr"
            />
          </b-row>

          <!-- ---------------- Summary section ------------------- -->
          <b-card no-body class="px-3 pt-3 mb-3">
            <b-row class="align-items-baseline" no-gutters>
              <b-col cols="6" class="d-flex flex-row align-items-baseline">
                <h4 class="header-title mb-2 w-700 text-light-blue text-left">
                  Pig Information Summary
                </h4>
                <b-badge
                  variant="light"
                  class="align-self-top ml-auto mr-2"
                  v-b-toggle
                  @click.prevent
                  v-b-toggle.run-report
                  >Run Report...</b-badge
                ></b-col
              >
              <b-col cols="6" class="ml-auto ps-2"
                ><b-alert
                  v-if="errorsOnPage > 0"
                  variant="danger"
                  show
                  class="text-center mb-2"
                  ><font-awesome-icon
                    :icon="['fas', 'exclamation-triangle']"
                    class="mr-2"
                  />Number of pig bodies with incorrect body type:
                  <span class="font-weight-bold">{{
                    errorsOnPage
                  }}</span></b-alert
                >
              </b-col>
            </b-row>
            <b-row
              class="justify-content-md-start py-2 align-items-start flex-row"
              no-gutters
            >
              <b-col cols="6" id="total-pigs">
                <b-table-simple
                  small
                  hover
                  responsive
                  class="text-left"
                  ref="totalsTable"
                >
                  <b-thead>
                    <b-tr class="bg-light-blue">
                      <b-th>Total Pigs</b-th>
                      <b-th>Total Value</b-th>
                      <b-th>Allocated</b-th>
                      <b-th>Unallocated</b-th>
                      <b-th>Unfinished</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-if="loadingPigInfo != 'list'">
                      <b-td>{{ pigSummary.total }}</b-td>
                      <b-td>{{ formatCurrency(pigSummary.totalValue) }}</b-td>
                      <b-td>{{ pigSummary.allocated }}</b-td>
                      <b-td>{{ pigSummary.unallocated }}</b-td>
                      <b-td>{{ pigSummary.partiallyAllocated }}</b-td>
                    </b-tr>
                    <b-tr v-else>
                      <b-td colspan="5" class="text-center red--text"
                        ><v-progress-circular
                          :size="20"
                          :width="3"
                          indeterminate
                          class="mr-2"
                        >
                        </v-progress-circular
                        >Loading</b-td
                      ></b-tr
                    >
                  </b-tbody></b-table-simple
                >
              </b-col>
              <b-col cols="6" class="ml-auto ps-2">
                <b-alert
                  v-if="damagedPigsCount > 0"
                  variant="warning"
                  show
                  class="text-center mb-0"
                  ><font-awesome-icon
                    :icon="['fas', 'exclamation-triangle']"
                    class="mr-2"
                  />Number of damaged pigs:
                  <span class="font-weight-bold">{{
                    damagedPigsCount
                  }}</span></b-alert
                >
              </b-col>
            </b-row>
            <run-report ref="runReport" />
          </b-card>

          <b-card no-body>
            <b-tabs id="pig-info-tab-list" card>
              <b-tab title="All Pigs" class="px-0">
                <PigInfoTable />
              </b-tab>
              <b-tab title="Boning Report" lazy class="px-0"
                ><boning-report
              /></b-tab>
              <b-tab title="Break Boning Report" lazy class="px-0"
                ><break-boning-report
              /></b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>
