<script setup>
import SideFilter from '@/components/SideFilter.vue'

import { DATE_END_STR, DATE_START_STR } from '@/constants/general'
import { useStore } from '@/utils/VuexHelpers'
import useWeekDateFilter from '@/utils/useWeekDateFilter'
import { getCurrentInstance, ref, computed } from 'vue'

const vm = getCurrentInstance().proxy
const store = useStore()

const filtersPig = computed(() => store.getters.filtersPig)
const sortingPig = computed(() => store.getters.sortingPig)

const pigSideFilter = ref(null)

const { updateDateFilter } = useWeekDateFilter('filtersPig', 'SET_FILTER_PIG')

const changeSortDirection = (ctx) => {
  store.commit('SET_SORTING_DIRECTION', ctx)
  store.commit('SET_MULTI_SORT_BY_PIG', ctx.sorting)
  store.dispatch('fetchPigInfo', { vm })
}
const multiSort = (ctx) => {
  store.commit('SET_MULTI_SORT_BY_PIG', ctx)
  store.dispatch('fetchPigInfo', { vm })
}
const updateWeekFilter = (dateRange) => {
  store.commit('SET_FILTER_PIG', { field: 'week', value: dateRange.weekNr })
  store.commit('SET_FILTER_PIG', {
    field: 'date_start',
    value: dateRange.startDay
  })
  store.commit('SET_FILTER_PIG', { field: 'date_end', value: dateRange.endDay })
  if (filtersPig.value.year.value != dateRange.year) {
    store.commit('SET_FILTER_PIG', { field: 'year', value: dateRange.year })
  }
  store.dispatch('fetchPigInfo', { vm })
}
const updateYearFilter = ({ year }) => {
  const cleanYear = parseInt(year.trim())
  if (cleanYear == filtersPig.value.year.value) return
  store.commit('SET_FILTER_PIG', { field: 'year', value: cleanYear })
  if (!filtersPig.value.week.value) return
  pigSideFilter.value.generateWeekDays(filtersPig.value.week.value, cleanYear)
}
const updateFilter = ({ value, field }) => {
  if (field === DATE_START_STR || field === DATE_END_STR) {
    updateDateFilter({ value, field })
  } else {
    store.commit('SET_FILTER_PIG', { value, field })
  }
  store.dispatch('fetchPigInfo', { vm })
}
const removeFilters = async () => {
  await store.dispatch('clearFiltersPig')
  store.dispatch('fetchPigInfo', { vm })
}
</script>
<template>
  <SideFilter
    class="noprint mt-0 elevation-2"
    :filterList="filtersPig"
    :sortingList="sortingPig"
    @input="updateFilter"
    @inputWeek="updateWeekFilter"
    @inputYear="updateYearFilter"
    @clear="removeFilters"
    @sort="multiSort"
    @direction="changeSortDirection"
    ref="pigSideFilter"
  />
</template>
