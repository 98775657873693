<template>
  <div class="container-fluid">
    <div class="wrapper">
      <side-filter
        :filterList="filtersSupp"
        @input="updateFilter"
        @clear="removeFilters()"
      />
      <div class="content-page">
        <b-row no-gutters class="my-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Suppliers</h4>
          </div>
        </b-row>
        <b-row class="d-flex row no-gutters mt-1">
          <b-button
            @click="$refs.suppModal.add(), (modalAction = 'add')"
            class="mr-2"
          >
            + New Supplier
          </b-button>
          <div class="d-flex align-items-center">
            Total Suppliers:
            <span class="font-weight-bold ml-2">{{ totalSuppliers }}</span>
          </div>
          <div class="ml-auto mr-2 d-flex align-items-center">
            Suppliers per page:
          </div>
          <b-select
            v-model="perPage"
            :options="perPageOptions"
            class="d-inline-block w-auto mr-4"
          ></b-select>
          <pagination-base
            :total="totalSuppliers"
            :perPage="perPageSuppliers"
            :current="pageNumberSupp + 1"
            @updatePage="updatePageNr"
          />
        </b-row>

        <!-- ------------ Modals --------------- -->
        <new-supplier-modal :type="modalAction" ref="suppModal" />
        <new-freight-charges-modal :type="chargesAction" ref="chargesModal" />

        <b-row no-gutters class="mt-3 d-flex text-left">
          <b-table
            id="supplier-table"
            striped
            hover
            sort-icon-left
            no-local-sorting
            :sort-by="sortbySupp"
            :sort-desc="!ascSupp"
            @sort-changed="sortChange"
            :fields="fields"
            :items="suppliers"
            :busy="loadingSuppliers == 'list'"
            class="mt-3"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner small class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(name)="data">
              <b-row no-gutters>{{ data.item.company.name }}</b-row>
              <b-row
                no-gutters
                v-if="data.item.company.extra"
                class="flex-column"
                ><small
                  class="font-italic"
                  v-for="item in data.item.company.extra.alias"
                  :key="item.id"
                  ><b class="mr-2 text-muted">Alias:</b>{{ item }}</small
                ></b-row
              >
            </template>
            <template #cell(phone)="data">
              <span v-if="data.item.company.contact">{{
                data.item.company.contact.phone
                  ? data.item.company.contact.phone
                  : ''
              }}</span>
            </template>
            <template #cell(abn)="data">
              {{ data.item.company.abn }}
            </template>
            <template #cell(type)="data">
              <b-row no-gutters>
                <span class="d-block">
                  <font-awesome-icon
                    v-if="data.item.type.type == 'Grower'"
                    icon="fas fa-tractor"
                    size="sm"
                    class="mr-2 text-success"
                  />
                  <font-awesome-icon
                    v-if="data.item.type.type == 'Freight'"
                    icon="fas fa-truck-arrow-right"
                    size="sm"
                    class="mr-2 text-info"
                  />
                  <font-awesome-icon
                    v-if="data.item.type.type == 'Boning'"
                    icon="fas fa-bone"
                    size="sm"
                    class="mr-2 text-danger"
                  />
                  <font-awesome-icon
                    v-if="data.item.type.type == 'Storage'"
                    icon="fas fa-box-open"
                    size="sm"
                    class="mr-2 text-warning"
                  />{{ data.item.type.type }}
                </span>
                <span class="d-block" v-if="isInGrid(data.item)"
                  ><font-awesome-icon
                    :icon="['fas', 'hand-holding-usd']"
                    class="mr-2 text-warning"
                  />On Grid</span
                >
              </b-row>
            </template>
            <template #cell(is_active)="data">
              <icon-check v-if="data.item.is_active == true"></icon-check>
              <icon-times v-else></icon-times>
            </template>
            <template #cell(address)="data">
              {{
                data.item.company.address
                  ? data.item.company.address.postaddress
                  : ''
              }}
            </template>
            <template #cell(actions)="data">
              <b-row class="text-nowrap mb-2" no-gutters>
                <!-- Hide delete button due to object relationship protection -->
                <!-- <icon-delete
                  @click.native="deleteSupp(data.item)"
                  class="mr-2"
                /> -->

                <icon-edit
                  @click.native="
                    ;(modalAction = 'edit'),
                      $refs.suppModal.editSupplier(data.item)
                  "
                  class="mr-2"
                />
                <icon-dollar
                  @click.native="fetchCharges(data.item.company_id, data)"
                  v-if="data.item.type.type === 'Freight'"
                />
                <icon-dollar
                  @click.native="fetchGrowerPricing(data.item.company_id, data)"
                  v-if="data.item.type.type === 'Grower'"
                  :title="'Show Pricing'"
                />
              </b-row>
            </template>

            <template #row-details="{ item, index }">
              <grower-pricing
                v-if="item.type.type == 'Grower'"
                :grower="item"
                :rowIndex="index"
              />
              <div v-else>
                <b-card
                  v-if="loadingSettings == `charges-${item.company_id}`"
                  class="mb-3"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner small class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </b-card>
                <b-card v-else>
                  <b-row no-gutters class="justify-content-end mb-2"
                    ><b-button
                      variant="warning"
                      size="sm"
                      @click="
                        $refs.chargesModal.add(item), (chargesAction = 'add')
                      "
                      ><font-awesome-icon icon="fas fa-plus" class="mr-2" />Add
                      freight charges</b-button
                    ></b-row
                  >
                  <b-row no-gutters>
                    <b-table-simple
                      small
                      hover
                      responsive
                      class="text-left"
                      ref="totalsTable"
                    >
                      <b-thead>
                        <b-tr class="bg-light-blue">
                          <b-th>Effective Date</b-th>
                          <b-th>$/kg (excl gst)</b-th>
                          <b-th>Min Charge</b-th>
                          <b-th>Fuel Levy</b-th>
                          <b-th>$/Pallet (excl gst)</b-th>
                          <b-th>Notes</b-th>
                          <b-th>Aplied to</b-th>
                          <b-th></b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="charge in freightCharges[item.company_id]"
                          :key="charge.id"
                        >
                          <b-td>{{ charge.effective_date | date }}</b-td>
                          <b-td>{{ charge.price_kg }}</b-td>
                          <b-td>{{ charge.min_charge }}</b-td>
                          <b-td>{{ charge.fuel_levy }}</b-td>
                          <b-td>{{ charge.price_pallet }}</b-td>
                          <b-td>{{ charge.notes ? item.notes : '-' }}</b-td>
                          <b-td>{{ customerCode(charge.applied_to) }}</b-td>
                          <b-td>
                            <icon-delete
                              @click.native="deleteCharge(charge)"
                              :position="'left'"
                              class="mr-2" />
                            <icon-edit
                              :position="'bottom'"
                              @click.native="
                                $refs.chargesModal.edit(charge),
                                  (chargesAction = 'edit')
                              "
                          /></b-td>
                        </b-tr> </b-tbody></b-table-simple
                  ></b-row>
                </b-card>
              </div>
            </template>
          </b-table>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NewSupplierModal from '../components/NewSupplierModal.vue'
import NewFreightChargesModal from '../components/NewFreightChargesModal.vue'
import PaginationBase from '../components/PaginationBase.vue'
import IconDelete from '../components/IconDelete.vue'
import iconDollar from '../components/iconDollar.vue'
import IconEdit from '../components/IconEdit.vue'
import SideFilter from '../components/SideFilter.vue'
import GrowerPricing from '../components/GrowerPricing.vue'

export default {
  components: {
    NewSupplierModal,
    NewFreightChargesModal,
    PaginationBase,
    IconDelete,
    IconEdit,
    SideFilter,
    iconDollar,
    GrowerPricing
  },
  name: 'SuppliersView',
  data() {
    return {
      modalAction: 'add',
      chargesAction: 'add',
      fields: [
        { key: 'name', label: 'Company Name' },
        { key: 'code', sortable: true },
        { key: 'address' },
        { key: 'phone', sortable: true },
        { key: 'abn' },
        { key: 'type', label: 'Service Type' },
        { key: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'suppliers',
      'freightCharges',
      'totalSuppliers',
      'loadingSuppliers',
      'perPageSuppliers',
      'pageNumberSupp',
      'perPageOptions',
      'ascSupp',
      'sortbySupp',
      'filtersSupp',
      'loadingSettings',
      'customerCodeMap',
      'customerNames'
    ]),
    perPage: {
      get() {
        return this.perPageSuppliers
      },
      set(value) {
        this.SET_PER_PAGE_SUPPLIERS(value)
        this.fetchSuppliers({ vm: this })
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchSuppliers',
      'fetchSupplierTypes',
      'deleteSupplier',
      'clearFiltersSupp',
      'fetchFreightCharges',
      'fetchCustomerNames',
      'deleteFreightCharges'
    ]),
    ...mapMutations([
      'SET_PAGE_NR_SUPP',
      'SET_PER_PAGE_SUPPLIERS',
      'SET_ASC_SUPP',
      'SET_SORT_BY_SUPP',
      'SET_FILTER_SUPP',
      'SET_ACTIVE',
      'SET_USING_VUETIFY'
    ]),
    isInGrid: function (item) {
      if (item.type.type == 'Grower' && item.extra?.ingrid == true) return true
      return false
    },
    fetchGrowerPricing: function (id, row) {
      row.toggleDetails()
    },
    customerCode: function (value) {
      if (value.search('Default') != -1) return value

      let arr = value.split(',')
      const mapped = arr.map((el) => {
        if (el) {
          return this.customerCodeMap[el].code
        }
      }, this)
      return mapped.join('/')
    },
    sortChange: function (ctx) {
      this.SET_ASC_SUPP(!ctx.sortDesc)
      this.SET_SORT_BY_SUPP(ctx.sortBy)
      this.fetchSuppliers({ vm: this })
    },
    fetchCharges: async function (id, row) {
      row.toggleDetails()
      if (!this.freightCharges[id]) {
        await this.fetchFreightCharges({ vm: this, id: id })
      }
    },
    deleteCharge: function (info) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete freight charges from ${info.effective_date}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then(async (value) => {
          if (value) {
            let res = await this.deleteFreightCharges({
              vm: this,
              payload: info
            })
            if (res === 'success') {
              this.fetchFreightCharges({
                vm: this,
                id: info.freight_company_id
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteSupp: function (info) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete ${info.company.name}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then(async (value) => {
          if (value) {
            let res = await this.deleteSupplier({ vm: this, payload: info })
            if (res === 'success') {
              this.fetchSuppliers({ vm: this })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updatePageNr: async function (nr) {
      this.SET_PAGE_NR_SUPP(nr - 1)
      let res = await this.fetchSuppliers({ vm: this })
      if (res == 'success')
        this.$root.$emit('bv::refresh::table', 'supplier-table')
    },
    updateFilter: async function (value) {
      await this.SET_FILTER_SUPP(value)
      this.fetchSuppliers({ vm: this })
    },
    removeFilters: async function () {
      await this.clearFiltersSupp()
      this.fetchSuppliers({ vm: this })
    }
  },
  mounted() {
    if (this.customerNames.length == 0) this.fetchCustomerNames({ vm: this })
  },
  created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE('suppliers')
    this.fetchSupplierTypes({ vm: this })
    this.fetchSuppliers({ vm: this })
  }
}
</script>
