import { PER_PAGE_MAX } from '@/constants/pagination'
import { WEEK_OPTIONS } from '@/constants/general'
import {
  getCurrentDay,
  getDayValues,
  getISOWeekNumber,
  getYear
} from '@/utils/DateHelpers'
import { PORKER_CODE_MAX } from '@/constants/product'

const state = {
  recOrderList: {},
  loadingRecOrder: '',

  //pagination
  perPageRecOrder: PER_PAGE_MAX,
  pageNrRecOrder: 0,

  //sorting
  sortbyRecOrder: 'date',
  ascRecOrder: false,

  //filters
  filtersRecOrder: {
    date_start: {
      type: 'date',
      label: 'Date',
      value: getCurrentDay(),
      readOnly: true
    },
    date_end: {
      type: 'date',
      label: 'End Date',
      value: getCurrentDay(),
      hidden: true
    },
    week: { type: 'week', label: 'Week Number', value: getISOWeekNumber() },
    year: { type: 'year', value: getYear(), hidden: true },
    weekDay: {
      type: 'selectWeekDay',
      name: 'day',
      options: WEEK_OPTIONS,
      label: 'Day of the week',
      noReduce: true,
      value: getDayValues()
    }
  }
}
const getters = {
  recOrderList: (state) => state.recOrderList,
  loadingRecOrder: (state) => state.loadingRecOrder,
  perPageRecOrder: (state) => state.perPageRecOrder,
  sortbyRecOrder: (state) => state.sortbyRecOrder,
  ascRecOrder: (state) => state.ascRecOrder,
  filtersRecOrder: (state) => state.filtersRecOrder,
  offsetRecOrder: (state) => {
    return state.perPageRecOrder * state.pageNrRecOrder
  }
}
const actions = {
  async fetchRecOrder({ commit, dispatch }, { vm }) {
    commit('SET_LOADING_REC_ORDER', 'list')
    let [resOrder, resAlloc] = await Promise.all([
      dispatch('fetchReconcileList', 'orders/'),
      dispatch('fetchReconcileList', 'piginfo/')
    ])
    commit('SET_LOADING_REC_ORDER', '')
    if (!resOrder.error && !resAlloc.error) {
      let reconcileList = {}
      reconcileList = await dispatch('parseReconcileOrders', resOrder.data)
      reconcileList = await dispatch('parseReconcileAllocations', {
        list: reconcileList,
        data: resAlloc.data
      })
      commit('SET_REC_ORDER_LIST', reconcileList)
    } else {
      if (resOrder.error) {
        dispatch(
          'handleError',
          {
            vm: vm,
            error: resOrder.error,
            title: 'Order Reconciliation',
            messagePrefix: 'Error while loading Orders! '
          },
          { root: true }
        )
      }
      if (resAlloc.error) {
        dispatch(
          'handleError',
          {
            vm: vm,
            error: resAlloc.error,
            title: 'Order Reconciliation',
            messagePrefix: 'Error while loading allocations! '
          },
          { root: true }
        )
      }
    }
  },
  async fetchReconcileList({ dispatch, getters }, endpoint) {
    let res = await dispatch(
      'fetch',
      {
        endpoint: endpoint,
        parameters: {
          limit: getters.perPageRecOrder,
          offset: getters.offsetRecOrder,
          sort_by: getters.sortbyRecOrder,
          ascending: getters.ascRecOrder
        },
        filters: getters.filtersRecOrder
      },
      { root: true }
    )
    return res
  },
  async parseReconcileOrders(context, data) {
    let orderByCustomer = {}

    data.forEach((order) => {
      const orderedItems = order.ordered_items
      if (!orderedItems || orderedItems.length === 0) return
      if (!orderedItems.some((item) => item.productcode <= PORKER_CODE_MAX))
        return

      const { company_id, company } = order.customer

      if (!orderByCustomer[company_id]) {
        orderByCustomer[company_id] = {
          name: company.name,
          orders: [],
          orderTotals: {},
          allocationTotals: {},
          transportTotals: {}
        }
      }

      const customerOrders = orderByCustomer[company_id]

      orderedItems.forEach((item) => {
        if (item.productcode > PORKER_CODE_MAX) return

        const itemType = item.product.product
        const quantity = parseInt(item.quantity, 10)

        customerOrders.orderTotals[itemType] =
          (customerOrders.orderTotals[itemType] || 0) + quantity
        customerOrders.orders.push(item)
      })
    })
    return orderByCustomer
  },
  async parseReconcileAllocations(context, { list, data }) {
    const allocByCustomer = list

    data.forEach((alloc) => {
      if (!alloc.allocation) return

      alloc.allocation.forEach((item) => {
        const itemType = item.part.part
        const companyId = item.company_id

        if (!allocByCustomer[companyId]) {
          allocByCustomer[companyId] = {
            name: item.destination.name,
            orders: [],
            orderTotals: {},
            allocationTotals: {},
            transportTotals: {}
          }
        }

        allocByCustomer[companyId].allocationTotals[itemType] =
          (allocByCustomer[companyId].allocationTotals[itemType] || 0) + 1
      })
    })
    return allocByCustomer
  }
}

const mutations = {
  SET_REC_ORDER_LIST: (state, payload) => (state.recOrderList = payload),
  SET_LOADING_REC_ORDER: (state, payload) => (state.loadingRecOrder = payload),
  SET_FILTER_REC_ORDER: (state, payload) => {
    state.filtersRecOrder[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
