<template>
  <b-row
    no-gutters
    :class="`${rootClass} bg-light-blue py-2 px-3 align-items-center`"
    :id="id"
  >
    <span class="font-weight-bold mr-3">TOTALS:</span>
    <slot name="prefix"></slot>
    <span
      v-if="Object.keys(totalsFiltered).length === 0"
      class="font-weight-bold"
      >-</span
    >
    <span class="mr-2" v-for="(value, key) in totalsFiltered" :key="value.id">
      {{ key }}: {{ value }}</span
    ><span class="ml-auto">
      <slot
        name="export-button"
        v-if="Object.keys(totalsFiltered).length !== 0"
      ></slot
    ></span>
  </b-row>
</template>

<script>
export default {
  name: 'SimpleTotals',
  data() {
    return {}
  },
  props: {
    totals: Object,
    id: String,
    rootClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    totalsFiltered: function () {
      Object.filter = (obj, predicate) =>
        Object.fromEntries(Object.entries(obj).filter(predicate))

      let filtered = Object.filter(this.totals, (total) => total[1] > 0)
      return filtered
    }
  }
}
</script>

<style></style>
