<script setup>
import BaseInputField from './BaseInputField.vue'
import { reactive, ref, defineProps, defineEmits, set, watch } from 'vue'

const props = defineProps({
  formFields: {
    type: Array,
    required: true
  },
  resetOnInputChange: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['submit'])

const formData = reactive({})
const valid = ref(true)
const formRef = ref(null)

const initializeFormData = () => {
  props.formFields.forEach((field) => {
    set(formData, field.key, field.value)
  })
}
const submitForm = () => {
  if (formRef.value.validate()) {
    emit('submit', formData)
  }
}
const resetForm = () => {
  initializeFormData()
}

const updateFormData = (key, value) => {
  formData[key] = value
}

watch(
  () => props.formFields,
  () => {
    if (props.resetOnInputChange) {
      initializeFormData()
    }
  },
  { deep: true }
)

// ON Created
initializeFormData()
</script>

<template>
  <v-form ref="formRef" v-model="valid" @submit.prevent="submitForm">
    <div v-for="(field, index) in formFields" :key="index">
      <BaseInputField
        :field="field"
        :value="formData[field.key]"
        @input="updateFormData(field.key, $event)"
        :key="index"
      />
    </div>
    <v-row no-gutters class="justify-end">
      <v-btn color="error" text @click="resetForm" class="me-2">Reset</v-btn>
      <v-btn color="primary" @click="submitForm" :loading="loading"
        >Submit</v-btn
      >
    </v-row>
  </v-form>
</template>

<style>
.v-input--checkbox .v-label {
  margin-bottom: 0;
}
</style>
