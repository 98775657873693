<script setup>
import SimpleTotals from '../components/SimpleTotals.vue'
import BoningExport from './pig-info-page/BoningExport.vue'

import { computed } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { formatDate, formatKilograms } from '@/helpers/stringHelpers'

const store = useStore()
const runReport = computed(() => store.getters.runReport)
const loadingPigInfo = computed(() => store.getters.loadingPigInfo)

const bodyTotal = computed(() => {
  const uniquePigs = new Set(
    runReport.value.breakBoning.pigs.map((pig) => pig.body_no)
  )
  const numPigs = uniquePigs.size
  return numPigs
})

const breakBoningFields = [
  { value: 'product', text: 'Product/Type', sortable: true },
  { value: 'date', text: 'Est. Loadout Date', sortable: true },
  { value: 'body_no', text: 'Body No#', sortable: true },
  { value: 'HSCW', text: 'HSCW', sortable: true },
  { value: 'CSCW', text: 'CSCW', sortable: true },
  { value: 'p2', text: 'P2', sortable: true },
  { value: 'kill_date', text: 'Kill Date', sortable: true },
  { value: 'customer', text: 'Destination', sortable: true }
]
</script>
<template>
  <v-container class="mx-0 mw-100">
    <v-data-table
      id="break-boning-table"
      :items="runReport.breakBoning.pigs"
      item-key="tableId"
      :items-per-page="runReport.breakBoning.pigs.length"
      :headers="breakBoningFields"
      fixed-header
      :height="runReport.breakBoning.pigs.length > 15 ? 800 : ''"
      class="w-100 elevation-2"
      :loading="loadingPigInfo === 'list'"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row class="justify-start align-center p-2 pt-0 bg-light-blue"
          ><SimpleTotals
            :totals="runReport.breakBoning.totals"
            id="break-boning-totals-tab"
            :root-class="'mb-0'"
          >
            <template #prefix v-if="bodyTotal !== 0"
              ><span class="mr-2">Body: {{ bodyTotal }}</span></template
            >
          </SimpleTotals>
          <v-col cols="auto" class="ms-auto pe-0">
            <BoningExport :booyong="true" />
          </v-col>
          <v-col cols="auto"> <BoningExport /> </v-col>
        </v-row>
      </template>
      <template v-slot:item.date="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.HSCW="{ value }">
        {{ formatKilograms(value, 2) }}
      </template>
      <template v-slot:item.CSCW="{ value }">
        {{ formatKilograms(value, 2) }}
      </template>
    </v-data-table>
  </v-container>
</template>
