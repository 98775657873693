<script setup>
import UpdateTransportDialog from '@/components/transport-summary-page/UpdateTransportDialog.vue'
import UpdateInvoiceDialog from '@/components/transport-summary-page/UpdateInvoiceDialog.vue'

import { getCurrentInstance, ref } from 'vue'
import { CARTON, DATE_END_STR, DATE_START_STR } from '@/constants/general'
import { useStore } from '@/utils/VuexHelpers'
import useWeekDateFilter from '@/utils/useWeekDateFilter'

const store = useStore()
const vm = getCurrentInstance().proxy
const transportDialog = ref(null)
const invoiceDialog = ref(null)

const { updateDateFilter } = useWeekDateFilter(
  'filtersTransp',
  'SET_FILTER_TRANSP'
)

const isCartonItem = (item) => {
  if (item.loaded_items.length && item.loaded_items[0].producttype === CARTON)
    return true
  return false
}

const isPorkerItem = (item) => {
  if (item.loaded_items.length && item.loaded_items[0].producttype !== CARTON)
    return true
  return false
}

const updateFreight = (payload) => {
  transportDialog.value.edit(payload)
}

const updateInvoice = (payload) => {
  invoiceDialog.value.edit(payload)
}

const updateFilter = ({ value, field }) => {
  if (field === 'delivery_by' && value) {
    store.commit('SET_FREIGHT_FILTER_INFO', value)
    value = value.name
  }
  if (field === DATE_START_STR || field === DATE_END_STR) {
    updateDateFilter({ value, field })
  } else {
    store.commit('SET_FILTER_TRANSP', { value, field })
  }
  store.dispatch('fetchTransport', { vm })
}
</script>

<template>
  <div class="container-fluid">
    <div class="wrapper">
      <side-filter
        :filterList="filtersTransp"
        @input="updateFilter"
        @inputWeek="updateWeekFilter"
        @inputYear="updateYearFilter"
        @clear="removeFilters()"
        ref="transpSideFilter"
      />
      <div class="content-page">
        <b-row no-gutters class="my-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Transport</h4>
          </div>
          <b-button
            @click="prepareForExport('fullTable')"
            size="sm"
            variant="warning"
            class="ml-auto"
          >
            <b-spinner
              v-if="generatingPDF == 'total-summary'"
              small
              class="mr-2"
            ></b-spinner>
            <font-awesome-icon
              v-else
              icon="fas fa-file-export"
              class="mr-2"
            />Export</b-button
          >
        </b-row>
        <UpdateTransportDialog ref="transportDialog" />
        <UpdateInvoiceDialog ref="invoiceDialog" />
        <b-row class="d-flex row no-gutters mt-1 mb-3">
          <div class="d-flex align-items-center">
            Transport Summary:
            <span class="font-weight-bold ml-2">{{ totalTransport }}</span>
          </div>
          <div class="ml-auto mr-2 d-flex align-items-center">
            Summaries per page:
          </div>
          <b-select
            v-model="perPage"
            :options="perPageOptions"
            class="d-inline-block w-auto mr-4"
          ></b-select>
          <pagination-base
            :total="totalTransport"
            :perPage="perPageTransport"
            :current="pageNumberTransp + 1"
            @updatePage="updatePageNr"
          />
        </b-row>

        <!-- ---------------- Summary section ------------------- -->
        <b-card class="mb-3" v-if="loadingTransport != 'list'">
          <b-row
            no-gutters
            class="align-items-baseline justify-content-between"
          >
            <b-col cols="6" class="d-flex flex-row align-items-baseline">
              <h4 class="header-title mb-3 w-700 text-light-blue text-left">
                Transport Summary
              </h4>
              <b-badge
                variant="light"
                class="align-self-top ml-auto"
                v-b-toggle
                @click.prevent
                href="#totals-table"
                >Show Details...</b-badge
              ></b-col
            >
          </b-row>

          <b-row
            class="py-2 align-items-start justify-content-between"
            no-gutters
          >
            <b-col cols="6">
              <b-table-simple
                id="total-pigs"
                small
                responsive
                hover
                class="text-left"
                ref="totalsTable"
              >
                <b-thead>
                  <b-tr class="bg-light-blue">
                    <b-th>Type</b-th>
                    <b-th>Weight</b-th>
                    <b-th>Quantity</b-th>
                    <b-th>Price</b-th>
                    <b-th v-if="singleFreight"
                      >Price for
                      {{
                        freightFilterInfo ? freightFilterInfo.code : '-'
                      }}</b-th
                    >
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td class="font-weight-semibold font-italic">Pigs</b-td>
                    <b-td>{{ transportSummary.pigsWeight | decimals2 }}kg</b-td>
                    <b-td>{{ transportSummary.pigs }}</b-td>
                    <b-td>${{ transportSummary.pigsPrice | decimals2 }}</b-td>
                    <b-td v-if="singleFreight"
                      >${{ transportSummary.splitPricePigs | decimals2 }}</b-td
                    >
                  </b-tr>
                  <b-tr>
                    <b-td class="font-weight-semibold font-italic"
                      >Cartons</b-td
                    >
                    <b-td
                      >{{ transportSummary.cartonsWeight | decimals2 }}kg</b-td
                    >
                    <b-td>{{ transportSummary.cartons }}</b-td>

                    <b-td
                      >${{ transportSummary.cartonsPrice | decimals2 }}</b-td
                    >
                    <b-td v-if="singleFreight"
                      >${{
                        transportSummary.splitPriceCartons | decimals2
                      }}</b-td
                    >
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr class="transport-summary-total">
                    <b-td class="font-weight-bold">Total</b-td>
                    <b-td
                      >{{
                        (transportSummary.pigsWeight +
                          transportSummary.cartonsWeight)
                          | decimals2
                      }}kg</b-td
                    >
                    <b-td>{{
                      transportSummary.cartons + transportSummary.pigs
                    }}</b-td>

                    <b-td
                      >${{
                        (transportSummary.cartonsPrice +
                          transportSummary.pigsPrice)
                          | decimals2
                      }}</b-td
                    >
                    <b-td v-if="singleFreight"
                      >${{
                        (transportSummary.splitPriceCartons +
                          transportSummary.splitPricePigs)
                          | decimals2
                      }}</b-td
                    >
                  </b-tr>
                </b-tfoot>
              </b-table-simple></b-col
            >
            <b-col class="d-flex justify-content-end align-self-end" cols="6">
              <b-button
                v-if="singleFreight"
                @click="prepareForExport('single')"
                size="sm"
                class="btn-sm btn-export btn-neutral"
              >
                <b-spinner
                  v-if="generatingPDF == 'total-summary'"
                  small
                  class="mr-2"
                ></b-spinner>
                <font-awesome-icon
                  v-else
                  icon="fas fa-file-export"
                  class="mr-2"
                />Export for
                {{ freightFilterInfo ? freightFilterInfo.code : '-' }}</b-button
              >
            </b-col>
          </b-row>

          <b-collapse id="totals-table">
            <b-table-simple
              small
              hover
              responsive
              class="text-left mt-2"
              ref="totalsTable"
              id="total-summary"
            >
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Customer</b-th>
                  <b-th class="text-right">Weight (kg)</b-th>
                  <b-th class="text-right">Cartons</b-th>
                  <b-th class="text-right">Pigs</b-th>
                  <b-th class="text-right">Price ($)</b-th>
                  <b-th v-if="singleFreight" class="text-right"
                    >Price ($) - {{ freightFilterInfo.code }}</b-th
                  >
                  <b-th>Delivered by</b-th>
                  <b-th>Invoice Nr.</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="item in transportListSorted" :key="item.id">
                  <b-td class="text-nowrap">{{ item.destination.name }}</b-td>
                  <b-td class="text-right">{{
                    item.job_description.total_weight | decimals2
                  }}</b-td>
                  <b-td class="text-right">{{
                    item.job_description.total_cartons
                  }}</b-td>
                  <b-td class="text-right">{{
                    item.job_description.total_pigs
                  }}</b-td>
                  <b-td class="text-right">{{
                    item.job_description.total_ex_GST | decimals2
                  }}</b-td>
                  <b-td v-if="singleFreight" class="text-right">{{
                    freightFilterTotal(item.job_description.filteredPrice)
                  }}</b-td>
                  <b-td>{{ freightCode(item.delivery_co_ids) }}</b-td>
                  <b-td>{{ item.invoice ? item.invoice : '-' }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </b-card>

        <b-card v-if="loadingTransport == 'list'">
          <div class="text-center text-danger my-2">
            <b-spinner small class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </b-card>
        <b-card v-for="item in transportList" :key="item.id" class="mb-3">
          <b-row no-gutters class="mb-4">
            <div class="w-700 text-muted font-italic">
              {{ item.date | date }}
            </div>
            <div class="d-flex flex-row ml-auto text-left">
              <div
                class="text-nowrap invoice-display"
                role="button"
                :id="`invoice-display-${item.id}`"
                @click="updateInvoice(item)"
              >
                <span class="w-700 text-muted mr-1">Invoice:</span
                ><span class="font-italic">{{
                  item.invoice ? item.invoice : '-'
                }}</span>
              </div>
              <div class="text-nowrap ml-2">
                <span class="w-700 text-muted mr-1">Docket:</span
                ><span class="font-italic">{{ item.docket }}</span>
              </div>
              <b-popover
                :target="`invoice-display-${item.id}`"
                triggers="hover"
                placement="top"
              >
                <template #title
                  ><font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="mr-2"
                  />Update Invoice Number</template
                >
                Click to edit.
              </b-popover>
            </div>
          </b-row>
          <b-row no-gutters class="mb-2">
            <h4>{{ item.destination.name }}</h4>
          </b-row>
          <b-row no-gutters class="d-flex align-items-center mb-2"
            ><font-awesome-icon
              icon="fas fa-truck-arrow-right"
              class="mr-1 text-info"
            />
            <h6 class="mr-2 text-muted mb-0">Delivered By:</h6>
            <p
              class="mr-3 freight-display"
              :id="`freight-display-${item.id}`"
              role="button"
              @click="updateFreight(item)"
            >
              {{ freightCode(item.delivery_co_ids) }}
            </p>
            <b-popover
              :target="`freight-display-${item.id}`"
              triggers="hover"
              placement="top"
            >
              <template #title
                ><font-awesome-icon
                  :icon="['fas', 'edit']"
                  class="mr-2"
                />Update Delivery Company</template
              >
              Click on names to edit.
            </b-popover>
          </b-row>
          <b-row
            v-if="deliveredByMultiple(item)"
            no-gutters
            class="d-flex align-items-start mb-2"
          >
            <small class="d-flex ml-3 mt-1">
              <font-awesome-icon
                icon="fas fa-dollar-sign"
                class="mr-1 text-warning"
              />
              <p class="mr-2 text-muted mb-0">Cost by Freight:</p></small
            >
            <b-col class="d-flex flex-column align-items-start">
              <p
                class="mr-3"
                v-for="cost in item.job_description.transport_cost"
                :key="cost.id"
              >
                <small
                  >{{ freightCode(cost.freight_company_id) }} - ${{
                    cost.cost_ex_gst | decimals2
                  }}</small
                >
              </p>
            </b-col>
          </b-row>
          <b-row no-gutters class="d-flex align-items-center mb-2">
            <font-awesome-icon
              icon="fas fa-map-marker-alt"
              class="text-danger mr-1"
            />
            <h6 class="mr-2 text-muted mb-0">Depot:</h6>
            <p>
              {{ item.depot.name }}
            </p>
          </b-row>
          <b-row
            class="justify-content-md-start bg-light-blue py-2 align-items-center"
            no-gutters
            ><b-col
              md="auto"
              class="d-flex text-nowrap align-items-baseline mx-3"
              ><h6 class="text-muted mr-1 mb-0">Date Loaded:</h6>
              <p>{{ item.job_description.loaded_date }}</p></b-col
            >
            <b-col
              md="auto"
              class="d-flex text-nowrap align-items-baseline mr-3"
              ><h6 class="text-muted mr-1 mb-0">Total Weight:</h6>
              <p>
                {{ item.job_description.total_weight | decimals2 }}kg
              </p></b-col
            >
            <b-col
              v-if="isCartonItem(item)"
              md="auto"
              class="d-flex text-nowrap align-items-baseline mr-3"
              ><h6 class="text-muted mr-1 mb-0">Date Required:</h6>
              <p>{{ item.job_description.required_date }}</p></b-col
            >
            <b-col
              v-if="isCartonItem(item)"
              md="auto"
              class="d-flex text-nowrap align-items-baseline mr-3"
              ><h6 class="text-muted mr-1 mb-0">Total Cartons:</h6>
              <p>{{ item.job_description.total_cartons }}</p></b-col
            >
            <b-col
              v-if="isPorkerItem(item)"
              md="auto"
              class="d-flex text-nowrap align-items-baseline mr-3"
              ><h6 class="text-muted mr-1 mb-0">Total Pigs:</h6>
              <p>{{ item.job_description.total_pigs }}</p></b-col
            >
            <b-col
              md="auto"
              class="d-flex text-nowrap align-items-baseline mr-3 ml-auto flex-column"
            >
              <b-row no-gutters class="align-items-baseline"
                ><h6 class="text-muted mr-1 mb-0">Total Price:</h6>
                <p>${{ item.job_description.total_ex_GST | decimals2 }}</p>
              </b-row>
              <b-row no-gutters
                ><small class="text-muted font-italic">excl GST</small></b-row
              ></b-col
            >
          </b-row>

          <b-row no-gutters class="mt-2 d-flex text-left">
            <b-table
              id="transp-instructions-table"
              striped
              hover
              small
              sort-icon-left
              :sort-by="sortby"
              :sort-desc="asc"
              :fields="isCartonItem(item) ? cartonFields : porkerFields"
              :items="item.loaded_items"
              :busy="loadingTransport == 'list'"
              class=""
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner small class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { pdfUtilsMixin } from '../mixins/pdfUtils'
import PaginationBase from '../components/PaginationBase.vue'
import SideFilter from '../components/SideFilter.vue'

export default {
  mixins: [pdfUtilsMixin],
  components: {
    PaginationBase,
    SideFilter
  },
  name: 'TransportView',
  data() {
    return {
      generatingPDF: '',
      modalAction: 'add',
      sortby: 'weight',
      asc: false,
      cartonFields: [
        { key: 'productname', sortable: true, label: 'Product Name' },
        { key: 'productcode', sortable: true, label: 'Product Code' },
        { key: 'producttype', sortable: true, label: 'Product Type' },
        { key: 'weight', sortable: true, label: 'Weight (kg)' },
        { key: 'cartons' }
      ],
      porkerFields: [
        { key: 'body_no', sortable: true, label: 'Body Number' },
        { key: 'producttype', sortable: true, label: 'Product Type' },
        { key: 'weight', sortable: true, label: 'Weight (kg)' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'transportList',
      'transportListSorted',
      'totalTransport',
      'loadingTransport',
      'perPageTransport',
      'pageNumberTransp',
      'perPageOptions',
      'sortbyTransp',
      'filtersTransp',
      'freightCodeMap',
      'freightNames',
      'transportSummary',
      'startDateTransp',
      'freightFilterInfo'
    ]),
    singleFreight() {
      if (this.filtersTransp.delivery_by.value) return true
      return false
    },
    perPage: {
      get() {
        return this.perPageTransport
      },
      set(value) {
        this.SET_PER_PAGE_TRANSPORT(value)
        this.fetchTransport({ vm: this })
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchTransport',
      'fetchDestinations',
      'fetchFreight',
      'clearFiltersTransp',
      'fetchFreightNames'
    ]),
    ...mapMutations([
      'SET_PAGE_NR_TRANSP',
      'SET_PER_PAGE_TRANSPORT',
      'SET_FILTER_TRANSP',
      'SET_FREIGHT_FILTER_INFO',
      'SET_ACTIVE',
      'SET_USING_VUETIFY'
    ]),
    freightFilterTotal: function (total) {
      if (!total) return ''
      if (isNaN(total)) return 0
      return parseFloat(total).toFixed(2)
    },
    deliveredByMultiple: function (item) {
      if (!item.job_description.transport_cost) return false
      if (item.job_description.transport_cost.length == 1) return false
      return true
    },
    prepareForExport: function (type) {
      const titleDatePart = this.sharedGenerateTitlePart(
        '',
        this.startDateTransp,
        this.filtersTransp,
        true
      )
      switch (type) {
        case 'fullTable':
          this.SharedExportToPDF({
            tableID: 'total-summary',
            totalsID: 'total-pigs',
            splitByOne: 21,
            splitBy: 28,
            totalsFontSize: 'small',
            orientation: 'portrait',
            fileName: `Transport-Sumamry-[${titleDatePart}]`,
            startDate: this.startDateTransp,
            filters: this.filtersTransp,
            title: 'TRANSPORT SUMMARY'
          })
          break
        case 'single':
          this.SharedExportToPDF({
            tableID: 'total-summary',
            totalsID: 'total-pigs',
            splitByOne: 20,
            splitBy: 28,
            totalsFontSize: 'small',
            orientation: 'portrait',
            fileName: `Transport-Sumamry-[${titleDatePart}]`,
            startDate: this.startDateTransp,
            filters: this.filtersTransp,
            title: 'TRANSPORT SUMMARY',
            extraTitle: [this.freightFilterInfo.name, 'h6', '1rem'],
            removeColumnsTotals: [-1, -1],
            removeColumnsMainTable: [-1, -1, -1, -1]
          })
          break
      }
    },
    freightCode: function (value) {
      let arr = value.split(',')
      const mapped = arr.map((el) => {
        if (el) {
          return this.freightCodeMap[el].code
        }
      }, this)
      return mapped.join('/')
    },
    updatePageNr: async function (nr) {
      this.SET_PAGE_NR_TRANSP(nr - 1)
      let res = await this.fetchTransport({ vm: this })
      if (res == 'success')
        this.$root.$emit('bv::refresh::table', 'transp-instrustions-table')
    },
    updateWeekFilter: async function (dateRange) {
      await Promise.all([
        this.SET_FILTER_TRANSP({ field: 'week', value: dateRange.weekNr }),
        this.SET_FILTER_TRANSP({
          field: 'date_start',
          value: dateRange.startDay
        }),
        this.SET_FILTER_TRANSP({ field: 'date_end', value: dateRange.endDay })
      ])
      if (this.filtersTransp.year.value != dateRange.year) {
        this.SET_FILTER_TRANSP({ field: 'year', value: dateRange.year })
      }
      this.fetchTransport({ vm: this })
    },
    updateYearFilter: async function ({ year }) {
      const cleanYear = parseInt(year.trim())
      if (cleanYear == this.filtersTransp.year.value) return
      this.SET_FILTER_TRANSP({ field: 'year', value: cleanYear })
      if (!this.filtersTransp.week.value) return
      this.$refs.transpSideFilter.generateWeekDays(
        this.filtersTransp.week.value,
        cleanYear
      )
    },
    removeFilters: async function () {
      await this.clearFiltersTransp()
      this.fetchTransport({ vm: this })
    }
  },
  async mounted() {
    if (this.freightNames?.length == 0)
      await this.fetchFreightNames({ vm: this })
  },
  async created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE('transport')
    await this.fetchTransport({ vm: this })
    this.fetchDestinations({ vm: this })
    this.fetchFreight({ vm: this })
  }
}
</script>

<style scoped>
.invoice-display:hover,
.freight-display:hover {
  font-weight: 700;
  text-decoration: underline;
}
p {
  margin-bottom: 0;
  line-height: 1.1;
}

.transport-summary-total > td {
  border-top: 1px solid #677787;
}

.font-weight-semibold {
  font-weight: 600;
}
.btn-sm {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
btn-neutral,
.btn-neutral:hover {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.btn-export {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  position: relative;
  text-transform: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
  -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.btn-export:hover {
  -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
</style>
