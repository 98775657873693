<template>
  <font-awesome-icon
    icon="fas fa-truck-arrow-right"
    v-b-tooltip.hover="{
      variant: 'info',
      title: 'Show Trasport Info'
    }"
    class="icon-button"
  />
</template>

<script>
export default {}
</script>

<style scoped>
.icon-button {
  cursor: pointer;
}
.icon-button:hover {
  color: #6d84ed;
}
</style>
