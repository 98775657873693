<template>
  <font-awesome-icon
    v-b-tooltip.hover="{ variant: 'info', title: 'Edit', placement: position }"
    :icon="['fas', 'edit']"
    class="icon-button"
  />
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: 'top'
    }
  }
}
</script>

<style scoped>
.icon-button {
  cursor: pointer;
}
.icon-button:hover {
  color: #007bff;
}
</style>
