import $ from 'jquery'
import html2pdf from 'html2pdf.js'

export const pdfUtilsMixin = {
  methods: {
    createTitle: async function (
      txt,
      el = 'h5',
      marg = '2rem',
      width = '100%'
    ) {
      let title = document.createElement(el)
      title.style.display = 'flex'
      title.style.justifyContent = 'center'
      title.style.width = width
      title.style.marginBottom = marg
      var text = document.createTextNode(txt)
      title.appendChild(text)
      return title
    },
    splitTable: async function (
      fontSizeSelect,
      splitByOne,
      splitBy,
      tableName,
      header = 1
    ) {
      let $mainTable = $(tableName).clone()
      $mainTable.css({ fontSize: fontSizeSelect })
      let miniTableCount = Math.ceil(
        ($mainTable.find('tr').length - splitByOne) / splitBy
      )
      let miniTableArray = []

      if (tableName.includes('#report-table')) {
        // Find row heights ----
        let rowHeights = []
        $(tableName + ' tr').each(function () {
          rowHeights.push($(this).height())
        })
        // Sum row heights
        const cumulativeSum = (
          (sum) => (value) =>
            (sum += value)
        )(0)
        rowHeights = rowHeights.map(cumulativeSum)

        // Divide by pixels required for each page
        rowHeights = rowHeights.map(function (v) {
          return v / 850
        })

        // Find number of rows for each page
        let closestIndexVal = []
        for (
          let i = 1;
          i < Math.ceil(rowHeights[rowHeights.length - 1]) + 1;
          i++
        ) {
          var loc = rowHeights.reduce(function (prev, curr) {
            return Math.abs(curr - i) < Math.abs(prev - i) ? curr : prev
          })
          closestIndexVal.push(rowHeights.indexOf(loc))
        }

        let closestIndex = closestIndexVal
          .slice(1)
          .map((v, i) => v - closestIndexVal[i])

        miniTableCount = closestIndex.length

        for (let i = 0; i < miniTableCount; i++) {
          let $secondTable = $mainTable.clone()
          $secondTable.find('tr').slice(header, closestIndexVal[i]).remove()
          if (i != miniTableCount - 1) {
            $secondTable
              .find('tr')
              .slice(closestIndex[i] + 1)
              .remove()
          }
          miniTableArray[i] = $secondTable[0]
        }
        if (miniTableCount > 0) {
          $mainTable.find('tr').slice(closestIndexVal[0]).remove()
        } else {
          $mainTable
            .find('tr')
            .slice(closestIndexVal[0] + 1)
            .remove()
        }
      } else {
        for (let i = 0; i < miniTableCount; i++) {
          let $secondTable = $mainTable.clone()
          if (i == 0) {
            $secondTable.find('tr').slice(header, splitByOne).remove()
          } else {
            $secondTable
              .find('tr')
              .slice(header, splitByOne + i * splitBy)
              .remove()
          }
          $secondTable
            .find('tr')
            .slice(splitBy + 1)
            .remove()
          if (header != 1) {
            $secondTable.find('tr').slice(0, 1).remove()
          }
          miniTableArray[i] = $secondTable[0]
        }

        $mainTable.find('tr').slice(splitByOne).remove()
      }
      let el2Copy = $mainTable[0].cloneNode(true)
      if (el2Copy.style.display == 'none') {
        el2Copy.style.display = 'table'
      }

      return [el2Copy, miniTableCount, miniTableArray]
    },
    SharedExportToPDF: async function ({
      tableID,
      totalsID = false,
      fontSize = 14,
      totalsFontSize = null,
      splitByOne,
      splitBy,
      headerRow = 1,
      orientation = 'landscape',
      fileName,
      startDate,
      filters = false,
      title: titlePart,
      extraTitle = false,
      removeColumnsTotals = false,
      removeColumnsMainTable = false
    }) {
      let frag = document.createDocumentFragment()
      let el2Copy = null
      if (totalsID) {
        let element2 = null
        element2 = document.getElementById(totalsID)
        el2Copy = element2.cloneNode(true)
        if (totalsFontSize) {
          el2Copy.style.fontSize = totalsFontSize
        }
        if (el2Copy.style.display == 'none') {
          el2Copy.style.display = 'block'
        }
        if (removeColumnsTotals) {
          el2Copy = await this.removeColumns(el2Copy, removeColumnsTotals)
        }
      }
      // Split tables into pages
      this.generatingPDF = tableID
      let [el1Copy, miniTableCount, miniTableArray] = await this.splitTable(
        fontSize,
        splitByOne,
        splitBy,
        `#${tableID}`,
        headerRow
      )

      let title = await this.createTitle('BANGALOW SWEET PORK', 'h2', '1rem')

      let dateTitle = null
      if (titlePart instanceof HTMLHeadingElement) {
        dateTitle = titlePart
      } else {
        dateTitle = await this.createTitle(
          `${this.sharedGenerateTitlePart(titlePart, startDate, filters)}`
        )
      }

      let extraTitleString = ''
      if (extraTitle) {
        extraTitleString = await this.createTitle(
          extraTitle[0],
          extraTitle[1],
          extraTitle[2]
        )
      }
      if (miniTableCount > 0) {
        el1Copy.classList.add('tableClass')
      }
      if (removeColumnsMainTable) {
        el1Copy = await this.removeColumns(el1Copy, removeColumnsMainTable)
      }
      let span = document.createElement('span')
      frag.appendChild(span)
      if (totalsID) {
        span.appendChild(el2Copy)
      }
      span.appendChild(el1Copy)
      for (let i = 0; i < miniTableCount; i++) {
        let el3Copy = miniTableArray[i].cloneNode(true)
        if (removeColumnsMainTable) {
          el3Copy = await this.removeColumns(el3Copy, removeColumnsMainTable)
        }
        if (el3Copy.style.display == 'none') {
          el3Copy.style.display = 'table'
        }
        if (i != miniTableCount - 1) {
          el3Copy.classList.add('tableClass')
        }
        span.appendChild(el3Copy)
      }
      if (extraTitle) {
        span.insertBefore(extraTitleString, span.firstChild)
      }
      span.insertBefore(dateTitle, span.firstChild)
      span.insertBefore(title, span.firstChild)

      span = await this.sharedHideButtons(span)
      span = await this.sharedRemoveSortArrows(span)

      const opt = {
        margin: [0.3, 0.4, 0.8, 0.4],
        filename: `${fileName}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 192,
          scale: 6,
          letterRendering: true,
          useCORS: true
          //width: 1000
        },
        pagebreak: { after: ['.tableClass'] },
        jsPDF: { unit: 'in', format: 'letter', orientation: orientation }
      }

      await html2pdf()
        .set(opt)
        .from(span)
        .toPdf()
        .get('pdf')
        .then(function (pdf) {
          let totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(
              'Page ' + i + ' of ' + totalPages,
              pdf.internal.pageSize.getWidth() / 2 - 0.3,
              pdf.internal.pageSize.getHeight() - 0.5
            )
          }
        })
        .save()

      this.generatingPDF = ''
    },
    sharedHideButtons: async function (el) {
      let buttons = el.getElementsByTagName('button')
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].style.display = 'none'
      }
      return el
    },
    sharedRemoveSortArrows: async function (el) {
      let headers = el.getElementsByTagName('th')
      for (let i = 0; i < headers.length; i++) {
        headers[i].removeAttribute('aria-sort')
      }
      return el
    },
    sharedGenerateTitlePart: function (
      type,
      startDate,
      filters,
      isFileName = false
    ) {
      let week = ''
      let date = this.$options.filters.date(startDate)
      if (filters) {
        if (filters?.date_start.value) {
          date = this.$options.filters.date(filters.date_start.value)
        }
        if (filters?.week.value) {
          week = `WEEK-${filters.week.value} `
        } else {
          if (filters?.date_end.value) {
            date =
              date + ' - ' + this.$options.filters.date(filters?.date_end.value)
          }
        }
      }
      if (isFileName) {
        date = date.replaceAll('/', '-')
      }
      if (type == '') return `${week}${date}`
      return `${type} ${week}${date}`
    },
    removeColumns: async function (table, cells) {
      // Getting the rows in table.
      let row = table.rows

      for (var j = 0; j < row.length; j++) {
        // Deleting the ith cell of each row.
        await cells.forEach((el) => {
          row[j].deleteCell(el)
        })
      }
      return table
    }
  }
}
