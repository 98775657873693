<script setup>
import BaseDateSelect from '@/components/base/BaseDateSelect.vue'
import OrderItemsEditTable from '@/components/orders-page/OrderItemsEditTable.vue'
import BaseSelectCustomer from '@/components/base/BaseSelectCustomer.vue'

import { ref, computed, defineProps, watch } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { useFreight } from '@/utils/useFreight'
import {
  DEFAULT_CARTON_CODE,
  DEFAULT_PORKER_CODE,
  PORKER_CODE_MAX
} from '@/constants/product'
import { getCurrentDay } from '@/utils/DateHelpers'
import { CARTON, PORKER } from '@/constants/general'

const props = defineProps([
  'edit',
  'orderDetails',
  'buttonSmall',
  'selectedDate'
])

const initialOrder = computed(() => ({
  date: props.selectedDate ?? getCurrentDay(),
  comment: '',
  customer_id: null,
  type: CARTON,
  status: 'OPEN',
  ordered_items: []
}))
const store = useStore()
const { getTransport } = useFreight(store)

const dialog = ref(false)
const order = ref(JSON.parse(JSON.stringify(initialOrder.value)))

const loadingOrders = computed(() => store.getters.loadingOrders)
const isOrderLoading = computed(() => {
  if (
    loadingOrders.value === 'create' ||
    loadingOrders.value.includes('update')
  )
    return true
  return false
})
const isFormValid = computed(() => {
  if (order.value.customer_id === null) return false
  if (order.value?.ordered_items.length === 0) return false

  let isInvalid = order.value.ordered_items.some(
    (item) => !item.primary_transport
  )
  if (isInvalid) return !isInvalid

  isInvalid = order.value.ordered_items.some(
    (item) => !item.productcode || item.productcode === DEFAULT_CARTON_CODE
  )
  return !isInvalid
})
const cartonItems = computed(() => filterItemsByType(CARTON))
const porkerItems = computed(() => filterItemsByType(PORKER))

let orderBackup = null

const filterItemsByType = (type) => {
  return order.value.ordered_items.filter((item) => {
    if (item.temp_type === type) return true
    if (!item.productcode) {
      if (item.product) {
        return type === CARTON
          ? item.product.code > PORKER_CODE_MAX
          : item.product.code <= PORKER_CODE_MAX
      }
      return false
    }
    return type === CARTON
      ? item.productcode > PORKER_CODE_MAX
      : item.productcode <= PORKER_CODE_MAX
  })
}

const calculateLastDate = (ordered_items, type, dateType) => {
  let items = []
  if (type === CARTON) {
    items = ordered_items.filter((item) => item.productcode > PORKER_CODE_MAX)
  } else {
    items = ordered_items.filter((item) => item.productcode <= PORKER_CODE_MAX)
  }
  const lastItem = items.at(-1)
  return lastItem ? lastItem[dateType] : null
}

const addOrderItem = (type) => {
  const code = type === PORKER ? DEFAULT_PORKER_CODE : DEFAULT_CARTON_CODE

  const { date, customer_id, id, ordered_items } = order.value
  const loadout_date =
    calculateLastDate(ordered_items, type, 'loadout_date') || date
  const boning_date =
    calculateLastDate(ordered_items, type, 'boning_date') || date

  const { primary, subsequent } = getTransport(customer_id, loadout_date)

  order.value.ordered_items.push({
    order_id: id,
    quantity: 1,
    productcode: code,
    primary_transport: primary,
    subsequent_transport: subsequent,
    boning_date,
    loadout_date,
    temp_type: type // Used to help filter correct items for new order items
  })
}

const addPorkerItem = () => addOrderItem(PORKER)
const addCartonItem = () => addOrderItem(CARTON)

const submitOrder = async () => {
  let res = 'error'
  if (props.edit) {
    res = await store.dispatch('updateOrder', {
      payload: order.value,
      onSuccess: 'insert'
    })
  } else {
    res = await store.dispatch('createOrder', { payload: order.value })
  }
  if (res !== 'success') {
    return
  }
  dialog.value = false
  if (!props.edit) {
    store.dispatch('fetchOrders')
  }
}

const resetValues = () => {
  if (props.edit) {
    order.value = JSON.parse(JSON.stringify(orderBackup))
  } else {
    order.value = JSON.parse(JSON.stringify(initialOrder.value))
  }
}

const handleDelete = () => {
  order.value.ordered_items = order.value.ordered_items.filter(
    (item) => !item.delete
  )
}

const closeDialog = () => {
  resetValues()
  dialog.value = false
}

const handleClickOutside = () => {
  if (props.edit) {
    resetValues()
  }
}

const updateCustomer = (value) => {
  order.value.customer_id = value

  order.value.ordered_items.forEach((item) => {
    const { primary, subsequent } = getTransport(value, item.loadout_date)
    item.primary_transport = primary
    item.subsequent_transport = subsequent
  })
}

watch(
  () => props.orderDetails,
  (newOrderDetails) => {
    if (!props.edit) return
    if (JSON.stringify(order.value) !== JSON.stringify(newOrderDetails)) {
      orderBackup = JSON.parse(JSON.stringify(newOrderDetails))
      order.value = JSON.parse(JSON.stringify(newOrderDetails))
    }
  },
  { deep: true, immediate: true }
)
</script>

<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="1400px"
    @click:outside="handleClickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="edit"
        small
        text
        color="info"
        class="mr-2"
        v-bind="attrs"
        v-on="on"
        ><font-awesome-icon icon="fa-solid fa-edit" class="mr-1" />Edit</v-btn
      >
      <v-btn
        v-else
        color="primary"
        :small="buttonSmall ? true : false"
        dark
        v-bind="attrs"
        v-on="on"
        class="mr-2 text-capitalize"
      >
        + Create New Order
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ !edit ? 'Create New ' : 'Edit ' }} Order</span>
      </v-card-title>
      <v-card-text>
        <v-row class="flex-column" no-gutters>
          <div class="my-2">
            <BaseSelectCustomer
              label="Customer"
              :customer="order.customer_id"
              @update:customer="updateCustomer"
            />
          </div>
          <BaseDateSelect
            :date="order.date"
            label="Processing Day"
            @update:date="order.date = $event"
          />
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-btn
            x-small
            outlined
            color="primary"
            class="me-2"
            @click="addPorkerItem"
            >+ Add PORKER item</v-btn
          ></v-row
        >
        <OrderItemsEditTable
          v-if="porkerItems.length > 0"
          :items="porkerItems"
          :customerId="order.customer_id"
          :item-type="PORKER"
          :min-date="order.date"
          @delete="handleDelete"
        />
        <v-row no-gutters class="mt-4">
          <v-btn x-small outlined color="primary" @click="addCartonItem"
            >+ Add CARTON item</v-btn
          ></v-row
        >
        <OrderItemsEditTable
          v-if="cartonItems.length > 0"
          :items="cartonItems"
          :customerId="order.customer_id"
          :item-type="CARTON"
          :min-date="order.date"
          @delete="handleDelete"
        />
        <v-row class="font-italic text-left mt-2">
          <small class="text-danger">* </small>required fields
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="resetValues" class="me-2">
          Reset
        </v-btn>
        <v-tooltip bottom :disabled="isFormValid" color="white">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                color="primary"
                @click="submitOrder"
                :disabled="!isFormValid"
                :loading="isOrderLoading"
              >
                Submit
              </v-btn>
            </div>
          </template>
          <span class="red--text">Please fill required fields!</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
