<script setup>
import OrdersFilter from '@/components/orders-page/OrdersFilter.vue'
import WeekdaysTabs from '@/components/orders-page/WeekdaysTabs.vue'
import SingleOrder from '@/components/orders-page/SingleOrder.vue'
import ChipMenu from '@/components/base/ChipMenu.vue'
import OrderFormDialog from '@/components/orders-page/OrderFormDialog.vue'
import PaginationBase from '@/components/PaginationBase.vue'
import OrderTotalsTable from '@/components/orders-page/OrderTotalsTable.vue'
import OrderTotalsSummary from '@/components/orders-page/OrderTotalsSummary.vue'
import BaseLoadingSpinner from '@/components/base/BaseLoadingSpinner.vue'
import SummaryWeekChips from '../components/orders-page/SummaryWeekChips.vue'
import BaseAlert from '@/components/base/BaseAlert.vue'

import { ref, getCurrentInstance, computed, watch } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { DEFAULT_VIEW, PAGE_VIEWS, CLASSIC_VIEW } from '@/constants/general'
import { ORDERS } from '@/constants/pagination'

const vm = getCurrentInstance().proxy
const store = useStore()

// STORE - getters
const orders = computed(() => store.getters.orders)
const totalOrders = computed(() => store.getters.totalOrders)
const currentView = computed(() => store.getters.orderPageView)
const loadingOrders = computed(() => store.getters.loadingOrders)
const perPageOptions = computed(() => store.getters.perPageOptions)
const perPageOrders = computed(() => store.getters.perPageOrders)
const pageNumberOrders = computed(() => store.getters.pageNumberOrders)

const perPage = computed({
  get: () => perPageOrders.value,
  set: (value) => {
    store.commit('SET_PER_PAGE_ORDERS', value)
    store.dispatch('fetchOrders')
  }
})

const totalPigs = ref('0')

const updateView = (newView) => {
  store.dispatch('changeOrderPageview', { payload: newView })
}

const updatePageNr = async (nr) => {
  store.commit('SET_PAGE_NR_ORDERS', nr - 1)
  await store.dispatch('fetchOrders')
}

watch(
  () => orders.value,
  () => {
    store.commit('UPDATE_SUMMARY')
  },
  { deep: true }
)

// ON CREATED
store.commit('SET_USING_VUETIFY', true)
store.commit('SET_ACTIVE', ORDERS)
store.dispatch('fetchProductNames', { vm })
store.dispatch('fetchOrders')
store.dispatch('fetchCustomerName')
</script>

<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <OrdersFilter />
        <div class="content-page mt-0">
          <v-row no-gutters class="my-2 align-center">
            <div class="page-title-box d-flex">
              <h4 class="page-title">Orders</h4>
            </div>
            <ChipMenu
              :items="PAGE_VIEWS"
              :active="currentView"
              @itemClick="updateView"
              :chip-class="'ms-auto me-4'"
            />
            <v-btn
              @click="prepareForExport()"
              small
              color="warning"
              :loading="generatingPDF === 'total-summary'"
            >
              <font-awesome-icon
                icon="fas fa-file-export"
                class="mr-2"
              />Export</v-btn
            >
          </v-row>
          <v-row
            class="mt-1 mb-3"
            no-gutters
            v-if="currentView === CLASSIC_VIEW"
          >
            <OrderFormDialog />
            <div class="d-flex align-items-center">
              Total Orders:
              <span class="font-weight-bold ml-2">{{ totalOrders }}</span>
            </div>
            <div class="ms-auto d-flex align-center">
              <div class="mr-2 d-flex align-items-center">Orders per page:</div>
              <b-select
                v-model="perPage"
                :options="perPageOptions"
                class="d-inline-block w-auto mr-4"
              ></b-select>
              <PaginationBase
                :total="totalOrders"
                :perPage="perPageOrders"
                :current="pageNumberOrders + 1"
                @updatePage="updatePageNr"
              />
            </div>
          </v-row>

          <!-- ---------------- Summary section ------------------- -->
          <b-card class="mb-3">
            <b-row no-gutters class="align-items-baseline">
              <b-col cols="5" class="d-flex flex-row align-items-baseline">
                <h4 class="header-title mb-0 w-700 text-light-blue text-left">
                  PORKER Orders Summary
                </h4>
                <b-badge
                  variant="light"
                  class="align-self-top ml-auto"
                  v-b-toggle.order-summary-collapse
                  @click.prevent
                  >Show Details...</b-badge
                ></b-col
              >
            </b-row>
            <SummaryWeekChips v-if="currentView === DEFAULT_VIEW" />

            <v-row
              class="justify-content-md-start py-2 mt-0 align-items-start flex-column"
              v-if="loadingOrders !== 'list'"
            >
              <OrderTotalsTable :total="totalPigs" />
              <v-col
                cols="12"
                md="10"
                lg="5"
                class="d-flex text-nowrap align-baseline"
              >
                <h6 class="text-muted mr-1 mb-0 font-italic">Total Pigs:</h6>
                <b-input
                  v-model="totalPigs"
                  type="number"
                  size="sm"
                  class="custom-width me-4"
                  debounce="300"
                ></b-input> </v-col
            ></v-row>
            <v-row v-else>
              <v-col cols="12" md="10" lg="5">
                <v-skeleton-loader
                  type="list-item-three-line, card-heading"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <OrderTotalsSummary />
            <WeekdaysTabs
              v-if="currentView === DEFAULT_VIEW"
              :orders="orders"
            />
            <template v-else>
              <BaseLoadingSpinner
                text="Orders"
                v-if="loadingOrders === 'list'"
              />
              <template v-else>
                <v-card-text v-for="order in orders" :key="order.id">
                  <SingleOrder :orderDetails="order" />
                </v-card-text>
              </template>
            </template>
          </b-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { pdfUtilsMixin } from '../mixins/pdfUtils'

export default {
  name: 'OrdersView',
  mixins: [pdfUtilsMixin],
  data() {
    return {
      generatingPDF: ''
    }
  },
  computed: {
    ...mapGetters(['startDateOrders', 'filtersOrders'])
  },
  methods: {
    prepareForExport: function () {
      const titleDatePart = this.sharedGenerateTitlePart(
        '',
        this.startDateOrders,
        this.filtersOrders,
        true
      )
      this.SharedExportToPDF({
        tableID: 'total-summary',
        totalsID: 'pigs-table',
        splitByOne: 23,
        splitBy: 31,
        fontSize: 12,
        totalsFontSize: 'small',
        orientation: 'portrait',
        fileName: `BSP-Allocations-[${titleDatePart}]`,
        startDate: this.startDateOrders,
        filters: this.filtersOrders,
        title: 'ALLOCATIONS'
      })
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
.custom-width {
  width: 75px;
}
</style>
