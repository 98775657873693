import axios from 'axios'

import { PER_PAGE_OPTIONS } from '@/constants/pagination'

const state = {
  customerList: null,
  customerNames: [],
  customerCodeMap: null,
  loadingCustomers: '',

  //pagination
  totalCust: 0,
  perPageCust: PER_PAGE_OPTIONS[0],
  pageNrCust: 0,

  //sorting
  sortbyCust: 'company_name',
  ascCust: true,

  //filters
  filtersCust: {
    customer_name: { type: 'text', label: 'Customer Name', value: '' },
    is_active: {
      type: 'checkbox',
      label: 'Customer Status',
      value: [],
      options: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false }
      ]
    },
    code: { type: 'text', label: 'Code', value: '' },
    phone: { type: 'text', label: 'Phone Number', value: '' },
    type: { type: 'text', label: 'Type', value: '' },
    contact: { type: 'text', label: 'Contact Name', value: '' }
  },
  sortMapCust: {
    company_name: 'companies.name'
  }
}
const getters = {
  customers: (state) => state.customerList,
  customerNames: (state) => state.customerNames,
  customerCodeMap: (state) => state.customerCodeMap,

  loadingCustomers: (state) => state.loadingCustomers,
  totalCustomers: (state) => state.totalCust,
  perPageCustomers: (state) => state.perPageCust,
  pageNumberCust: (state) => state.pageNrCust,
  sortbyCust: (state) => state.sortbyCust,
  sortMapCust: (state) => state.sortMapCust,
  ascCust: (state) => state.ascCust,
  filtersCust: (state) => state.filtersCust,
  offsetCust: (state) => {
    return state.perPageCust * state.pageNrCust
  }
}
const actions = {
  async fetchCustomers({ commit, dispatch, getters }, { vm }) {
    commit('SET_LOADING_CUSTOMERS', 'list')
    const sort_by =
      getters.sortMapCust[getters.sortbyCust] ?? getters.sortbyCust
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'customers/',
        parameters: {
          limit: getters.perPageCustomers,
          offset: getters.offsetCust,
          sort_by,
          ascending: getters.ascCust
        },
        filters: getters.filtersCust
      },
      { root: true }
    )
    commit('SET_LOADING_CUSTOMERS', '')
    if (!res.error) {
      const result = res.data.map(
        ({ company, company: { name: company_name }, ...rest }) => ({
          company,
          company_name,
          ...rest
        })
      )
      commit('SET_CUSTOMER_LIST', result)
      commit('SET_TOTAL_CUST', res.total)
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'List of Customers',
          messagePrefix: 'Error while loading customers! '
        },
        { root: true }
      )
    }
  },
  async fetchCustomerNames({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'customers/customerlist?sort_by=name'
    try {
      commit('SET_LOADING_CUSTOMERS', 'names')
      let res = await axios.get(URI)
      commit('SET_CUSTOMER_NAMES', res.data.data)
      dispatch('mapCustomerCode')
      commit('SET_LOADING_CUSTOMERS', '')
    } catch (error) {
      commit('SET_LOADING_CUSTOMERS', '')
      const messagePrefix = 'Error while loading customer names! '
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      } else {
        dispatch(
          'handleError',
          {
            vm,
            error,
            title: 'List of Customer Names',
            messagePrefix
          },
          { root: true }
        )
      }
    }
  },
  mapCustomerCode({ getters, commit }) {
    let map = new Object()
    getters.customerNames.forEach((el) => {
      map[el.company_id] = { code: el.code, name: el.name }
    })
    commit('SET_CUSTOMER_CODE_MAP', map)
  },
  async createCustomer({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'customers/'
    try {
      commit('SET_LOADING_CUSTOMERS', 'create')
      await axios.post(URI, payload)
      commit('SET_LOADING_CUSTOMERS', '')
      vm.$bvToast.toast(
        'New Customer created successfuly! ' + payload.company.name,
        {
          title: 'Create Customer',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_CUSTOMERS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Customer Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async updateCustomer({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'customers/' + payload.id
    try {
      commit('SET_LOADING_CUSTOMERS', 'create')
      await axios.patch(URI, payload)
      commit('SET_LOADING_CUSTOMERS', '')
      vm.$bvToast.toast(
        'Customer: ' +
          payload.company.name +
          ' information updated successfuly!',
        {
          title: 'Update Customer',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_CUSTOMERS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Customer Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async deleteCustomer({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'customers/' + payload.id
    try {
      commit('SET_LOADING_CUSTOMERS', 'delete')
      await axios.delete(URI)
      commit('SET_LOADING_CUSTOMERS', '')
      vm.$bvToast.toast(
        'Customer deleted successfuly! ' + payload.company.name,
        {
          title: 'Delete Customer',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_CUSTOMERS', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Customer Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async clearFiltersCust({ commit }) {
    commit('SET_FILTER_CUST', { field: 'customer_name', value: '' })
    commit('SET_FILTER_CUST', { field: 'is_active', value: [] })
    commit('SET_FILTER_CUST', { field: 'code', value: '' })
    commit('SET_FILTER_CUST', { field: 'phone', value: '' })
    commit('SET_FILTER_CUST', { field: 'type', value: '' })
    commit('SET_FILTER_CUST', { field: 'contact', value: '' })
  }
}
const mutations = {
  SET_CUSTOMER_LIST: (state, payload) => (state.customerList = payload),
  SET_LOADING_CUSTOMERS: (state, payload) => (state.loadingCustomers = payload),
  SET_CUSTOMER_NAMES: (state, payload) => (state.customerNames = payload),
  SET_CUSTOMER_CODE_MAP: (state, payload) => (state.customerCodeMap = payload),

  SET_TOTAL_CUST: (state, payload) => (state.totalCust = payload),
  SET_PAGE_NR_CUST: (state, payload) => (state.pageNrCust = payload),
  SET_PER_PAGE_CUSTOMERS: (state, payload) => (state.perPageCust = payload),
  SET_SORT_BY_CUST: (state, payload) => (state.sortbyCust = payload),
  SET_ASC_CUST: (state, payload) => (state.ascCust = payload),
  SET_FILTER_CUST: (state, payload) => {
    if (
      state.filtersCust[payload.field].type == 'checkbox' &&
      payload.value.length == 0
    ) {
      while (state.filtersCust.is_active.value.length > 0) {
        state.filtersCust.is_active.value.pop()
      }
      return
    }
    state.filtersCust[payload.field].value = payload.value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
