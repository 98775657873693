<template>
  <b-modal id="customer-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <label for="company-name"
        >Company Name:<span class="text-danger">*</span></label
      >
      <b-form-input
        id="company-name"
        v-model="customer.company.name"
        type="text"
        placeholder="Enter company name"
        required
        class=""
      ></b-form-input>

      <b-row no-gutters class="align-items-baseline"
        ><label
          class="mt-3"
          v-if="customer.company.extra.alias.length > 0"
          for="company-alias-0"
          >Name Alias:</label
        ><small
          class="pointer hover-blue"
          :class="
            customer.company.extra.alias.length > 0 ? 'ml-auto mt-3' : 'mt-1'
          "
          @click="customer.company.extra.alias.push('')"
          ><font-awesome-icon icon="fas fa-plus" class="mr-1" />
          <b>Add new alias</b></small
        ></b-row
      ><b-row
        v-for="(item, key) in customer.company.extra.alias"
        :key="key"
        no-gutters
        class="justify-content-right"
        ><b-col class="mr-2">
          <b-form-input
            :id="`company-alias-${key}`"
            v-model="customer.company.extra.alias[key]"
            type="text"
            placeholder="Enter name alias"
            class="mb-1"
          ></b-form-input
        ></b-col>
        <b-col class="col-auto"
          ><small class="pointer hover-red" @click="removeAlias(key)"
            ><b>Remove</b></small
          ></b-col
        ></b-row
      >

      <b-form-checkbox
        v-if="type == 'edit'"
        v-model="customer.is_active"
        class="mt-3"
        >Active</b-form-checkbox
      >

      <b-form-group
        id="customer-code-group"
        label="Customer Code:"
        label-for="customer-code"
        class="mt-3"
      >
        <b-form-input
          id="customer.code"
          v-model="customer.code"
          placeholder="Enter code"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="customer-type-group"
        label="Customer Type:"
        label-for="customer-type"
      >
        <b-form-input
          id="customer-type"
          v-model="customer.type"
          placeholder="Enter Type"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="customer-phone-group"
        label="Customer Phone:"
        label-for="customer-phone"
      >
        <b-form-input
          id="customer-phone"
          v-model="customer.company.contact.phone"
          placeholder="Enter phone"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="customer-contact-name-group"
        label="Customer Contact Name:"
        label-for="customer-contact-name"
      >
        <b-form-input
          id="customer-contact-name"
          v-model="customer.company.contact.contact"
          placeholder="Enter Contact Name"
        ></b-form-input>
      </b-form-group>
      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingCustomers == 'create'"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'NewCustomerModal',
  props: {
    type: String
  },
  data() {
    return {
      customer: {
        company: {
          name: '',
          contact: { phone: '', contact: '' },
          extra: { alias: [] }
        },
        code: '',
        is_active: true,
        type: ''
      },
      tempInfo: null
    }
  },
  computed: {
    ...mapGetters(['loadingCustomers']),
    actionString() {
      if (this.type == 'add') return 'Create New Customer'
      return 'Update Customer Information'
    }
  },
  methods: {
    ...mapActions(['createCustomer', 'fetchCustomers', 'updateCustomer']),
    cleanAliasArray: async function () {
      this.customer.company.extra.alias =
        this.customer.company.extra.alias.filter((e) => e.trim())
      return
    },
    removeAlias: function (id) {
      this.customer.company.extra.alias.splice(id, 1)
    },
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      await this.cleanAliasArray()
      if (this.type == 'add') {
        res = await this.createCustomer({
          payload: this.customer,
          vm: this
        })
      }
      if (this.type == 'edit') {
        res = await this.updateCustomer({
          payload: this.customer,
          vm: this
        })
      }
      if (res === 'success') {
        this.$bvModal.hide('customer-modal')
        this.fetchCustomers({ vm: this })
      }
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: function () {
      this.resetValues()
      this.$bvModal.show('customer-modal')
    },
    editCustomer: function (info) {
      this.tempInfo = info
      this.restoreValues()
      this.$bvModal.show('customer-modal')
    },
    // Reset customer form values
    resetValues: function () {
      if (Object.prototype.hasOwnProperty.call(this.customer, 'id'))
        delete this.customer.id
      this.customer.company.name = ''
      this.customer.code = ''
      this.customer.type = ''
      this.customer.company.contact.phone = ''
      this.customer.company.extra.alias = []
      this.customer.is_active = true
      this.customer.company.contact.contact = ''
    },
    // Restore customer form values to initial
    restoreValues: function () {
      this.customer.id = this.tempInfo.id
      this.customer.is_active = this.tempInfo.is_active
      this.customer.company.name = this.tempInfo.company.name
      this.customer.code = this.tempInfo.code
      this.customer.type = this.tempInfo.type
      this.customer.company.contact.phone = this.tempInfo.company.contact
        ? this.tempInfo.company.contact.phone
        : ''
      this.customer.company.extra.alias = this.tempInfo.company.extra
        ? this.tempInfo.company.extra.alias
        : []
      this.customer.company.contact.contact = this.tempInfo.company.contact
        ? this.tempInfo.company.contact.contact
        : ''
    }
  }
}
</script>

<style></style>
