<template>
  <div>
    <b-row no-gutters class="mt-3 d-flex align-items-center">
      <b-col cols="2" class="text-left">
        <div class="mr-2">{{ getDisplayName(fileName) }}</div>
      </b-col>
      <b-col cols="8" class="">
        <b-file
          :id="fileName"
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          class=""
        ></b-file>
      </b-col>
      <b-col cols="2" class="text-left">
        <b-button @click="upload" class="ml-2 text-white"
          ><b-spinner small v-if="fileLoading(fileName)"></b-spinner>
          Upload</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'FileInput',
  props: {
    fileName: String
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getFileByName', 'getDisplayName', 'fileLoading']),
    file: {
      set(file) {
        this.SET_FILE({ file: file, name: this.fileName })
      },
      get() {
        return this.getFileByName(this.fileName)
      }
    }
  },
  methods: {
    ...mapMutations(['SET_FILE']),
    ...mapActions(['uploadFile']),
    upload: function () {
      this.uploadFile({ fileName: this.fileName, vm: this })
    }
  }
}
</script>
