import { PER_PAGE_MAX } from '@/constants/pagination'
import { getISOWeekNumber, getYear, getWeekBounds } from '@/utils/DateHelpers'

function getLastWeek() {
  return getISOWeekNumber() - 1
}
const { start, end } = getWeekBounds(getLastWeek())
async function getPigCat(pig, fees) {
  const cat = pig.specification.cat
  const weight = pig.specification.HSCW
  switch (cat) {
    case 'SUK':
      return fees.SUK.config.name
    case 'SP':
      return fees.SP.config.name
    case 'BP':
      return fees.BP.config.name
    default:
      break
  }
  if (
    weight >= fees.XSMALL.config.minweight &&
    weight <= fees.XSMALL.config.maxweight
  ) {
    return 'XSMALL'
  }
  if (
    weight > fees.SMALL.config.minweight &&
    weight <= fees.SMALL.config.maxweight
  ) {
    return 'SMALL'
  }
  if (
    weight > fees.MEDIUM.config.minweight &&
    weight <= fees.MEDIUM.config.maxweight
  ) {
    return 'MEDIUM'
  }
  if (
    weight > fees.LARGE.config.minweight &&
    weight <= fees.LARGE.config.maxweight
  ) {
    return 'LARGE'
  }
  if (weight > fees.XLARGE.config.minweight) {
    return 'XLARGE'
  }
  return 'OTHER'
}

async function generateCostList(procFees) {
  let costList = new Object()
  procFees.forEach((element) => {
    costList[element.name] = { qty: 0, total: 0, config: element }
  })
  return costList
}

const state = {
  procCostList: {},
  loadingProcCost: '',

  //pagination
  perPageProcCost: PER_PAGE_MAX,
  pageNrProcCost: 0,

  //sorting
  sortbyProcCost: 'date',
  ascProcCost: false,

  //filters
  filtersProcCost: {
    date_start: {
      type: 'date',
      label: 'Start Date',
      value: start,
      readOnly: true
    },
    date_end: {
      type: 'date',
      label: 'End Date',
      value: end,
      readOnly: true
    },
    week: {
      type: 'week',
      label: 'Week Number',
      value: getLastWeek()
    },
    year: { type: 'year', value: getYear(), hidden: true }
  },

  missingCostTypeError: ''
}
const getters = {
  procCostList: (state) => state.procCostList,
  loadingProcCost: (state) => state.loadingProcCost,
  perPageProcCost: (state) => state.perPageProcCost,
  sortbyProcCost: (state) => state.sortbyProcCost,
  ascProcCost: (state) => state.ascProcCost,
  filtersProcCost: (state) => state.filtersProcCost,
  offsetProcCost: (state) => {
    return state.perPageProcCost * state.pageNrProcCost
  },
  missingCostTypeError: (state) => state.missingCostTypeError
}
const actions = {
  async fetchProcCost({ getters, commit, dispatch }, { vm }) {
    commit('SET_LOADING_PROC_COST', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'piginfo/',
        parameters: {
          limit: getters.perPageProcCost,
          offset: getters.offsetProcCost,
          sort_by: getters.sortbyProcCost,
          ascending: getters.ascProcCost
        },
        filters: getters.filtersProcCost
      },
      { root: true }
    )
    if (!res.error) {
      try {
        const procCostList = await dispatch('parsePigInfo', {
          data: res.data,
          effectiveDate: getters.filtersProcCost.date_start,
          vm: vm
        })
        commit('SET_PROC_COST_LIST', procCostList)
      } catch (error) {
        dispatch(
          'handleError',
          {
            vm: vm,
            error: error,
            title: 'Processing Costs',
            messagePrefix: ''
          },
          { root: true }
        )
      } finally {
        commit('SET_LOADING_PROC_COST', '')
      }
    } else {
      commit('SET_LOADING_PROC_COST', '')
      if (res.error) {
        dispatch(
          'handleError',
          {
            vm: vm,
            error: res.error,
            title: 'Processing Costs',
            messagePrefix: 'Error while loading Pig Information! '
          },
          { root: true }
        )
      }
    }
  },
  async parsePigInfo({ dispatch, commit }, { data, vm, effectiveDate }) {
    const res = await dispatch('fetchProcPrice', {
      vm: vm,
      effectiveDate: effectiveDate
    })
    if (res === 'error')
      throw new Error('Error while loading processing price list')

    let fees = res

    let costList = await generateCostList(fees)
    commit('SET_MISSING_COST_TYPE_ERROR', '')
    let costMissing = await dispatch('checkForMissingCost', costList)

    if (costMissing) {
      const costError = `Cost for ${costMissing} is missing! Please create new cost.`
      commit('SET_MISSING_COST_TYPE_ERROR', costError)
      throw new Error(
        'Error while loading processing price list - missing cost.'
      )
    }

    for (const pig of data) {
      const cat = await getPigCat(pig, costList)

      costList.GRADING.qty += pig.specification.HSCW
      costList.GRADING.total +=
        costList.GRADING.config.cost * pig.specification.HSCW

      costList.LEVY.qty += 1
      costList.LEVY.total += costList.LEVY.config.cost

      if (cat != 'OTHER') {
        costList[cat].qty += 1
        costList[cat].total += costList[cat].config.cost
      }
    }
    return costList
  },
  async checkForMissingCost({ rootGetters }, costList) {
    let missingType = []
    rootGetters.processingCostTypes.forEach((type) => {
      if (!costList[type]) missingType.push(type)
    })
    return missingType.join(', ')
  }
}

const mutations = {
  SET_PROC_COST_LIST: (state, payload) => (state.procCostList = payload),
  SET_LOADING_PROC_COST: (state, payload) => (state.loadingProcCost = payload),
  SET_FILTER_PROC_COST: (state, payload) => {
    state.filtersProcCost[payload.field].value = payload.value
  },
  SET_MISSING_COST_TYPE_ERROR: (state, payload) =>
    (state.missingCostTypeError = payload)
}

export default {
  state,
  getters,
  actions,
  mutations
}
