<script setup>
import ProductProcessButton from '@/components/ProductProcessButton.vue'

import {
  computed,
  defineProps,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue'
import { formatKilograms } from '@/helpers/stringHelpers'
import { useStore } from '@/utils/VuexHelpers'
import {
  ALLOWED_BARCODE_LEGTHS,
  BARCODE_TYPE_BSP,
  BARCODE_TYPE_GS1,
  BARCODE_TYPE_MAP,
  BSP_BARCODE_LENGTH,
  GS1_BARCODE_LENGTH
} from '@/constants/product'
import { formatScannerDate } from '@/utils/DateHelpers'

const props = defineProps({
  currentIndex: {
    type: Boolean,
    required: true
  }
})

const vm = getCurrentInstance().proxy
const store = useStore()
const productNames = computed(() => store.getters.productNames)
const loadingProducts = computed(() => store.getters.loadingProducts)
const locationStorage = computed(() => store.getters.locationStorage)
const locationCustomer = computed(() => store.getters.locationCustomer)
const loadingStock = computed(() => store.getters.loadingStock)
const currentBarcode = ref('')
const location = ref('')
const docket = ref('')
const scannedItems = ref([])
const scanningAction = ref('DELIVERED')
const productFields = [
  { key: 'date', sortable: true },
  { key: 'product' },
  { key: 'code', thClass: ['text-right'], tdClass: ['text-right'] },
  { key: 'barcode', thClass: ['text-right'], tdClass: ['text-right'] },
  {
    key: 'carton_no',
    label: 'Carton Number',
    thClass: ['text-right'],
    tdClass: ['text-right']
  },
  { key: 'weight', thClass: ['text-right'], tdClass: ['text-right'] },
  { key: 'location' },
  { key: 'docket', thClass: ['text-right'], tdClass: ['text-right'] },
  { key: 'actions', thClass: ['text-right'], tdClass: ['text-right'] }
]

const totalWeight = computed(() => {
  return scannedItems.value.reduce((total, item) => {
    return (total += item.weight)
  }, 0)
})
const locationOptions = computed(() => {
  switch (scanningAction.value.toLowerCase()) {
    case 'instorage':
      return locationStorage.value
    case 'delivered':
      return locationCustomer.value
    default:
      return []
  }
})

const onBarcodeScanned = (event) => {
  if (props.currentIndex === false) return
  const keycode = event.keyCode || event.which

  if (keycode >= 48 && keycode <= 57) {
    // A number key was pressed
    currentBarcode.value += String.fromCharCode(keycode)
    return
  }

  if (keycode === 13) {
    // Enter key was pressed
    processBarcode()
  }
}

const processBarcode = () => {
  const currentBarcodeLength = currentBarcode.value.length
  if (!ALLOWED_BARCODE_LEGTHS.includes(currentBarcodeLength)) {
    showWarning(
      `Barcode must be ${GS1_BARCODE_LENGTH} or ${BSP_BARCODE_LENGTH} digits long!`
    )
    currentBarcode.value = ''
    return
  }

  let barcodeType = ''
  let attribute = ''

  if (currentBarcodeLength === GS1_BARCODE_LENGTH) {
    barcodeType = BARCODE_TYPE_GS1
    attribute = 'GS1 code'
  }
  if (currentBarcodeLength === BSP_BARCODE_LENGTH) {
    barcodeType = BARCODE_TYPE_BSP
    attribute = 'code'
  }

  let itemInfo = extractBarcodeInfo(barcodeType)
  if (!itemInfo) {
    showWarning(
      `Can't find product with this ${attribute}: ${currentBarcode.value.slice(
        BARCODE_TYPE_MAP[barcodeType].code.start,
        BARCODE_TYPE_MAP[barcodeType].code.end
      )}. Please create product first!`,
      'danger'
    )
    currentBarcode.value = ''
    return
  }

  const duplicate = scannedItems.value.some(
    (item) => item.barcode === currentBarcode.value
  )
  if (duplicate) {
    showWarning('The product you scanned is already in the stock list!')
  } else {
    itemInfo.location = location.value
    itemInfo.docket = docket.value
    scannedItems.value.unshift(itemInfo)
  }
  currentBarcode.value = ''
}

const extractBarcodeInfo = (type = BARCODE_TYPE_BSP) => {
  const productCode = currentBarcode.value.slice(
    BARCODE_TYPE_MAP[type].code.start,
    BARCODE_TYPE_MAP[type].code.end
  )

  let itemDetails = null
  if (type === BARCODE_TYPE_BSP) {
    const code = parseInt(productCode)
    itemDetails = findItem(code, type)
  } else {
    itemDetails = findItem(productCode, type)
  }
  if (!itemDetails) {
    return null
  }

  const { name, id, code } = itemDetails
  const weight =
    parseFloat(
      currentBarcode.value.slice(
        BARCODE_TYPE_MAP[type].weight.start,
        BARCODE_TYPE_MAP[type].weight.end
      )
    ) / 100
  const date = formatScannerDate(
    currentBarcode.value.slice(
      BARCODE_TYPE_MAP[type].date.start,
      BARCODE_TYPE_MAP[type].date.end
    )
  )
  const cartonNumber = parseInt(
    currentBarcode.value.slice(BARCODE_TYPE_MAP[type].cartonNumber.start)
  )
  return {
    productId: id,
    barcode: currentBarcode.value,
    code,
    weight,
    date,
    product: name,
    carton_no: cartonNumber
  }
}

const findItem = (key, keyType) => {
  let keyField = keyType === BARCODE_TYPE_BSP ? 'code' : 'barcode'
  const product = productNames.value.find((el) => el[keyField] === key)

  if (!product) {
    return null
  }

  return { name: product.product, id: product.id, code: product.code }
}
const showWarning = (message, variant = 'warning') => {
  // Create the toast
  vm.$bvToast.toast(message, {
    toaster: 'b-toaster-top-right',
    title: `Product Scanning Warning`,
    variant: variant,
    solid: true
  })
}

const changeScanningAction = (action) => {
  if (scanningAction.value === action) return
  scanningAction.value = action
  location.value = ''
}

const clearScanned = () => {
  scannedItems.value = []
}

watch(
  () => scannedItems.value,
  (newValue) => {
    localStorage.setItem('scanned-items', JSON.stringify(newValue))
  },
  { deep: true }
)

onMounted(() => {
  const storageItems = localStorage.getItem('scanned-items')
  if (storageItems) {
    try {
      scannedItems.value = JSON.parse(storageItems)
    } catch (e) {
      localStorage.removeItem('scanned-items')
    }
  }
})

// ON CREATED
window.addEventListener('keydown', onBarcodeScanned)
if (productNames.value.length === 0 && loadingProducts.value !== 'names') {
  store.dispatch('fetchProductNames', { vm })
}

onUnmounted(() => {
  window.removeEventListener('keydown', onBarcodeScanned)
})
</script>
<template>
  <b-card-text class="text-left">
    <b-row no-gutters class="mb-3 align-items-center">
      <b-col cols="auto">
        <small class="d-flex align-items-center">
          <span class="text-muted font-weight-bold"
            >Total Scanned Products:</span
          >
          <span class="font-weight-bold ml-2">{{
            scannedItems.length > 0 ? scannedItems.length : '-'
          }}</span>
        </small>
        <small class="d-flex align-items-center">
          <span class="text-muted font-weight-bold">Total Weight:</span>
          <span class="font-weight-bold ml-2">{{
            scannedItems.length > 0 ? formatKilograms(totalWeight, 2) : '-'
          }}</span>
        </small>
      </b-col>

      <b-button-group class="mx-auto">
        <b-button
          :variant="
            scanningAction == 'INSTORAGE' ? 'primary' : 'outline-primary'
          "
          @click="changeScanningAction('INSTORAGE')"
          ><font-awesome-icon
            :icon="
              scanningAction == 'INSTORAGE'
                ? ['far', 'check-square']
                : ['far', 'square']
            "
            class="mr-2 font-weight-bold"
          />Add to Storage</b-button
        >
        <b-button
          :variant="
            scanningAction == 'DELIVERED' ? 'primary' : 'outline-primary'
          "
          @click="changeScanningAction('DELIVERED')"
          ><font-awesome-icon
            :icon="
              scanningAction == 'DELIVERED'
                ? ['far', 'check-square']
                : ['far', 'square']
            "
            class="mr-2"
          />Ship to Customer</b-button
        >
        <b-button
          :variant="scanningAction == 'EXPIRED' ? 'danger' : 'outline-danger'"
          @click="changeScanningAction('EXPIRED')"
          ><font-awesome-icon
            :icon="
              scanningAction == 'EXPIRED'
                ? ['far', 'check-square']
                : ['far', 'square']
            "
            class="mr-2"
          />Expire Product</b-button
        >
      </b-button-group>

      <ProductProcessButton
        :action="scanningAction"
        :scanned-items="scannedItems"
        @clearScannedItems="clearScanned"
      />
    </b-row>
    <b-row v-if="scanningAction !== 'EXPIRED'" no-gutters class="mb-3">
      <b-col
        cols="auto"
        class="d-flex align-items-center mr-4 fit-content"
        v-if="scanningAction !== 'EXPIRED'"
      >
        <vue-select
          id="scanner-location-select"
          label="name"
          placeholder="Select a location ..."
          :options="locationOptions"
          v-model="location"
          :reduce="(location) => location.name"
          :loading="loadingStock === 'destination'"
          class=""
          :style="{ minWidth: '250px' }"
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!location"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template #option="obj">
            {{ `${obj.code} - ${obj.name}` }}
          </template>
          <template #selected-option="{ name }">
            {{ name }}
          </template>
        </vue-select>
      </b-col>

      <b-col class="d-flex align-items-center mr-4">
        <label for="scanner-docket-input" class="mr-2 mb-0">Docket:</label>
        <b-form-input
          type="text"
          id="scanner-docket-input"
          v-model="docket"
          class=""
          :style="{ maxWidth: '150px' }"
        >
        </b-form-input>
      </b-col>
    </b-row>
    <b-table
      striped
      :fields="productFields"
      :items="scannedItems"
      small
      show-empty
      sort-icon-left
      emptyText="Start Scaning!"
      ><template #empty="scope">
        <div class="scanner-empty-table">
          <p>{{ scope.emptyText }}</p>
          <b-img :src="require('../assets/barcode-scanning-icon.png')"></b-img>
          <p>Items in this list appear automatically</p>
        </div>
      </template>
      <template #cell(weight)="{ item }">
        {{ formatKilograms(item.weight, 2) }}</template
      >
      <template #cell(actions)="{ index }">
        <b-link class="text-danger" @click="scannedItems.splice(index, 1)">
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="mr-2"
          />Remove</b-link
        >
      </template>
    </b-table>
    <b-row no-gutters class="mb-3" v-if="scannedItems.length > 15">
      <b-col cols="auto" class="mr-auto pr-2">
        <small class="d-flex align-items-center">
          <span class="text-muted font-weight-bold"
            >Total Scanned Products:</span
          >
          <span class="font-weight-bold ml-2">{{
            scannedItems.length > 0 ? scannedItems.length : '-'
          }}</span>
        </small>
        <small class="d-flex align-items-center">
          <span class="text-muted font-weight-bold">Total Weight:</span>
          <span class="font-weight-bold ml-2">{{
            scannedItems.length > 0 ? formatKilograms(totalWeight, 2) : '-'
          }}</span>
        </small>
      </b-col>
      <ProductProcessButton
        :action="scanningAction"
        :scannedItems="scannedItems"
        @clearScannedItems="clearScanned"
    /></b-row>
  </b-card-text>
</template>

<style scoped>
.scanner-empty-table {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
