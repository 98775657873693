<template>
  <div class="container-fluid">
    <div class="wrapper">
      <side-filter
        :filterList="filtersCust"
        @input="updateFilter"
        @clear="removeFilters()"
      />
      <div class="content-page">
        <b-row no-gutters class="mb-3 mt-2 align-items-center">
          <div class="page-title-box d-flex">
            <h4 class="page-title">Customers</h4>
          </div>
        </b-row>
        <b-row class="d-flex row no-gutters mt-1">
          <b-button
            @click="$refs.custModal.add(), (modalAction = 'add')"
            class="mr-2"
          >
            + New Customer
          </b-button>
          <div class="d-flex align-items-center">
            Total Customers:
            <span class="font-weight-bold ml-2">{{ totalCustomers }}</span>
          </div>
          <div class="ml-auto mr-2 d-flex align-items-center">
            Customers per page:
          </div>
          <b-select
            v-model="perPage"
            :options="perPageOptions"
            class="d-inline-block w-auto mr-4"
          ></b-select>
          <pagination-base
            :total="totalCustomers"
            :perPage="perPageCustomers"
            :current="pageNumberCust + 1"
            @updatePage="updatePageNr"
          />
        </b-row>

        <new-customer-modal :type="modalAction" ref="custModal" />
        <modify-customer-transport-modal
          ref="tranModal"
          :type="transModalAction"
        />

        <b-row no-gutters class="mt-3 d-flex text-left">
          <b-table
            id="customer-table"
            striped
            hover
            sort-icon-left
            no-local-sorting
            :sort-by="sortbyCust"
            :sort-desc="!ascCust"
            @sort-changed="sortChange"
            :fields="fields"
            :items="customers"
            :busy="loadingCustomers == 'list'"
            class="mt-3"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner small class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(company_name)="data">
              <b-row no-gutters>{{ data.item.company_name }}</b-row>
              <b-row
                no-gutters
                v-if="data.item.company.extra"
                class="flex-column"
                ><small
                  class="font-italic"
                  v-for="item in data.item.company.extra.alias"
                  :key="item.id"
                  ><b class="mr-2 text-muted">Alias:</b>{{ item }}</small
                ></b-row
              >
            </template>
            <template #cell(is_active)="data">
              <icon-check v-if="data.item.is_active == true"></icon-check>
              <icon-times v-else></icon-times>
            </template>
            <template #cell(contact)="data">
              <b-row
                v-if="data.item.company.contact"
                class="align-items-center"
              >
                <font-awesome-icon
                  icon="fas fa-user"
                  size="sm"
                  class="mr-2 text-muted"
                />{{
                  data.item.company.contact.contact
                    ? data.item.company.contact.contact
                    : '-'
                }}
              </b-row>
              <b-row
                v-if="data.item.company.contact"
                class="align-items-center"
              >
                <font-awesome-icon
                  icon="fas fa-phone"
                  size="sm"
                  class="mr-2 text-info"
                />
                {{
                  data.item.company.contact.phone
                    ? data.item.company.contact.phone
                    : '-'
                }}
              </b-row>
            </template>
            <template #cell(actions)="data">
              <b-row class="text-nowrap mb-2" no-gutters>
                <!-- Hide delete button due to object relationship protection -->
                <!-- <icon-delete
                  @click.native="deleteCust(data.item)"
                  class="mr-2"
                /> -->
                <icon-edit
                  class="mr-2"
                  @click.native="
                    ;(modalAction = 'edit'),
                      $refs.custModal.editCustomer(data.item)
                  "
                />
                <icon-transport
                  v-if="data.item.company.transport_schedule"
                  @click.native="data.toggleDetails"
                />
              </b-row>
            </template>
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  small
                  hover
                  responsive
                  class="text-left"
                  v-if="row.item.company.transport_schedule.length > 0"
                >
                  <b-thead>
                    <b-tr class="bg-light-blue">
                      <b-th
                        v-for="item in row.item.company.transport_schedule"
                        :key="item.id"
                        >{{ item.weekday | weekDay }}
                        <icon-edit
                          class="text-muted"
                          :position="'right'"
                          @click.native="
                            $refs.tranModal.edit(
                              row.item.company.transport_schedule[
                                item.weekday - 1
                              ]
                            ),
                              (transModalAction = 'edit')
                          "
                        />
                      </b-th>
                      <b-th>
                        <b-button
                          @click="
                            $refs.tranModal.add({
                              weekday:
                                row.item.company.transport_schedule.length + 1,
                              company: row.item.company
                            }),
                              (transModalAction = 'add')
                          "
                          variant="warning"
                          size="sm"
                          v-if="row.item.company.transport_schedule.length < 5"
                          ><font-awesome-icon
                            icon="fas fa-plus"
                            class="mr-2"
                          />Add Schedule</b-button
                        ></b-th
                      >
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr class="bg-white">
                      <b-td
                        v-for="item in row.item.company.transport_schedule"
                        :key="item.id"
                        >{{ freightCode(item.schedule.delivery_co_ids) }}</b-td
                      >
                    </b-tr>
                  </b-tbody></b-table-simple
                >
                <b-button
                  v-else
                  variant="warning"
                  size="sm"
                  @click="
                    $refs.tranModal.add({
                      weekday: 1,
                      company: row.item.company
                    }),
                      (transModalAction = 'add')
                  "
                  ><font-awesome-icon icon="fas fa-plus" class="mr-2" />Create
                  Schedule
                </b-button>
              </b-card>
            </template>
          </b-table>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NewCustomerModal from '../components/NewCustomerModal.vue'
import ModifyCustomerTransportModal from '../components/ModifyCustomerTransportModal.vue'
import PaginationBase from '../components/PaginationBase.vue'
// import IconDelete from '../components/IconDelete.vue'
import IconEdit from '../components/IconEdit.vue'
import IconCheck from '../components/IconCheck.vue'
import IconTimes from '../components/IconTimes.vue'
import IconTransport from '../components/IconTransport.vue'
import SideFilter from '../components/SideFilter.vue'

export default {
  components: {
    NewCustomerModal,
    ModifyCustomerTransportModal,
    PaginationBase,
    // IconDelete,
    IconEdit,
    IconCheck,
    IconTimes,
    SideFilter,
    IconTransport
  },
  name: 'CustomersView',
  data() {
    return {
      modalAction: 'add',
      transModalAction: 'add',
      fields: [
        { key: 'company_name', sortable: true },
        { key: 'code', sortable: true },
        { key: 'contact' },
        { key: 'type', sortable: true },
        { key: 'is_active', sortable: true, label: 'Active' },
        { key: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'customers',
      'totalCustomers',
      'loadingCustomers',
      'perPageCustomers',
      'pageNumberCust',
      'perPageOptions',
      'ascCust',
      'sortbyCust',
      'filtersCust',
      'freightNames',
      'freightCodeMap',
      'sortMapCust'
    ]),
    perPage: {
      get() {
        return this.perPageCustomers
      },
      set(value) {
        this.SET_PER_PAGE_CUSTOMERS(value)
        this.fetchCustomers({ vm: this })
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchCustomers',
      'deleteCustomer',
      'clearFiltersCust',
      'fetchFreightNames'
    ]),
    ...mapMutations([
      'SET_PAGE_NR_CUST',
      'SET_PER_PAGE_CUSTOMERS',
      'SET_ASC_CUST',
      'SET_SORT_BY_CUST',
      'SET_FILTER_CUST',
      'SET_ACTIVE',
      'SET_USING_VUETIFY'
    ]),
    sortChange: function ({ sortDesc, sortBy }) {
      this.SET_ASC_CUST(!sortDesc)
      this.SET_SORT_BY_CUST(sortBy)
      this.fetchCustomers({ vm: this })
    },
    freightCode: function (value) {
      let arr = value.split(',')
      const mapped = arr.map((el) => {
        if (el) {
          return this.freightCodeMap[el].code
        }
      }, this)
      return mapped.join('/')
    },
    deleteCust: function (info) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete ${info.company.name}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then(async (value) => {
          if (value) {
            let res = await this.deleteCustomer({ vm: this, payload: info })
            if (res === 'success') {
              this.fetchCustomers({ vm: this })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updatePageNr: async function (nr) {
      this.SET_PAGE_NR_CUST(nr - 1)
      let res = await this.fetchCustomers({ vm: this })
      if (res == 'success')
        this.$root.$emit('bv::refresh::table', 'customer-table')
    },
    updateFilter: async function (value) {
      await this.SET_FILTER_CUST(value)
      this.fetchCustomers({ vm: this })
    },
    removeFilters: async function () {
      await this.clearFiltersCust()
      this.fetchCustomers({ vm: this })
    }
  },
  created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE('customers')
    this.fetchCustomers({ vm: this })
    if (this.freightNames.length == 0) this.fetchFreightNames({ vm: this })
  }
}
</script>
