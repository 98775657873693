<template>
  <div>
    <b-card no-body>
      <b-row no-gutters class="my-2 mx-3 align-items-center">
        <h4 class="header-title w-700 mb-0 text-light-blue text-left">
          PAR Price
        </h4>
        <base-button
          size="sm"
          :icon="['fas', 'fa-plus']"
          variant="warning"
          class="ml-auto font-weight-bold"
          :loading="loadingSettings == 'par-price'"
          @click.native="$refs.priceModal.add(), (procPriceModalAction = 'add')"
          >Add PAR Price</base-button
        >
      </b-row>
      <base-table-grouped
        groupBy="category"
        :groups="parPriceTypes"
        :fields="tableFields"
        :items="parPrice"
        :busy="loadingSettings == 'par-price'"
        hover
        small
        thead-class="bg-light-blue"
        @addItem="$refs.priceModal.add(), (procPriceModalAction = 'add')"
        @editItem="$refs.priceModal.edit($event), (priceModalAction = 'edit')"
        @deleteItem="deleteItem($event)"
      >
        <template #cell(effective_date)="{ value }">
          {{ value | date }}
        </template>
        <template #cell(value)="{ value }">
          {{ value | decimals2 }}
        </template>
      </base-table-grouped>
    </b-card>
    <!-- Modals -->
    <new-par-price-modal ref="priceModal" :type="priceModalAction" />
  </div>
</template>

<script>
import NewParPriceModal from '../components/NewParPriceModal.vue'
import BaseTableGrouped from '../components/BaseTableGrouped.vue'
import BaseButton from '../components/BaseButton.vue'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ParPriceList',
  components: {
    NewParPriceModal,
    BaseTableGrouped,
    BaseButton
  },
  data() {
    return {
      tableFields: [
        {
          key: 'effective_date',
          label: 'Effective Date',
          class: ['pl-3', 'text-left']
        },
        {
          key: 'category',
          label: 'Category',
          class: 'text-left'
        },
        {
          key: 'value',
          label: 'Price ($)',
          class: 'text-left'
        },
        {
          key: 'actions',
          label: '',
          class: ['text-left', 'max-w-actions']
        }
      ],
      priceModalAction: 'add'
    }
  },
  computed: {
    ...mapGetters(['parPrice', 'loadingSettings', 'parPriceTypes'])
  },
  methods: {
    ...mapActions(['fetchParPrice', 'deleteParPrice']),
    deleteItem: function (item) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete ${item.category} PAR Price ($${item.value}) for ${item.effective_date}?`,
          {
            buttonSize: 'sm',
            size: 'sm',
            okTitle: 'Yes',
            cancelTitle: 'No'
          }
        )
        .then(async (value) => {
          if (value) {
            this.deletePrice(item)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deletePrice: async function (info) {
      try {
        let res = await this.deleteParPrice({ vm: this, payload: info })
        if (res === 'success') {
          this.fetchParPrice({ vm: this })
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  async mounted() {
    this.fetchParPrice({ vm: this })
  }
}
</script>
