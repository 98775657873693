<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps(['items', 'chipClass', 'active'])
const emit = defineEmits(['itemClick'])

const menuToggle = ref(false)
const selectedItem = ref(props.active)

const selectItem = (value) => {
  if (value === selectedItem.value) return
  selectedItem.value = value
  emit('itemClick', value)
}
</script>

<template>
  <v-menu v-model="menuToggle" offset-y>
    <template v-slot:activator="{ on }">
      <v-chip color="primary" v-on="on" outlined :class="chipClass">
        <font-awesome-icon :icon="['far', 'fa-eye']" class="mr-2" />
        <span>{{ selectedItem }}</span>
      </v-chip>
    </template>
    <v-list>
      <v-list-item v-for="item in items" :key="item" @click="selectItem(item)"
        ><v-list-item-subtitle>{{ item }}</v-list-item-subtitle></v-list-item
      >
    </v-list>
  </v-menu>
</template>
