<template>
  <b-row no-gutters class="mb-2">
    <label for="customer-select"
      >{{ label }}<span class="text-danger">*</span></label
    >
    <vue-select
      id="customer-select"
      placeholder="Select a customer ..."
      :get-option-label="
        ({ code, name }) => {
          return (code ? `${code} - ` : '') + name
        }
      "
      :multiple="multiple"
      :disabled="disabled"
      :options="customerNames"
      :reduce="(customer) => customer[attribute]"
      v-model="cust"
      class="w-100"
      ><template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="Array.isArray(cust) ? customer.length == 0 : !customer"
          v-bind="attributes"
          v-on="events"
        />
      </template>
      <template #option="{ name, code, contact, phone }">
        {{ code ? `${code} - ` : '' }}{{ name }}<br />
        <cite>{{ `${phone ? phone : ''} - ${contact ? contact : ''}` }}</cite>
      </template>
    </vue-select>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SelectCustomer',
  props: {
    label: {
      type: String,
      default: 'Customer'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    attribute: {
      type: String,
      default: 'id'
    },
    customer: [Array, Number]
  },
  computed: {
    ...mapGetters(['customerNames']),
    cust: {
      get() {
        return this.customer
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    ...mapActions(['fetchCustomerNames'])
  },
  async created() {
    if (this.customerNames.length == 0) {
      this.fetchCustomerNames({ vm: this })
    }
  }
}
</script>

<style></style>
