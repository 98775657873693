<template>
  <div id="login-card">
    <h4 class="mt-0">Sign In</h4>
    <p class="text-muted mb-5">
      Enter your username and password to access account.
    </p>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="loginError != ''"
      @dismissed="SET_LOGIN_ERROR('')"
    >
      <i class="fas fa-exclamation-triangle mr-2"></i>{{ loginError }}
    </b-alert>
    <b-form @submit="onSubmit" class="d-flex flex-column text-left">
      <b-form-group id="input-group-1">
        <label label-for="input-1">Username:</label>
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="text"
          placeholder="Enter your username"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2">
        <label label-for="input-2">Password:</label>
        <b-input-group class="">
          <template #append>
            <b-input-group-text @click="showPass()"
              ><strong :class="[showPassword ? 'text-forecast' : 'text-muted']"
                ><font-awesome-icon
                  class="icon-width"
                  :icon="
                    showPassword ? 'far fa-eye' : 'far fa-eye-slash'
                  " /></strong
            ></b-input-group-text>
          </template>
          <b-form-input
            id="input-2"
            v-bind:type="showPassword ? 'text' : 'password'"
            v-model="form.password"
            placeholder="Enter your password"
            required
          ></b-form-input>
        </b-input-group>
        <router-link to="resetpass" class="text-muted float-end"
          ><small>Forgot your password?</small></router-link
        >
      </b-form-group>

      <b-form-group id="input-group-4">
        <b-form-checkbox v-model="form.checked" value="me"
          >Remember me</b-form-checkbox
        >
      </b-form-group>

      <b-button
        type="submit"
        class="btn-forecast d-flex justify-content-center align-items-center"
        ><b-spinner v-if="authLoading" small></b-spinner
        ><font-awesome-icon
          v-if="!authLoading"
          icon="fas fa-sign-in-alt"
        /><span class="ml-2">Sign In</span></b-button
      >
    </b-form>
    <footer class="footer footer-alt">
      <!-- <p class="text-muted">
        Don't have an account?
        <router-link to="signup" class="text-muted"><b>Sign Up</b></router-link>
      </p> -->
    </footer>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { Auth, Hub } from 'aws-amplify'

export default {
  name: 'UserLogin',
  components: {},
  data() {
    return {
      form: {
        email: '',
        password: '',
        checked: []
      },
      showPassword: false,
      authLoading: false,
      myListener: null // Store listeners callback data, it is needed for destroying listener
    }
  },
  computed: mapGetters([
    'resetPass',
    'signUp',
    'resetCode',
    'resetSuccess',
    'signUpConfirm',
    'signUpSuccess',
    'loginError',
    'userGroups'
  ]),
  methods: {
    ...mapMutations([
      'SET_AUTH_USER',
      'SET_FORCE_PASS_USER',
      'SET_CONFIRM_USERNAME',
      'SET_CONFIRM_EMAIL',
      'SET_LOGIN_ERROR'
    ]),
    onSubmit(event) {
      event.preventDefault()
      this.login(this.form.email, this.form.password)
    },
    showPass: function () {
      this.showPassword = !this.showPassword
    },
    // Login function
    async login(username, password) {
      this.authLoading = true
      try {
        Auth.configure({
          authenticationFlowType: 'USER_PASSWORD_AUTH'
        })
        let user = await Auth.signIn(username, password)
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.resetPassForce(user)
        }
        this.authLoading = false
      } catch (err) {
        console.log(err)
        this.SET_LOGIN_ERROR(err.message)
        if (err.code == 'UserNotConfirmedException') {
          this.resendConfirmationCode(username)
        }
      }
      this.authLoading = false
    },
    resetPassForce: async function (user) {
      this.SET_CONFIRM_USERNAME(user.username)
      this.SET_CONFIRM_EMAIL('registered email')
      this.SET_FORCE_PASS_USER(user)
      this.$router.push({ path: '/resetpass/force' })
    },
    async resendConfirmationCode(username) {
      try {
        await Auth.resendSignUp(username)
        this.SET_CONFIRM_USERNAME(username)
        this.SET_CONFIRM_EMAIL('registered email')
        this.$router.push({ path: '/signup/confirm' })
      } catch (err) {
        console.log('error resending code: ', err)
        this.SET_LOGIN_ERROR(err.message)
      }
    },
    startListening: function () {
      Hub.listen(
        'auth',
        (this.myListener = ({ payload: { event, data } }) => {
          switch (event) {
            case 'signIn':
              this.signInUser(data)
              break
            case 'signOut':
              console.log('logout from HUB')
              break
            case 'customOAuthState':
            //this.setState({ customState: data })
          }
        })
      )
    },
    async signInUser(user) {
      if (user.attributes) {
        this.SET_AUTH_USER(user)
      } else {
        await this.addAttributes()
      }
      this.authLoading = false
      const stockManager = await this.userIsInGroup('stock-manager')
      if (stockManager) {
        this.$router.push({ path: '/product-stock?tab=4' })
        return
      }
      this.$router.push({ path: '/pig-info' })
    },
    async addAttributes() {
      try {
        const userWithAtt = await Auth.currentAuthenticatedUser()
        this.SET_AUTH_USER(userWithAtt)
      } catch (err) {
        console.log(err)
      }
    },
    async userIsInGroup(group) {
      if (!this.userGroups) return false

      const index = this.userGroups.findIndex((element) => element == group)
      if (index === -1) return false

      return true
    }
  },
  mounted() {
    this.startListening()
  },
  beforeDestroy() {
    this.SET_LOGIN_ERROR('')
    Hub.remove('auth', this.myListener)
  }
}
</script>
<style scoped></style>
