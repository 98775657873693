import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import '@/assets/css/base.css'

// AWS Amplify Imports
import Amplify from 'aws-amplify'
import awsConfig from './aws-exports'

// Vue-Select Imports
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Bootsrap Imports
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

// Vuetify
import vuetify from '@/plugins/vuetify'

// Font Awesome
import '@/plugins/fontAwesome'

// Components
Vue.component('vue-select', vSelect)

Amplify.configure(awsConfig)

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.use(BootstrapVue)

// importing the helper
import { formatDate } from './helpers/stringHelpers'
import interceptorsSetup from './helpers/jwt.interceptor'
interceptorsSetup()

// Filters
Vue.filter('date', function (value) {
  if (value) {
    return formatDate(value)
  }
  return '-'
})
Vue.filter('decimals2', function (value) {
  if (isNaN(value)) return 0
  return parseFloat(value).toFixed(2)
})
Vue.filter('decimals3', function (value) {
  if (isNaN(value)) return 0
  return parseFloat(value).toFixed(3)
})
Vue.filter('weekDay', function (value) {
  let map = new Object()
  map[1] = 'Monday'
  map[2] = 'Tuesday'
  map[3] = 'Wednesday'
  map[4] = 'Thursday'
  map[5] = 'Friday'

  return map[value]
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
