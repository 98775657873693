export const PORKER_CODE_MAX = 9
export const DEFAULT_PORKER_CODE = 1
export const DEFAULT_CARTON_CODE = 999

export const BARCODE_TYPE_BSP = 'BSP'
export const BARCODE_TYPE_GS1 = 'GS1'
export const BSP_BARCODE_LENGTH = 22
export const GS1_BARCODE_LENGTH = 44
export const ALLOWED_BARCODE_LEGTHS = [BSP_BARCODE_LENGTH, GS1_BARCODE_LENGTH]

export const BARCODE_TYPE_MAP = Object.freeze({
  [BARCODE_TYPE_BSP]: {
    code: {
      start: 0,
      end: 6
    },
    weight: {
      start: 6,
      end: 10
    },
    date: {
      start: 10,
      end: 16
    },
    cartonNumber: {
      start: 16
    }
  },
  [BARCODE_TYPE_GS1]: {
    code: {
      start: 2,
      end: 16
    },
    weight: {
      start: 20,
      end: 26
    },
    date: {
      start: 28,
      end: 34
    },
    cartonNumber: {
      start: 39
    }
  }
})
