<template>
  <div>
    <b-navbar
      toggleable="md"
      :class="isProdServer ? 'bd-navbar-prod' : 'bd-navbar-test'"
      class="bd-navbar bd-navbar-prod py-1"
    >
      <div class="container-fluid">
        <b-navbar-brand class="logo" href="#">
          <img
            v-if="isProdServer"
            src="../assets/logo.png"
            class="d-inline-block align-top"
            alt="forecast"
            height="45"
          />
          <img
            v-else
            src="../assets/test_logo_new.png"
            class="d-inline-block align-top"
            alt="Test Server"
            height="45"
          />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <top-bar />
          <b-navbar-nav class="ml-auto align-items-center">
            <b-nav-item-dropdown
              v-if="allowAccess(['admin'])"
              right
              class="mr-3"
              :toggle-class="active == 'system-settings' ? 'active' : ''"
            >
              <template #button-content
                >System <font-awesome-icon :icon="['fas', 'cog']" size="lg"
              /></template>
              <b-dropdown-item to="system-settings"
                >System Settings</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              right
              class="nav-item-user align-self-stretch d-flex align-items-center"
            >
              <template #button-content>
                <b-img
                  :src="require('../assets/Avatar-grey-white.png')"
                  v-bind="mainProps"
                  rounded="circle"
                  alt="Circle image"
                ></b-img>
                <!-- <span v-if="authLoading">
                  <b-spinner small class="m-2"></b-spinner>
                  Signing out ...
                </span> -->
                <span class="username">{{ prefUsername }}</span>
              </template>
              <b-dropdown-item @click="handleSignOutButtonClick">
                <font-awesome-icon icon="fa-sign-out-alt" class="mr-2" />Sign
                Out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Auth } from 'aws-amplify'

import TopBar from '../components/TopBar.vue'

export default {
  name: 'NavBar',
  components: {
    TopBar
  },
  async mounted() {},
  data() {
    return {
      mainProps: {
        width: 32,
        height: 32,
        class: 'm1 mr-2'
      },
      authLoading: false
    }
  },
  computed: {
    ...mapGetters(['authUser', 'active', 'userGroups', 'isProdServer']),
    prefUsername() {
      if (!this.authUser.attributes) {
        return this.authUser.username
      }
      if (this.authUser.attributes.preferred_username) {
        return this.authUser.attributes.preferred_username
      } else {
        return this.authUser.username
      }
    }
  },
  methods: {
    ...mapMutations(['SET_AUTH_USER']),
    allowAccess: function (groups) {
      if (!this.userGroups) return false

      let found = false
      groups.forEach((group) => {
        const index = this.userGroups.findIndex((element) => element == group)
        if (index !== -1) found = true
      })
      return found
    },
    async handleSignOutButtonClick() {
      try {
        this.authLoading = true
        await Auth.signOut()
        this.SET_AUTH_USER(null)
        this.authLoading = false
        this.$router.push({ path: '/login' })
      } catch (error) {
        this.authLoading = false
        console.log('error signing out: ', error)
      }
    }
  },
  beforeDestroy() {}
}
</script>

<style>
h4 {
  font-weight: 700;
}
</style>

<style scoped>
/* Wrapper */
.wrapper {
  display: flex;
  overflow: inherit;
  height: 100%;
  width: 100%;
}
@media (min-width: 992px) {
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 95%;
  }
}
.bd-navbar {
  box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
}
.bd-navbar-prod {
  background-color: #000000;
}
.bd-navbar-test {
  background-color: #00488f;
}
@media (min-width: 768px) {
  .nav-item-user {
    background-color: #464f5b;
    border: 1px solid #404954;
    min-height: 63px;
    margin: -8px 0;
    padding: 0 8px;
  }
}
.logo {
  width: 160px;
}
.username {
  color: rgba(255, 255, 255, 0.55);
}
.username:hover {
  color: rgba(255, 255, 255, 0.75);
}
</style>

<style lang="scss">
@import '../assets/css/variables';

/* Wrapper */
.wrapper {
  display: flex;
  overflow: inherit;
  height: 100%;
  width: 100%;
}
.content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 15px 5px 30px;
  position: relative;
  margin-right: -15px;
  width: 100%;
  padding-bottom: 60px;
  min-height: 100vh;
}

*:focus {
  outline: none;
}

.navbar-nav {
  text-align: left;
}
.dropdown-item {
  color: map-get($colors, text) !important;
  padding: 7px 20px !important;
}
.dropdown-item:active {
  color: map-get($colors, text-active) !important;
  background-color: map-get($colors, nav-link-hover) !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: map-get($colors, text-hover) !important;
  background-color: map-get($colors, nav-link-hover) !important;
}
.dropdown-menu {
  padding: 0.25rem 0 !important;
  font-size: 0.9rem !important;
}
</style>
