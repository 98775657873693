<template>
  <b-container fluid="lg">
    <b-row no-gutters class="my-2 align-items-center">
      <div class="page-title-box d-flex my-3 align-items-center">
        <font-awesome-icon :icon="['fas', 'cog']" size="lg" />
        <h4 class="ml-2 page-title">System Settigs</h4>
      </div>
    </b-row>

    <par-price-list class="mb-4" />
    <processing-price-list class="mb-4" />
  </b-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ParPriceList from '../components/ParPriceList.vue'
import ProcessingPriceList from '../components/ProcessingPriceList.vue'

export default {
  name: 'SystemSettings',
  components: {
    ParPriceList,
    ProcessingPriceList
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...mapMutations(['SET_ACTIVE', 'SET_USING_VUETIFY'])
  },
  created() {
    this.SET_USING_VUETIFY(false)
    this.SET_ACTIVE('system-settings')
  }
}
</script>
