<script setup>
import SideFilter from '@/components/SideFilter.vue'

import { useStore } from '@/utils/VuexHelpers'
import { computed, ref } from 'vue'
import {
  formatDateWithAddedDays,
  getDateByYearAndWeek
} from '@/utils/DateHelpers'

const store = useStore()
const filtersBone = computed(() => store.getters.filtersBone)

const boneSideFilter = ref(null)

const updateDatePicker = (day) => {
  store.commit('SET_FITLER_BONE', {
    field: 'boning_date',
    value: day
  })
}

const updateFilter = (value) => {
  if (value.field === 'weekDay') {
    const { value: filterValue } = value
    if (!filterValue) return

    const { week, year } = filtersBone.value
    const date = getDateByYearAndWeek(year.value, week.value, filterValue.nr)
    updateDatePicker(date)
  }
  store.commit('SET_FITLER_BONE', value)
  store.dispatch('fetchBoningList')
}

const updateWeekFilter = async (dateRange) => {
  store.commit('SET_FITLER_BONE', {
    field: 'week',
    value: dateRange.weekNr
  })
  const dayNr = filtersBone.value.weekDay.value.nr
  const date = formatDateWithAddedDays(dateRange.startDay, dayNr)
  updateDatePicker(date)
  if (filtersBone.value.year.value != dateRange.year) {
    store.commit('SET_FITLER_BONE', { field: 'year', value: dateRange.year })
  }
  store.dispatch('fetchBoningList')
}

const updateYearFilter = ({ year }) => {
  const cleanYear = parseInt(year.trim())
  if (cleanYear === filtersBone.value.year.value) return
  store.commit('SET_FITLER_BONE', { field: 'year', value: cleanYear })
  if (!filtersBone.value.week.value) return
  boneSideFilter.value.generateWeekDays(filtersBone.value.week.value, cleanYear)
}
</script>

<template>
  <SideFilter
    :filterList="filtersBone"
    :hideClear="true"
    @input="updateFilter"
    @inputWeek="updateWeekFilter"
    @inputYear="updateYearFilter"
    ref="boneSideFilter"
    class="mt-0"
  />
</template>
