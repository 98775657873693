<template>
  <b-modal id="charges-modal" :title="actionString" hide-footer>
    <b-form @submit="onSubmit" @reset="onReset">
      <label for="charges-date"
        >Effective Date:<span class="text-danger">*</span></label
      >
      <b-datepicker
        id="charges-date"
        v-model="charges.effective_date"
        class="mb-3"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }"
        locale="en-Au"
        start-weekday="1"
        required
      ></b-datepicker>

      <label for="charges-price-kg"
        >Price $/Kg:<span class="text-danger">*</span></label
      >
      <b-input
        class="mb-3"
        id="charges-price-kg"
        required
        v-model="charges.price_kg"
      ></b-input>

      <label for="charges-min"
        >Min Charge:<span class="text-danger">*</span></label
      >
      <b-input
        class="mb-3"
        id="charges-min"
        required
        v-model="charges.min_charge"
      ></b-input>

      <label for="charges-fuel"
        >Fuel Levy:<span class="text-danger">*</span></label
      >
      <b-input
        class="mb-3"
        id="charges-fuel"
        required
        v-model="charges.fuel_levy"
      ></b-input>

      <label for="charges-pallet"
        >Price $/Pallet:<span class="text-danger">*</span></label
      >
      <b-input
        class="mb-3"
        id="charges-pallet"
        required
        v-model="charges.price_pallet"
      ></b-input>

      <label for="charges-notes">Notes:</label>
      <b-input
        class="mb-3"
        id="charges-notes"
        v-model="charges.notes"
      ></b-input>

      <select-customer
        :customer="charge"
        :multiple="true"
        :disabled="allCheck"
        :attribute="'company_id'"
        :label="'Applied to Customer:'"
        @input="(val) => setValues(val)"
      />
      <b-form-checkbox
        class="mb-3"
        id="checkbox-all"
        v-model="allCheck"
        name="checkbox-all"
        :value="true"
        :unchecked-value="false"
      >
        Apply to All Customers
      </b-form-checkbox>

      <div class="font-italic mb-2">
        <span class="text-danger">* </span>required fields
      </div>
      <b-row no-gutters>
        <b-button type="submit" variant="primary" class="mr-2"
          ><b-spinner
            v-if="loadingSettings == `charges-${charges.freight_company_id}`"
            small
            class="mr-2"
          ></b-spinner>
          Submit</b-button
        >
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SelectCustomer from '../components/SelectCustomer.vue'
import { getCurrentDay } from '@/utils/DateHelpers'

export default {
  name: 'NewFreightChargesModal',
  components: {
    SelectCustomer
  },
  props: {
    type: { type: String, default: 'edit' }
  },
  data() {
    return {
      charges: {
        effective_date: '',
        price_kg: 0,
        min_charge: 0,
        fuel_levy: 0,
        price_pallet: 0,
        notes: '',
        applied_to: '',
        freight_company_id: ''
      },
      allCheck: false,
      tempInfo: null
    }
  },
  computed: {
    ...mapGetters(['freightNames', 'loadingSettings']),
    actionString() {
      if (this.type == 'add') return 'Create New Freight Charges'
      return 'Update Freight Charges'
    },
    charge() {
      if (this.charges.applied_to == '') return []
      if (this.charges.applied_to == 'Default') return []
      let c = this.charges.applied_to.split(',').map(Number)
      return c
    }
  },
  methods: {
    ...mapActions([
      'createFreightCharges',
      'updateFreightCharges',
      'fetchFreightCharges'
    ]),
    setValues: function (val) {
      this.charges.applied_to = val.join(',')
    },
    onSubmit: async function (event) {
      let res = 'error'
      event.preventDefault()
      if (this.type == 'add') {
        let copy = JSON.parse(JSON.stringify(this.charges))
        if (this.allCheck) {
          copy.applied_to = 'Default'
        }
        res = await this.createFreightCharges({
          payload: copy,
          vm: this
        })
      }
      if (this.type == 'edit') {
        let copy = JSON.parse(JSON.stringify(this.charges))
        if (this.allCheck) {
          copy.applied_to = 'Default'
        }
        res = await this.updateFreightCharges({
          payload: copy,
          vm: this
        })
      }
      if (res !== 'error') {
        this.fetchFreightCharges({
          vm: this,
          id: this.charges.freight_company_id
        })
        this.$bvModal.hide('charges-modal')
      }
    },
    onReset: function (event) {
      event.preventDefault()
      if (this.type == 'add') {
        this.resetValues()
        return
      }
      this.restoreValues()
    },
    add: function (payload) {
      this.charges.freight_company_id = payload.company_id
      this.resetValues()
      this.$bvModal.show('charges-modal')
    },
    edit: function (info) {
      this.tempInfo = info
      this.charges.id = this.tempInfo.id
      this.charges.freight_company_id = this.tempInfo.freight_company_id
      this.restoreValues()
      this.$bvModal.show('charges-modal')
    },
    // Reset charges form values
    resetValues: function () {
      if (Object.prototype.hasOwnProperty.call(this.charges, 'id'))
        delete this.charges.id
      this.charges.price_kg = 0
      this.charges.min_charge = 0
      this.charges.fuel_levy = 0
      this.charges.price_pallet = 0
      this.charges.notes = ''
      this.charges.applied_to = ''
      this.allCheck = false
      this.charges.effective_date = getCurrentDay()
    },
    // Restore charges form values to initial
    restoreValues: function () {
      if (this.tempInfo.applied_to.search('Default') != -1) {
        this.charges.applied_to = ''
        this.allCheck = true
      } else {
        this.charges.applied_to = this.tempInfo.applied_to
        this.allCheck = false
      }
      this.charges.price_kg = this.tempInfo.price_kg
      this.charges.min_charge = this.tempInfo.min_charge
      this.charges.fuel_levy = this.tempInfo.fuel_levy
      this.charges.price_pallet = this.tempInfo.price_pallet
      this.charges.notes = this.tempInfo.notes
      this.charges.applied_to = this.tempInfo.applied_to
      this.charges.effective_date = this.tempInfo.effective_date
    }
  }
}
</script>

<style></style>
