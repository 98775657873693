import { computed, getCurrentInstance } from 'vue'
import { getISOWeekday } from './DateHelpers'

export function useFreight(store) {
  const freightNames = computed(() => store.getters.freightNames)
  const freightCodeMap = computed(() => store.getters.freightCodeMap)
  const loadingSuppliers = computed(() => store.getters.loadingSuppliers)
  const customerNames = computed(() => store.getters.customerNames)
  const loadingCustomers = computed(() => store.getters.loadingCustomers)

  const vm = getCurrentInstance().proxy

  if (
    freightNames.value.length === 0 &&
    loadingSuppliers.value !== 'freightnames'
  ) {
    store.dispatch('fetchFreightNames', { vm })
  }

  if (customerNames.value.length === 0 && loadingCustomers.value !== 'names') {
    store.dispatch('fetchCustomerNames', { vm })
  }

  function getFreightText(freightIds, textType = 'code', separator = '/') {
    if (!freightIds) return '-'
    if (!freightCodeMap.value) return ''

    let arr = freightIds.split(',')
    const mapped = arr.map((el) => {
      return freightCodeMap.value[el] ? freightCodeMap.value[el][textType] : el
    })
    return mapped.join(separator)
  }

  function getTransport(customerId, date) {
    const selectedCustomer = customerNames.value.find(
      (name) => name.id === customerId
    )
    if (!selectedCustomer || !selectedCustomer.transport_schedule) {
      return { primary: null, subsequent: '' }
    }

    const dayOfWeek = getISOWeekday(date)
    const schedule = selectedCustomer.transport_schedule.find(
      (item) => item.weekday === dayOfWeek
    )
    if (!schedule) return { primary: null, subsequent: '' }

    const transports = schedule.schedule.delivery_co_ids.split(',')
    return { primary: transports[0], subsequent: transports.slice(1).join(',') }
  }

  return { getFreightText, getTransport }
}
